import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { AxiosError, AxiosResponse } from 'axios';
import FormCancelButton from 'components/common/FormCancelButton';
import OwnerForm from 'components/user/OwnerForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { DataWrapper, OwnerSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AddNewOwner: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<OwnerSchema>();
  const userAPI = new UserResource();

  const [errorMessage, setErrorMessage] = useState<OwnerSchema>();

  const addUser = useMutation<
    AxiosResponse<DataWrapper<Partial<OwnerSchema>>>,
    AxiosError,
    Partial<OwnerSchema>
  >((data) => userAPI.createOwner(data), {
    onSuccess: (res) => {
      toast({
        title: strings.owner_created,
        description: res?.data?.data?.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('userList');
      history.push(routes.users.list);
    },
    onError: (error) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  /*
   *set error message if Email is already taken.
   */
  const { setError } = methods;

  useEffect(() => {
    if (errorMessage?.email) {
      setError(
        'email',
        {
          type: 'manual',
          message: errorMessage?.email?.toString(),
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [errorMessage, setError]);

  const onSubmit = (data: Partial<OwnerSchema>) => {
    const mappedData = { ...data };

    if (!mappedData.deceased) {
      mappedData.deceased_since = '';
    }
    delete mappedData.deceased;

    if (!mappedData.address_forwarding) {
      mappedData.address_forwarding_country_code = '';
      mappedData.address_forwarding_title = '';
      mappedData.address_forwarding_reason = '';
      mappedData.address_forwarding_salutation = '';
      mappedData.address_forwarding_adittion = '';
      mappedData.address_forwarding_email = '';
      mappedData.address_forwarding_house_number = '';
      mappedData.address_forwarding_phone = '';
      mappedData.address_forwarding_prename = '';
      mappedData.address_forwarding_street = '';
      mappedData.address_forwarding_surname = '';
      mappedData.address_forwarding_town = '';
      mappedData.address_forwarding_zip_code = '';
    }

    addUser.mutate(mappedData);
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {strings.add_new_owner}
        </title>
      </Helmet>

      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.add_new_owner}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.add_new_owner}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {addUser.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.user_creation_failed}
                  </Alert>
                )}
                <OwnerForm />
                <ButtonGroup justifyContent="flex-end">
                  <FormCancelButton isLoading={addUser.isLoading} size="lg" />

                  <Button
                    colorScheme="primary"
                    type="submit"
                    size="lg"
                    isLoading={addUser.isLoading}>
                    {strings.add_new_owner}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddNewOwner;
