import Resource from 'api/resource';
import axios, { AxiosPromise, Canceler } from 'axios';
import {
  CareTakerSchema,
  Disable2FADTO,
  Enable2FADTO,
  Generate2FACodeResponse,
  LanguageTypesSchema,
  MutationResponse,
  OwnerSchema,
  TenantInfoSettings,
  UserSchema,
} from 'constants/schema';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

class UserResource extends Resource<UserSchema> {
  constructor() {
    super('user/users');
  }

  permissions(id: string | number) {
    return http({
      url: '/' + this.uri + '/' + id + '/permissions',
      method: 'get',
    });
  }

  /**
   * Upload profile
   */
  uploadProfile(data: FormData) {
    return http({
      url: `/user/profile-picture`,
      method: 'post',
      data: data,
    });
  }
  /**
   * Delete profile
   */

  deleteProfile() {
    return http({
      url: '/user/profile-picture',
      method: 'delete',
    });
  }

  loginLogList(query: Record<string, string>) {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: `/user/user-logs`,
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  generateQRCode(): AxiosPromise<Generate2FACodeResponse> {
    return http({
      url: '/user/generate-2fa',
      method: 'post',
    });
  }

  enable2FA(data: Enable2FADTO): AxiosPromise<MutationResponse> {
    return http({
      url: '/user/enable-2fa',
      method: 'post',
      data: data,
    });
  }

  async createOwner(data: Partial<OwnerSchema>) {
    return http({
      url: '/owner',
      method: 'post',
      data: data,
    });
  }

  async getOwner(id: string) {
    return http({
      url: `/owner/${id}`,
      method: 'get',
    });
  }

  async updateOwner(id: string, data: OwnerSchema) {
    return http({
      url: `/owner/${id}`,
      method: 'put',
      data: data,
    });
  }

  async createCareTaker(data: Partial<CareTakerSchema>) {
    return http({
      url: '/caretaker',
      method: 'post',
      data: data,
    });
  }

  async getCareTaker(id: string) {
    return http({
      url: `/caretaker/${id}`,
      method: 'get',
    });
  }

  async updateCareTaker(id: string, data: CareTakerSchema) {
    return http({
      url: `/caretaker/${id}`,
      method: 'put',
      data: data,
    });
  }

  sendEmailVerificaiton(email: string) {
    return http({
      url: '/user/resend-verify-email',
      method: 'post',
      data: { email },
    });
  }

  disable2FA(id: string) {
    return http({
      url: `/user/disable-2fa/${id}`,
      method: 'post',
    });
  }

  updateLanguage(language: LanguageTypesSchema) {
    return http({
      url: `user/set-my-language`,
      method: 'put',
      data: { language_code: language },
    });
  }

  disableOwn2FA(data: Disable2FADTO) {
    return http({
      url: '/user/disable-2fa',
      method: 'post',
      data: data,
    });
  }

  async getOmsOwnerOrCareTaker(id: number, type: string) {
    return http({
      url: `oms/${type}/${id}`,
      method: 'get',
    });
  }

  getUserSettings(): AxiosPromise<TenantInfoSettings> {
    return http({
      url: `user/settings`,
      method: 'get',
    });
  }
}

export { UserResource as default };
