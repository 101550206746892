import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { Select as ReactSelect } from 'chakra-react-select';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import CustomDateInput from 'components/common/CustomDateInput';
import { strings } from 'config/localization';
import { ReactSelectOption } from 'constants/interfaces';
import {
  AppartmentAddress,
  TicketOwnerSchema,
  Unit,
  UnitTypeEnum,
  User,
} from 'constants/schema';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiCalendar } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { renderFullName } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';

const PRIORITIES = ['low', 'medium', 'high', 'urgent'] as const;
const STATUSES = ['open', 'in-progress', 'done', 'closed'] as const;

export type FilterParamsFromForm = {
  title: string;
  priority: string;
  status: string | undefined;
  assigned_user_id: string;
  address_id: string;
  apartment_id: string;
  house_owner: string;
  order_by: string;
  order: 'asc' | 'desc';
  created_at_start: Date | null;
  created_at_end: Date | null;
};

type Props = {
  handleAdvancedSearch: (data: FilterParamsFromForm) => void;
  handleResetSearch?: () => void;
  isStatus?: boolean;
  filter: FilterParamsFromForm;
};

const initialValue: FilterParamsFromForm = {
  title: '',
  priority: '',
  status: '',
  assigned_user_id: '',
  address_id: '',
  apartment_id: '',
  house_owner: '',
  order_by: 'createdAt',
  order: 'desc',
  created_at_start: null,
  created_at_end: null,
};

const TicketSearch: React.FC<Props> = ({ isStatus = true, ...props }) => {
  const { handleAdvancedSearch, filter } = props;

  const [startDate, setStartDate] = useState<Date | null>(
    filter.created_at_start
  );
  const [endDate, setEndDate] = useState<Date | null>(filter.created_at_end);

  const [formValue, setFormValue] = useState<FilterParamsFromForm>(filter);

  let firstRender = useRef(true);

  const ticketAPI = new TicketResource();

  const apartmentList = useQuery<Unit[]>(
    [`apartmentList`, formValue.address_id],
    () =>
      ticketAPI
        .getApartmentList({
          address_id: formValue.address_id || '',
        })
        .then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const userList = useQuery<User[]>(
    'userList',
    () => ticketAPI.getUserList().then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const ownerList = useQuery<TicketOwnerSchema[]>(
    'ownerList',
    () => ticketAPI.getOwnerList({ limit: 2000 }).then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const apartmentAddressList = useQuery<AppartmentAddress[]>(
    ['apartmentAddressList'],
    () => ticketAPI.getApartmentAddressList().then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const apartmentAddressOptions =
    apartmentAddressList.data?.map((apartmentAddress) => {
      return {
        value: apartmentAddress.id,
        label: `${apartmentAddress.name}, ${apartmentAddress.addition}`,
      };
    }) ?? [];

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    formValue.created_at_start = startDate;
    formValue.created_at_end = endDate;

    handleAdvancedSearch(formValue);
  }, [endDate, formValue, handleAdvancedSearch, startDate]);

  const handleReset = () => {
    setFormValue(initialValue);
    setStartDate(null);
    setEndDate(null);
    handleAdvancedSearch(initialValue);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreatedDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleOwnerChange = (option: ReactSelectOption<number> | null) => {
    setFormValue((prevState) => ({
      ...prevState,
      house_owner: String(option?.value),
    }));
  };

  const handleAddressChange = (option: ReactSelectOption<number> | null) => {
    setFormValue((prevState) => ({
      ...prevState,
      apartment_id: '',
      address_id: String(option?.value),
    }));
  };

  const getFormattedRangeDate = (sDate: Date | null, eDate: Date | null) => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const completeOwnerList =
    ownerList.data?.map((owner) => ({
      value: owner.id,
      label: `${owner.last_name}, ${owner.first_name}`,
    })) ?? [];

  const selectedApartment = apartmentList.data?.find(
    (apartment) => apartment.id === Number(formValue.apartment_id)
  );

  const selectedOwnerList =
    selectedApartment?.info.owners.map((owner) => {
      return {
        value: owner.id,
        label: `${owner.last_name}, ${owner.first_name}`,
      };
    }) ?? [];

  const ownerOptions = formValue.apartment_id
    ? selectedOwnerList
    : completeOwnerList;

  return (
    <>
      <Stack sx={wrapperStyles}>
        <form>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="4"
            alignItems={['initial', 'initial', 'flex-end']}>
            <Grid
              gap="4"
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(4, 1fr)',
              ]}
              flex="1">
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.title}</FormLabel>
                  <Input
                    size="lg"
                    id="title"
                    type="text"
                    name="title"
                    value={formValue.title}
                    onChange={handleInputChange}
                    placeholder={strings.title}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.priority}</FormLabel>
                  <CustomChakraSelect
                    id="priority"
                    size="lg"
                    name="priority"
                    rounded="sm"
                    placeholder={strings.select_priority}
                    value={formValue.priority}
                    onChange={handleInputChange}>
                    {PRIORITIES.map((priority) => {
                      return (
                        <option key={priority} value={priority}>
                          {strings[priority].toUpperCase()}
                        </option>
                      );
                    })}
                  </CustomChakraSelect>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.assignee}</FormLabel>
                  <CustomChakraSelect
                    placeholder={strings.select_assignee}
                    id="assigned_user_id"
                    name="assigned_user_id"
                    size="lg"
                    value={formValue.assigned_user_id}
                    rounded="sm"
                    onChange={handleInputChange}>
                    {userList.data?.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {renderFullName(user.first_name, user.last_name)}
                        </option>
                      );
                    })}
                  </CustomChakraSelect>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.created_at}</FormLabel>
                  <InputGroup>
                    <InputRightElement
                      pointerEvents="none"
                      h="100%"
                      children={<BiCalendar />}
                      color="gray.500"
                    />
                    <ReactDatePicker
                      placeholderText={strings.created_at}
                      dateFormat="yyyy-MM-dd"
                      customInput={<CustomDateInput />}
                      onChange={handleCreatedDateChange}
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      value={getFormattedRangeDate(startDate, endDate)}
                      shouldCloseOnSelect={false}
                      autoComplete="off"
                      portalId="root-popover"
                    />
                  </InputGroup>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.object}</FormLabel>
                  <ReactSelect
                    isSearchable={true}
                    placeholder={strings.object_name}
                    options={apartmentAddressOptions}
                    menuPortalTarget={document.body}
                    value={
                      apartmentAddressOptions?.find(
                        (option) =>
                          option.value === Number(formValue.address_id)
                      ) ?? null
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={handleAddressChange}></ReactSelect>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{strings.unit}</FormLabel>
                  <CustomChakraSelect
                    placeholder={strings.select_unit}
                    id="apartment_id"
                    size="lg"
                    name="apartment_id"
                    rounded="sm"
                    value={formValue.apartment_id}
                    onChange={handleInputChange}>
                    {apartmentList.data?.map((apartment) => {
                      return (
                        <option key={apartment.id} value={apartment.id}>
                          {`${strings.getString(apartment.info.type)} ${
                            apartment.info.type === UnitTypeEnum.APARTMENT
                              ? apartment.info.stwe_number
                              : apartment.info.garage_number
                          }, ${apartment.name}`}
                        </option>
                      );
                    })}
                  </CustomChakraSelect>
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="owner">
                  <FormLabel>{strings.owner}</FormLabel>
                  <ReactSelect
                    name="house_owner"
                    isSearchable={true}
                    placeholder={`${strings.last_name}, ${strings.first_name}`}
                    options={ownerOptions}
                    menuPortalTarget={document.body}
                    value={
                      ownerOptions?.find(
                        (option) =>
                          option.value === Number(formValue.house_owner)
                      ) ?? null
                    }
                    className="react-select-container"
                    classNamePrefix="react-select"
                    onChange={handleOwnerChange}></ReactSelect>
                </FormControl>
              </GridItem>
              {isStatus && (
                <GridItem>
                  <FormControl>
                    <FormLabel>{strings.status}</FormLabel>
                    <CustomChakraSelect
                      id="status"
                      name="status"
                      rounded="sm"
                      value={formValue.status}
                      placeholder={strings.select_status}
                      onChange={handleInputChange}>
                      {STATUSES.map((status) => {
                        return (
                          <option key={status} value={status}>
                            {strings[status].toUpperCase()}
                          </option>
                        );
                      })}
                    </CustomChakraSelect>
                  </FormControl>
                </GridItem>
              )}
            </Grid>
            <Flex alignItems="end">
              <Button
                size="lg"
                colorScheme="primary"
                onClick={handleReset}
                variant="outline">
                {strings.reset_filter}
              </Button>
            </Flex>
          </Stack>
        </form>
      </Stack>
    </>
  );
};

export default TicketSearch;
