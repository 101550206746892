import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { reactSelectStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CompressedUploader from 'components/common/CompressedUploader';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { TICKET_ACCEPT_FILE_TYPE } from 'constants/common';
import {
  DataWrapper,
  ReactSelectOptionSchema,
  TicketInfoSchema,
  TicketPriority,
  Unit,
  User,
} from 'constants/schema';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import ReactSelect from 'react-select';
import {
  getTicketTitle,
  getUnitLabel,
  isCreatedFromOwnerOrCareTaker,
  renderFullName,
} from 'utils';

const PRIORITIES = ['low', 'medium', 'high', 'urgent'] as const;
const CreatedAtInput = forwardRef((props) => {
  return <Input {...props} />;
});
CreatedAtInput.displayName = 'CreatedAtInput';

interface Props {
  ticketData?: TicketInfoSchema;
  clearFileInputToggle?: boolean;
  isAllowedToEditAssignee: boolean;
}

export interface TicketFormValues {
  title: string;
  apartment_id: ReactSelectOptionSchema<number> | null;
  assigned_user_id?: ReactSelectOptionSchema<number> | null;
  house_owner: ReactSelectOptionSchema<number | null> | null;
  priority: TicketPriority | null;
  description: string;
  files: File[];
}

const TicketForm: React.FC<Props> = (props) => {
  const {
    ticketData,
    clearFileInputToggle = false,
    isAllowedToEditAssignee,
  } = props;
  const ticketApi = new TicketResource();

  const {
    register,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useFormContext<TicketFormValues>();

  const [unitId, setUnitId] = useState<number | null>(null);
  const [ownerList, setOwnerList] = useState<
    ReactSelectOptionSchema<number | null>[]
  >([]);

  const unitQuery = useQuery<DataWrapper<Unit[]>>(
    [`unitList`],
    () => ticketApi.getApartmentList().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const userQuery = useQuery<User[]>(
    'userList',
    () => ticketApi.getUserList().then((res) => res.data.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const isCreatedByOwnerOrCaretaker =
    ticketData && isCreatedFromOwnerOrCareTaker(ticketData);

  const deleteAttachment = useMutation((attachmentId: number) =>
    ticketApi.destroyAttachment(attachmentId)
  );

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachment.mutate(attachmentId);
  };

  const unitList = unitQuery?.data?.data;
  const userList = userQuery?.data;

  const unitOptions = useMemo(() => {
    return unitList?.map((unit) => ({
      label: getUnitLabel(unit.info),
      value: unit.id,
    }));
  }, [unitList]);

  const userOptions = userList?.map((user) => ({
    label: renderFullName(user.first_name, user.last_name) ?? user.name,
    value: user.id,
  }));

  const getOwnerList = useCallback(
    (unitId: number) => {
      const selectedUnits = unitList?.find((unit) => unit.id === unitId);

      const ownerOptions = selectedUnits?.info?.owners?.map((owner) => ({
        value: owner.id,
        label: renderFullName(owner.first_name, owner.last_name) ?? owner.name,
      }));

      return ownerOptions ?? [];
    },
    [unitList]
  );

  // setValue (default value) for object/apartment
  useEffect(() => {
    if (!ticketData) return;
    const defaultValue = unitOptions?.find(
      (item) => item.value === ticketData?.apartment_id
    );
    if (defaultValue?.value) setValue('apartment_id', defaultValue);
  }, [unitOptions, ticketData, setValue]);

  // setValue (default value) for assignee/user
  useEffect(() => {
    if (!ticketData) return;
    const defaultValue = userOptions?.find(
      (item) => item.value === ticketData?.assigned_user_id
    );
    if (defaultValue?.value) setValue('assigned_user_id', defaultValue);
  }, [userOptions, ticketData, setValue]);

  // setValue (default value) for house owner and owner list
  useEffect(() => {
    if (!ticketData) return;

    const defaultUnitId = ticketData.apartment_id;
    setUnitId(defaultUnitId);

    if (defaultUnitId) {
      const owners = getOwnerList(defaultUnitId);
      setOwnerList(owners);

      const defaultHouseOwner = owners?.find(
        (owner) => owner.value === ticketData.house_owner
      );

      if (defaultHouseOwner) {
        setValue('house_owner', defaultHouseOwner);
      }
    }
  }, [getOwnerList, ticketData, setValue]);

  // setValue (default value) for rest of the form inputs
  useEffect(() => {
    if (!ticketData) return;
    setValue('title', getTicketTitle(ticketData) ?? '');
    setValue('priority', ticketData.priority);
    setValue('description', ticketData.description);
  }, [ticketData, setValue]);

  if (unitQuery.isLoading && userQuery.isLoading) return <CenterSpinner />;

  const handleUnitChange = (unit: ReactSelectOptionSchema<number>) => {
    const newUnitId = unit.value;
    if (unitId === newUnitId) return;

    setOwnerList(getOwnerList(newUnitId));
    setUnitId(newUnitId);
    setValue('house_owner', null);
  };

  const isObjectFieldEmpty = !getValues('apartment_id');

  return (
    <Stack direction="column" spacing="4">
      <Grid
        gap="4"
        templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
        w="100%">
        <GridItem>
          <FormControl
            isInvalid={!!errors?.title}
            isRequired
            isReadOnly={isCreatedByOwnerOrCaretaker}>
            <FormLabel>{strings.title}</FormLabel>
            <Input
              id="title"
              size="lg"
              type="text"
              placeholder={strings.enter_title}
              {...register('title', {
                validate: (value) => {
                  if (!value) return strings.title_required;
                  if (value.trim() === '') return strings.title_required;
                },
              })}
            />
            <FormErrorMessage>
              <>{errors?.title && errors?.title?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <br />
        <GridItem>
          <FormControl isInvalid={!!errors?.apartment_id}>
            <FormLabel>{strings.unit}</FormLabel>
            <Controller
              control={control}
              name="apartment_id"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  id="apartment_id"
                  placeholder={strings.select_unit}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                      handleUnitChange(selectedOption);
                    }
                  }}
                  options={unitOptions}
                  styles={reactSelectStyles}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.apartment_id &&
                (errors.apartment_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.house_owner}>
            <FormLabel>{strings.house_owner}</FormLabel>
            <Controller
              control={control}
              name="house_owner"
              render={({ field }) => (
                <ReactSelect
                  isDisabled={isObjectFieldEmpty}
                  {...field}
                  placeholder={strings.first_select_the_unit_then_the_owner}
                  options={ownerList}
                  styles={reactSelectStyles}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.house_owner &&
                (errors.house_owner as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl isInvalid={!!errors?.priority}>
            <FormLabel>{strings.priority_label}</FormLabel>
            <CustomChakraSelect
              size="lg"
              id="priority"
              placeholder={strings.select_priority}
              {...register('priority')}>
              {PRIORITIES.map((priority) => (
                <option key={priority} value={priority}>
                  {strings[priority]}
                </option>
              ))}
            </CustomChakraSelect>
            <FormErrorMessage>
              <>{errors?.priority && errors?.priority?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl isInvalid={!!errors?.assigned_user_id}>
            <FormLabel>{strings.assignee}</FormLabel>
            <Controller
              control={control}
              name="assigned_user_id"
              render={({ field }) => (
                <ReactSelect
                  isDisabled={!isAllowedToEditAssignee}
                  {...field}
                  id="assigned_user_id"
                  placeholder={strings.select_assignee}
                  options={userOptions}
                  styles={reactSelectStyles}
                />
              )}
            />
            <FormErrorMessage>
              {errors?.assigned_user_id &&
                (errors.assigned_user_id as FieldError)?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>
      <Grid gap="4">
        <GridItem>
          <FormControl
            isInvalid={!!errors?.description}
            isReadOnly={isCreatedByOwnerOrCaretaker}>
            <FormLabel>{strings.descriptions}</FormLabel>
            <Textarea
              id="description"
              placeholder={strings.enter_description}
              rows={4}
              {...register('description')}
            />
            <FormErrorMessage>
              <>{errors?.description && errors?.description?.message}</>
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem>
          <FormControl>
            <FormLabel>{strings.upload_files}</FormLabel>
            <CompressedUploader
              uploadedFiles={ticketData?.ticket_attachments}
              deleteAttachment={(attachmentId: number) =>
                handleDeleteAttachment(attachmentId)
              }
              acceptFileType={TICKET_ACCEPT_FILE_TYPE}
              clearFileInputToggle={clearFileInputToggle}
              fileKey="files"
            />
          </FormControl>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default TicketForm;
