import Resource from 'api/resource';
import { Owner } from 'constants/schema';
import http from 'utils/http';

class OMSOwnersResource extends Resource<Owner> {
  constructor() {
    super(`oms/owners`);
  }

  downloadContactSharing() {
    return http({
      url: '/oms/owners/download-csv',
      method: 'get',
      responseType: 'blob',
    });
  }

  downloadOwnersListWithObject() {
    return http({
      url: '/oms/owners/apartment-list',
      method: 'get',
      responseType: 'blob',
    });
  }
}

export { OMSOwnersResource as default };
