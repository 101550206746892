import { Center, Image, Stack } from '@chakra-ui/react';
import { useTenantContext } from 'context/TenantContext';
import React from 'react';

const LogoInformation: React.FC = () => {
  const { tenant } = useTenantContext();
  return (
    <Stack spacing="4">
      <Center>
        {tenant.logoTransparent ? (
          <Image src={tenant.logoTransparent} alt="logo" w="52" />
        ) : null}
      </Center>
    </Stack>
  );
};

export default LogoInformation;
