import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import PermissionResources from 'api/permissions';
import RolesResource from 'api/roles';
import UsersResource from 'api/user';
import { CenterSpinner } from 'components/common/CenterSpinner';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { rolesData } from 'constants/common';
import { RoleId, UserSchema } from 'constants/schema';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { capitalizeFirstLetter } from 'utils';
import { validEmail } from 'utils/validate';
import TwoFaSwitch from './TwoFaSwitch';

interface Props {
  userData?: UserSchema;
  role?: { id: RoleId; name: string };
}
interface Permission {
  id: number;
  name: string;
  guard_name: string;
}
interface PermissionGroupRole {
  id: number;
  name: string;
  permissions: Permission[];
}

const UserForm: React.FC<Props> = (props) => {
  const { userData, role } = props;
  const isInitialMount = useRef(true);

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext<UserSchema>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [roleId, setRoleId] = useState(
    userData?.role_id ? userData?.role_id : 0
  );
  const [oldRoleId, setOldRoleId] = useState(
    userData?.role_id ? userData?.role_id : 0
  );
  const rolesApi = useMemo(() => new RolesResource(), []);
  const usersApi = new UsersResource();
  const permissionGroupsApi = new PermissionResources();

  const { data: permissionGroup, isLoading: permissionLoading } = useQuery(
    'permission-groups-for-user-edit',
    () => permissionGroupsApi.list().then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const setValueForPermission = useCallback(
    (permissionList: Permission[]) => {
      let permissionObj: Record<string, boolean> = {};
      const permissionGroupList = permissionGroup;

      // Create a obj comprising of all the permissions with its value set to false initially
      permissionGroupList?.forEach((role: PermissionGroupRole) => {
        role.permissions.forEach((per: Permission) => {
          permissionObj = { ...permissionObj, [per.id.toString()]: false };
        });
      });
      permissionList.forEach((res) => {
        permissionObj[res.id.toString()] = true; // the value of available permissions are set to true
      });
      const permissions = Object.keys(permissionObj).filter(
        (k) => permissionObj[k]
      );
      setValue('permissions', permissions);
    },
    [permissionGroup, setValue]
  );

  useEffect(() => {
    const getRoles = async (id: number) => {
      const response = await rolesApi.getPermissions(id);
      const permissionLists: Permission[] = response?.data?.data;
      setValueForPermission(permissionLists);
    };

    if (role) {
      setRoleId(role.id);
      setValue('role_id', role.id);
      getRoles(role.id);
    }
  }, [role, rolesApi, setValue, setValueForPermission]);

  useEffect(() => {
    const fetchUserPermissionRoles = async () => {
      if (userData?.id) {
        const response = await usersApi.permissions(userData?.id);
        const viaRole = response?.data?.data?.via_role;
        const directRole = response?.data?.data?.direct;
        const permissionLists: Permission[] = [...viaRole, ...directRole];
        setValueForPermission(permissionLists);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (permissionGroup && permissionGroup?.length > 0 && userData?.id) {
      fetchUserPermissionRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionGroup, userData?.id]);

  const handleRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const roleId = Number(e.target.value);
    if (userData?.role_id && oldRoleId != roleId) {
      setRoleId(roleId);
      setModalOpen(true);
    } else getPermissions(roleId);
    clearErrors('role_id');
  };

  const getPermissions = async (roleId: number) => {
    if (roleId) {
      setRoleId(roleId);
      setOldRoleId(roleId);
      const response = await rolesApi.getPermissions(roleId);
      const permissionLists: Permission[] = response?.data?.data;
      setValueForPermission(permissionLists);
    }
  };

  const onModalClose = () => {
    setRoleId(oldRoleId);
    setModalOpen(false);
  };

  const handleConfirmRole = () => {
    getPermissions(roleId);
    setModalOpen(false);
  };

  if (permissionLoading) return <CenterSpinner />;

  const isSetToEdit = !!userData?.id;

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          {isSetToEdit && (
            <>
              <GridItem mb="14">
                <FormControl isInvalid={!!errors?.role_id} isRequired>
                  <FormLabel>{strings.role}</FormLabel>
                  <CustomChakraSelect
                    placeholder={userData?.role_id ? '' : strings.select_role}
                    id="role"
                    rounded="sm"
                    size="lg"
                    value={roleId}
                    {...register('role_id', {
                      required: strings.required_role,
                    })}
                    onChange={handleRole}>
                    {rolesData?.map((role) => (
                      <option key={role.id} value={role.id}>
                        {capitalizeFirstLetter(strings.getString(role.name))}
                      </option>
                    ))}
                  </CustomChakraSelect>
                  <FormErrorMessage>
                    {errors?.role_id && errors?.role_id?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>

              <GridItem justifySelf="right" mb="14">
                <Stack direction={['column', 'row']}>
                  <FormLabel>{strings.FA_status}</FormLabel>
                  <TwoFaSwitch
                    has2FaEnabled={userData.has_2fa_enabled}
                    userId={userData?.id}
                  />
                </Stack>
              </GridItem>
            </>
          )}
          <GridItem>
            <FormControl isInvalid={!!errors?.salutation} isRequired>
              <FormLabel>{strings.salutation}</FormLabel>
              <CustomChakraSelect
                placeholder={strings.select_salutation}
                size="lg"
                defaultValue={userData?.salutation}
                isRequired={false}
                {...register('salutation', {
                  required: strings.salutation_is_required,
                })}>
                <option value="mr">{strings.mr}</option>
                <option value="ms">{strings.ms}</option>
              </CustomChakraSelect>
              <FormErrorMessage>
                {errors?.salutation && errors?.salutation?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <br />
          <GridItem>
            <FormControl isInvalid={!!errors?.last_name} isRequired>
              <FormLabel>{strings.last_name}</FormLabel>
              <Input
                type="text"
                size="lg"
                defaultValue={userData?.last_name}
                placeholder={strings.last_name}
                {...register('last_name', {
                  required: strings.required_last_name,
                })}
              />
              <FormErrorMessage>
                {errors?.last_name && errors?.last_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.first_name} isRequired>
              <FormLabel>{strings.first_name}</FormLabel>
              <Input
                {...register('first_name', {
                  required: strings.required_first_name,
                })}
                size="lg"
                type="text"
                defaultValue={userData?.first_name}
                placeholder={strings.first_name}
              />
              <FormErrorMessage>
                {errors?.first_name && errors?.first_name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem mt="14">
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>{strings.email_address}</FormLabel>
              <Input
                defaultValue={userData?.email}
                size="lg"
                placeholder={strings.email_address}
                {...register('email', {
                  required: strings.required_email,
                  validate: (value) =>
                    validEmail(value) || strings.valid_email_address,
                })}
                isReadOnly={isSetToEdit}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Modal isOpen={isModalOpen} isCentered onClose={onModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.role}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.change_confirm}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button colorScheme="primary" onClick={handleConfirmRole}>
                  {strings.yes}
                </Button>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={onModalClose}>
                  {strings.no}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </form>
  );
};

export default UserForm;
