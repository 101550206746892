import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/menu';
import { Box, Flex } from '@chakra-ui/react';
import UserResource from 'api/user';
import { AxiosError, AxiosResponse } from 'axios';
import { strings } from 'config/localization';
import { LanguageTypesSchema } from 'constants/schema';
import { BiChevronDown } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const LanguageMenu = () => {
  const currentLanguage = strings.getLanguage();
  const isLoggedIn = useSelector(
    (state: RootState) => state?.data?.auth?.isLoggedIn
  );

  const userAPI = new UserResource();

  const updateUser = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    LanguageTypesSchema
  >((data) => userAPI.updateLanguage(data), {
    onSettled() {
      window.location.reload();
    },
  });

  const handleLanguage = (Language: LanguageTypesSchema) => {
    localStorage.setItem('language', Language);
    if (isLoggedIn) {
      updateUser.mutate(Language);
    } else {
      window.location.reload();
    }
  };

  return (
    <Menu>
      <MenuButton
        p={2}
        transition="all 0.2s"
        _hover={{ borderBottom: 'gray.300' }}
        _expanded={{ borderBottom: 'gray.400' }}>
        <Flex gap={2} alignItems="center">
          <Box>
            {currentLanguage === 'en'
              ? strings.english
              : currentLanguage === 'fr'
              ? strings.french
              : currentLanguage === 'it'
              ? strings.italian
              : strings.german}
          </Box>
          <Box>
            <BiChevronDown />
          </Box>
        </Flex>
      </MenuButton>
      <MenuList rootProps={{ style: { transform: 'scale(0)' } }}>
        <MenuItem onClick={() => handleLanguage('de')}>
          {strings.german}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => handleLanguage('en')}>
          {strings.english}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => handleLanguage('fr')}>
          {strings.french}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => handleLanguage('it')}>
          {strings.italian}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default LanguageMenu;
