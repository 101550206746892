import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import ObjektListItems from 'components/oms/ObjektListItems';
import ObjektSearch from 'components/oms/ObjektSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { Address, DataWrapper } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import useCustomToast from 'hooks/useCustomToast';
import { useDebounce } from 'hooks/useDebounce';
import { useFilter } from 'hooks/useFilter';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

export type ObjektListFilterParams = {
  currentPage: number;
  pageSize: number;
  keyword: string;
  address: string;
};

const ObjektList: React.FC = () => {
  const { search } = useLocation();

  const checkCreateObjectPermission = useCheckPermission(
    [PermissionRequest['manage:oms']],
    routes.oms.objects.add
  );

  const { searchValues, updateUrl } = useFilter();

  const objectAPI = new OMSObjectsResource();

  const [filterParams, setFilterParams] = useState<ObjektListFilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    address: searchValues.address ?? '',
  });

  const { keyword, address, ...restFilters } = filterParams;
  const debouncedKeyword = useDebounce(keyword);
  const debouncedAddress = useDebounce(address);

  const { showErrorToast } = useCustomToast();

  const objectList = useQuery<DataWrapper<Address[]>>(
    ['objectList', debouncedKeyword, debouncedAddress, restFilters],
    async () => {
      const queryParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        keyword: debouncedKeyword || null,
        address: debouncedAddress || null,
      };

      const res = await objectAPI.list(queryParams);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: () => showErrorToast(),
    }
  );

  useEffect(() => {
    updateUrl(filterParams);
  }, [updateUrl, filterParams]);

  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {strings.manage_objects}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.units}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.manage_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack>
          <Flex justify="space-between">
            <Heading size="lg">{strings.manage_objects}</Heading>
            <AccessControl
              allowedPermissions={[PermissionRequest['manage:oms']]}>
              <Button
                p={2}
                transition="all 0.2s"
                h="12"
                pl="6"
                pr="6"
                borderRadius="sm"
                bg="primary.400"
                onClick={checkCreateObjectPermission}
                _hover={{ borderBottom: 'primary.300' }}
                _expanded={{ borderBottom: 'primary.400' }}>
                <Box color="white">{strings.add_new_object}</Box>
              </Button>
            </AccessControl>
          </Flex>
        </Stack>
        <ObjektSearch
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Flex>
              <Heading
                as="h3"
                p="16px"
                fontSize="18px"
                fontWeight="500"
                lineHeight="21px">
                {strings.objects}
              </Heading>
            </Flex>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.object}</Th>
                  <Th>{strings.object_addition}</Th>
                  <Th>{strings.address}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {objectList.data?.data.map((data) => (
                  <ObjektListItems key={data.id} data={data} search={search} />
                ))}
                {objectList.isLoading && (
                  <TableSkeletonLoader rows={6} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={objectList}
        />
      </Stack>
    </>
  );
};

export default ObjektList;
