import Resource from 'api/resource';
import { PermissionGroupSchema } from 'constants/schema';

class PermissionResources extends Resource<PermissionGroupSchema> {
  constructor() {
    super('user/permission-groups');
  }
}

export { PermissionResources as default };
