import { useEffect, useState } from 'react';

const useNetworkStatus = () => {
  const [online, setOnline] = useState<boolean>(navigator.onLine);
  const [connectionChange, setConnectionChange] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
      !navigator.onLine && setConnectionChange(true);
    };
    const handleOffline = () => {
      setOnline(false);
      navigator.onLine && setConnectionChange(true);
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return [online, connectionChange];
};

export default useNetworkStatus;
