import routes from 'constants/routes';
import Dashboard from 'pages/dashboard';
import { Route, Router as BrowserRouter, Switch } from 'react-router-dom';
import history from 'utils/history';
import AuthenticateRoute from './AuthenticateRoute';
import PublicRoute from './PublicRoute';

// Top level application router.
const Router = () => (
  <BrowserRouter history={history}>
    <Switch>
      <Route
        path={[
          routes.auth.login,
          routes.auth.forgotPassword,
          routes.auth.resetPassword,
          routes.auth.setPassword,
          routes.auth.verifyEmail,
          routes.auth.api,
        ]}>
        <PublicRoute />
      </Route>
      <AuthenticateRoute path={routes.dashboard} component={Dashboard} />
    </Switch>
  </BrowserRouter>
);

export default Router;
