import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';
import { AxiosError, AxiosResponse } from 'axios';
import { CenterSpinner } from 'components/common/CenterSpinner';
import EditOwnerDocumentForm, {
  EditOwnerDocumentFormData,
} from 'components/owner_document/EditOwnerDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { DataWrapper, Document } from 'constants/schema';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';

interface EditOwnersDocumentParams {
  id: string;
}

interface EditOwnerDocumentsDTO {
  name: string;
  tag: string;
  document_year: string;
  apartment_owner_ids: number[];
  owner_ids: number[];
}

const EditOwnersDocument: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<EditOwnerDocumentFormData>();
  const ownerDocumentAPI = new OwnerDocumentResource();
  const [errorMessage, setErrorMessage] = useState('');

  const { id } = useParams<EditOwnersDocumentParams>();

  const ownerDocumentQuery = useQuery<DataWrapper<Document>>(
    [`owner-document${id}-get`, id],
    () => ownerDocumentAPI.get(id).then((res) => res.data),
    {
      enabled: !!id,
    }
  );

  const documentData = ownerDocumentQuery?.data?.data;

  const updateOwnerDocument = useMutation<
    AxiosResponse<EditOwnerDocumentsDTO>,
    AxiosError,
    EditOwnerDocumentsDTO
  >((data) => ownerDocumentAPI.update(id, data), {
    onSuccess: () => {
      toast({
        title: strings.general_document_updated,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries(`owner-document${id}-get`);
      history.push(routes.dms.object.list);
    },
    onError: () => {
      setErrorMessage(strings.general_document_udpate_failed);
    },
  });

  const onSubmit = (data: EditOwnerDocumentFormData) => {
    if (data.postData.apartmentIds.length === 0) {
      methods.setError('postData.apartmentIds', {
        message: strings.select_object_validation,
      });
      return;
    }

    if (data.postData.ownerShipIds.length === 0) {
      methods.setError('postData.ownerShipIds', {
        message: strings.select_owners_validation,
      });
      return;
    }

    const editOwnerDocumentsDTO: EditOwnerDocumentsDTO = {
      name: documentData?.name ?? '',
      tag: data.tag,
      document_year: data.year,
      apartment_owner_ids: data.postData.ownerShipIds,
      owner_ids: data.postData.ownerIds,
    };

    updateOwnerDocument.mutate(editOwnerDocumentsDTO);
  };

  if (ownerDocumentQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ownerDocumentQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.general_document_not_found}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {strings.document} | {strings.document_units}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.object.list}>
              {strings.document}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.object.list}>
              {strings.document_units}
            </BreadcrumbLink>
          </BreadcrumbItem>

          {documentData && (
            <BreadcrumbItem>
              <BreadcrumbLink
                as={RouterLink}
                to={routes.dms.object.objectDocumentEdit.replace(':id', id)}>
                {documentData.name}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
        </Breadcrumb>

        {documentData && (
          <Flex justify="space-between">
            <Heading size="lg" textTransform="capitalize">
              {documentData.name}
            </Heading>
          </Flex>
        )}

        <Stack>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing="10"
                justifyContent="space-between">
                {updateOwnerDocument.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {errorMessage}
                  </Alert>
                )}

                {documentData && (
                  <EditOwnerDocumentForm
                    ownerDocumentData={documentData}
                    isLoading={updateOwnerDocument.isLoading}
                  />
                )}
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditOwnersDocument;
