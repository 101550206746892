import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import RolesResource from 'api/roles';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { UserSchema } from 'constants/schema';
import React from 'react';
import { useQuery } from 'react-query';
import RoleUsersListItem from './RoleUsersListItem';

export interface RoleUsersProps {
  roleId: number;
}

const RoleUsers: React.FC<RoleUsersProps> = (props) => {
  const { roleId } = props;

  const rolesApi = new RolesResource();

  const { data: roleUserList } = useQuery([`roleUsers${roleId}`, roleId], () =>
    rolesApi.listUsers(roleId).then((res) => res.data.data)
  );

  return (
    <>
      <Stack sx={wrapperStyles}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>{strings.sn}</Th>
                <Th>{strings.name}</Th>
                <Th>{strings.email}</Th>
                <Th isNumeric>{strings.phone}</Th>
                <Th isNumeric></Th>
              </Tr>
            </Thead>
            <Tbody>
              {roleUserList?.map((user: UserSchema, index: number) => (
                <RoleUsersListItem
                  userData={user}
                  key={user.id}
                  index={index + 1}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default RoleUsers;
