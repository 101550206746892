import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

const ReactDatePickerRoot = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange>
) => <ReactDatePicker portalId="root-popover" {...props} />;

export default ReactDatePickerRoot;
