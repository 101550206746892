import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { Document } from 'constants/schema';
import FileSaver from 'file-saver';
import useCustomToast from 'hooks/useCustomToast';
import React from 'react';
import { BiCloudDownload, BiEdit, BiTrash } from 'react-icons/bi';
import { useMutation } from 'react-query';

import { Link as RouterLink, useLocation } from 'react-router-dom';
import { formatFileNameDMS } from 'utils';

interface Props {
  document: Document;
  onDeleteClick: (item: Document) => void;
}

const DocumentListItem: React.FC<Props> = (props) => {
  const { document, onDeleteClick } = props;

  const location = useLocation();

  const isCurrLocationFromObjects = location.pathname.includes(
    routes.dms.object.objectDocuments.replace(':id', '')
  );
  const dmsAPI = new OwnerDocumentResource();

  const { showErrorToast } = useCustomToast();

  const downloadDocument = useMutation(dmsAPI.downloadDMSdocuments, {
    onSuccess: (data) => {
      FileSaver.saveAs(data.data, formatFileNameDMS(document));
    },
    onError: () => {
      showErrorToast();
    },
  });

  const onDownloadClick = (file_path: string) =>
    downloadDocument.mutate(file_path);

  return (
    <Tr key={document.id}>
      <Td>{document.name}</Td>
      <Td>{strings[document.tag]}</Td>
      <Td isNumeric>{document.document_year}</Td>
      <Td isNumeric>
        <RouterLink
          to={
            isCurrLocationFromObjects
              ? routes.dms.object.objectDocumentEdit.replace(
                  ':id',
                  String(document.id)
                )
              : routes.dms.owner.ownerDocumentEdit.replace(
                  ':id',
                  String(document.id)
                )
          }>
          <Tooltip hasArrow label={strings.edit_document}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_user}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>

        {document?.file_path && (
          <Tooltip hasArrow label={strings.download}>
            <IconButton
              icon={<BiCloudDownload size="18" />}
              variant="link"
              aria-label={strings.download}
              minW="8"
              onClick={() => onDownloadClick(document.file_name)}
            />
          </Tooltip>
        )}

        <Tooltip hasArrow label={strings.delete}>
          <IconButton
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete}
            onClick={() => onDeleteClick(document)}
            color="red.500"
            minW="8"
          />
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default DocumentListItem;
