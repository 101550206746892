import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ResponsiveValue,
  useDisclosure,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React, { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

interface Props {
  isLoading: boolean;
  size?: ResponsiveValue<(string & {}) | 'sm' | 'md' | 'lg' | 'xs'>;
}
const FormCancelButton: React.FC<Props> = (props) => {
  const { isLoading, size } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    formState: { dirtyFields },
  } = useFormContext();
  const history = useHistory();

  const onCancelClick = () => {
    const isDirty = !!Object.keys(dirtyFields).length;
    if (isDirty) {
      onOpen();
    } else {
      history.goBack();
    }
  };

  return (
    <Fragment>
      <Button
        size={size}
        variant="outline"
        colorScheme="primary"
        isDisabled={isLoading}
        onClick={onCancelClick}>
        {strings.cancel}
      </Button>
      <>
        <Modal isOpen={isOpen} isCentered onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.discard_changes}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.discard_changes_warning}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={onClose}>
                  {strings.no_thank_you}
                </Button>
                <Button colorScheme="primary" onClick={() => history.goBack()}>
                  {strings.discard}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Fragment>
  );
};

export default FormCancelButton;
