import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import NewsletterAPI from 'api/newsletter';
import { CenterSpinner } from 'components/common/CenterSpinner';
import ComposeNewsletterForm, {
  ComposeNewsletterFormValues,
} from 'components/newsletter/ComposeNewsletterForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { NewsletterStatus, NewsletterStatusEnum } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import history from 'utils/history';

interface EditNewsletterParams {
  id: string;
}

interface EditNewsletterDTO {
  recipient_group: string[];
  subject: string;
  status: NewsletterStatus;
  message: string;
  file: string[];
}

const EditNewsLetter: React.FC = (props) => {
  const newsletterAPI = new NewsletterAPI();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast();
  const [errMsg, setErrMsg] = useState('');
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<ComposeNewsletterFormValues>();
  const queryClient = useQueryClient();
  const { id: newsletterId } = useParams<EditNewsletterParams>();
  const [preview, setPreview] = useState<boolean>(false);
  const tinymceRef = useRef<Editor>(new Editor({}));

  const { data: newsletterDetail, isLoading: newsletterDetailLoading } =
    useQuery(
      ['newsletter-edit', newsletterId],
      async () => {
        const result = await newsletterAPI
          .get(newsletterId)
          .then((res) => res.data.data);
        return result;
      },
      {
        cacheTime: 0,
        refetchOnWindowFocus: false,
      }
    );

  if (newsletterDetail?.status === NewsletterStatusEnum.DISPATCHED) {
    history.replace(routes.newsletter.newsletters.list);
  }

  const composeNewsletter = useMutation((data: EditNewsletterDTO) =>
    newsletterAPI.update(newsletterId, data)
  );

  const uploadFiles = useMutation((formData: FormData) =>
    newsletterAPI.uploadToBucket(formData)
  );

  const handleUploadFiles = async (files: File[]) => {
    const result: {
      file_id: string;
      file_name: string;
      mime_type: string;
      file_size: string;
      file_path: string;
    }[] = [];

    for (let index = 0; index < files.length; index += 1) {
      const formData = new FormData();
      formData.append('image', files[index], files[index].name);

      const uploads = await uploadFiles.mutateAsync(formData);
      if (uploads.status === 201) {
        const { file_id, file_name, mime_type, file_size, file_path } =
          uploads.data;

        result.push({
          file_id: file_id,
          file_name: file_name,
          file_path: file_path,
          file_size: file_size,
          mime_type: mime_type,
        });
      }
    }
    return result;
  };

  const onSubmit = async (data: ComposeNewsletterFormValues) => {
    setIsLoading(true);
    const formData = { ...data };
    let uploadedFilesIds = newsletterDetail
      ? newsletterDetail?.newsletterFiles?.map((file) => file.id)
      : [];

    if (data?.file && data?.file?.length > 0) {
      const uploadedFiles = await handleUploadFiles(data.file);
      const newUploadedFiles = uploadedFiles.map((file) => file.file_id);
      uploadedFilesIds = [...uploadedFilesIds, ...newUploadedFiles];
    }
    const editNewsletterDTO: EditNewsletterDTO = {
      recipient_group: formData.recipient_group,
      subject: formData.subject,
      status: formData.status,
      message: formData.message,
      file: uploadedFilesIds,
    };

    composeNewsletter.mutate(editNewsletterDTO, {
      onSuccess: () => {
        queryClient.invalidateQueries('newsLetterList');
        toast({
          title: strings.newsletter_sent_sucessfully,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.newsletter.newsletters.list);
      },
      onError: () => {
        setErrMsg(`${strings.newsletter} error `);
        setIsLoading(false);
      },
    });
  };

  const validateMandatoryFields = () => {
    if (!methods.getValues('subject')) {
      methods.setError('subject', {
        message: strings.subject_field_is_required,
      });
    }
    if (tinymceRef.current?.editor?.getContent() === '') {
      methods.setError('message', {
        message: strings.message_field_is_required,
      });
    }
    if (methods.getValues('recipient_group')?.length === 0) {
      methods.setError('recipient_group', {
        message: strings.recipient_group_field_is_required,
      });
    }
  };

  const handleDraftButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(methods.formState.errors).length > 0) {
      return;
    }

    const formValues = methods.getValues();
    formValues.status = NewsletterStatusEnum.DRAFT;
    onSubmit(formValues);
  };

  if (newsletterDetailLoading) {
    return <CenterSpinner />;
  }

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.newsletter} | {strings.edit_newsletter}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink onClick={() => setPreview(false)}>
            {strings.edit_newsletter}
          </BreadcrumbLink>
        </BreadcrumbItem>
        {preview && (
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.preview_message}</BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {preview ? strings.preview_message : strings.edit_newsletter}
        </Heading>
        <Button
          variant="outline"
          size="lg"
          colorScheme="primary"
          onClick={handleDraftButtonClick}
          isLoading={
            newsletterDetail?.status === NewsletterStatusEnum.DRAFT
              ? isLoading
              : false
          }>
          {strings.save_as_draft}
        </Button>
      </Flex>
      <FormProvider {...methods}>
        <form ref={formRef} onSubmit={methods.handleSubmit(onSubmit)}>
          {errMsg && (
            <Alert status="error">
              <AlertIcon />
              {errMsg}
            </Alert>
          )}
          {!newsletterDetailLoading && newsletterDetail && (
            <ComposeNewsletterForm
              data={newsletterDetail}
              onSubmit={onSubmit}
              isLoading={isLoading}
              tinymceRef={tinymceRef}
              validateMandatoryFields={validateMandatoryFields}
            />
          )}
        </form>
      </FormProvider>
    </Stack>
  );
};

export default EditNewsLetter;
