import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { Address } from 'constants/schema';
import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from '../../services/AccessControl';

interface Props {
  data: Address;
  search?: string;
}

const ObjektListItems: React.FC<Props> = (props) => {
  const { data, search } = props;
  const { id: objectID } = data;

  const { name, addition, street, house_number, zip_code, town } = data;

  return (
    <Tr>
      <Td>
        <RouterLink
          to={
            routes.oms.objects.edit.replace(':id', objectID.toString()) + search
          }>
          {name}
        </RouterLink>
      </Td>
      <Td>{addition ?? '-'}</Td>
      <Td>{`${street} ${house_number}, ${zip_code} ${town}`}</Td>
      <Td>
        <AccessControl allowedPermissions={[PermissionRequest['manage:oms']]}>
          <RouterLink
            to={
              routes.oms.objects.edit.replace(':id', objectID.toString()) +
              search
            }>
            <Tooltip hasArrow label={strings.edit_object}>
              <IconButton
                icon={<BiEdit />}
                variant="link"
                aria-label={strings.edit_object}
                color="blue.300"
                minW="6"
              />
            </Tooltip>
          </RouterLink>
        </AccessControl>
      </Td>
    </Tr>
  );
};

export default ObjektListItems;
