import UserResource from 'api/user';
import { TenantInfoSettings } from 'constants/schema';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

interface ThemeContextInterface {
  tenant: TenantInfoSettings;
  setTenant: React.Dispatch<React.SetStateAction<TenantInfoSettings>>;
}

interface TenantProviderProps {
  children: React.ReactNode;
}

export const TenantContext = createContext<ThemeContextInterface | undefined>(
  undefined
);

export const THEME_OPTIONS = [
  { value: 'theme-default', label: 'Default' },
  { value: 'theme-orange-custom', label: 'Orange' },
  { value: 'theme-maroon-custom', label: 'Maroon' },
  { value: 'theme-light-blue-custom', label: 'Light Blue' },
  { value: 'theme-blue-custom', label: 'Blue' },
  { value: 'theme-green-custom', label: 'Green' },
  { value: 'theme-grey-custom', label: 'Grey' },
];

export const SIDEBAR_OPTIONS = [
  { value: 'blackgreen.400', label: 'Dark' },
  { value: 'grey.600', label: 'Light' },
];

const THEME_ARRAY = THEME_OPTIONS.map((e) => e.value);

const TenantProvider: React.FC<TenantProviderProps> = ({ children }) => {
  const [tenant, setTenant] = useState({
    theme: 'theme-default',
    tenant: 'Tenant',
    sidebar: 'blackgreen.400',
    agbLink: '/',
    dataProtectionLink: '/',
    imprintLink: '/',
    contactLink: '/',
    logo: '',
    logoTransparent: '',
  });

  const theme = tenant.theme;
  const usersAPI = new UserResource();

  const { data } = useQuery('tenant-settings', usersAPI.getUserSettings, {
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data) {
      setTenant({
        theme: data.data.theme ?? 'theme-default',
        tenant: data.data.tenant ?? 'Tenant',
        sidebar: data.data.sidebar === 'light' ? 'grey.600' : 'blackgreen.400',
        agbLink: data.data.agbLink ?? '/',
        dataProtectionLink: data.data.dataProtectionLink ?? '/',
        imprintLink: data.data.imprintLink ?? '/',
        contactLink: data.data.contactLink ?? '/',
        logo: data.data.logo ?? '',
        logoTransparent: data.data.logoTransparent ?? '',
      });
    }
  }, [data]);

  useEffect(() => {
    document.body.classList.remove(...THEME_ARRAY);
    if (theme) document.body.classList.add(theme);
  }, [theme]);

  return (
    <TenantContext.Provider value={{ tenant, setTenant }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;

export const useTenantContext = () => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenantContext must be used within a TenantProvider');
  }
  return context;
};
