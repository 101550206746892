import Resource from 'api/resource';
import axios, { Canceler } from 'axios';
import { TicketInfoSchema, TicketStatus } from 'constants/schema';
import http from 'utils/http';

const CancelToken = axios.CancelToken;
let cancel: Canceler;

class TicketResource extends Resource<TicketInfoSchema> {
  constructor() {
    super('ticket/tickets');
  }

  updateTicketStatus(
    ticketId: number,
    status: TicketStatus,
    updatedBy: number
  ) {
    return http({
      url: `/ticket/tickets/${ticketId}/update-status`,
      method: 'put',
      data: {
        status,
        updated_by: updatedBy,
      },
    });
  }

  getTicketComment(ticketId: number, page: number, limit: number) {
    return http({
      url: `/ticket/tickets/${ticketId}/comments`,
      method: 'get',
      params: {
        page,
        limit,
      },
    });
  }

  getTickeLog(ticketId: number, page: number, limit: number) {
    return http({
      url: `/ticket/tickets/${ticketId}/ticket-activities`,
      method: 'get',
      params: {
        page,
        limit,
      },
    });
  }

  ticketComment(
    ticketId: number,
    comment: string,
    createdBy: number,
    parentId?: number
  ) {
    return http({
      url: `/ticket/tickets/${ticketId}/comments`,
      method: 'post',
      data: {
        comment,
        created_by: createdBy,
        parent_id: parentId,
      },
    });
  }

  getApartmentList(params?: { address_id: string }) {
    return http({
      url: `/ticket/apartments`,
      method: 'get',
      params,
    });
  }

  getOwnerList(params?: { limit: number }) {
    return http({
      url: `/ticket/owners`,
      method: 'get',
      params,
    });
  }

  /**
   * Used for Assignee
   */
  getUserList() {
    return http({
      url: `/ticket/users`,
      method: 'get',
    });
  }

  /**
   * Upload to Bucket
   */
  uploadToBucket(data: FormData) {
    return http({
      url: `/ticket/tickets/attachment`,
      method: 'post',
      data: data,
    });
  }

  /**
   * Delete Attachment
   */
  destroyAttachment(attachmentId: number) {
    return http({
      url: `/ticket/tickets/attachment/${attachmentId}`,
      method: 'delete',
    });
  }

  getApartmentAddressList() {
    return http({
      url: `/ticket/apartment-address`,
      method: 'get',
    });
  }
}

export { TicketResource as default };
