import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import React from 'react';

interface Props {
  isOpen: boolean;
  name: string;
  onClose: () => void;
  onCancelClick: () => void;
  fileUploadState: {
    totalMB: number;
    loadedMB: number;
    progressPercentage: number;
  };
}
const FileUploadModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, fileUploadState, name, onCancelClick } = props;

  const cancel = () => {
    onCancelClick();
    onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{strings.document_uploading}</ModalHeader>
        <ModalBody>
          <Text>{name}</Text>
          <Progress
            value={fileUploadState.progressPercentage}
            colorScheme="primary"
            mt="2"
          />
          <Text align="right" mt="2" fontSize={'sm'}>
            {fileUploadState.loadedMB} / {fileUploadState.totalMB} MB
          </Text>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="primary" onClick={cancel}>
              {strings.cancel}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FileUploadModal;
