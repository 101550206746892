import {
  Box,
  Divider,
  Flex,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import { strings } from 'config/localization';
import {
  COMPANY_COUNTRY,
  COMPANY_HOUSE_NO,
  COMPANY_NAME,
  COMPANY_STREET,
  COMPANY_TOWN,
  COMPANY_ZIP_CODE,
} from 'constants/common';
import { useTenantContext } from 'context/TenantContext';
import { IoAttachOutline } from 'react-icons/io5';
import { formatBytes, localizeGroupOfRecipient } from 'utils';
import AttachmentListItem from './AttachmentListItem';

export interface ComposeMessageParams {
  recipient_group: string[];
  subject: string;
  message: string;
  status?: string;
  file: {
    fileName: string[];
    fileSize: number[];
    fileUrl: string[];
  };
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  composeNewsletterContent: ComposeMessageParams;
}

const PreviewNewsletterModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, composeNewsletterContent } = props;

  const { tenant } = useTenantContext();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="821px">
        <ModalHeader>{strings.preview}</ModalHeader>
        <ModalCloseButton />
        <ModalBody bg="white">
          <Stack direction="column" spacing="32px" bg="#F9F9F9" p="24px">
            <Stack dir="column">
              <Flex>
                {strings.subject}:&nbsp;
                <Box fontWeight="bold">{composeNewsletterContent.subject}</Box>
              </Flex>
              <Flex>
                {strings.group_of_recipients}:
                {composeNewsletterContent.recipient_group.map((item, id) => (
                  <Flex pl="4" key={id}>
                    {id !== 0 && <Divider orientation="vertical" pl="3" />}
                    <Box
                      fontWeight="bold"
                      textTransform="capitalize"
                      alignSelf="center">
                      {localizeGroupOfRecipient(item)}
                    </Box>
                  </Flex>
                ))}
              </Flex>
            </Stack>
            <Stack spacing="0" boxShadow="0px 4px 24px 0px #0000001A;">
              <Stack
                w="100%"
                h="68px"
                bg="primary.500"
                p="18px"
                alignItems="start">
                <Image src={tenant.logo} h="100%" />
              </Stack>
              <Stack bg="white">
                <Box px="32px" py="48px">
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                    }
                    disabled={true}
                    inline={true}
                    initialValue={composeNewsletterContent?.message}
                  />
                </Box>
                <Stack p="12px" spacing="8px" alignItems="center">
                  <Text
                    textAlign="center"
                    color="#A0AEC0"
                    fontSize="8px"
                    lineHeight="12px">
                    {COMPANY_NAME}
                    <br />
                    {COMPANY_STREET} {COMPANY_HOUSE_NO}
                    <br />
                    {COMPANY_ZIP_CODE}, {COMPANY_TOWN}
                    <br />
                    {COMPANY_COUNTRY}
                    <br />
                  </Text>
                  <Text color="#A0AEC0" fontSize="8px" lineHeight="12px">
                    Vom Newsletter <Link color="primary.500">abmelden</Link>
                  </Text>
                </Stack>
                <Flex
                  px="18px"
                  py="8px"
                  bg="gray.50"
                  justifyContent="center"
                  columnGap="24px"
                  fontSize="8px"
                  lineHeight="16px">
                  <Link href={tenant.dataProtectionLink}>Datenschutz</Link>
                  <Link href={tenant.agbLink}>AGB</Link>
                  <Link href={tenant.imprintLink}>Impressum</Link>
                </Flex>
              </Stack>
            </Stack>
            <Stack>
              {composeNewsletterContent.file.fileName.length > 0 && (
                <Flex columnGap="4px">
                  <Icon as={IoAttachOutline} w="24px" h="24px" />
                  <Text
                    marginX="2px"
                    marginY="2px"
                    color="#2D3748"
                    fontWeight="semibold">
                    {strings.attachment}
                  </Text>
                </Flex>
              )}
              <Flex as="ul" flexWrap="wrap">
                {composeNewsletterContent.file.fileName.map((name, i) => (
                  <AttachmentListItem
                    key={i}
                    link={composeNewsletterContent.file.fileUrl[i]}
                    name={name}
                    size={formatBytes(
                      composeNewsletterContent.file.fileSize[i]
                    )}
                  />
                ))}
              </Flex>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PreviewNewsletterModal;
