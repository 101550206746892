import { Lang } from './types';

const fr: Lang = {
  /*login Credential*/
  login: 'Connexion',
  login_now: 'Connexion',
  log_in: 'Connexion',
  password: 'Mot de passe',
  email: 'Courriel',
  your_email_address: 'Votre adresse électronique',
  your_password: 'Votre mot de passe',
  forgot_password: 'Mot de passe oublié?',
  back_to_login: 'Retour à la page de connexion',
  password_reset: 'Demander la réinitialisation du mot de passe',
  login_message:
    "Entrez vos données d'identification pour vous connecter à {0}.",
  forgot_message:
    "Veuillez saisir l'adresse électronique que vous utilisez pour vous connecter à votre compte.",
  immo_portal: 'Immobilienportal',
  password_info:
    'Le mot de passe doit comporter au moins 1 chiffre, 1 caractère spécial, 1 lettre majuscule et 5 autres caractères.',
  invalid_login_msg: "L'email ou le mot de passe ne correspond pas.",
  user_delete_error:
    "L'utilisateur ne peut pas être supprimé L'utilisateur détient un stock de MMS",
  change_language: 'Changer de langue',
  invalid_token: 'Ce jeton de réinitialisation de mot de passe est invalide.',
  two_factor_authentication: 'Authentification à deux facteurs',
  enter_the_pin_from_google_authentication:
    "Entrez le code de l'authentification Google.",
  authenticate: 'Authentifier',
  one_time_password: 'Mot de passe à usage unique',
  FA_status: 'Statut 2FA',
  disable_2FA: 'Désactiver 2FA',
  enable_2FA: 'Activer 2FA',
  fa_message:
    "Pour activer l'authentification à deux facteurs, veuillez scanner ce code QR avec l'application Google Authenticator.",
  code: 'Code',
  fa_status_tooltip: 'Générer la clé secrète pour activer le 2FA',
  authenticator_code: "Code d'authentification",
  FA_successfully: '2FA est activé avec succès',
  FA_enable: '2FA est actuellement activé pour votre compte',
  fa_Disable: '2FA est maintenant désactivé',
  authenticator_code_error: "Erreur de code d'authentification",
  required_authenticator_code: "Code d'authentification requis",
  fa_disable_title:
    "Si vous souhaitez désactiver l'authentification à deux facteurs. Veuillez confirmer votre mot de passe et cliquer sur le bouton Désactiver 2FA.",
  activate_2FA: 'Activer 2FA',
  deactivate_2FA: 'Désactiver 2FA',

  /*button*/
  save: 'Sauvez',
  save_now: 'Sauvez maintenant',
  filter: 'Filtre',
  back: 'Dos',
  editing: 'Modification de',
  apply_filter: 'Appliquer le filtre',
  reset_filter: 'Réinitialiser le filtre',
  more_filter: 'Plus de filtres',
  cancel: 'Annuler',
  clear: 'Clair',
  reply: 'Réponse',
  comment: 'Commentaire',
  save_and_create_new_ticket: 'Sauvegarder et créer un nouveau ticket',
  confirm: 'Confirmer',
  profile: 'Profil',
  my_profile: 'Mon profil',
  edit_profile: 'Modifier le profil',
  profile_updated: 'Profil mis à jour.',
  profle_update_failed: 'La mise à jour du profil a échoué.',
  password_updated: 'Mot de passe mis à jour.',
  not_match_new_confirm_password: 'Les mots de passe ne correspondent pas.',
  incorrect_password: "Le mot de passe n'est pas correct.",
  setting: 'Réglage de',
  settings: 'Paramètres',
  logout: 'Déconnexion',
  loggedOut: 'Déconnecté',
  change_password: 'Changer le mot de passe',
  change_now: 'Changez maintenant',
  Current_password: 'Mot de passe actuel',
  new_password: 'Nouveau mot de passe',
  confirm_password: 'Confirmer le mot de passe',
  invalid_current_password: 'Le mot de passe actuel est incorrect.',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  email_not_found: 'Adresse e-mail non trouvée.',
  email_required: "L'adresse électronique est requise",

  /*Module*/
  user_management: 'Gestion des utilisateurs',
  ticket: 'Billets',
  dashboard: 'Dashboard',

  /*Splash Screen*/
  choose_subsystem: 'Choisir le sous-système',

  /*User Management*/
  user_management_system: 'Système de gestion des utilisateurs',
  user: 'Utilisateurs',
  user_list: "Listes d'utilisateurs",
  user_rights: 'Droits des utilisateurs',
  user_roles: 'Rôles des utilisateurs',
  user_name: "Nom de l'utilisateur",
  user_profile: "Profil de l'utilisateur",
  user_details: "Détails de l'utilisateur",
  role_details: 'Détails du rôle',
  role: 'Rôle',
  add_additional_role: 'Ajouter un rôle supplémentaire',
  add_additional_rights: 'Ajouter des droits supplémentaires',
  additional_rights: 'Droits supplémentaires',
  manage_extra_rights: 'Gérer les droits supplémentaires',
  right_from_role: 'Droits du rôle',
  add_role: 'Ajouter un rôle',
  role_setup: 'Configuration du rôle',
  system: 'Système',
  role_name: 'Nom du rôle',
  privillage: 'Privilège',
  add_user: 'Ajouter un utilisateur',
  all_users: 'Tous les utilisateurs',
  all_user_with_that_role: 'Tous les utilisateurs ayant ce rôle',
  add_new_user: 'Ajouter un nouvel utilisateur',
  add_new_role: 'Ajouter un nouveau rôle',
  view_user: "Afficher l'utilisateur",
  edit_user: "Modifier l'utilisateur",
  delete_user: "Supprimer l'utilisateur",
  save_user: "Sauvegarder l'utilisateur",
  upload_image: "Télécharger l'image",
  drop_image_or_click_to_select_image:
    'Déposer une image ou cliquer pour sélectionner une image',
  assigned_tickets: 'Billets assignés',
  access_user: "Accéder à l'utilisateur",
  manage_user: 'Gérer les utilisateurs',
  access_user_log: "Accéder au journal de l'utilisateur",
  manage_owner: 'Gérer le propriétaire',
  manage_admin: "Gérer l'admin",
  manage_caretaker: 'Gérer le gardien',
  access_oms: 'Accéder à OMS',
  manage_oms: 'Gérer OMS',
  access_dms_ownership: 'Accéder aux documents',
  manage_dms_ownership: 'Gérer les documents',
  access_dms_owner: 'Accéder aux messages',
  manage_dms_owner: 'Gérer les messages',
  access_faq: 'Accéder à la FAQ',
  manage_faq: 'Gérer la FAQ',
  access_faq_group: 'Accéder aux groupes de FAQ',
  manage_faq_group: 'Gérer les groupes de FAQ',
  access_ticket: 'Lire les tickets',
  assign_ticket: 'Attribuer des tickets',
  manage_own_owner_information: 'Modifier ses propres données',
  access_own_owner_object: 'Accéder à ses propres objets',
  access_own_owner_document: 'Accéder à ses propres documents',
  access_newsletter: "Voir les bulletins d'information et les projets",
  manage_newsletter: "Éditer les bulletins d'information et les brouillons",
  action: 'Action',
  first_name: 'Prénom',
  last_name: 'Nom de famille',
  email_address: 'Adresse électronique',
  valid_email_address: 'Veuillez fournir une adresse électronique valide.',
  contact_info: 'Informations de contact',
  address: 'Adresse',
  view: 'Voir',
  add: 'Ajouter',
  edit: 'Modifier',
  delete: 'Supprimer',
  search: 'Recherche',
  login_with_new_password:
    'Veuillez vous connecter avec un nouveau mot de passe',
  msg_valid_password: 'Veuillez fournir un mot de passe valide.',
  msg_password_reset_link:
    'Nous avons envoyé par e-mail le lien de réinitialisation de votre mot de passe!',
  select_role: 'Sélectionner le rôle',
  rights: 'Droits',
  reset_password: 'Réinitialiser le mot de passe',
  reset_password_subtitle:
    'Veuillez saisir les champs que vous utilisez pour réinitialiser votre compte.',
  save_reset_password: 'Enregistrer Réinitialiser le mot de passe',
  create_password: 'Créer un mot de passe',
  create_password_subtitle:
    'Veuillez saisir les champs pour créer un mot de passe pour votre compte.',
  save_password: 'Enregistrer le mot de passe',
  password_helper_text:
    'Minimum 8 caractères, au moins une majuscule, une minuscule, un chiffre et un caractère spécial.',
  resend_verification_email: "Renvoyer l'email de vérification",
  verification_email_sent: 'Courriel de vérification envoyé',
  email_verification_success: 'Email vérifié avec succès.',
  email_verification_failed: "La vérification de l'e-mail a échoué.",
  resend_verify_email_error: "Erreur de renvoi de la vérification de l'e-mail.",
  resend_verify_email_success:
    "L'e-mail de vérification a été envoyé avec succès. Vérifiez votre boîte de réception.",
  resend_verify_email_success_to:
    "L'e-mail de vérification a été envoyé avec succès à",
  email_already_verified: "L'email est déjà vérifié.",
  login_to_continue: 'Veuillez vous connecter pour continuer.',
  create_password_to_proceed: 'Créez un mot de passe pour continuer.',
  change_confirm: 'Etes-vous sûr de vouloir changer cela ?',
  cellphone: 'Téléphone portable',
  invalid_cellphone: 'Téléphone portable invalide',
  email_placeholder: 'youremail@example.com',
  admin: 'Admin',
  verified: 'Vérifié',
  not_yet_verified: 'Pas encore vérifié',

  user_login_log: 'Journaux de connexion des utilisateurs',
  all_login_logs: 'Tous les journaux de connexion',
  correspondence_language: 'Langue de correspondance',
  correspondence_language_placeholder:
    'Sélectionner la langue de correspondance',
  correspondence_language_required: 'La langue de correspondance est requise',

  adittion: 'Adittion',
  deceased: 'Deceased',
  deceased_since: 'Deceased Since',
  address_forwarding: 'Address Forwarding',
  data_sharing: 'Partage de données',
  data_sharing_activated: 'Partage de données activé',
  owner_created: 'Owner has been created',
  employee_with_ticket_support: 'Employee With Ticket Support',
  salutation: 'Salutation',
  select_salutation: 'Sélectionner la civilité',
  salutation_is_required: 'La civilité est requise',
  valid_only_number: 'Veuillez entrer uniquement des nombres.',
  city_is_required: 'La ville est requise',
  notes_notizen: 'Notes',
  notes_cannot_be_more_than_2000_characters_long:
    'Les notes ne peuvent pas dépasser 2000 caractères',
  max_characters_exceeded: 'Limite de caractères maximale dépassée',
  add_new_owner: 'Ajouter un nouveau propriétaire',
  add_new: 'Ajouter un nouveau {0}',
  employee: 'Employé',
  caretaker: 'Gardien',
  deceased_since_is_required: 'Deceased since is required',
  owner_updated: 'Owner has been updated',
  owner_details: 'Owner Details',
  edit_owner: 'Edit Owner',
  send_verification_email: 'Send Invitation Email',
  email_verification_sent: 'Email Verification sent',

  address_forwarding_reason_guardianship: 'Guardianship',
  address_forwarding_reason_temporary_change_of_address:
    'Temporary change of address',
  address_forwarding_reason_different_billing_address:
    'Different billing address',
  address_forwarding_reason_address_of_the_property: 'Address of the property',

  reason: 'Reason',
  select_reason: 'Select reason',
  reason_is_required: 'Reason is required',

  customer_number: 'Customer Number',
  customer_number_is_required: 'Customer number is required',
  owner_since: 'Propriétaire depuis',
  required_owner_since: '"Propriétaire depuis" requis',

  discard_changes: 'Discard changes',
  discard_changes_warning: 'Do you really want to discard your changes?',
  discard: 'Discard',
  no_thank_you: 'No, thank you',
  caretaker_created: 'Concierge a été créé',
  add_caretaker: 'Ajouter un nouveau concierge',
  caretaker_details: 'Détails de la conciergerie',
  edit_caretaker: 'Modifier le concierge',

  sn: '#',
  name: 'Nom',
  assignee: 'Cessionnaire',
  description: 'Description',
  yes: 'Oui',
  no: 'Non',
  active: 'Actif',
  in_active: 'Inactif',
  select_apartment: 'Sélectionnez un appartement',
  select_unit: 'Sélectionnez une unité',
  select_assignee: 'Sélectionnez le destinataire',
  select_owner: 'Sélectionnez le propriétaire',
  select_priority: 'Sélectionnez la priorité',
  select_source: 'Sélectionnez la source',
  write_description: 'Description écrite',
  csv_datasharing: 'CSV Partage des données',

  /*Ticket Board*/
  ticket_system: 'Système de tickets',
  all_tickets: 'Tous les billets',
  ticket_board: 'Planches à billets',
  add_task: 'Ajouter une tâche',
  open: 'Ouvrir',
  inprogress: 'En cours',
  'in-progress': 'En cours',
  done: 'Terminé',
  closed: 'Fermé',
  document: 'Document',
  normal_task: 'Tâche normale',
  create_ticket: 'Créer un ticket',
  ticket_details: 'Détails du billet',
  ticket_title: 'Titre du billet',
  object: 'Objet',
  all_objects: 'Tous les objets',
  customer: 'Client',
  house_owner: 'Propriétaire de la maison',
  priority_label: 'Label de priorité',
  priority: 'Priorité',
  upload_files: 'Télécharger des fichiers',
  descriptions: 'Descriptions',
  save_create_new_ticket: 'Sauvegarder et créer un nouveau ticket',
  author: 'Auteur',
  created_by: 'Créé par',
  created_at: 'Créé à',
  last_modified_by: 'Dernière modification par',
  last_modified_at: 'Dernière modification à',
  last_changed_by: 'Dernière modification par',
  last_changed_at: 'Dernier changement à',
  status: 'Statut',
  ticket_log: 'Log',
  ticket_list: 'Listes de tickets',
  repeat_task: 'Répéter la tâche',
  apartment: 'Appartement',
  apartments: 'Appartements',
  unit: 'Unité',
  ticket_created: 'Le ticket a été créé.',
  has_been_created: 'a été créé.',
  has_not_been_created: "n'a pas été créé.",
  has_been_merged: 'a été fusionné',
  has_not_been_merged: "n'a pas été fusionné",
  has_been_updated: 'a été mis à jour.',
  has_not_been_updated: "n'a pas été mis à jour.",
  has_been_deleted: 'a été supprimé.',
  has_not_been_deleted: "n'a pas été supprimé.",
  ticket_error: "Le ticket n'a pas été créé.",
  expand: 'Développez',
  ticket_list_error:
    "Une erreur s'est produite dans le traitement de votre demande.",
  title_required: 'Le titre est obligatoire.',
  house_owner_is_required: 'Propriétaire de la maison est nécessaire.',
  repeating_info: 'Répétition des informations',
  interval: 'Intervalle',
  repeat_from: 'Répéter à partir de',
  repeat_until: "Répéter jusqu'à ce que",
  comment_here: 'Commentez ici...',
  comment_required: 'Un commentaire est nécessaire.',
  id_not_found: 'ID non trouvé.',
  edit_ticket: 'Modifier le ticket',
  ticket_updated: 'Ticket mis à jour avec succès',
  change_status: 'Changement de statut',
  status_updated: 'Statut mis à jour avec succès',
  select_status: 'Sélectionner le statut',
  comment_error: 'Erreur de commentaire',
  view_more: 'Voir plus',
  view_less: 'Voir moins',
  spent_time: 'Temps passé',
  adjust_time_spent: 'Ajuster le temps passé',
  correct_spent_time: 'Temps passé correct',
  spent_time_message: 'Le temps passé a été correct manuellement.',
  task: 'Tâche',
  low: 'Faible',
  medium: 'Moyen',
  high: 'Haut',
  urgent: 'Urgent',
  select_type: 'Sélectionnez le type',
  select_type_art: 'Sélectionnez le type',
  ticket_detail: 'Détail du billet',
  edit_task: 'Modifier la tâche',
  created_ticket: 'billet créé',
  updated_ticket: 'billet actualisé',
  changed_status: 'changement de statut',
  assigned_ticket: 'billet assigné',
  commented: 'a commenté',
  order_by: 'Commander par',
  createdat: 'Créé à',
  order: 'Commandez',
  asc: 'Ascendant',
  desc: 'Descendant',
  showing_all_options: 'Afficher toutes les options',
  appointment: 'Rendez-vous',
  appointment_date_time: 'Date et heure du rendez-vous',
  enter_appointment_date_time: "Entrez la date et l'heure du rendez-vous",
  full_screen: 'Plein écran',
  add_new_ticket: 'Ajouter un nouveau ticket',

  source: 'Source',
  destination: 'Destination',
  street: 'Rue',
  house_no: 'Numéro de maison',
  zip_code: 'Code postal',
  town: 'Ville',
  country: 'Pays',
  phone: 'Numéro de téléphone',
  zip_code_abbreviation: 'CP',

  add_now: 'Ajouter maintenant',
  date_time: 'Date/Heure',
  city: 'Ville',
  file: 'Fichier',
  images: 'Images',
  upload_file: 'Télécharger un fichier',
  question: 'Question',
  from: 'De',
  to: 'À',
  article: 'Article',
  type: 'Type',
  objects: 'Objets',
  object_id: "ID d'objet",
  tickets: 'Billets',
  ticket_source: 'Source du billet',

  required: 'Requis',
  user_created: "L'utilisateur a été créé",
  user_creation_failed: "La création de l'utilisateur a échoué.",
  user_udpate_failed: "La mise à jour de l'utilisateur a échoué.",
  password_update_failed: 'La mise à jour du mot de passe a échoué.',
  user_not_found: 'Utilisateur introuvable',
  update: 'Mettre à jour',
  user_updated: "L'utilisateur a été mis à jour avec succès",
  user_deleted: "L'utilisateur a été supprimé.",
  roles_not_found: 'Rôles introuvables',
  confirm_delete: 'Êtes-vous sûr de vouloir supprimer ceci ?',

  select: 'Sélectionner',
  collapse_sidebar: 'Réduire la barre latérale',
  expand_sidebar: 'Développer la barre latérale',
  max_upload_size_5:
    "Taille maximum des pièces jointes à télécharger jusqu'à 5 Mo.",
  max_upload_size_10:
    "Taille maximum des pièces jointes à télécharger jusqu'à 10 Mo.",
  max_upload_size_15:
    "Taille maximum des pièces jointes à télécharger jusqu'à 15 Mo.",
  max_upload_size_100:
    "Taille maximum des pièces jointes à télécharger jusqu'à 100 Mo.",
  max_upload_size_1GB:
    "Taille maximum des pièces jointes à télécharger jusqu'à 1 Go.",
  file_upload_failed: 'Le téléchargement de fichiers a échoué',

  drag_drop_files_msg:
    "Glissez et déposez des fichiers dans le champ ou cliquez sur le ' + ' pour sélectionner des fichiers.",
  files: 'Fichiers',
  delete_file: 'Supprimer le fichier',
  only_1_file_is_allowed: 'Seul 1 fichier est autorisé.',
  uploading: 'Téléchargement en cours',
  invalid_profile_upload: 'Téléchargement de profil non valide',
  file_must_be_an_image_file: 'Le fichier doit être un fichier image.',
  clear_profile_pic: "Effacer l'image de profil",
  are_you_sure: 'Êtes-vous sûr ? Vous ne pourrez pas annuler cette action.',
  upload_label: 'Télécharger le profil',
  remove_label: 'Supprimer le profil',
  invalid_file_size: 'Taille de fichier non valide',
  file_size_info: 'La taille du fichier doit être inférieure à 5 Mo',
  uploading_profile: 'Téléchargement du profil',
  no_permission_msg:
    "Vous n'avez pas la permission d'y accéder. Veuillez contacter l'administrateur.",

  house_number: 'Numéro de maison',
  house: 'Maison',
  flat: 'Appartement',
  fresh: 'Frais',
  consumed: 'Consommé',
  general_information: ' Informations générales',
  change_ownership: 'Changement de propriété',
  oil_level_report: "Rapport sur le niveau d'huile",
  malfunction_defect_deficiencies: 'Panne, Défaut, Déficiences',

  // common
  lang: 'fr',
  english: 'Anglais',
  german: 'Allemand',
  polish: 'Polonais',
  french: 'Français',
  italian: 'Italien',
  result: 'Résultat',
  no_results_found: 'Aucun résultat trouvé.',
  no_records_found: 'Aucun enregistrement trouvé',
  error: 'Erreur',
  no_options: "Pas d'options",
  showing: 'Afficher',
  manually: 'Manuellement',
  general: 'Général',
  gotoDetails: 'Aller aux détails',
  out_of: 'sur',
  loading: 'Chargement...',
  show_rows: 'Afficher les lignes',
  error_boundary_heading_text: "Désolé, quelque chose s'est mal passé.",
  error_boundary_paragraph_text:
    'Notre équipe a été informée et nous allons corriger cela dès que possible.',
  reload_page: 'Recharger la page',
  take_me_home: 'Ramène-moi à la maison',
  manual: 'Manuel',
  ['v-office']: 'V-Office',
  guest: 'Invité',
  owner: 'Propriétaire',
  lead: 'Menant',
  supplier: 'Fournisseur',
  test: 'Test',
  other: 'Autre',
  daily: 'Quotidien',
  weekly: 'Hebdomadaire',
  bi_weekly: 'Bi-hebdomadaire',
  monthly: 'Mensuel',
  semi_anually: 'Semi-Annuel',
  anually: 'Annuel',
  previous: 'Précédent',
  next: 'Suivant',
  date_validation_msg: 'Veuillez entrer une date valide',
  freeze_inventory: "Geler l'inventaire",
  create_inventory_list: "Créer des listes d'inventaire",
  correct_inventory: "Correctif d'inventaire",
  create_delta_list: 'Créer des listes delta',
  unfreeze_inventory_end: "Dégeler l'inventaire et finir le stockage",
  download_pdf: 'Télécharger en PDF',
  download_excel: 'Télécharger en Excel',
  download__delta_list_pdf: 'Télécharger la liste delta en PDF',
  unfreeze_inventory: "Dégeler l'inventaire",
  no_transferring: "(Aucun transfert d'articles possible.)",
  download_as: '(Télécharger en PDF et enregistrer, puis imprimer.)',
  transfer_items: '(Transférer des articles pour correction de stockage)',
  what_change: '(Quels changements ont été effectués pendant le stockage.)',
  step_1: 'Etape 1',
  step_2: 'Etape 2',
  step_3: 'Etape 3',
  step_4: 'Etape 4',
  step_5: 'Etape 5',
  actual_quantity: 'Quantité réelle',
  delta: 'Delta',
  no_data_found: 'Aucune donnée trouvée',
  ms: 'Mme.',
  Ms: 'Mme.',
  diverse: 'Diverse',
  Diverse: 'Diverse',
  language: 'Langue',
  phone_number: 'Numéro de téléphone',
  sleeping_facilities: 'Installations de couchage',
  number: 'Numéro',
  until: "Jusqu'à",
  all_logs: 'Tous les journaux',

  drag_drop_csv_files_msg:
    "Glisser & Déposer uniquement les fichiers .csv ici, ou cliquer pour sélectionner les fichiers .csv. Télécharger des fichiers jusqu'à 10 MB.",
  drag_drop_csv_15MB_files_msg:
    "Glisser & Déposer uniquement les fichiers .csv ici, ou cliquer pour sélectionner les fichiers .csv. Télécharger des fichiers jusqu'à 15 MB.",
  processing_data: 'Traitement des données',
  failed: 'Échoué',
  file_uploaded_successfully: 'Fichier téléchargé avec succès.',
  uploaded_on: 'Dernier téléchargé le',
  by: 'par',
  transferred: 'Transféré',
  internal_server_error: 'Erreur interne du serveur',
  cannot_upload_csv_in_worng_category:
    'Impossible de télécharger le fichier CSV dans la mauvaise catégorie',
  upload_date: "Date d'importation",
  location: 'Emplacement',

  /* OMS */
  object_management_system: "Système de gestion d'objets",
  units: 'Unités',
  oms: 'OMS',
  object_list: "Listes d'objets",
  unit_type: "Type d'unités",
  manage_units: 'Gérer les unités',
  manage_objects: 'Gérer les objets',
  apartment_quota: "Quotas d'objets",
  quota: 'Quota',
  stwe_number: 'Numéro STWE',
  floor: 'Étage',
  floor_number: 'Numéro Étage',
  rooms: 'Pièces',
  room_number: 'Numéro Pièces',
  number_of_rooms: 'Nombre de pièces',
  living_space: 'Surface habitable (m²)',
  garage_number: 'N° de garage',
  garage_quota: 'Quota des garages',
  garage_type_is_required: 'Garagen-Art ist erforderlich',
  garage_quota_is_required: 'Le type de garage est requis',
  garage_number_is_required: 'Le numéro de garage est obligatoire',
  add_another_garage: 'ajouter un autre garage',
  no_abbreviation: 'N°',
  season_name: 'Nom de la saison',
  construction_year: 'Année de construction',
  from_date: 'Date de début',
  to_date: 'Date de fin',
  add_new_object: 'Ajouter un nouvel objet',
  add_object: 'Ajouter un objet',
  add_new_unit: 'Ajouter un nouvel unité',
  add_new_apartment: 'Ajouter un nouvel appartement',
  add_apartment: 'Ajouter un appartement',
  edit_object: "Modifier l'objet",
  status_changed: 'Statut modifié',
  features: 'Caractéristiques',
  picture: 'Image',
  text: 'Texte',
  cannot_get_apartment_details:
    "Impossible d'obtenir les détails de l'appartement",
  must_be_greater_than_zero: 'Doit être supérieur à 0',
  basic_information: 'Informations de base',
  copy: 'Copie',
  save_object: "Sauvegarder l'objet",
  save_apartment: 'Sauvegarder Appartement',
  add_owner: 'Ajouter un propriétaire',
  enter_owner: 'Saisir le propriétaire',
  no_owner: "Vous n'avez pas encore associé de propriétaire à cet objet.",
  owner_is_required: "Vous devez associer au moins un propriétaire à l'unité !",
  ical_link: 'Lien iCal',
  copied: 'Copié',
  copy_to_clipboard_ctrl_c_enter:
    'Copier dans le presse-papiers : Ctrl+C, Enter',
  edit_ownership: 'Traiter la propriété',
  delete_ownership: 'Supprimer la propriété',
  select_ownership: 'Sélectionner la propriété',
  actions: 'Actions',
  price: 'Prix',
  garage: 'Garage',
  garage_type: 'Type de garage',
  quantity: 'Quantité',
  store: 'Magasin',
  restaurant: 'Restaurant',
  select_object_type: "Sélectionner le type d'objet",
  enter_apartment_number: "Saisir le numéro du STWE / de l'appartement",
  enter_stwe_number: 'Entrez le numéro STWE',
  select_floor: "Sélectionnez l'étage",
  enter_living_space: "Entrez l'espace de vie",
  select_rooms: 'Sélectionnez les pièces',
  select_garage_type: 'Choisir le type de garage',
  enter_garage_number: 'Saisir le n° de garage',
  enter_street: 'Saisir la rue',
  enter_quantity: 'Anzahl eingeben',
  enter_zip_code: 'Saisir le code postal',
  select_country: 'Choisir un pays',
  select_unit_type: "Choisir le type d'unité",
  enter_apartment_quota: "Saisir les quotas d'objets",
  enter_quota: 'Saisir les quota',
  enter_garage_quota: 'Saisir les cotes des garages',
  enter_house_number: 'Saisir le numéro de rue',
  enter_town: 'Saisir le lieu',
  enter_object_name: "Saisir le nom de l'objet",
  enter_object_addition: "Saisir l'ajout d'objet",
  enter_construction_year: "Saisir l'année de construction",
  enter_address_adittion: "Saisir l'année de suffixe",
  confirm_remove_garage:
    'È sicuro che questo rimuoverà tutti i garage salvati da questo appartamento.',
  object_type_is_required: "Le type d'objet est requis",
  object_name_is_required: "Le nom de l'objet est obligatoire",
  object_number_is_required: "Le complément d'objet est nécessaire",
  object_number: "Numéro d'objet",
  unit_number: "Numéro d'unité",
  object_name: "Nom de l'objet",
  object_addition: "Ajout d'objet",
  permanent_rental: 'Location permanente',
  vacation_rental: 'Location de vacances',
  unknown: 'Froid',
  single_garage: 'Garage individuelle',
  indoor_parking_space: 'Place de parking intérieure',
  outdoor_parking_lot: 'Place de parking extérieure',
  own_use: 'Usage personnel',
  studio: 'Studio',
  '1': '1',
  '1.5': '1,5',
  '2': '2',
  '2.5': '2,5',
  '3': '3',
  '3.5': '3,5',
  '4': '4',
  '4.5': '4,5',
  '5': '5',
  '5.5': '5,5',
  '6+': '6+',
  'floor_-2': '2e étage du sous-sol',
  'floor_-1': '1er étage du sous-sol',
  floor_0: 'rez-de-chaussée',
  floor_1: '1er étage',
  floor_2: '2e étage',
  floor_3: '3e étage',
  floor_4: '4e étage',
  floor_5: '5e étage',
  floor_6: '6e étage',
  change_caretaker: 'Changer de concierge',
  change_caretaker_info: 'Voulez-vous vraiment changer de concierge?',
  change: 'Modifier',
  remove_caretaker: 'Supprimer le concierge',
  remove_caretaker_info: 'Voulez-vous vraiment supprimer le concierge?',
  remove: 'Supprimer',
  select_caretaker: 'Sélectionner le concierge',
  caretaker_is_required: 'Un concierge est nécessaire',
  enter_first_name: 'Entrez votre prénom',
  enter_last_name: 'Entrez le nom de famille',
  enter_email_address: "Saisir l'adresse e-mail",
  enter_phone_number: 'Saisir le téléphone',
  enter_title: 'Entrez Le Titre',
  add_notes: 'Ajouter des notes',
  edit_unit: 'Modifier Unités',
  add_new_garage: 'Ajouter un nouveau garage',
  add_garage: 'Ajouter un garage',

  /**DMS Service */
  document_management_system: 'Système de gestion de documents',
  dms: 'DMS',
  all_documents: 'Tous les documents',
  upload_document: 'Télécharger un document',
  edit_document: 'Modifier le document',
  document_name: 'Nom du document',
  owner_name: 'Nom du propriétaire',
  availability: 'Disponibilité',
  no_of_documents: 'Nombre de documents',
  select_object: 'Sélectionner un objet',
  documents: 'Documents',
  general_document_deleted: 'Le document a été supprimé.',
  delete_general_document: 'Supprimer le document',
  general_document_not_found: 'Document non trouvé',
  general_document_udpate_failed: 'La mise à jour du document a échoué.',
  general_document_created: 'Le document a été créé',
  general_document_creation_failed: 'La création du document a échoué.',
  general_document_updated: 'Document mis à jour avec succès',
  owner_documents: 'Documents de propriétaires',
  import_file: 'Importer le fichier',
  the_file_field_is_required: 'Le champ de fichier est requis.',

  apartment_name: "Nom de l'appartement",
  title: 'Titre',
  first_name_is_required: 'Le prénom est requis',
  last_name_is_required: 'Le nom de famille est requis',
  status_is_required: 'Le statut est requis',
  living_space_is_required: 'La superficie habitable est requise',
  stwe_number_is_required: 'Le numéro STWE est requis',
  street_name_is_required: 'Le nom de rue est requis',
  apartment_quota_is_required: "Le Quotas d'objets est requis",
  country_is_required: 'Le pays est requis',
  invalid_phone_number: 'Numéro de téléphone non valide',
  appartment_is_required: "L'appartement est requis",
  house_number_is_required: 'Le numéro de maison est requis',
  town_is_required: 'Le Ville est requis',
  zip_code_is_required: 'Le code postal est requis',
  phone_number_is_required: 'Le numéro de téléphone est requis',

  mr: 'M.',
  Mr: 'M.',
  mrs: 'Mme',
  misc: 'Divers',

  insurance_policy: 'Insurance policy',
  stwe_foundation_contract: 'STWE foundation contract',
  stwe_regulations: 'STWE regulations',
  house_rules: 'House rules',
  annual_invoice: 'Annual invoice',
  annual_report: 'Annual report',
  minutes: 'Minutes',
  investment_plan: 'Investment plan',
  budget: 'Budget',
  correspondence: 'Correspondence',
  invitation: 'Invitation',
  audit_report: `Rapport d'audit`,
  power_of_attorney: `Pouvoir de l'avocat`,
  plans: 'Plans',
  service_charge_invoice: 'Facture de frais de service',
  offer: 'Offre',
  report: 'Rapport',
  locking_plan: 'Plan de fermeture',
  await_contact_details: 'Coordonnées du concierge',
  restructuring_report: 'Rapport de restructuration',
  additional_contribution_to_reserve_fund_invoice:
    'Contribution supplémentaire au compte du fonds de réserve',
  additional_contribution_reserve_fund_list:
    'Contribution supplémentaire au fonds de réserve Liste',

  assignment: 'Assignment',
  assignment_placeholder: 'Select Assignment',
  assignment_required: 'Assignment is required',

  year: 'Year',
  year_placeholder: 'Select Year',
  year_required: 'Year is required',

  tag: 'Tag',
  tag_placeholder: 'Select Tag',
  tag_required: 'Tag is required',

  document_upload_info: `Please upload a document first and assign for it the corresponding assignment,
   the appropriate designation and the respective year.`,
  document_upload_info2: `Veuillez d'abord sélectionner une unité pour pouvoir attribuer le document à 
  un propriétaire ici.`,
  current_owner: 'Current Owner',
  apartment_number: "Numéro d'appartement",
  previous_owner: 'Previous owner',

  document_owner: 'Document - Owner',
  document_units: 'Document - Unités',
  total: 'Total',

  select_owners_validation: 'Please select owners',
  select_object_validation: 'Please select objects',
  document_upload: 'Document Upload',
  reset: 'Réinitialisation',
  reset_selection: 'Réinitialiser la sélection',
  reset_warning:
    "Voulez-vous vraiment réinitialiser la sélection que vous avez faite jusqu'à présent ?",
  document_properties: 'Propriétés du document',
  remove_assignment: "Supprimer l'affectation'",
  remove_assignment_warning:
    'Voulez-vous vraiment supprimer des unités déjà attribuées?',
  document_uploading: 'Document en cours de téléchargement',
  assigned_units: 'Unités attribuées',
  other_units: 'Autres unités',
  delete_document_info:
    'Voulez-vous vraiment supprimer le document {0} de manière irréversible ?',
  delete_document_notes:
    'Veuillez noter que le document sélectionné est toujours lié aux unités et propriétaires suivants:',
  delete_document_warning: `Attention : En cliquant sur "Supprimer", le document sélectionné sera également supprimé de manière irréversible de toutes les unités et de tous les propriétaires qui y sont liés`,
  document_deleted_successfully: 'Le document a été supprimé',

  /**FAQ Service */
  frequently_ask_question: 'Question fréquemment posée',
  faq: 'FAQ',
  all_faq: 'Toutes les FAQ',
  success: 'Succès',
  group: 'Groupes',
  all_groups: 'Tous les groupes',
  add_faq: 'Ajouter FAQ',
  edit_faq: 'Modifier FAQ',
  delete_faq: 'Supprimer FAQ',
  select_group: 'Sélectionner le groupe',
  faq_deleted_successfully: 'FAQ supprimée avec succès',

  faq_group: 'Groupe FAQ',
  add_group: 'Ajouter un groupe',
  edit_group: 'Modifier le groupe',
  delete_group: 'Supprimer le groupe',
  invalid_data: 'Les données données sont invalides',
  download: 'Télécharger',
  download_image: "Télécharger l'image",
  select_date: 'Sélectionner une date',
  select_date_time: 'Sélectionner une date/heure',

  /*Internet Status*/
  wifi: 'La connexion Internet a été restaurée.',
  nowifi: 'Vous naviguez en mode hors ligne.',
  refresh: 'Actualiser',

  /*required*/
  required_first_name: 'Le prénom est requis.',
  required_last_name: 'Le nom de famille est requis.',
  required_email: "L'email est requis.",
  required_cellphone: 'Le numéro de téléphone est requis.',
  required_role: 'Le rôle est requis.',
  required_address: "L'adresse est requise.",
  required_type: 'Le type est requis.',
  required_document_name: 'Le nom du document est requis.',
  required_upload: "Le téléchargement d'un fichier est requis",
  required_group_name: 'Le nom du groupe est requis.',
  required_status: 'Le statut est requis.',
  required_owner: 'Le propriétaire est requis.',
  required_ownership: 'La propriété est requise.',
  only_one_file_is_allowed: 'Un seul fichier est autorisé.',
  required_password: 'Un mot de passe est requis.',
  required_sleeping_facilities:
    'Des installations de couchage sont nécessaires',
  required_pets_allowed: 'Le champ "Animaux autorisés" est obligatoire',

  /*Message*/
  email_taken: "L'email a déjà été pris.",

  /* Appartment Address Form*/
  address_location: 'Adresse & Emplacement',
  address_adittion: "Suffixe d'adresse",
  distance_lake: 'Distance du lac',
  distance_shopping: 'Distance du shopping',
  distance_restaurant: 'Distance du restaurant',
  distance_city: 'Distance de la ville',
  distance_train_station: 'Distance de la gare',
  distance_bank_atm: 'Distance banque / ATM',
  distance_doctor: 'Distance du médecin',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'Les cartes / directions de Google Maps facilitent la navigation des invités vers votre appartement et offrent une transparence maximale',
  type_is_required: 'Le type est requis',
  pets_allowed: 'Animaux acceptés',

  /**lyrics form */
  headline: 'A la une',
  full_description: 'Description complète',
  short_description: 'Brève description',
  directions: 'Directions',

  /**Settings Form */
  season_and_prices: 'Saisons et prix',
  please_assign_price:
    "Veuillez attribuer les saisons disponibles à l'appartement et donner un prix.",
  assigned: 'Attribué',
  adjust_order_of_images:
    "Le dispositif / l'ordre des images peut être facilement ajusté via glisser-déposer.",
  delete_image: "Supprimer l'image",

  // Newsletter
  newsletter_subscribed: 'Abonnement à la newsletter',
  newsletter: "Lettre d'information",
  newsletter_contact: "Lettre d'information",
  newsletters: "Lettres d'information",
  newsletter_overview: "Vue d'ensemble de la lettre d'information",
  draft: 'Brouillon',
  drafts: 'Brouillons',
  group_of_recipients: 'Groupe de destinataires',
  all_newsletters: "Toutes les lettres d'information",
  new_newsletter: "Nouvelle lettre d'information",
  create_new_newsletter: "Créer une nouvelle lettre d'information",
  recipients: 'Destinataires',
  subject: 'Objet',
  all_draft: 'Tous les brouillons',
  message: 'Message',
  attach_file: 'Joindre un fichier',
  attachment: 'Pièce jointe:',
  attachments: 'Pièces jointes:',
  preview: 'Aperçu',
  save_as_draft: 'Enregistrer en tant que brouillon',
  compose_message: 'Composer un message',
  preview_message: "Aperçu de la lettre d'information",
  download_our_app_now: 'Téléchargez notre application maintenant',
  about_us: 'À propos de nous',
  customer_care: 'Service client',
  contact_us: 'Contactez-nous',
  privacy_policy: 'Politique de confidentialité',
  subscribe: "S'abonner",
  unsubscribe: 'Se désabonner',
  select_recipient_group: 'Sélectionner le destinataire',
  edit_newsletter: "Modifier la lettre d'information",
  delete_newsletter: "Supprimer la lettre d'information",
  newsletter_deleted: "Lettre d'information supprimée.",
  newsletter_delete_error:
    "La suppression de la lettre d'information a échoué.",
  newsletter_sent_sucessfully: "Lettre d'information envoyée avec succès.",
  newsletter_saved_to_draft_sucessfully:
    'La newsletter a été sauvegardée dans le brouillon avec succès.',
  newsletter_updated_sucessfully:
    'La newsletter a été mise à jour avec succès.',
  hello: 'Bonjour',
  to_send: 'Envoyer',
  save_and_send: 'Enregistrer et envoyer',
  recipient_group_field_is_required:
    'Le champ Groupe de destinataires est obligatoire.',
  subject_field_is_required: 'Le champ "Sujet" est obligatoire.',
  message_field_is_required: 'Le champ "Message" est obligatoire.',
  send_newsletter: "Envoyer le bulletin d'information",
  attention_notification_will_be_send_continue:
    'Attention! La notification sera envoyée en continu',
  privacy: 'Vie privée',
  imprint: 'Impression',
  text_search: 'Recherche de texte',
  date: 'Date',

  dispatched: 'Expédié',
  app: 'Application',
  connect_with_us: 'Connectez-vous avec nous',
  website: 'Site web',
  newsletter_unsubscribe: 'Se désabonner de la newsletter',

  first_select_the_unit_then_the_owner:
    "Sélectionnez d'abord l'unité, puis le propriétaire",

  enter_description: 'Titre eingeben',
  // Delete Owner Message
  delete_owner_confirmation:
    'Voulez-vous vraiment supprimer irrévocablement le {0} {1}, {2} ?',
  linked_units_message:
    'Veuillez noter que le {0} sélectionné est encore lié aux unités suivantes :',
  delete_attention_message:
    'Attention : En cliquant sur Supprimer, vous supprimez irrévocablement le {0} sélectionné de toutes les unités liées en tant que telles.',

  no_object_associated_with_message:
    "Aucun autre objet n'est associé au {0} sélectionné.",

  all_roles: 'Tous les rôles',
  upload_failed: 'Le téléchargement a échoué !',
  attention: 'Attention:',

  could_not_be_uploaded: 'Impossible de télécharger.',
  documents_are_uploaded: 'Les documents sont en cours de téléchargement',
  close: 'Fermer',
};

export default fr;
