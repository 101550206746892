import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Editor } from '@tinymce/tinymce-react';
import NewsletterAPI from 'api/newsletter';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CompressedUploader from 'components/common/CompressedUploader';
import { strings } from 'config/localization';
import { NEWSLETTER_ACCEPT_FILE_TYPE } from 'constants/common';
import { ReactSelectOption } from 'constants/interfaces';
import {
  NewsletterDetail,
  NewsletterStatus,
  NewsletterStatusEnum,
} from 'constants/schema';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { BiShow, BiTrash } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  capitalizeFirstLetter,
  GroupOfRecipientOptionLocalization,
  localizeGroupOfRecipient,
} from 'utils';
import PreviewNewsletterModal from './PreviewNewsletterModal';

interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

interface Props {
  data?: NewsletterDetail | null;
  clearFileInputToggle?: boolean;
  onSubmit: (data: ComposeNewsletterFormValues) => void;
  isLoading?: boolean;
  tinymceRef: React.MutableRefObject<Editor>;
  validateMandatoryFields: () => void;
}

export interface ComposeNewsletterFormValues {
  recipient_group: string[];
  subject: string;
  message: string;
  file: File[];
  status: NewsletterStatus;
}

interface ComposeNewsletterContent {
  recipient_group: string[];
  subject: string;
  message: string;
  file: {
    fileName: string[];
    fileSize: number[];
    fileUrl: string[];
  };
  status: NewsletterStatus;
}

const ComposeNewsletterForm: React.FC<Props> = (props) => {
  let {
    data,
    clearFileInputToggle = false,
    onSubmit,
    isLoading,
    tinymceRef,
    validateMandatoryFields,
  } = props;
  const groupOfRecipientOptions = GroupOfRecipientOptionLocalization();

  const cancelConfirmRef = useRef<HTMLButtonElement>(null);
  const newsletterAPI = new NewsletterAPI();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();
  const history = useHistory();

  const deleteAttachment = useMutation((attachmentId: number) =>
    newsletterAPI.destroyAttachment(attachmentId)
  );

  const handleDeleteAttachment = (attachmentId: number) => {
    deleteAttachment.mutate(attachmentId);
  };

  const {
    register,
    formState: { errors },
    setValue,
    control,
    getValues,
    clearErrors,
  } = useFormContext<ComposeNewsletterFormValues>();

  const [composeNewsletterContent, setComposeNewsletterContent] =
    useState<ComposeNewsletterContent>({
      recipient_group: data?.recipient_group ?? [],
      subject: data?.subject ?? '',
      message: data?.message ?? '',
      file: {
        fileName: [],
        fileSize: [],
        fileUrl: [],
      },
      status: data?.status ?? NewsletterStatusEnum.DRAFT,
    });

  const addedFiles = useWatch({ name: 'file', control });
  useEffect(() => {
    if (addedFiles?.length > 0) {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: addedFiles.map((item) => item.name),
          fileSize: addedFiles.map((item) => item.size),
          fileUrl: [],
        },
      }));
    } else {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: [],
          fileSize: [],
          fileUrl: [],
        },
      }));
    }
  }, [addedFiles]);

  useEffect(() => {
    const previouslyAddedFileName: string[] =
      data?.newsletterFiles?.map((item) => item?.file_name) ?? [];
    const previouslyAddedFileSize: number[] =
      data?.newsletterFiles?.map((item) => item?.file_size) ?? [];

    if (data && data?.newsletterFiles?.length > 0) {
      setComposeNewsletterContent((prevState) => ({
        ...prevState,
        file: {
          fileName: previouslyAddedFileName,
          fileSize: previouslyAddedFileSize,
          fileUrl: [],
        },
      }));
    }
  }, [data]);

  useEffect(() => {
    setValue('recipient_group', composeNewsletterContent.recipient_group);
    setValue('subject', composeNewsletterContent.subject);
    setValue('message', tinymceRef.current.props.value ?? '');
    setValue('status', composeNewsletterContent.status);

    if (composeNewsletterContent.recipient_group.length > 0) {
      clearErrors('recipient_group');
    }
    if (composeNewsletterContent.subject) {
      clearErrors('subject');
    }
    if (tinymceRef.current.props.value !== '') {
      clearErrors('message');
    }
  }, [composeNewsletterContent, setValue, clearErrors, tinymceRef]);

  const handleSelectOptionChange = (
    recipient: readonly ReactSelectOption<string>[]
  ) => {
    const recipient_group_array = recipient?.map((element) => element.value);
    setComposeNewsletterContent((prevState) => ({
      ...prevState,
      recipient_group: recipient_group_array,
    }));
  };

  const handleEditorChange = (content: string) => {
    setComposeNewsletterContent((prevState) => ({
      ...prevState,
      message: content,
    }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const subject = e.target.value;
    setComposeNewsletterContent((prevState) => ({
      ...prevState,
      subject,
    }));
  };

  const handleClearEditor = () => {
    tinymceRef.current.editor?.setContent('');
    setComposeNewsletterContent((prevState) => ({
      ...prevState,
      file: {
        fileName: [],
        fileSize: [],
        fileUrl: [],
      },
    }));
  };

  const handleSaveAndSendButtonClick = () => {
    validateMandatoryFields();

    if (Object.keys(errors).length === 0) {
      onConfirmOpen();
    }
  };

  const handleConfirmClick = () => {
    const formValues = getValues();
    formValues.status = NewsletterStatusEnum.DISPATCHED;
    onSubmit(formValues);
  };

  const imageUploadNewsletter = async (blobInfo: BlobInfo) => {
    try {
      const formData = new FormData();
      formData.append('image', blobInfo.blob(), blobInfo.filename());
      const data = await newsletterAPI.uploadToBucket(formData);
      return data.data.file_url;
    } catch (error) {
      throw strings.error;
    }
  };

  return (
    <>
      <Stack sx={wrapperStyles} overflow="auto">
        <Grid gap="4">
          <GridItem>
            <FormControl
              width="100%"
              isInvalid={!!errors?.recipient_group}
              isRequired>
              <FormLabel>{strings.recipients}</FormLabel>
              <Controller
                control={control}
                name="recipient_group"
                rules={{
                  required: strings.recipient_group_field_is_required,
                }}
                render={({ field: { ref } }) => (
                  <ReactSelect
                    ref={ref}
                    id="recipient_group"
                    placeholder={strings.select_recipient_group}
                    styles={reactSelectStyles}
                    value={composeNewsletterContent?.recipient_group?.map(
                      (item) => ({
                        value: item,
                        label: localizeGroupOfRecipient(
                          capitalizeFirstLetter(item)
                        ),
                      })
                    )}
                    options={groupOfRecipientOptions}
                    onChange={handleSelectOptionChange}
                    isMulti
                    closeMenuOnSelect={false}
                  />
                )}
              />
              <FormErrorMessage>
                <>
                  {errors?.recipient_group && errors?.recipient_group?.message}
                </>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl width="100%" isInvalid={!!errors?.subject} isRequired>
              <FormLabel>{strings.subject}</FormLabel>
              <Input
                type="text"
                size="lg"
                placeholder={strings.subject}
                {...register('subject', {
                  required: strings.subject_field_is_required,
                  validate: (value) => {
                    if (value?.trim() === '')
                      return strings.subject_field_is_required;
                  },
                })}
                value={composeNewsletterContent?.subject}
                onChange={handleInputChange}
              />
              <FormErrorMessage>
                <>{errors?.subject && errors?.subject?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl width="100%" isInvalid={!!errors?.message} isRequired>
              <FormLabel>{strings.message}</FormLabel>
              <Controller
                control={control}
                name="message"
                rules={{
                  required: strings.message_field_is_required,
                }}
                render={() => {
                  return (
                    <Editor
                      tinymceScriptSrc={
                        process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'
                      }
                      plugins="wordcount"
                      init={{
                        icons: 'material',
                        images_upload_handler: imageUploadNewsletter,
                        plugins: 'code image link lists media',
                        toolbar:
                          ' undo redo | fontsize | fontfamily | styles | bold italic underline forecolor backcolor | link image code | align | bullist numlist | preview ',
                        font_size_formats:
                          '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                        toolbar_location: 'bottom',
                        height: 500,
                        menubar: 'false',
                        textcolor_rows: '4',
                        branding: false,
                        link_default_target: '_blank',
                      }}
                      onEditorChange={handleEditorChange}
                      value={composeNewsletterContent?.message}
                      ref={tinymceRef}
                      id="message"
                    />
                  );
                }}
              />

              <FormErrorMessage>
                <>{errors?.message && errors?.message?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <ButtonGroup
            variant="outline"
            spacing="2"
            justifyContent={'flex-end'}
            p="1"
            rounded="md">
            <Tooltip label={strings.preview} hasArrow>
              <IconButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                aria-label="Send email"
                icon={<BiShow />}
                _hover={{
                  color: 'green',
                }}
                onClick={onOpen}
              />
            </Tooltip>
            <Tooltip label={strings.clear} hasArrow>
              <IconButton
                size="sm"
                variant="outline"
                colorScheme="gray"
                aria-label="Send email"
                icon={<BiTrash />}
                _hover={{
                  color: 'red',
                }}
                onClick={handleClearEditor}
              />
            </Tooltip>
          </ButtonGroup>
          <GridItem>
            <FormControl width="100%">
              <FormLabel>{strings.upload_files}</FormLabel>
              <CompressedUploader
                uploadedFiles={data?.newsletterFiles}
                deleteAttachment={(attachmentId: number) =>
                  handleDeleteAttachment(attachmentId)
                }
                clearFileInputToggle={clearFileInputToggle}
                fileKey="file"
                acceptFileType={NEWSLETTER_ACCEPT_FILE_TYPE}
              />
            </FormControl>
          </GridItem>
        </Grid>
        <Stack>
          <AlertDialog
            motionPreset="slideInBottom"
            leastDestructiveRef={cancelConfirmRef}
            onClose={onConfirmClose}
            isOpen={isConfirmOpen}
            isCentered>
            <AlertDialogOverlay />
            <AlertDialogContent>
              <AlertDialogHeader>{strings.send_newsletter} ?</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                {strings.attention_notification_will_be_send_continue} ?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelConfirmRef} onClick={onConfirmClose}>
                  {strings.no}
                </Button>
                <Button
                  ml={3}
                  colorScheme="red"
                  isLoading={isLoading}
                  onClick={handleConfirmClick}>
                  {strings.yes}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </Stack>

        <Stack>
          <ButtonGroup justifyContent="flex-end">
            <Button
              variant="outline"
              colorScheme="primary"
              onClick={() => history.goBack()}
              size="lg">
              {strings.cancel}
            </Button>
            <Button
              size="lg"
              colorScheme="primary"
              onClick={handleSaveAndSendButtonClick}>
              {strings.send_newsletter}
            </Button>
          </ButtonGroup>
        </Stack>
      </Stack>
      <PreviewNewsletterModal
        isOpen={isOpen}
        onClose={onClose}
        composeNewsletterContent={composeNewsletterContent}
      />
    </>
  );
};

export default ComposeNewsletterForm;
