import { Address } from 'constants/schema';
import Resource from './resource';

class OMSObjectsResource extends Resource<Address> {
  constructor() {
    super(`oms/apartment-address`);
  }
}

export { OMSObjectsResource as default };
