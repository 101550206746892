import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Table,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { strings } from 'config/localization';
import { OMS_GET_ROLE_TYPES_NAME, ROLE_TYPES } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { Address, Apartment, UnitTypeEnum, UserSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { ReactElement, useState } from 'react';
import { BiEdit, BiMailSend, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { renderFullName } from 'utils';

interface Props {
  userData: UserSchema;
  index: number;
  search?: string;
}
const UserListItem: React.FC<Props> = (props) => {
  const { userData, index, search } = props;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const userAPI = new UserResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const checkEditUserPermission = useCheckPermission(
    [PermissionRequest['manage:user']],
    routes.users.edit.replace(':id', userData.id.toString())
  );

  const checkEditOwnerPermission = useCheckPermission(
    [PermissionRequest['manage:user']],
    routes.users.owner.edit.replace(':id', userData.id.toString())
  );
  const checkEditCareTakerPermission = useCheckPermission(
    [PermissionRequest['manage:caretaker']],
    routes.users.careTaker.edit.replace(':id', userData.id.toString())
  );

  const sendVerificationEmail = () =>
    sendVerificationEmailMutation.mutate(userData.email);

  const [deleteMsg, setDeleteMsg] = useState('');

  const sendVerificationEmailMutation = useMutation(
    userAPI.sendEmailVerificaiton,
    {
      onSuccess: () => {
        toast({
          title: strings.email_verification_sent,
          status: 'success',
          isClosable: true,
        });
      },

      onError: () => {
        toast({
          title: strings.error_boundary_heading_text,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const deleteUser = useMutation(() => userAPI.destroy(userData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('userList');

      setDeleteModalOpen(false);
      setSelectedUserId(null);
      toast({
        title: strings.user_deleted,
        status: 'success',
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setSelectedUserId(userData.id);
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setSelectedUserId(null);
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUser.mutate();
  };

  const isOwner = userData?.role === ROLE_TYPES.OWNER;
  const isCareTaker = userData?.role === ROLE_TYPES.CARETAKER;

  const isOwnerOrCareTaker = isOwner || isCareTaker;

  const link = () => {
    if (isOwner) {
      return (
        routes.users.owner.profile.replace(':id', userData.id.toString()) +
        search
      );
    }

    if (isCareTaker) {
      return (
        routes.users.careTaker.profile.replace(':id', userData.id.toString()) +
        search
      );
    }

    return routes.users.profile.replace(':id', userData.id.toString()) + search;
  };

  const clickEdit = () => {
    if (isOwner) {
      checkEditOwnerPermission();
    } else if (isCareTaker) {
      checkEditCareTakerPermission();
    } else {
      checkEditUserPermission();
    }
  };

  const { data: ownersOrCareTakerQuery, isLoading } = useQuery(
    [`user${selectedUserId}-get`, selectedUserId],
    () =>
      userAPI
        .getOmsOwnerOrCareTaker(
          selectedUserId!,
          isOwner
            ? OMS_GET_ROLE_TYPES_NAME.OWNERS
            : OMS_GET_ROLE_TYPES_NAME.CARETAKERS
        )
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0, // For update on navigation if the owner changed their data.
      enabled: selectedUserId != undefined && isOwnerOrCareTaker,
    }
  );

  const renderApartments = (apartments: Apartment[]) => {
    return apartments.map((item: Apartment) => (
      <Tr key={item.id}>
        <Td paddingLeft="0">{item.name}</Td>
        <Td>{item.type}</Td>
        <Td paddingRight="0">
          {item.type === UnitTypeEnum.GARAGE
            ? item.garage_number
            : item.stwe_number}
        </Td>
      </Tr>
    ));
  };

  const renderDeleteUserModalBody = () => {
    const roleString = strings.getString(userData?.role?.split(' ').join('_'));

    if (isOwnerOrCareTaker) {
      return (
        <Flex display="flex" flexDirection="column" gap="32px">
          <Text fontWeight="none" color="blackAlpha.700" lineHeight="24px">
            {strings.formatString(
              strings.delete_owner_confirmation,
              roleString,
              userData?.last_name,
              userData?.first_name
            )}
          </Text>
          <Text fontWeight="400" color="blackAlpha.700" lineHeight="24px">
            {strings.formatString(strings.linked_units_message, roleString)}
          </Text>
          <Box>
            <Text fontWeight="medium" color="blackAlpha.700">
              {strings.units}
            </Text>
            <Box paddingLeft="12px" overflow="auto" maxHeight="150px">
              <Table fontSize="14px" lineHeight="12px">
                {ownersOrCareTakerQuery?.data?.addresses?.length > 0 ||
                ownersOrCareTakerQuery?.data?.apartments?.length > 0 ? (
                  renderApartments(
                    isOwner
                      ? ownersOrCareTakerQuery?.data?.apartments
                      : ownersOrCareTakerQuery?.data?.addresses?.flatMap(
                          (data: Address) => data?.apartments
                        )
                  )
                ) : (
                  <Text
                    fontWeight="400"
                    lineHeight="24px"
                    textAlign="center"
                    padding="12px">
                    {strings.formatString(
                      strings.no_object_associated_with_message,
                      roleString
                    )}
                  </Text>
                )}
              </Table>
            </Box>
          </Box>

          <Text color="red.400" lineHeight="24px">
            {strings.formatString(
              strings.delete_attention_message,
              strings.getString(userData?.role?.split(' ').join('_'))
            )}
          </Text>
        </Flex>
      );
    } else {
      return (
        <>
          {strings.confirm_delete}
          {deleteMsg && (
            <Alert status="error" mb={2}>
              <AlertIcon />
              {deleteMsg}
            </Alert>
          )}
        </>
      );
    }
  };

  return (
    <Tr key={userData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink to={link}>
          <Stack direction="row" alignItems="center">
            <Avatar
              size="sm"
              rounded="full"
              src={userData.profile_picture_url}
            />
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {renderFullName(userData.first_name, userData.last_name)}
            </Text>
          </Stack>
        </RouterLink>
      </Td>
      <Td>
        {!isOwner
          ? userData.address
          : `${userData.owner_details.street} ${userData.owner_details.house_number}, ${userData.owner_details.zip_code}, ${userData.owner_details.town}`}
      </Td>
      <Td>{userData.email}</Td>
      <Td>{userData.contact_number || '-'}</Td>
      <Td>
        <Text textTransform="capitalize">
          {strings.getString(userData?.role?.split(' ').join('_'))}
        </Text>
      </Td>
      <Td>
        <Stack position="relative" direction="column">
          <Switch
            size="md"
            name="has_payment_enable"
            colorScheme="green"
            isChecked={userData.has_2fa_enabled}
            isDisabled={true}
          />
        </Stack>
      </Td>
      <Td textAlign="right">
        <RouterLink to={link}>
          <Tooltip hasArrow label={strings.view_user}>
            <IconButton
              icon={<BiShow size="18" />}
              variant="link"
              aria-label={strings.view_user}
              color="green.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <Link onClick={clickEdit}>
          <Tooltip hasArrow label={strings.edit_user}>
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_user}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </Link>
        {!!userData.email_verified_at ? null : (
          <Link onClick={sendVerificationEmail}>
            <Tooltip hasArrow label={strings.send_verification_email}>
              <IconButton
                isDisabled={sendVerificationEmailMutation.isLoading}
                icon={<BiMailSend size="18" />}
                variant="link"
                aria-label={strings.send_verification_email}
                minW="8"
              />
            </Tooltip>
          </Link>
        )}

        <Tooltip hasArrow label={strings.delete_user}>
          <IconButton
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete_user}
            color="red.300"
            minW="8"
            onClick={onDeletePress}
          />
        </Tooltip>

        {!isLoading && (
          <Modal
            isOpen={isDeleteModalOpen}
            isCentered
            onClose={onDeleteModalClose}
            size="xl">
            <ModalOverlay />
            <ModalContent maxW="700px">
              <ModalHeader padding="24px">{strings.delete_user}</ModalHeader>
              <ModalCloseButton />
              <ModalBody fontSize="18px" paddingTop="8px">
                {renderDeleteUserModalBody()}
              </ModalBody>
              <ModalFooter>
                <ButtonGroup>
                  <Button variant="outline" onClick={onDeleteModalClose}>
                    {strings.no_thank_you}
                  </Button>
                  <AccessControl
                    allowedPermissions={[PermissionRequest['manage:user']]}
                    renderNoAccess={(data: ReactElement) => data}>
                    <Button
                      colorScheme="red"
                      onClick={onDeleteConfirm}
                      isLoading={deleteUser.isLoading}>
                      {strings.delete}
                    </Button>
                  </AccessControl>
                </ButtonGroup>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </Td>
    </Tr>
  );
};

export default UserListItem;
