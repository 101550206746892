import {
  Avatar,
  Box,
  Flex,
  Icon,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { getTag } from 'components/common/PriorityTag';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { TicketPriority } from 'constants/schema';
import React from 'react';
import { BiBuildingHouse } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { truncateString } from 'utils';
import { DateTimeFormat } from 'utils/DateFormat';

interface Props {
  ticketId: number;
  title: string;
  appointment_date: string;
  object: string;
  assignedUserStatus: boolean;
  assignedUserName: string;
  priority: TicketPriority;
  avatar: string;
  search?: string;
}

const TaskListItem: React.FC<Props> = (props) => {
  const {
    ticketId,
    assignedUserStatus,
    assignedUserName,
    title,
    appointment_date,
    object,
    priority,
    avatar,
    search,
  } = props;
  return (
    <Box
      border="1px"
      borderColor="gray.300"
      p="2"
      mx="2"
      position="relative"
      rounded="lg">
      <Stack direction="row" spacing="3">
        <Stack spacing={2} flex="1">
          <Stack direction="row">
            <Link
              to={
                routes.ticket.task.view.replace(':id', ticketId.toString()) +
                search
              }>
              <Tooltip hasArrow label={title} fontSize="xs">
                <Text
                  color="heading"
                  fontWeight="medium"
                  _hover={{ color: 'primary.400' }}>
                  {truncateString(title, 20)}
                </Text>
              </Tooltip>
            </Link>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="end">
            <Stack direction="column" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                {assignedUserName && (
                  <>
                    <Avatar src={avatar} w="4" h="4" />
                    <Tooltip hasArrow label={strings.assignee} fontSize="xs">
                      <Box fontSize="xs" fontWeight="normal" color="gray.400">
                        <CustomTagTitle
                          status={assignedUserStatus}
                          name={assignedUserName}
                        />
                      </Box>
                    </Tooltip>
                  </>
                )}
              </Stack>
            </Stack>
            {object && (
              <Flex
                title={object}
                _hover={{ cursor: 'pointer' }}
                style={{ marginLeft: 'auto' }}>
                <Icon as={BiBuildingHouse} w={6} h={6} color="gray.400" />
                <Tooltip hasArrow label={strings.object} fontSize="xs">
                  <Text
                    ml="2"
                    alignSelf="flex-end"
                    fontSize="sm"
                    fontWeight="light"
                    color="gray.500">
                    {truncateString(object, 10)}
                  </Text>
                </Tooltip>
              </Flex>
            )}
          </Stack>
          <Box borderBottom="1px" borderColor="gray.100"></Box>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Text
                fontSize="xs"
                fontWeight="normal"
                color="gray.400"
                lineHeight={1}>
                {appointment_date ? strings.appointment : ''}
              </Text>
              <Text
                fontSize="xs"
                fontWeight="normal"
                color="gray.400"
                alignSelf="left"
                lineHeight={1}>
                {DateTimeFormat(appointment_date)}
              </Text>
            </Stack>
            <Tooltip hasArrow label={strings.priority} fontSize="xs">
              <Flex alignSelf="right">{getTag(priority)}</Flex>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default TaskListItem;
