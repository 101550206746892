import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../store';

const useLoggedInUser = () => {
  const { loggedInUser } = useSelector(
    (state: RootState) => ({
      loggedInUser: state?.data?.auth?.user,
    }),
    shallowEqual
  );

  if (!loggedInUser) {
    window.location.replace(routes.auth.login);
  }

  // The explicit cast is justified by the fact that if
  // `loggedInUser` is `null`, the user is redirected to
  // the login screen anyways.
  return loggedInUser as UserSchema;
};

export default useLoggedInUser;
