import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';
import { strings } from 'config/localization';
import {
  ApartmentOwnerDocument,
  Document,
  UnitTypeEnum,
} from 'constants/schema';
import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { renderFullName } from 'utils';

interface Props {
  selectedForDelete?: Document | null;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const DocumentDeleteModal: React.FC<Props> = (props) => {
  const { selectedForDelete, isOpen, onClose, refetch } = props;

  const ownerDocumentAPI = new OwnerDocumentResource();
  const id = selectedForDelete?.id;

  const toast = useToast();

  const ownerDocumentQuery = useQuery(
    [`owner-document${id}-get`, id],
    () => {
      if (!id) {
        return;
      }
      return ownerDocumentAPI.get(id).then((res) => res.data);
    },
    {
      enabled: !!id,
    }
  );

  const deleteDocumentMutation = useMutation(
    () => ownerDocumentAPI.destroy(id),
    {
      onSuccess: () => {
        refetch();
        onClose();
        toast({
          title: strings.document_deleted_successfully,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const ownerDocumentsList: ApartmentOwnerDocument[] =
    ownerDocumentQuery?.data?.data?.apartment_owner_documents ?? [];

  const uniqueApartmentIdsArray = Array.from(
    new Set(
      ownerDocumentsList?.map((item) => item.apartment_owner.apartment_id)
    )
  );

  const renderList = () => {
    if (ownerDocumentQuery.isLoading) {
      return (
        <Box p={2} mx="4">
          <Skeleton height="5" />
          <Skeleton height="4" mt="8" />
        </Box>
      );
    }

    return uniqueApartmentIdsArray?.map((e) => {
      const uniqueOwners = ownerDocumentsList.filter(
        (item) => item.apartment_owner.apartment_id === e
      );

      return (
        <Table key={e} mt={2}>
          <Thead>
            <Tr>
              <Th>
                <Stack direction={['column', 'row']}>
                  <Text color="paragraph">
                    {
                      uniqueOwners[0].apartment_owner.apartment?.info.address
                        .name
                    }
                  </Text>
                  <Spacer />
                  <Text color="paragraph">
                    {strings.getString(
                      uniqueOwners[0].apartment_owner.apartment?.info.type ?? ''
                    )}
                  </Text>
                  <Text color="paragraph">
                    {uniqueOwners[0].apartment_owner.apartment?.info.type ===
                    UnitTypeEnum.APARTMENT
                      ? uniqueOwners[0].apartment_owner.apartment?.info
                          .stwe_number
                      : uniqueOwners[0].apartment_owner.apartment?.info.type ===
                        UnitTypeEnum.GARAGE
                      ? uniqueOwners[0].apartment_owner.apartment?.info
                          .garage_number
                      : null}
                  </Text>
                </Stack>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {uniqueOwners.map((item) => (
              <Tr key={item.id}>
                <Td>
                  {renderFullName(
                    item?.apartment_owner?.owner?.first_name,
                    item?.apartment_owner?.owner?.last_name
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      );
    });
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{strings.delete_general_document}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            {strings.formatString(
              strings.delete_document_info,
              selectedForDelete?.name ?? ''
            )}
          </Box>
          <Box mt="8">{strings.delete_document_notes}</Box>

          <TableContainer mt={10} overflowY="auto" maxHeight="350px">
            {renderList()}
          </TableContainer>

          <Box color="red.500" mt="8">
            {strings.delete_document_warning}
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={onClose}>
              {strings.no_thank_you}
            </Button>
            <Button
              variant="solid"
              isLoading={deleteDocumentMutation.isLoading}
              onClick={() => deleteDocumentMutation.mutate()}
              colorScheme="red">
              {strings.delete}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DocumentDeleteModal;
