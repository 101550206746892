import {
  Box,
  Center,
  Flex,
  IconButton,
  Image,
  Link,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { withAuthState } from 'components/hoc/auth';
import ChangeLanguagePopOver from 'components/sidebar/ChangeLanguagePopOver';
import MenuLink from 'components/sidebar/MenuLink';
import SidebarMenu from 'components/sidebar/SidebarSubMenu';
import routes from 'constants/routes';
import { AuthProps } from 'constants/schema';
import { useTenantContext } from 'context/TenantContext';
import { serviceListData } from 'data/serviceListData';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  BiChevronsLeft,
  BiChevronsRight,
  BiEdit,
  BiLogOut,
  BiUser,
} from 'react-icons/bi';
import { IoSettingsSharp } from 'react-icons/io5';
import { NavLink, useLocation } from 'react-router-dom';
import HideControl from 'services/HideControl';
import history from 'utils/history';
import { strings } from '../../config/localization';
import useLoggedInUser from '../../hooks/useLoggedInUser';

const navBoxStyles = {
  px: '5px',
  py: '15px',
  cursor: 'pointer',
};

const overlayCoverStyles = {
  backgroundColor: 'rgba(0,0,0,0.3)',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '140vh',
  zIndex: 10,
};

const hoverMenuBoxStyles = {
  maxW: '220px',
  borderRadius: 'sm',
  bg: 'primary.400',
  color: 'white',
  boxShadow: 'dark-lg',
};

const listItemHoverStyle = {
  py: '12px',
  px: '6px',
  borderRadius: 'base',
  _hover: {
    bg: 'rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
  },
};

const Sidebar: React.FC<AuthProps> = (props) => {
  const { logout } = props;
  const location = useLocation();
  const [isLargerThan1140] = useMediaQuery('(min-width: 1140px)');
  const isInitialMount = useRef(true);

  // Sidebar is collapsed for splash screen and my profile
  const isSidebarNotExpandable =
    location.pathname === routes.splash ||
    location.pathname === routes.profile.myProfile ||
    location.pathname === routes.profile.edit;

  const [isExpanded, setIsExpanded] = useState<boolean>(() =>
    JSON.parse(localStorage.getItem('sidebarExpandable') || 'false')
  );

  const loggedInUser = useLoggedInUser();

  const onLogoutClick = async () => {
    try {
      logout();
    } catch (err) {}
  };

  /**
   * Callback function for the menu click
   *
   * @param route route to switch next
   * @param theme theme-variable
   * @returns
   */
  const handleMenuClick = (route: string) => {
    const isSameSubsystem =
      location.pathname.split('/')[1] === route.split('/')[1];

    if (isSameSubsystem && !isLargerThan1140) {
      setIsExpanded((prevState) => !prevState);
      return;
    }
    history.push(route);
  };

  useEffect(() => {
    localStorage.setItem('sidebarExpandable', JSON.stringify(isExpanded));
  }, [isExpanded]);

  // Collapse the sidebar when the user clicks on other menu in smaller screen.
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else if (!isLargerThan1140) {
      setIsExpanded(false);
    }
  }, [location.pathname, isLargerThan1140]);

  // Show overlay backdrop for iPad when the sidebar is expanded
  const shouldShowOverlayBackdrop = isExpanded && !isLargerThan1140;
  // Sidebar pushes the content to the right in larger screen
  const isSidebarOfOverlayType = !isLargerThan1140;
  // Show submenus on hover (on popover) only if the sidebar is collapsed in larger screen
  const shouldShowHoverSubMenus =
    isSidebarNotExpandable || (!isExpanded && isLargerThan1140);
  // Enable tooltip on menus only for larger screen when the sidebar is expanded
  const shouldDisableToolTipOnMenus =
    isSidebarNotExpandable || (!isExpanded && isLargerThan1140);

  const { tenant } = useTenantContext();

  return (
    <>
      {shouldShowOverlayBackdrop && (
        <Box onClick={() => setIsExpanded(false)} sx={overlayCoverStyles} />
      )}
      {isSidebarOfOverlayType && <Box w="45px" />}
      <Stack
        direction="row"
        spacing="0"
        h="100vh"
        position={isSidebarOfOverlayType ? 'absolute' : 'relative'}>
        <Stack
          direction="row"
          spacing="0"
          bg={tenant.sidebar}
          w="50px"
          h="100%"
          overflowX="hidden"
          overflowY="auto"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}>
          <Stack
            direction="column"
            w="50px"
            minW="50px"
            justifyContent="space-between">
            <Flex direction="column" color="white">
              <Box
                position="relative"
                borderBottom="1px solid rgba(255, 255, 255, 0.7);">
                <Link as={NavLink} to={routes.splash}>
                  <Center sx={navBoxStyles}>
                    {tenant.logo ? (
                      <Image src={tenant.logo} alt="company-logo" />
                    ) : null}
                  </Center>
                </Link>
              </Box>
              {/* Display menubar  */}
              {serviceListData.map((menu) => (
                <HideControl
                  key={menu.id}
                  hideFor={menu.hideForLabel}
                  renderNoAccess={(data: ReactElement) => {
                    return data ? data : <></>;
                  }}>
                  <MenuLink
                    menu={menu}
                    shouldDisableToolTipOnMenus={shouldDisableToolTipOnMenus}
                    shouldShowHoverSubMenus={shouldShowHoverSubMenus}
                    handleClickCallback={handleMenuClick}
                  />
                </HideControl>
              ))}
            </Flex>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center">
              <Box mt={10}>
                <Tooltip
                  hasArrow
                  label={
                    isExpanded
                      ? strings.collapse_sidebar
                      : strings.expand_sidebar
                  }>
                  <IconButton
                    icon={isExpanded ? <BiChevronsLeft /> : <BiChevronsRight />}
                    variant="link"
                    aria-label={
                      isExpanded
                        ? strings.collapse_sidebar
                        : strings.expand_sidebar
                    }
                    color="white"
                    size="lg"
                    fontSize="x-large"
                    onClick={() => setIsExpanded((prevState) => !prevState)}
                    isDisabled={isSidebarNotExpandable}
                  />
                </Tooltip>
              </Box>
              <Popover placement="top" trigger="hover">
                <PopoverTrigger>
                  <Box>
                    <IconButton
                      icon={<IoSettingsSharp />}
                      variant="link"
                      aria-label={strings.profile}
                      color="white"
                      fontSize="large"
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent sx={hoverMenuBoxStyles} zIndex="popover">
                  <PopoverArrow bg="primary.400" />
                  <PopoverBody>
                    <List>
                      <ListItem
                        sx={listItemHoverStyle}
                        onClick={() => history.push(routes.profile.myProfile)}>
                        <Stack direction="row" alignItems="center" spacing={4}>
                          <BiUser />
                          <Text color="white">{strings.my_profile}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem
                        sx={listItemHoverStyle}
                        onClick={() => history.push(routes.profile.edit)}>
                        <Stack direction="row" alignItems="center" spacing={4}>
                          <BiEdit />
                          <Text color="white">{strings.edit_profile}</Text>
                        </Stack>
                      </ListItem>
                      <ListItem sx={listItemHoverStyle}>
                        <ChangeLanguagePopOver />
                      </ListItem>
                      <ListItem sx={listItemHoverStyle} onClick={onLogoutClick}>
                        <Stack direction="row" alignItems="center" spacing={4}>
                          <BiLogOut />
                          <Text color="white">{strings.loggedOut}</Text>
                        </Stack>
                      </ListItem>
                    </List>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Stack>
          </Stack>
        </Stack>

        {!isSidebarNotExpandable && (
          <Box
            transform={`translateX('0')`}
            width={isExpanded ? '190px' : '0px'}
            bg="primary.500"
            height={['130vh', '100vh']}
            transition="all 0.4s ease-in-out"
            zIndex={!isLargerThan1140 ? 'banner' : 'auto'}
            overflow="hidden">
            <SidebarMenu />
          </Box>
        )}
      </Stack>
    </>
  );
};

export default withAuthState(Sidebar);
