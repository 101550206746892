import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';

import { wrapperStyles } from 'assets/css/commonStyles';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import { DMS_ACCEPT_FILE_TYPE } from 'constants/common';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getCurrentYear, getYearsList } from 'utils';
import OwnerShipDocumentsSelection from './OwnerShipDocumentsSelection';

interface Props {
  isLoading: boolean;
}

interface PostDataInterface {
  ownerIds: number[];
  apartmentIds: number[];
  ownerShipIds: number[];
}

export interface OwnerDocumentFormValues {
  upload_files: File;
  name: string;
  tag: string;
  year: number;
  postData: PostDataInterface;
}

const OwnerDocumentForm: React.FC<Props> = (props) => {
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useFormContext<OwnerDocumentFormValues>();

  const { isLoading } = props;

  const [postData, setPostData] = useState<PostDataInterface>({
    ownerIds: [],
    apartmentIds: [],
    ownerShipIds: [],
  });

  const [clearFileInputToggle, setClearFileInputToggle] = useState(false);

  useEffect(() => {
    setValue('postData', postData);
    clearErrors();
  }, [postData]);

  const documentsAPI = new OwnerDocumentResource();

  const toast = useToast();

  const { data: tagsList } = useQuery(
    'tags-list',
    () => documentsAPI.getDocumentTags().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const resetForm = () => {
    setClearFileInputToggle((prevState) => !prevState);
    reset();
    setPostData({
      ownerIds: [],
      apartmentIds: [],
      ownerShipIds: [],
    });
    onClose();
  };

  const { isOpen, onClose, onOpen } = useDisclosure();
  const currYear = getCurrentYear();

  return (
    <>
      <Stack direction="column" spacing="4" sx={wrapperStyles}>
        <Grid templateColumns="repeat(3, 1fr)" gap={8}>
          <GridItem w="100%" colSpan={2}>
            <FormControl isInvalid={!!errors?.upload_files} isRequired={true}>
              <FormLabel>{strings.upload_files}</FormLabel>
              <Uploader
                fileKey="upload_files"
                acceptFileType={DMS_ACCEPT_FILE_TYPE}
                maxFiles={1}
                clearErrors={clearErrors}
                multiple={false}
                clearFileInputToggle={clearFileInputToggle}
              />
              <FormErrorMessage>
                <>{errors?.upload_files && errors?.upload_files?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <Stack direction="column" spacing="4">
              <FormControl colorScheme="primary" isInvalid={!!errors.name}>
                <FormLabel color="gray.600">{strings.name}</FormLabel>
                <Input
                  id="role_group"
                  placeholder={strings.document_name}
                  rounded="sm"
                  {...register('name')}
                />

                <FormErrorMessage>
                  <>{errors?.name && errors?.name?.message}</>
                </FormErrorMessage>
              </FormControl>

              {tagsList && (
                <FormControl
                  colorScheme="primary"
                  isInvalid={!!errors.tag}
                  isRequired>
                  <FormLabel color="gray.600">{strings.tag}</FormLabel>
                  <CustomChakraSelect
                    id="role_group"
                    placeholder={strings.tag_placeholder}
                    rounded="sm"
                    {...register('tag', {
                      required: strings.tag_required,
                    })}>
                    {tagsList?.data.map((item) => (
                      <option key={item} value={item}>
                        {strings[item]}
                      </option>
                    ))}
                  </CustomChakraSelect>

                  <FormErrorMessage>
                    <>{errors?.tag && errors?.tag?.message}</>
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl
                colorScheme="primary"
                isInvalid={!!errors.year}
                isRequired>
                <FormLabel color="gray.600">{strings.year}</FormLabel>
                <CustomChakraSelect
                  defaultValue={currYear}
                  id="year"
                  placeholder={strings.year_placeholder}
                  rounded="sm"
                  {...register('year', {
                    required: strings.year_required,
                  })}>
                  {getYearsList(1960).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </CustomChakraSelect>
                <FormErrorMessage>
                  <>{errors?.year && errors?.year?.message}</>
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>

      <Box mt="5">
        <OwnerShipDocumentsSelection
          postData={postData}
          setPostData={setPostData}
        />
      </Box>

      <ButtonGroup alignSelf="flex-end">
        <Button colorScheme="primary" variant="outline" onClick={onOpen}>
          {strings.reset}
        </Button>
        <Button colorScheme="primary" type="submit" isLoading={isLoading}>
          {strings.save}
        </Button>
      </ButtonGroup>

      <Modal isOpen={isOpen} isCentered onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.reset_selection}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.reset_warning}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={onClose}>
                {strings.no_thank_you}
              </Button>
              <Button colorScheme="blue" onClick={resetForm}>
                {strings.reset}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OwnerDocumentForm;
