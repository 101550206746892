import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { strings } from 'config/localization';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import DropdownSvg from './DropdownSvg';
import DropUpSvg from './DropUpSvg';

interface Props {
  options: OptionType[];
  disable?: boolean;
  name: string;
  defaultValue?: string;
  value?: string;
  field: 'label' | 'value';
}

export interface CountryOption {
  id?: number;
  name?: string;
  country_code?: string;
  default?: boolean;
  label?: string;
}

export interface OptionType {
  value?: number | string;
  label?: string;
}

export default function CustomSelectCountry(props: Props) {
  const formContext = useFormContext();
  const focusRef = useRef<HTMLInputElement>(null);
  const {
    isOpen: isModalOpen,
    onToggle: onModalToggle,
    onClose: onModalClose,
  } = useDisclosure();
  const { name, defaultValue, options, field } = props;

  const selectHandler = (option: OptionType | null) => {
    if (option) {
      setSelectedOption(option);
      formContext.clearErrors(name);
      onModalClose();
      formContext.setValue(name, option.value, { shouldDirty: true });
    }
  };

  const [selectedOption, setSelectedOption] = useState<OptionType>();

  useEffect(() => {
    if (defaultValue && options.find((e) => e[field] === defaultValue)) {
      setSelectedOption(options.find((e) => e[field] === defaultValue));
      formContext.setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={`container-and-modal`}>
      <div className="custom-select-container">
        <span
          aria-live="polite"
          aria-atomic="false"
          aria-relevant="additions text"
          className="outer-line-span"
        />

        <Popover
          matchWidth
          initialFocusRef={focusRef}
          gutter={1}
          isOpen={isModalOpen}
          onClose={onModalClose}>
          <PopoverTrigger>
            <div className="all-content-container" onClick={onModalToggle}>
              <div className="text-placeholder-and-input-field-container">
                {selectedOption?.label ? (
                  <div className="input-field-inner-container">
                    {selectedOption?.label}
                  </div>
                ) : (
                  <Text color="gray.500">{strings.select_country}</Text>
                )}
              </div>
              <div className="seperator-arrow-outer-container">
                <div className="arrow-inner-container" aria-hidden="true">
                  <div style={{ opacity: 1 }}>
                    {isModalOpen ? <DropUpSvg /> : <DropdownSvg />}
                  </div>
                </div>
              </div>
            </div>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              width="full"
              rootProps={{
                width: 'full',
                maxWidth: 'unset',
              }}
              border="none">
              <div style={{ border: '1px solid #EBECF2', height: '100%' }}>
                <Select
                  autoFocus
                  backspaceRemovesValue={false}
                  controlShouldRenderValue={false}
                  hideSelectedOptions={false}
                  isClearable={false}
                  isMulti={false}
                  classNamePrefix="country-select"
                  components={{ DropdownIndicator: null }}
                  menuIsOpen
                  options={options}
                  onChange={selectHandler}
                  placeholder={strings.select_country}
                  tabSelectsValue={false}
                  focusBorderColor="#007EB3"
                />
              </div>
            </PopoverContent>
          </Portal>
        </Popover>
      </div>
    </div>
  );
}
