import { AnyUnitInfo } from 'constants/schema';
import Resource from './resource';

class OMSApartmentsResource extends Resource<AnyUnitInfo> {
  constructor() {
    super(`oms/apartments`);
  }
}

export { OMSApartmentsResource as default };
