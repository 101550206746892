import { AxiosPromise, Canceler, default as axios } from 'axios';
import {
  DataWrapper,
  LoginDTO,
  MutationResponse,
  MutationResponse2,
  UserSchema,
} from 'constants/schema';
import { ResetPasswordFormValues } from 'pages/auth/ResetPassword';
import { SetPasswordFormValues } from 'pages/auth/SetPassword';
import {
  ResendVerificationEmailFormValues,
  VerifyEmailValues,
} from 'pages/auth/VerifyEmail';
import http from 'utils/http';
import { ChangePasswordFormValues } from '../components/profile/ChangePassword';
import { ProfileFormValues } from '../components/profile/ProfileForm';
import { ForgotPasswordFormValues } from '../pages/auth/ForgotPassword';

export function login(data: LoginDTO) {
  return http({
    url: '/user/login',
    method: 'post',
    data,
  });
}

export function getAuthUser(): AxiosPromise<DataWrapper<UserSchema>> {
  return http({
    url: '/user/auth/user',
    method: 'get',
  });
}

export function updateAuthUser(
  data: ProfileFormValues
): AxiosPromise<MutationResponse2> {
  return http({
    url: '/user/auth/user',
    method: 'put',
    data: data,
  });
}

export function getAuthPermission() {
  return http({
    url: '/user/auth/user-permissions',
    method: 'get',
  });
}

export function logout() {
  return http({
    url: '/user/logout',
    method: 'post',
  });
}

const CancelToken = axios.CancelToken;
let cancel: Canceler;

export function refreshUser(refreshToken: string, userID: string) {
  if (cancel) {
    cancel(); // cancel request
  }

  return http({
    url: '/user/refresh-token',
    method: 'post',
    data: { refresh_token: refreshToken, user_id: userID },
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });
}

export function forgotPassword(data: ForgotPasswordFormValues) {
  return http({
    url: '/user/forgot-password',
    method: 'post',
    data: data,
  });
}

export function changePassword(
  data: ChangePasswordFormValues
): AxiosPromise<MutationResponse> {
  return http({
    url: '/user/change-password',
    method: 'post',
    data: data,
  });
}

export function resendVerificationEmail(
  data: ResendVerificationEmailFormValues
) {
  return http({
    url: '/user/resend-verify-email',
    method: 'post',
    data: data,
  });
}

export function resetPassword(data: ResetPasswordFormValues) {
  return http({
    url: '/user/reset-password',
    method: 'post',
    data: data,
  });
}

export function setNewPassword(data: SetPasswordFormValues) {
  return http({
    url: '/user/set-new-password',
    method: 'post',
    data: data,
  });
}

export function verifyEmail(data: VerifyEmailValues) {
  return http({
    url: '/user/verify-email',
    method: 'post',
    data: data,
  });
}
