import { useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { UserPermission } from 'constants/interfaces';
import { AllowedPermissions, AuthState } from 'constants/schema';
import {
  default as React,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react';
import { connect } from 'react-redux';
import { checkPermissions } from 'utils/listInfo';

interface Props {
  userPermissions: UserPermission;
  allowedPermissions: AllowedPermissions;
  renderNoAccess?: (data: ReactElement) => ReactElement;
}

const AccessControl: React.FC<PropsWithChildren<Props>> = ({
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess = () => null,
}) => {
  const toast = useToast();
  const permitted = checkPermissions(userPermissions, allowedPermissions);

  if (permitted) {
    return <>{children}</>;
  }

  const handleClick = () => {
    toast({
      title: strings.no_permission_msg,
      status: 'error',
      isClosable: true,
    });
  };

  const element = (
    <>
      {React.Children.map(children, (child) => {
        if (isValidElement<HTMLAttributes<HTMLElement>>(child)) {
          return React.cloneElement<HTMLAttributes<HTMLElement>>(child, {
            onClick: handleClick,
          });
        }
      })}
    </>
  );
  return renderNoAccess(element);
};

// Compose AccessControl component with redux

export default connect((state: { data: { auth: AuthState } }) => ({
  userPermissions: state?.data?.auth?.permissions,
}))(AccessControl);
