import Resource from 'api/resource';
import { FAQSchema } from 'constants/schema';
import http from 'utils/http';

class FAQResource extends Resource<FAQSchema> {
  constructor() {
    super('faq/faqs');
  }
  updateFAQ(id: number, data: FormData) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'POST',
      data: data,
    });
  }
}

export { FAQResource as default };
