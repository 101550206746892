import LocalizedStrings from 'react-localization';
import Deutsch from '../lang/de';
import english from '../lang/en';
import french from '../lang/fr';
import italian from '../lang/it';

export const strings = new LocalizedStrings({
  de: Deutsch,
  en: english,
  it: italian,
  fr: french,
});
