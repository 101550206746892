import { Lang } from './types';

const en: Lang = {
  /*login Credential*/
  login: 'Login',
  login_now: 'Login',
  log_in: 'Log In',
  password: 'Password',
  email: 'Email',
  your_email_address: 'Your Email Address',
  your_password: 'Your Password',
  forgot_password: 'Forgot Password?',
  back_to_login: 'Back to Login',
  password_reset: 'Request Password Reset',
  login_message: 'Enter your credentials to login in to {0}.',
  forgot_message: 'Please enter the email you use to sign in to your account.',
  immo_portal: 'Immobilienportal',
  password_info:
    'Password must have at-least 1 number, 1 special character, 1 capital letter and 5 others characters.',
  invalid_login_msg: 'Email or password did not match.',
  user_delete_error: 'User cannot be deleted User is holding MMS Stock',
  change_language: 'Change Language',
  invalid_token: 'This password reset token is invalid.',
  two_factor_authentication: 'Two Factor Authentication',
  enter_the_pin_from_google_authentication:
    'Enter the code from Google Authentication.',
  authenticate: 'Authenticate',
  one_time_password: 'One Time Password',
  FA_status: '2FA-Status',
  disable_2FA: 'Disable 2FA',
  enable_2FA: 'Enable 2FA',
  fa_message:
    'To enable two-factor authentication, please scan this QR code with the Google Authenticator app.',
  code: 'Code',
  fa_status_tooltip: 'Generate secret key to enable 2FA',
  authenticator_code: 'Authenticator Code',
  FA_successfully: '2FA is Enable Successfully',
  FA_enable: '2FA is Currently Enabled for your account',
  fa_Disable: '2FA is now Disabled',
  authenticator_code_error: 'Authenticator Code Error',
  required_authenticator_code: 'Required Authenticator Code',
  fa_disable_title:
    'If you are looking to disable two factor authentication. Please confirm your password and click Disable 2FA button.',
  activate_2FA: 'Activate 2FA',
  deactivate_2FA: 'Deactivate 2FA',

  /*button*/
  save: 'Save',
  save_now: 'Save Now',
  filter: 'Filter',
  back: 'Back',
  editing: 'Editing',
  apply_filter: 'Apply Filter',
  reset_filter: 'Reset Filter',
  more_filter: 'More Filter',
  cancel: 'Cancel',
  clear: 'Clear',
  reply: 'Reply',
  comment: 'Comment',
  save_and_create_new_ticket: 'Save and Create New Ticket',
  confirm: 'Confirm',
  profile: 'Profile',
  my_profile: 'My Profile',
  edit_profile: 'Edit Profile',
  profile_updated: 'Profile Updated.',
  profle_update_failed: 'Profile Update Failed.',
  password_updated: 'Password Updated.',
  not_match_new_confirm_password: 'The passwords do not match.',
  incorrect_password: 'Password is not correct.',
  setting: 'Setting',
  settings: 'Settings',
  logout: 'Logout',
  loggedOut: 'Logout',
  change_password: 'Change Password',
  change_now: 'Change Now',
  Current_password: 'Current Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  invalid_current_password: 'Current password is incorrect.',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  email_not_found: 'Email address not found.',
  email_required: 'Email address is required',

  /*Module*/
  user_management: 'User Management',
  ticket: 'Tickets',
  dashboard: 'Dashboard',

  /*Splash Screen*/
  choose_subsystem: 'Choose Subsystem',

  /*User Management*/
  user_management_system: 'User Management System',
  user: 'Users',
  user_list: 'User List',
  user_rights: 'User Rights',
  user_roles: 'User Roles',
  user_name: 'User Name',
  user_profile: 'User Profile',
  user_details: 'User Details',
  role_details: 'Role Details',
  role: 'Role',
  add_additional_role: 'Add Additional Role',
  add_additional_rights: 'Add Additional Rights',
  additional_rights: 'Additional Rights',
  manage_extra_rights: 'Manage extra rights',
  right_from_role: 'Rights From Role',
  add_role: 'Add Role',
  role_setup: 'Role Setup',
  system: 'System',
  role_name: 'Role Name',
  privillage: 'Privillage',
  add_user: 'Add User',
  all_users: 'All Users',
  all_user_with_that_role: 'All user with that role',
  add_new_user: 'Add New User',
  add_new_role: 'Add New Role',
  view_user: 'View User',
  edit_user: 'Edit User',
  delete_user: 'Delete User',
  save_user: 'Save User',
  upload_image: 'Upload Image',
  drop_image_or_click_to_select_image: 'Drop Image or click to select Image',
  assigned_tickets: 'Assigned Tickets',
  access_user: 'Access user-management',
  manage_user: 'Manage user',
  access_user_log: 'Access user login log',
  manage_owner: 'Manage owner',
  manage_admin: 'Manage admin',
  manage_caretaker: 'Manage caretaker',
  access_oms: 'Access object-management',
  manage_oms: 'Manage objects',
  access_dms_ownership: 'Access DMS ownership',
  manage_dms_ownership: 'Manage DMS ownership',
  access_dms_owner: 'Access DMS owner',
  manage_dms_owner: 'Manage DMS owner',
  access_faq: 'Access FAQ',
  manage_faq: 'Manage FAQ',
  access_faq_group: 'Access FAQ group',
  manage_faq_group: 'Manage FAQ group',
  access_ticket: 'Access ticket-system',
  assign_ticket: 'Assign ticket',
  manage_own_owner_information: 'Manage own data',
  access_own_owner_object: 'Access own objects',
  access_own_owner_document: 'Access own documents',
  access_newsletter: 'See Newsletters and drafts',
  manage_newsletter: 'Edit Newsletters and drafts ',
  action: 'Action',
  first_name: 'First Name',
  last_name: 'Last Name',
  email_address: 'Email Address',
  valid_email_address: 'Please provide a valid email address.',
  contact_info: 'Contact Info',
  address: 'Address',
  view: 'View',
  add: 'Add',
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',
  login_with_new_password: 'Please login with new password',
  msg_valid_password: 'Please provide valid password.',
  msg_password_reset_link: 'We have emailed your password reset link!',
  select_role: 'Select Role',
  rights: 'Rights',
  reset_password: 'Reset Password',
  reset_password_subtitle:
    'Please enter the fields you use to reset to your account.',
  save_reset_password: 'Save Reset Password',
  create_password: 'Create Password',
  create_password_subtitle:
    'Please enter the fields to create a password for your account.',
  save_password: 'Save Password',
  password_helper_text:
    'Minimum 8 chars, at least one uppercase, one lowercase, one number and one special char.',
  resend_verification_email: 'Resend verification email',
  verification_email_sent: 'Verification email sent',
  email_verification_success: 'Email verified successfully.',
  email_verification_failed: 'Email verification failed.',
  resend_verify_email_error: 'Error resending email verification.',
  resend_verify_email_success:
    'Verification email has been sent successfully. Check your inbox.',
  resend_verify_email_success_to:
    'Verification email has been sent successfully to',
  email_already_verified: 'Email is already verified.',
  login_to_continue: 'Please Login to continue.',
  create_password_to_proceed: 'Create a password to proceed.',
  change_confirm: 'Are you sure you want to change this ?',
  cellphone: 'Cellphone',
  invalid_cellphone: 'Invalid Cellphone',
  email_placeholder: 'youremail@example.com',
  admin: 'Admin',
  verified: 'Verified',
  not_yet_verified: 'Not yet verified',

  user_login_log: 'User Login Logs',
  all_login_logs: 'All Login Logs',
  correspondence_language: 'Correspondence Language',
  correspondence_language_placeholder: 'Select Correspondence Language',
  correspondence_language_required: 'Correspondence Language is required',

  adittion: 'Adittion',
  deceased: 'Deceased',
  deceased_since: 'Deceased Since',
  address_forwarding: 'Address Forwarding',
  data_sharing: 'Data sharing',
  data_sharing_activated: 'Data sharing activated',
  owner_created: 'Owner has been created',
  employee_with_ticket_support: 'Employee With Ticket Support',
  salutation: 'Salutation',
  select_salutation: 'Select salutation',
  salutation_is_required: 'Salutation is required',
  valid_only_number: 'Please enters only numbers.',
  city_is_required: 'City is required',
  notes_notizen: 'Notes',
  notes_cannot_be_more_than_2000_characters_long:
    'Notes cannot be more than 2000 characters long',
  max_characters_exceeded: 'Maximum character limit exceeded',
  add_new_owner: 'Add New Owner',
  add_new: 'Add New {0}',
  employee: 'Employee',
  caretaker: 'Caretaker',
  deceased_since_is_required: 'Deceased since is required',
  owner_updated: 'Owner has been updated',
  owner_details: 'Owner Details',
  edit_owner: 'Edit Owner',
  send_verification_email: 'Send Invitation Email',
  email_verification_sent: 'Email Verification sent',

  address_forwarding_reason_guardianship: 'Guardianship',
  address_forwarding_reason_temporary_change_of_address:
    'Temporary change of address',
  address_forwarding_reason_different_billing_address:
    'Different billing address',
  address_forwarding_reason_address_of_the_property: 'Address of the property',

  reason: 'Reason',
  select_reason: 'Select reason',
  reason_is_required: 'Reason is required',

  customer_number: 'Customer Number',
  customer_number_is_required: 'Customer number is required',
  owner_since: 'Owner Since',
  required_owner_since: '"Owner since" required',

  discard_changes: 'Discard changes',
  discard_changes_warning: 'Do you really want to discard your changes?',
  discard: 'Discard',
  no_thank_you: 'No, thank you',
  caretaker_created: 'Caretaker has been created',
  add_caretaker: 'Add New Caretaker',
  caretaker_details: 'Caretaker Details',
  edit_caretaker: 'Edit Caretaker',

  sn: '#',
  name: 'Name',
  assignee: 'Assignee',
  description: 'Description',
  yes: 'Yes',
  no: 'No',
  active: 'Active',
  in_active: 'Inactive',
  select_apartment: 'Select Apartment',
  select_unit: 'Select unit',
  select_assignee: 'Select Assignee',
  select_owner: 'Select Owner',
  select_priority: 'Select Priority',
  select_source: 'Select Source',
  write_description: 'Write Description',
  csv_datasharing: 'CSV Data sharing',

  /*Ticket Board*/
  ticket_system: 'Ticket System',
  all_tickets: 'All Tickets',
  ticket_board: 'Ticket Boards',
  add_task: 'Add Task',
  open: 'Open',
  inprogress: 'In Progress',
  'in-progress': 'In Progress',
  done: 'Done',
  closed: 'Closed',
  document: 'Document',
  normal_task: 'Normal Task',
  create_ticket: 'Create Ticket',
  ticket_details: 'Ticket Details',
  ticket_title: 'Ticket Title',
  object: 'Object',
  all_objects: 'All Objects',
  customer: 'Customer',
  house_owner: 'House Owner',
  priority_label: 'Priority Label',
  priority: 'Priority',
  upload_files: 'Upload Files',
  descriptions: 'Descriptions',
  save_create_new_ticket: 'Save and Create New Ticket',
  author: 'Author',
  created_by: 'Created By',
  created_at: 'Created At',
  last_modified_by: 'Last Modified By',
  last_modified_at: 'Last modified At',
  last_changed_by: 'Last Change By',
  last_changed_at: 'Last Change At',
  status: 'Status',
  ticket_log: 'Log',
  ticket_list: 'Ticket Lists',
  repeat_task: 'Repeat Task',
  apartment: 'Apartment',
  apartments: 'Apartments',
  unit: 'Unit',
  ticket_created: 'Ticket has been created.',
  has_been_created: 'has been created.',
  has_not_been_created: 'has not been created.',
  has_been_merged: 'has been merged',
  has_not_been_merged: 'has not been merged',
  has_been_updated: 'has been updated.',
  has_not_been_updated: 'has not been updated.',
  has_been_deleted: 'has been deleted.',
  has_not_been_deleted: 'has not been deleted.',
  ticket_error: 'Ticket has not been created.',
  expand: 'Expand',
  ticket_list_error: 'There was an error processing your request.',
  title_required: 'Title is required.',
  house_owner_is_required: 'House owner is required.',
  repeating_info: 'Repeating Info',
  interval: 'Interval',
  repeat_from: 'Repeat From',
  repeat_until: 'Repeat Until',
  comment_here: 'Comment Here...',
  comment_required: 'Comment is required.',
  id_not_found: 'ID not found.',
  edit_ticket: 'Edit Ticket',
  ticket_updated: 'Ticket updated successfully',
  change_status: 'Change Status',
  status_updated: 'Status updated successfully',
  select_status: 'Select Status',
  comment_error: 'Comment error',
  view_more: 'View More',
  view_less: 'View Less',
  spent_time: 'Spent Time',
  adjust_time_spent: 'Adjust Time Spent',
  correct_spent_time: 'Correct Spent Time',
  spent_time_message: 'The spent time has been correct manually.',
  task: 'Task',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  urgent: 'Urgent',
  select_type: 'Select Type',
  select_type_art: 'Select Type',
  ticket_detail: 'Ticket Detail',
  edit_task: 'Edit Task',
  created_ticket: 'created ticket',
  updated_ticket: 'updated ticket',
  changed_status: 'changed status',
  assigned_ticket: 'assigned ticket',
  commented: 'commented',
  order_by: 'Order By',
  createdat: 'Created At',
  order: 'Order',
  asc: 'Ascending',
  desc: 'Descending',
  showing_all_options: 'Showing all options',
  appointment: 'Appointment',
  appointment_date_time: 'Appointment date and time',
  enter_appointment_date_time: 'Enter appointment date time',
  full_screen: 'Fullscreen',
  add_new_ticket: 'Add New Ticket',

  source: 'Source',
  destination: 'Destination',
  street: 'Street',
  house_no: 'House No.',
  zip_code: 'Post Code',
  town: 'Town',
  country: 'Country',
  phone: 'Phone No.',
  zip_code_abbreviation: 'ZIP',

  add_now: 'Add Now',
  date_time: 'Date/Time',
  city: 'City',
  file: 'File',
  images: 'Images',
  upload_file: 'Upload File',
  question: 'Question',
  from: 'From',
  to: 'To',
  article: 'Article',
  type: 'Type',
  objects: 'Objects',
  object_id: 'Object ID',
  tickets: 'Tickets',
  ticket_source: 'Ticket Source',
  required: 'Required',

  user_created: 'User has been created',
  user_creation_failed: 'User creation failed.',
  user_udpate_failed: 'User update failed.',
  password_update_failed: 'Password update failed.',
  user_not_found: 'User not found',
  update: 'Update',
  user_updated: 'User updated successfully',
  user_deleted: 'User has been deleted.',
  roles_not_found: 'Roles not found',
  confirm_delete: 'Are you sure you want to delete this ?',
  select: 'Select',
  collapse_sidebar: 'Collapse Sidebar',
  expand_sidebar: 'Expand Sidebar',
  max_upload_size_5: 'Maximum upload attachments size upto 5 MB.',
  max_upload_size_10: 'Maximum upload attachments size upto 10 MB.',
  max_upload_size_15: 'Maximum upload attachments size upto 15 MB.',
  max_upload_size_100: 'Maximum upload attachments size upto 100 MB.',
  max_upload_size_1GB: 'Maximum upload attachments size upto 1 GB.',
  file_upload_failed: 'File upload failed',

  drag_drop_files_msg:
    'Drag and drop files into the field or click the "+" to select files.',
  files: 'Files',
  delete_file: 'Delete File',
  only_1_file_is_allowed: 'Only 1 file is allowed.',
  uploading: 'Uploading',
  invalid_profile_upload: 'Invalid Profile Upload',
  file_must_be_an_image_file: 'File must be an image type.',
  clear_profile_pic: 'Clear Profile Image',
  are_you_sure: "Are you sure? You can't undo this action afterwards.",
  upload_label: 'Upload Profile',
  remove_label: 'Remove Profile',
  invalid_file_size: 'Invalid file size',
  file_size_info: 'File size must be less than 5MB',
  uploading_profile: 'Uploading Profile',
  no_permission_msg:
    'You do not have permission to access it. Please contact administrator.',
  house_number: 'House number',
  house: 'House',
  flat: 'Flat',
  fresh: 'Fresh',
  consumed: 'Consumed',

  // common
  lang: 'en',
  english: 'English',
  german: 'German',
  polish: 'Polish',
  french: 'French',
  italian: 'Italian',
  result: 'Result',
  no_results_found: 'No results found.',
  no_records_found: 'No records found',
  error: 'Error',
  no_options: ' No Options',
  showing: 'Showing',
  manually: 'Manually',
  general: 'General',
  gotoDetails: 'Go to Details',
  out_of: 'out of',
  loading: 'Loading...',
  show_rows: 'Show rows',
  error_boundary_heading_text: 'Sorry, something went wrong.',
  error_boundary_paragraph_text:
    'Our team has been notified and we will get it fixed as soon as we can.',
  reload_page: 'Reload page',
  take_me_home: 'Take me home',
  manual: 'Manual',
  ['v-office']: 'V-Office',
  guest: 'Guest',
  owner: 'Owner',
  lead: 'Lead',
  supplier: 'Supplier',
  test: 'Test',
  other: 'Other',
  daily: 'Daily',
  weekly: 'Weekly',
  bi_weekly: 'Bi-Weekly',
  monthly: 'Monthly',
  semi_anually: 'Semi-Anually',
  anually: 'Anually',
  previous: 'Previous',
  next: 'Next',
  date_validation_msg: 'Please enter a valid date',
  freeze_inventory: 'Freeze Inventory',
  create_inventory_list: 'Create Inventory Lists',
  correct_inventory: 'Correct Inventory',
  create_delta_list: 'Create Delta Lists',
  unfreeze_inventory_end: 'Unfreeze Inventory & End Stocktaking',
  download_pdf: 'Download PDF',
  download_excel: 'Download Excel',
  download__delta_list_pdf: 'Download Delta List PDF',
  unfreeze_inventory: 'Unfreeze Inventory',
  no_transferring: '(No Transferring of Items possible.)',
  download_as: '(Download as PDF and save, then print.)',
  transfer_items: '(Transfer Items from or to Stocktaking correction.)',
  what_change: '(What changes have been made during Stocktaking.)',
  step_1: 'Step 1',
  step_2: 'Step 2',
  step_3: 'Step 3',
  step_4: 'Step 4',
  step_5: 'Step 5',
  actual_quantity: 'Actual Quantity',
  delta: 'Delta',
  no_data_found: 'No data found',
  ms: 'Ms.',
  Ms: 'Ms.',
  diverse: 'Diverse',
  Diverse: 'Diverse',
  language: 'Language',
  phone_number: 'Phone Number',
  sleeping_facilities: 'Sleeping Facilities',
  number: 'Number',
  until: 'Until',
  all_logs: 'All Logs',

  drag_drop_csv_files_msg:
    'Drag & Drop only .csv files here, or click to select .csv files. Upload files upto 10MB.',
  drag_drop_csv_15MB_files_msg:
    'Drag & Drop only .csv files here, or click to select .csv files. Upload files upto 15MB.',
  processing_data: 'Processing data',
  failed: 'Failed',
  file_uploaded_successfully: 'File uploaded successfullly.',
  uploaded_on: 'Last Uploaded on',
  by: 'by',
  transferred: 'transferred',
  internal_server_error: 'Internal Server Error',
  cannot_upload_csv_in_worng_category: 'Cannot Upload CSV in wrong category',
  upload_date: 'Upload Date',
  location: 'Location',
  general_information: 'General Information',
  change_ownership: 'Change of Ownership',
  oil_level_report: 'Oil level report',
  malfunction_defect_deficiencies: ' Malfunction, Defect, Deficiencies',

  /* OMS */
  object_management_system: 'Object Management System',
  units: 'Units',
  oms: 'OMS',
  object_list: 'Object list',
  unit_type: 'Unit type',
  manage_units: 'Manage units',
  manage_objects: 'Manage objects',
  apartment_quota: 'Apartment quotas',
  quota: 'Quota',
  stwe_number: 'STWE number',
  floor: 'Floor',
  floor_number: 'Floor - Number',
  rooms: 'Rooms',
  room_number: 'Room - Number',
  number_of_rooms: 'Number of rooms',
  living_space: 'Living space (m²)',
  garage_number: 'Garage no.',
  garage_quota: 'Garage quota',
  garage_type_is_required: 'Garage type is required',
  garage_quota_is_required: 'Garage quota is necessary',
  garage_number_is_required: 'Garage number is required',
  add_another_garage: 'Add another garage',
  no_abbreviation: 'No.',
  season_name: 'Season Name',
  construction_year: 'Construction year',
  from_date: 'Start Date',
  to_date: 'End Date',
  add_new_object: 'Add new object',
  add_object: 'Add object',
  add_new_unit: 'Add new unit',
  add_new_apartment: 'Add new apartment',
  add_apartment: 'Add apartment',
  edit_object: 'Edit object',
  status_changed: 'Status changed',
  features: 'Features',
  picture: 'Picture',
  text: 'Text',
  cannot_get_apartment_details: 'Cannot get apartment details',
  must_be_greater_than_zero: 'Must be greater than 0',
  basic_information: 'Basic Information',
  copy: 'Copy',
  save_object: 'Save object',
  save_apartment: 'Save apartment',
  add_owner: 'Add owner',
  enter_owner: 'Enter owner',
  no_owner: 'You have not yet linked an owner to this object.',
  owner_is_required: 'You must associate at least one owner with the unit!',
  ical_link: 'iCal-Link',
  copied: 'Copied',
  copy_to_clipboard_ctrl_c_enter: 'Copy to clipboard: Ctrl+C, Enter',
  edit_ownership: 'Edit ownership',
  delete_ownership: 'Delete ownership',
  select_ownership: 'Select ownership',
  actions: 'Actions',
  price: 'Price',
  garage: 'Garage',
  garage_type: 'Garage type',
  quantity: 'Quantity',
  store: 'Geschäft',
  restaurant: 'Restaurant',
  select_object_type: 'Select object type',
  enter_apartment_number: 'Enter STWE / apartment number',
  enter_stwe_number: 'Enter STWE number',
  select_floor: 'Select floor',
  enter_living_space: 'Enter living space',
  select_rooms: 'Select rooms',
  select_garage_type: 'Select garage type',
  enter_garage_number: 'Enter garage no.',
  enter_street: 'Enter street',
  enter_quantity: 'Enter quantity',
  enter_zip_code: 'Enter ZIP code',
  select_country: 'Select country',
  select_unit_type: 'Select unit type',
  enter_apartment_quota: 'Enter apartment quotas',
  enter_quota: 'Enter quota',
  enter_garage_quota: 'Enter garage quotas',
  enter_house_number: 'Enter house number',
  enter_town: 'Enter town',
  enter_object_name: 'Enter object name',
  enter_object_addition: 'Enter object addition',
  enter_construction_year: 'Enter construction year',
  enter_address_adittion: 'Enter address adittion',
  confirm_remove_garage:
    'Are you sure, this will remove all saved garages from this apartment.',
  object_type_is_required: 'Object type is required',
  object_name_is_required: 'Object name is required',
  object_number_is_required: 'Object number is required',
  object_number: 'Object number',
  unit_number: 'Unit number',
  object_name: 'Object name',
  object_addition: 'Object addition',
  permanent_rental: 'Permanent rental',
  vacation_rental: 'Vacation rental',
  unknown: 'Unknown',
  single_garage: 'Single garage',
  indoor_parking_space: 'Indoor parking space',
  outdoor_parking_lot: 'Outdoor parking lot',
  own_use: 'Own use',
  studio: 'Studio',
  '1': '1',
  '1.5': '1.5',
  '2': '2',
  '2.5': '2.5',
  '3': '3',
  '3.5': '3.5',
  '4': '4',
  '4.5': '4.5',
  '5': '5',
  '5.5': '5.5',
  '6+': '6+',
  'floor_-2': '2nd basement floor',
  'floor_-1': '1st basement floor',
  floor_0: 'ground floor',
  floor_1: '1st floor',
  floor_2: '2nd floor',
  floor_3: '3rd floor',
  floor_4: '4th floor',
  floor_5: '5th floor',
  floor_6: '6th floor',
  change_caretaker: 'Change Caretaker',
  change_caretaker_info: 'Do you really want to change the Caretaker?',
  change: 'Change',
  remove_caretaker: 'Remove Caretaker',
  remove_caretaker_info: 'Do you really want to Remove the Caretaker?',
  remove: 'Remove',
  select_caretaker: 'Select Caretaker',
  caretaker_is_required: 'Caretaker is required',
  enter_first_name: 'Enter First Name',
  enter_last_name: 'Enter Last Name',
  enter_email_address: 'Enter E-mail Address',
  enter_phone_number: 'Enter Phonenumber',
  enter_title: 'Enter Title',
  add_notes: 'Add notes',
  edit_unit: 'Edit Unit',
  add_new_garage: 'Add New Garage',
  add_garage: 'Add Garage',

  /**DMS Service */
  document_management_system: 'Document Management System',
  dms: 'DMS',
  all_documents: 'All Documents',
  upload_document: 'Upload Document',
  edit_document: 'Edit Document',
  document_name: 'Document Name',
  owner_name: 'Owner Name',
  availability: 'Availability',
  no_of_documents: 'No. of Documents',
  select_object: 'Select Object',
  documents: 'Documents',
  general_document_deleted: 'Document has been deleted.',
  delete_general_document: 'Delete Document',
  general_document_not_found: 'Document not found',
  general_document_udpate_failed: 'Document update failed.',
  general_document_created: 'Document has been created',
  general_document_creation_failed: 'Document creation failed.',
  general_document_updated: 'Document updated successfully',
  owner_documents: 'Owner Documents',
  import_file: 'Import File',
  the_file_field_is_required: 'The file field is required.',

  apartment_name: 'Apartment Name',
  title: 'Title',
  first_name_is_required: 'First name is required',
  last_name_is_required: 'Last name is required',
  status_is_required: 'Status is required',
  living_space_is_required: 'Living space is required',
  stwe_number_is_required: 'STWE number is required',
  apartment_quota_is_required: 'Object quotas is required',
  street_name_is_required: 'Street name is required',
  country_is_required: 'Country is required',
  invalid_phone_number: 'Invalid phone number',
  appartment_is_required: 'Appartment is required',
  house_number_is_required: 'House number is required',
  town_is_required: 'Town is required',
  zip_code_is_required: 'Post code is required',
  phone_number_is_required: 'Phone number is required',

  mr: 'Mr.',
  Mr: 'Mr.',
  mrs: 'Mrs.',
  misc: 'Misc.',

  insurance_policy: 'Insurance policy',
  stwe_foundation_contract: 'STWE foundation contract',
  stwe_regulations: 'STWE regulations',
  house_rules: 'House rules',
  annual_invoice: 'Annual invoice',
  annual_report: 'Annual report',
  minutes: 'Minutes',
  investment_plan: 'Investment plan',
  budget: 'Budget',
  correspondence: 'Correspondence',
  invitation: 'Invitation',
  audit_report: 'Audit Report',
  power_of_attorney: 'Power of Attorney',
  plans: 'Plans',
  service_charge_invoice: 'Service Charge Invoice',
  offer: 'Offer',
  report: 'Report',
  locking_plan: 'Locking plan',
  await_contact_details: 'Caretaker contact details',
  restructuring_report: 'Restructuring report',
  additional_contribution_to_reserve_fund_invoice:
    'Additional contribution to reserve-fund account',
  additional_contribution_reserve_fund_list:
    'Additional contribution reserve-fund list',

  assignment: 'Assignment',
  assignment_placeholder: 'Select Assignment',
  assignment_required: 'Assignment is required',

  year: 'Year',
  year_placeholder: 'Select Year',
  year_required: 'Year is required',

  tag: 'Tag',
  tag_placeholder: 'Select Tag',
  tag_required: 'Tag is required',

  document_upload_info: `Please upload a document first and assign for it the corresponding assignment,
   the appropriate designation and the respective year.`,
  document_upload_info2: `Please select a unit first to be able to assign the document to an owner here.`,
  current_owner: 'Current Owner',
  apartment_number: 'Apartment - Number',
  previous_owner: 'Previous owner',

  document_owner: 'Document - Owner',
  document_units: 'Document - Units',
  total: 'Total',
  select_owners_validation: 'Please select owners',
  select_object_validation: 'Please select objects',
  document_upload: 'Document Upload',
  reset: 'Reset',
  reset_selection: 'Reset Selection',
  reset_warning:
    'Do you really want to reset the selection you have made so far?',
  remove_assignment: 'Remove assignment',
  remove_assignment_warning:
    'Do you really want to remove already assigned units?',
  document_uploading: 'Document is uploading',
  assigned_units: 'Assigned units',
  other_units: 'Other units',
  delete_document_info:
    'Do you really want to delete the document {0} irreversibly?',
  delete_document_notes:
    'Please note that the selected document is still linked to the following units and owners:',
  delete_document_warning: `Attention: By clicking on "Delete", the selected document will also be irreversibly deleted from all linked units and owners.`,
  document_deleted_successfully: 'Document has been deleted',

  /**FAQ Service */
  frequently_ask_question: 'Frequently Ask Question',
  faq: 'FAQs',
  all_faq: 'All FAQs',
  success: 'Success',
  group: 'Groups',
  all_groups: 'All Groups',
  add_faq: 'Add FAQ',
  edit_faq: 'Edit FAQ',
  delete_faq: 'Delete FAQ',
  select_group: 'Select Group',
  faq_deleted_successfully: 'FAQ deleted successfully',
  document_properties: 'Document Properties',

  faq_group: 'FAQ Group',
  add_group: 'Add Group',
  edit_group: 'Edit Group',
  delete_group: 'Delete Group',
  invalid_data: 'The given data is invalid',
  download: 'Download',
  download_image: 'Download Image',
  select_date: 'Select Date',
  select_date_time: 'Select Date/Time',

  /*Internet Status*/
  wifi: 'Internet connection was restored.',
  nowifi: 'You are browsing on offline mode.',
  refresh: 'Refresh',

  /*required*/
  required_first_name: 'First name is required.',
  required_last_name: 'Last name is required.',
  required_email: 'Email is required.',
  required_cellphone: 'Cellphone is required.',
  required_role: 'Role is required.',
  required_address: 'Address is required.',
  required_type: 'Type is required.',
  required_document_name: 'Document name is required.',
  required_upload: 'Uploading a file is required',
  required_group_name: 'Group name is required.',
  required_status: 'Status is required.',
  required_owner: 'Owner is required.',
  required_ownership: 'Ownership is required.',
  only_one_file_is_allowed: 'Only 1 file is allowed.',
  required_password: 'Password is required.',
  required_sleeping_facilities: 'Sleeping facilities are required',
  required_pets_allowed: 'Pets allowed field is required',

  /*Message*/
  email_taken: 'The email has already been taken.',

  // /* Appartment Address Form*/
  address_location: 'Address & Location',
  address_adittion: 'Address adittion',
  distance_lake: 'Distance Lake',
  distance_shopping: 'Distance Shopping',
  distance_restaurant: 'Distance restaurant',
  distance_city: 'Distance city',
  distance_train_station: 'Distance train station',
  distance_bank_atm: 'Distance bank / ATM',
  distance_doctor: 'Distance Doctor',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'The Google Maps map / directions make it easier for guests to navigate to your apartment and offers maximum transparency',
  type_is_required: 'Type is required',
  pets_allowed: 'Pets Allowed',

  /**lyrics form */
  headline: 'Headline',
  full_description: 'Full description',
  short_description: 'Short description',
  directions: 'Directions',

  /**Settings Form */
  season_and_prices: 'Seasons & Prices',
  please_assign_price:
    'Please assign the available seasons to the apartment and give a price.',
  assigned: 'Assigned',
  adjust_order_of_images:
    'The arrangement / order of the images can be easily adjusted via drag & drop.',
  delete_image: 'Delete Image',

  // Newsletter
  newsletter_subscribed: 'Newsletter subscribed',
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Newsletters',
  newsletter_overview: 'Newsletter Overview',
  draft: 'Draft',
  drafts: 'Drafts',
  group_of_recipients: 'Group of Recipients',
  all_newsletters: 'All Newsletters',
  new_newsletter: 'New Newsletter',
  create_new_newsletter: 'Create New Newsletter',
  recipients: 'Recipients',
  subject: 'Subject',
  all_draft: 'All Drafts',
  message: 'Message',
  attach_file: 'Attach File',
  attachment: 'Attachment:',
  attachments: 'Attachments:',
  preview: 'Preview',
  save_as_draft: 'Save as Draft',
  compose_message: 'Compose Message',
  preview_message: 'Preview Newsletter',
  download_our_app_now: 'Download our app now ',
  about_us: 'About Us',
  customer_care: 'Customer Care',
  contact_us: 'Contact Us',
  privacy_policy: 'Privacy Policy',
  subscribe: 'Subscribe',
  unsubscribe: 'Unsubscribe',
  select_recipient_group: 'Select Recipient',
  edit_newsletter: 'Edit Newsletter',
  delete_newsletter: 'Delete Newsletter',
  newsletter_deleted: 'Newsletter deleted.',
  newsletter_delete_error: 'Newsletter delete failed.',
  newsletter_sent_sucessfully: 'Newsletter sent successfully.',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter saved to draft successfully.',
  newsletter_updated_sucessfully: 'Newsletter updated successfully.',
  hello: 'Hello',
  to_send: 'To Send',
  save_and_send: 'Save and Send',
  recipient_group_field_is_required: 'Recipient Group field is required.',
  subject_field_is_required: 'Subject field is required.',
  message_field_is_required: 'Message field is required.',
  send_newsletter: 'Send Newsletter',
  attention_notification_will_be_send_continue:
    'Attention! Notification will be send continue',
  privacy: 'Privacy',
  imprint: 'Imprint',
  text_search: 'Text Search',
  date: 'Date',

  dispatched: 'Dispatched',
  app: 'App',
  connect_with_us: 'Connect with us',
  website: 'Website',
  newsletter_unsubscribe: 'Newsletter unsubscribe',

  first_select_the_unit_then_the_owner: 'First select the unit, then the owner',
  enter_description: 'Enter Description',
  // Delete Owner Message
  delete_owner_confirmation:
    'Do you really want to delete the {0} {1}, {2} irrevocably?',
  linked_units_message:
    'Please note that the selected {0} is still linked to the following units:',
  delete_attention_message:
    'Attention: Clicking on Delete will irrevocably delete the selected {0} from all linked units as such.',

  no_object_associated_with_message:
    'There are no other objects associated with the selected {0}.',

  all_roles: 'All Roles',
  upload_failed: 'Upload Failed!',
  attention: 'Attention:',
  could_not_be_uploaded: 'could not be uploaded.',
  documents_are_uploaded: 'Documents are being uploaded',
  close: 'Close',
};

export default en;
