import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { DMS_APARTMENT_SEARCH_API } from 'constants/common';
import { ApartmentInfo, DataWrapper, GarageInfo } from 'constants/schema';
import useWordSearch from 'hooks/useWordSearch';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { BiSearchAlt2 } from 'react-icons/bi';
import { OwnerDocumentFormValues } from './OwnerDocumentForm';
import OwnerShipDocumentsSelectionObjectsColumn from './OwnerShipDocumentsSelectionObjectsColumn';
import OwnerShipDocumentsSelectionOwnersColumn from './OwnerShipDocumentsSelectionOwnersColumn';

interface PostDataInterface {
  ownerIds: number[];
  apartmentIds: number[];
  ownerShipIds: number[];
}

interface Props {
  postData: PostDataInterface;
  setPostData: React.Dispatch<React.SetStateAction<PostDataInterface>>;
  isEditMode?: boolean;
  initialOwnerShipIds?: number[];
}

type QueryParams = {
  address_name?: string;
  owner_name?: string;
};

const OwnerShipDocumentsSelection: React.FC<Props> = (props) => {
  const {
    loading: apartmentListLoading,
    result: apartmentQuery,
    setQuery,
  } = useWordSearch<DataWrapper<(ApartmentInfo | GarageInfo)[]>, QueryParams>(
    `${DMS_APARTMENT_SEARCH_API}`
  );

  const {
    postData,
    setPostData,
    isEditMode = false,
    initialOwnerShipIds = [],
  } = props;
  const {
    watch,
    formState: { errors },
  } = useFormContext<OwnerDocumentFormValues>();

  const yearValue = watch('year');
  const tagValue = watch('tag');

  // First step: The user selects a year and a tag.
  const stepOneDone = !!yearValue && !!tagValue;
  // Second step: The user selects an apartment.
  const stepTwoDone = stepOneDone && !!postData.apartmentIds.length;

  const [selectedUnits, setSelectedUnits] = useState<
    (ApartmentInfo | GarageInfo)[]
  >([]);

  const [isChecked, setIsChecked] = useState<boolean | null>(false);
  const {
    isOpen: isRemoveOwnershipModalOpen,
    onClose: closeRemoveOwnershipModal,
    onOpen: openRemoveOwnershipModal,
  } = useDisclosure();

  const addOwnershipId = (id: number) => {
    let currArray: number[] = [...postData.ownerShipIds];

    if (currArray.includes(id)) {
      currArray = postData.ownerShipIds.filter((value) => value !== id);
    } else {
      currArray.push(id);
    }

    setPostData((prevData) => {
      return {
        ...prevData,
        ownerShipIds: currArray,
      };
    });
  };

  useEffect(() => {
    if (isEditMode && selectedUnits.length === 0) {
      const existingOwnerData =
        apartmentQuery?.data?.filter((item) =>
          initialOwnerShipIds?.some((e) =>
            item.apartment_owner_ids?.includes(e)
          )
        ) ?? [];

      setSelectedUnits(existingOwnerData);
    }
  }, [apartmentQuery?.data]);

  useEffect(() => {
    const searchedResults = postData.apartmentIds.filter((value) => {
      if (
        apartmentQuery?.data?.find(
          (unit: ApartmentInfo | GarageInfo) => unit.id === value
        )
      ) {
        return true;
      }
      return false;
    });

    if (searchedResults.length === 0) {
      setIsChecked(false);
    } else if (searchedResults.length === apartmentQuery?.data?.length) {
      setIsChecked(true);
    } else {
      setIsChecked(null);
    }
  }, [postData.apartmentIds, apartmentQuery?.data]);

  const handleCheckboxChange = () => {
    if (isChecked === false) {
      addAllApartments();
    } else {
      if (isEditMode) {
        openRemoveOwnershipModal();
      } else {
        removeAllApartments();
      }
    }
  };

  const addApartmentData = (item: ApartmentInfo | GarageInfo) => {
    let currArray: number[] = [...postData.apartmentIds];
    let currOwnerShipIds: number[] = [];
    let currItems = [...selectedUnits];

    const getAllCurrentOwners = item.currentOwners?.map(
      (unit) => unit.pivot.id
    );

    const getAllPastOwners = item.pastOwners?.map((unit) => unit.pivot.id);

    const mergedArray = [...getAllCurrentOwners, ...getAllPastOwners];

    if (currArray.includes(item.id)) {
      currArray = postData.apartmentIds.filter((value) => value !== item.id);

      currItems = selectedUnits.filter((value) => value.id !== item.id);

      currOwnerShipIds = postData.ownerShipIds.filter(
        (element) => !mergedArray.includes(element)
      );
    } else {
      currArray.push(item.id);
      currItems.push(item);
      currOwnerShipIds = [...getAllCurrentOwners, ...postData.ownerShipIds];
    }
    setSelectedUnits(currItems);

    setPostData((prevData) => {
      return {
        ...prevData,
        apartmentIds: currArray,
        ownerShipIds: currOwnerShipIds,
      };
    });
  };

  const addAllApartments = () => {
    let allApartmentIds: number[] = [...postData.apartmentIds];
    let allCurrentOwners: number[] = [...postData.ownerShipIds];
    let currItems = [...selectedUnits];

    apartmentQuery?.data?.forEach((unit: ApartmentInfo | GarageInfo) => {
      if (!allApartmentIds.includes(unit.id)) {
        allApartmentIds.push(unit.id);
        currItems.push(unit);
      }

      unit.currentOwners.forEach((element) => {
        if (!allCurrentOwners.includes(element.pivot.id)) {
          allCurrentOwners.push(element.pivot.id);
        }
      });
    });

    setSelectedUnits(currItems);

    setPostData((prevData) => {
      return {
        ...prevData,
        apartmentIds: allApartmentIds,
        ownerShipIds: allCurrentOwners,
      };
    });
  };

  const removeAllApartments = () => {
    if (isRemoveOwnershipModalOpen) {
      closeRemoveOwnershipModal();
    }
    let allApartmentIds: number[] = postData.apartmentIds.slice();
    let allCurrentOwners: number[] = postData.ownerShipIds.slice();
    let currItems: (ApartmentInfo | GarageInfo)[] = selectedUnits.slice();

    allApartmentIds = allApartmentIds.filter(
      (val) =>
        !apartmentQuery?.data?.some(
          (unit: ApartmentInfo | GarageInfo) => unit.id === val
        )
    );

    currItems = currItems.filter(
      (val) =>
        !apartmentQuery?.data?.some(
          (unit: ApartmentInfo | GarageInfo) => val.id === unit.id
        )
    );

    allCurrentOwners = allCurrentOwners.filter(
      (ownerId) =>
        !apartmentQuery?.data?.some((unit: ApartmentInfo | GarageInfo) =>
          unit.currentOwners.some((owner) => owner.pivot.id === ownerId)
        ) &&
        !apartmentQuery?.data?.some((unit: ApartmentInfo | GarageInfo) =>
          unit.pastOwners.some((owner) => owner.pivot.id === ownerId)
        )
    );

    setSelectedUnits(currItems);

    setPostData((prevData) => {
      return {
        ...prevData,
        apartmentIds: allApartmentIds,
        ownerShipIds: allCurrentOwners,
      };
    });
  };

  const updateSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery((prevState: QueryParams | null) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <>
      <Grid templateColumns="repeat(3, 1fr)" gap={8}>
        <GridItem w="100%" colSpan={2}>
          <Stack sx={wrapperStyles}>
            <Flex align="center" justify="space-between" mb={4}>
              <Heading as="h2" size="md" mr={4}>
                {strings.units}
              </Heading>

              <Stack direction={['column', 'row']} spacing="10">
                <InputGroup>
                  <InputLeftElement>
                    <BiSearchAlt2 color="gray.400" />
                  </InputLeftElement>
                  <Input
                    name="address_name"
                    onChange={updateSearch}
                    placeholder={`${strings.object_name}`}
                  />
                </InputGroup>

                <InputGroup>
                  <InputLeftElement>
                    <BiSearchAlt2 color="gray.400" />
                  </InputLeftElement>
                  <Input
                    name="owner_name"
                    onChange={updateSearch}
                    placeholder={`${strings.current_owner}`}
                  />
                </InputGroup>
              </Stack>
            </Flex>
            <Box h="500px" overflowY="auto">
              <OwnerShipDocumentsSelectionObjectsColumn
                handleCheckboxChange={handleCheckboxChange}
                addApartmentData={addApartmentData}
                isChecked={isChecked}
                stepOneDone={stepOneDone}
                apartmentQueryData={apartmentQuery?.data ?? []}
                initialOwnerShipIds={initialOwnerShipIds}
                apartmentIds={postData.apartmentIds}
                apartmentListLoading={apartmentListLoading}
                isEditMode={isEditMode}
              />
            </Box>
          </Stack>

          <FormControl isInvalid={!!errors?.postData?.apartmentIds}>
            <FormErrorMessage>
              <>
                {errors?.postData?.apartmentIds &&
                  errors?.postData?.apartmentIds?.message}
              </>
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <Stack sx={wrapperStyles}>
            <Heading as="h2" size="md" mr={4} mb={10}>
              {strings.owner}
            </Heading>

            <Box h="500px" overflowY="auto">
              <OwnerShipDocumentsSelectionOwnersColumn
                selectedUnits={selectedUnits}
                addOwnershipId={addOwnershipId}
                ownerShipIds={postData.ownerShipIds}
                stepTwoDone={stepTwoDone}
                stepOneDone={stepOneDone}
              />
            </Box>
          </Stack>
          <FormControl isInvalid={!!errors?.postData?.ownerShipIds}>
            <FormErrorMessage>
              <>
                {errors?.postData?.ownerShipIds &&
                  errors?.postData?.ownerShipIds?.message}
              </>
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <Modal
        isOpen={isRemoveOwnershipModalOpen}
        isCentered
        onClose={closeRemoveOwnershipModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.remove_assignment}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.remove_assignment_warning}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={closeRemoveOwnershipModal}>
                {strings.no_thank_you}
              </Button>
              <Button colorScheme="blue" onClick={removeAllApartments}>
                {strings.remove}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default OwnerShipDocumentsSelection;
