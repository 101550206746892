import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import { AuthState } from 'constants/schema';
import { ForgotPassword, Login } from 'pages/auth';
import ResetPassword from 'pages/auth/ResetPassword';
import SetPassword from 'pages/auth/SetPassword';
import VerifyEmail from 'pages/auth/VerifyEmail';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

const PublicRoute: React.FC<AuthState> = (props) => {
  const { isLoggedIn } = props;

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const redirectUrl = searchParams.get('redirect');

  const redirectUrlBase64Decode = window.atob(redirectUrl as string);

  const redirectRoute = redirectUrlBase64Decode || routes.splash;

  return !isLoggedIn ? (
    <Switch>
      <Route path={routes.auth.login} component={Login} />
      <Route path={routes.auth.forgotPassword} component={ForgotPassword} />
      <Route path={routes.auth.resetPassword} component={ResetPassword} />
      <Route path={routes.auth.setPassword} component={SetPassword} />
      <Route path={routes.auth.verifyEmail} component={VerifyEmail} />
    </Switch>
  ) : (
    <Redirect to={redirectRoute} />
  );
};

export default withAuthState(PublicRoute);
