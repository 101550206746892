/* eslint-disable import/no-anonymous-default-export */
import { TicketAction, TicketActionTypes } from 'actions/data/ticket';

const INITIAL_STATE = {
  ticketLogInfo: {},
};

export default function (ticketState = INITIAL_STATE, action: TicketAction) {
  switch (action.type) {
    case TicketActionTypes.SET_TICKET_LOG_INFO:
      return {
        ...ticketState,
        ticketLogInfo: action.data,
      };

    default:
      return ticketState;
  }
}
