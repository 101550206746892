import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import http from 'utils/http';
const CancelToken = axios.CancelToken;
let cancelTokenSource: CancelTokenSource;

interface Props {
  url: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

export const useFileUploadMutation = (props: Props) => {
  const { url, onSuccess, onError } = props;
  const [fileUploadState, setFileUploadState] = useState({
    totalMB: 0,
    loadedMB: 0,
    progressPercentage: 0,
  });

  const cancelMutation = () => {
    cancelTokenSource.cancel();

    setFileUploadState({
      totalMB: 0,
      loadedMB: 0,
      progressPercentage: 0,
    });
  };

  const onUploadProgress = useCallback((ev: ProgressEvent) => {
    const totalMB = parseFloat((ev.total / (1024 * 1024)).toFixed(2));
    const loadedMB = parseFloat((ev.loaded / (1024 * 1024)).toFixed(2));
    const progressPercentage = Math.round((loadedMB / totalMB) * 100);

    setFileUploadState({
      totalMB: totalMB,
      loadedMB: loadedMB,
      progressPercentage: progressPercentage,
    });
  }, []);

  const mutation = useMutation(
    (resource: FormData) => {
      cancelTokenSource = CancelToken.source();

      return http({
        url: url,
        method: 'post',
        data: resource,
        onUploadProgress: onUploadProgress,
        cancelToken: cancelTokenSource.token,
      });
    },
    {
      onSuccess,
      onError,
    }
  );

  return { ...mutation, fileUploadState, cancelMutation };
};
