import { IconButton, Td, Tooltip, Tr } from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { AnyUnitInfo, Owner } from 'constants/schema';
import React, { Fragment } from 'react';
import { BiEdit } from 'react-icons/bi';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';
import { renderFullName } from 'utils';

interface Props {
  data: AnyUnitInfo;
  search?: string;
  unitNumber: string;
}

const UnitListItems: React.FC<Props> = (props) => {
  const { data, search, unitNumber } = props;

  const { type, address, owners, id: objectID } = data;
  const { street, house_number } = address;

  return (
    <Tr>
      <Td>{address.name}</Td>
      <Td>{address.addition || '-'}</Td>
      <Td>
        <RouterLink
          to={
            routes.oms.units.edit[type].replace(':id', objectID.toString()) +
            search
          }>
          {street}, {house_number}
        </RouterLink>
      </Td>
      <Td>
        <OwnerNames owners={owners} />
      </Td>
      <Td>{strings[type]}</Td>
      <Td>{unitNumber}</Td>
      <Td>
        <AccessControl allowedPermissions={[PermissionRequest['manage:oms']]}>
          <RouterLink
            to={
              routes.oms.units.edit[type].replace(':id', objectID.toString()) +
              search
            }>
            <Tooltip hasArrow label={strings.edit_unit}>
              <IconButton
                icon={<BiEdit />}
                variant="link"
                aria-label={strings.edit_unit}
                color="blue.300"
                minW="6"
              />
            </Tooltip>
          </RouterLink>
        </AccessControl>
      </Td>
    </Tr>
  );
};

const OwnerNames: React.FC<{ owners: Owner[] }> = ({ owners }) => {
  return (
    <>
      {owners.map((owner, index: number) => (
        <Fragment key={owner.id}>
          <RouterLink
            to={routes.users.owner.profile.replace(':id', owner.id.toString())}>
            {renderFullName(owner.first_name, owner.last_name) || owner.name}
          </RouterLink>
          <span>{index !== owners.length - 1 && '; '}</span>
        </Fragment>
      ))}
    </>
  );
};

export default UnitListItems;
