import { Stack } from '@chakra-ui/react';
import UnitPageHeader from 'components/oms/UnitPageHeader';
import { strings } from 'config/localization';
import React from 'react';
import { Helmet } from 'react-helmet';
import ApartmentForm from './ApartmentForm';

const CreateApartment: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {strings.add_new_apartment}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <UnitPageHeader breadcrumb={strings.add_new_apartment} />
        <ApartmentForm />
      </Stack>
    </>
  );
};

export default CreateApartment;
