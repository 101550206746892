import { Box, Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import React from 'react';

export interface TicketSkeletonLoaderProps {
  rows?: number;
}

const TicketSkeletonLoader: React.FC<TicketSkeletonLoaderProps> = (props) => {
  const { rows = 3 } = props;
  return (
    <>
      {Array(rows)
        .fill(0)
        .map((_, index: number) => (
          <Box p={2} key={index} mx="4">
            <Box
              p={2}
              width="full"
              border="1px"
              borderColor="gray.100"
              rounded="lg">
              <Skeleton width="24" height="5" />
              <Flex justifyContent="space-between" alignItems="center" mt={8}>
                <Flex alignItems="center">
                  <SkeletonCircle size="6" mr={4} />
                  <Skeleton width="20" height="4" />
                </Flex>
                <Skeleton width="16" height="4" />
              </Flex>
            </Box>
          </Box>
        ))}
    </>
  );
};

export default TicketSkeletonLoader;
