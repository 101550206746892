import TicketResource from 'api/ticket';
import { TicketBoardState, TicketStatus } from 'constants/schema';
import { DraggableLocation } from 'react-beautiful-dnd';

// a little function to help us with reordering the result
export const reorder = (
  list: unknown[],
  startIndex: number,
  endIndex: number
): unknown[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const reorderTickets = (
  tickets: TicketBoardState,
  source: DraggableLocation,
  destination: DraggableLocation,
  userId: number,
  updateTotalTickets: (source: TicketStatus, destination: TicketStatus) => void
) => {
  const current = [...tickets[source.droppableId as TicketStatus]];
  const next = [...tickets[destination.droppableId as TicketStatus]];
  const target = current[source.index];
  const ticketApi = new TicketResource();
  const status = destination.droppableId as TicketStatus;
  // moving to same list
  if (source.droppableId === destination.droppableId) {
    // Update the time and updated_by
    ticketApi.updateTicketStatus(current[source.index]['id'], status, userId);
    const reordered = reorder(current, source.index, destination.index);
    return {
      ...tickets,
      [source.droppableId]: reordered,
    };
  }

  // moving to different list
  // Update status, update_by and time
  ticketApi.updateTicketStatus(current[source.index]['id'], status, userId);

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  updateTotalTickets(
    source.droppableId as TicketStatus,
    destination.droppableId as TicketStatus
  );

  return {
    ...tickets,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };
};
