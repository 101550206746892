import Resource from 'api/resource';
import { FAQGroupSchema } from 'constants/schema';
import http from 'utils/http';

class FAQGroupResource extends Resource<FAQGroupSchema> {
  constructor() {
    super('faq/groups');
  }
  updateFAQGroup(id: string | number, data: FormData) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'POST',
      data: data,
    });
  }
}

export { FAQGroupResource as default };
