import { Text } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { TicketStatus } from 'constants/schema';

export const getStatus = (status: TicketStatus) => {
  let color = 'gray.400';
  switch (status) {
    case 'done':
      color = 'green.300';
      break;
    case 'in-progress':
      color = 'blue.300';
      break;
    case 'open':
      color = 'gray.400';
      break;
    default:
      break;
  }

  return (
    <Text
      color={color}
      textTransform="uppercase"
      fontWeight="medium"
      fontSize="sm">
      {strings.getString(status.split('-').join('')).toUpperCase()}
    </Text>
  );
};
