import axios, { AxiosError, CancelTokenSource } from 'axios';
import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import http from 'utils/http';

interface FileUploadState {
  totalMB: number;
  loadedMB: number;
  progressPercentage: number;
  cancelTokenSource: CancelTokenSource;
  fileName: string;
  error?: AxiosError;
}

interface Props {
  url: string;
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

const useMultiFileUploadMutation = (props: Props) => {
  const { url, onSuccess, onError } = props;
  const [fileUploadStates, setFileUploadStates] = useState<FileUploadState[]>(
    []
  );

  const cancelMutations = () => {
    fileUploadStates.forEach((uploadState) => {
      if (!uploadState.cancelTokenSource.token.reason) {
        // Cancel only if the promise is not settled
        uploadState.cancelTokenSource.cancel(
          `Upload for ${uploadState.fileName} canceled.`
        );
      }
    });
  };

  const onUploadProgress = useCallback(
    (index: number, fileName: string) => (ev: ProgressEvent) => {
      const totalMB = parseFloat((ev.total / (1024 * 1024)).toFixed(2));
      const loadedMB = parseFloat((ev.loaded / (1024 * 1024)).toFixed(2));
      const progressPercentage = Math.round((loadedMB / totalMB) * 100);

      setFileUploadStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = {
          fileName,
          totalMB,
          loadedMB,
          progressPercentage,
          cancelTokenSource: newStates[index].cancelTokenSource,
        };
        return newStates;
      });
    },
    []
  );

  const mutations = useMutation(
    (resources: File[]) => {
      const uploadPromises = resources.map((resource, index) => {
        const cancelTokenSource = axios.CancelToken.source();

        if (index === 0) {
          setFileUploadStates([
            {
              totalMB: 0,
              loadedMB: 0,
              progressPercentage: 0,
              cancelTokenSource,
              fileName: resource?.name,
            },
          ]);
        } else {
          setFileUploadStates((prevStates) => [
            ...prevStates,
            {
              totalMB: 0,
              loadedMB: 0,
              progressPercentage: 0,
              cancelTokenSource,
              fileName: resource?.name,
            },
          ]);
        }

        const formData = new FormData();
        formData.append('image', resource, resource?.name);

        return http({
          url: url,
          method: 'post',
          data: formData,
          onUploadProgress: onUploadProgress(index, resource?.name ?? ''),
          cancelToken: cancelTokenSource.token,
        }).catch((error: AxiosError) => {
          // Save the error information for the file
          setFileUploadStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index].error = error;
            return newStates;
          });
          throw error; // Re-throw the error for the next catch block
        });
      });

      return Promise.allSettled(uploadPromises);
    },
    {
      onSuccess,
      onError,
    }
  );

  return { ...mutations, fileUploadStates, cancelMutations };
};

export default useMultiFileUploadMutation;
