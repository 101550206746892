import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import OMSObjectsResource from 'api/oms-objects';
import { CenterSpinner } from 'components/common/CenterSpinner';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import ObjectForm from './ObjectForm';

interface EditObjectParams {
  id: string;
}

const CreateObject: React.FC = () => {
  const { id: objectID } = useParams<EditObjectParams>();
  const local = useLocation();
  let { search } = local;

  const objectAPI = new OMSObjectsResource();

  const {
    data: objectDetails,
    isLoading,
    isError,
  } = useQuery(
    `objectDetails-${objectID}`,
    () =>
      objectAPI
        .get(objectID)
        .then((res) => res.data.data)
        .catch((err) => {
          throw new Error("Can't get object details");
        }),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  } else if (isError) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.cannot_get_apartment_details}
      </Alert>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {strings.edit_object}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.units}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.oms.objects.list + search}>
              {strings.manage_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {objectDetails?.name}
              {objectDetails?.addition ? `, ${objectDetails.addition}` : ''}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex gridGap="2">
          <Heading size="lg" textTransform="capitalize">
            {objectDetails?.name}
            {objectDetails?.addition ? `, ${objectDetails.addition}` : ''}
          </Heading>
        </Flex>
        <Stack>
          <ObjectForm data={objectDetails} />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateObject;
