import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { resendVerificationEmail, verifyEmail } from 'api/auth';
import { AxiosError, AxiosResponse } from 'axios';
import LogoInformation from 'components/auth/LogoInformation';
import { CenterSpinner } from 'components/common/CenterSpinner';
import LanguageMenu from 'components/common/LanguageMenu';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as authService from 'services/auth';
import { validEmail } from 'utils/validate';

export interface ResendVerificationEmailFormValues {
  email: string;
}

export interface VerifyEmailValues {
  id: string;
  hash: string;
  signature: string;
  expires: string;
}

const EMAIL_ALREADY_VERIFIED_RES_TEXT =
  'Email already verified. Please Login to continue.';
const EMAIL_ALREADY_VERIFIED_RES_TEXT_IN_RESEND =
  'Email has already been verified.';

const VerifyEmail: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ResendVerificationEmailFormValues>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const id = params.get('id') || '';
  const hash = params.get('hash') || '';
  const signature = params.get('signature') || '';
  const expires = params.get('expires') || '';

  useEffect(() => {
    if (id === '' || hash === '' || signature === '' || expires === '')
      history.push(routes.auth.login);

    const handleEmailVerification = async () => {
      const data = {
        id,
        hash,
        signature,
        expires,
      };
      try {
        const response = await verifyEmail(data);
        setIsLoading(false);
        toast({
          title: strings.email_verification_success,
          description: strings.create_password_to_proceed,
          status: 'success',
          isClosable: true,
        });
        authService.persist({
          accessToken: response.data?.token,
          refreshToken: response.data?.refresh_token,
          expiryTime: response.data?.expires_in,
        });
        history.push(routes.auth.setPassword);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setIsLoading(false);
        setErrorMsg(error?.response?.data?.message);
      }
    };

    handleEmailVerification();
  }, [id, hash, signature, expires, history, toast]);

  const resendVerificationEmailMutation = useMutation<
    AxiosResponse<unknown>,
    AxiosError,
    ResendVerificationEmailFormValues
  >((data) => resendVerificationEmail(data), {
    onSuccess: () => {
      toast({
        title: strings.resend_verify_email_success,
        status: 'success',
        isClosable: true,
      });
      history.push(routes.auth.login);
    },
    onError: (error) => {
      if (
        error?.response?.data?.message ===
        EMAIL_ALREADY_VERIFIED_RES_TEXT_IN_RESEND
      ) {
        toast({
          title: strings.email_already_verified,
          status: 'warning',
          isClosable: true,
        });
      } else {
        toast({
          title: strings.resend_verify_email_error,
          status: 'error',
          isClosable: true,
        });
      }
    },
  });

  const onSubmit = async (data: ResendVerificationEmailFormValues) => {
    resendVerificationEmailMutation.mutate(data);
  };

  if (isLoading) return <CenterSpinner />;

  const isEmailAlreadyVerified = errorMsg === EMAIL_ALREADY_VERIFIED_RES_TEXT;

  return (
    <Box bg="gray.50" minH="100vh">
      <Stack pos="absolute" right="5%">
        <LanguageMenu />
      </Stack>
      <Stack direction="column" spacing="12" py="20" alignItems="center">
        <LogoInformation />
        <Box bg="white" p="14" shadow="box" rounded="sm" minWidth="lg">
          {isEmailAlreadyVerified ? (
            <Alert status="success">
              <AlertIcon />
              {strings.email_already_verified} {strings.login_to_continue}
            </Alert>
          ) : (
            <Alert status="error">
              <AlertIcon />
              {strings.email_verification_failed}
            </Alert>
          )}

          <Box mt={10}>
            {!isEmailAlreadyVerified && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                  <FormLabel color="gray.600">{strings.email}</FormLabel>
                  <Input
                    id="email"
                    placeholder={strings.your_email_address}
                    {...register('email', {
                      required: strings.required,
                      validate: (value) =>
                        validEmail(value) || strings.valid_email_address,
                    })}
                  />
                  <FormErrorMessage>
                    <>{errors.email && errors.email?.message}</>
                  </FormErrorMessage>
                </FormControl>
                <Button
                  width="full"
                  type="submit"
                  size="lg"
                  fontSize="md"
                  fontWeight="semibold"
                  colorScheme="primary"
                  textTransform="uppercase"
                  boxShadow="md"
                  isLoading={resendVerificationEmailMutation.isLoading}
                  mt={4}>
                  {strings.resend_verification_email}
                </Button>
              </form>
            )}
          </Box>

          <Flex justifyContent="center" mt={8}>
            <Link to={routes.auth.login}>
              <Button
                variant="link"
                color="gray.600"
                fontWeight="medium"
                leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}
                isDisabled={resendVerificationEmailMutation.isLoading}>
                {strings.back_to_login}
              </Button>
            </Link>
          </Flex>
        </Box>
      </Stack>
    </Box>
  );
};

export default VerifyEmail;
