import Resource from 'api/resource';
import { AxiosPromise } from 'axios';
import { DataWrapper, RoleSchema, UserSchema } from 'constants/schema';
import http from 'utils/http';

class RolesResource extends Resource<RoleSchema> {
  constructor() {
    super('user/roles');
  }

  listUsers(roleId: number): AxiosPromise<DataWrapper<UserSchema[]>> {
    return http({
      url: '/' + this.uri + '/' + roleId + '/users',
      method: 'get',
    });
  }

  getPermissions(id: number) {
    return http({
      url: '/' + this.uri + '/' + id + '/permissions',
      method: 'get',
    });
  }
}

export { RolesResource as default };
