import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
} from '@chakra-ui/react';
import { OwnerSchema } from 'constants/schema';
import { useFormContext } from 'react-hook-form';

interface SimpleInputProps {
  variableKey: keyof OwnerSchema;
  label: string;
  placeholder?: string;
  isRequired: boolean;
  isRequiredValidation?: string;
  isReadOnly?: boolean;
  defaultValue?: string;
}

const RenderSimpleInput: React.FC<SimpleInputProps> = (props) => {
  const {
    variableKey,
    label,
    isRequired,
    isRequiredValidation,
    isReadOnly,
    defaultValue,
    placeholder,
  } = props;

  const {
    register,
    formState: { errors },
  } = useFormContext<OwnerSchema>();

  return (
    <GridItem>
      <FormControl isInvalid={!!errors?.[variableKey]} isRequired={isRequired}>
        <FormLabel>{label}</FormLabel>
        <Input
          {...register(variableKey, {
            ...(isRequired ? { required: isRequiredValidation } : {}),
          })}
          size="lg"
          type="text"
          defaultValue={defaultValue}
          placeholder={placeholder ?? label}
          isReadOnly={isReadOnly}
        />
        <FormErrorMessage>
          {errors?.[variableKey] && errors?.[variableKey]?.message}
        </FormErrorMessage>
      </FormControl>
    </GridItem>
  );
};

export default RenderSimpleInput;
