import { FormControl, Grid, GridItem, useToast } from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { AxiosError, AxiosResponse } from 'axios';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import { TicketInfoSchema, TicketStatus } from 'constants/schema';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { checkPermissions } from 'utils/listInfo';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import { RootState } from '../../store';

interface Props {
  ticketId: number;
  status: TicketStatus;
}

interface TicketStatusFormDTO {
  status: TicketStatus;
  updated_by: number;
}

const STATUSES = ['open', 'in-progress', 'done', 'closed'] as const;

const StatusForm: React.FC<Props> = (props) => {
  const { ticketId, status } = props;
  const toast = useToast();
  const ticketAPI = new TicketResource();
  const methods = useForm<TicketInfoSchema>();
  const loggedInUser = useLoggedInUser();
  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const updateTicket = useMutation<
    AxiosResponse<TicketStatusFormDTO>,
    AxiosError,
    TicketStatusFormDTO
  >((data) =>
    ticketAPI.updateTicketStatus(ticketId, data.status, data.updated_by)
  );

  const handleStatus = async (status: TicketStatus) => {
    let data = {
      status,
      updated_by: loggedInUser.id,
    };
    updateTicket.mutate(data, {
      onSuccess: () => {
        toast({
          title: strings.ticket_updated,
          status: 'success',
          isClosable: true,
        });
      },
    });
  };
  const TrailingDash = {
    fontWeight: 'normal',
    position: 'relative',
    paddingRight: '4',
    paddingLeft: '4',
    textTransform: 'capitalize',
    _after: {
      content: `""`,
      position: 'absolute',
      top: '1',
      right: '0',
      width: '1px',
      height: '38px',
      bg: 'gray.300',
    },
  };

  const isPermitted = useMemo(() => {
    return checkPermissions(userPermissions, [
      PermissionRequest['edit:ticket'],
    ]);
  }, [userPermissions]);

  return (
    <>
      <form>
        <Grid
          gap="4"
          templateColumns="repeat(1, 1fr)"
          w="100%"
          sx={TrailingDash}>
          <GridItem>
            <FormControl w="100%">
              <CustomChakraSelect
                size="lg"
                rounded="sm"
                id="status"
                disabled={!isPermitted}
                defaultValue={status}
                bg="gray.50"
                {...methods.register('status')}
                // TODO: Can this cast be avoided with better types?
                onChange={(e) => handleStatus(e.target.value as TicketStatus)}>
                {STATUSES.map((status) => {
                  return (
                    <option key={status} value={status}>
                      {strings.getString(status)}
                    </option>
                  );
                })}
              </CustomChakraSelect>
            </FormControl>
          </GridItem>
        </Grid>
      </form>
    </>
  );
};
export default StatusForm;
