import { useCallback, useRef, useState } from 'react';

function useIntersectionObserver(loading: boolean = false) {
  const [visible, setVisible] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver>();

  const observer = useCallback(
    (node: HTMLElement | null) => {
      if (loading) {
        setVisible(false);
        return;
      }
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !loading) {
          setVisible(true);
        } else setVisible(false);
      });
      if (node) observerRef.current.observe(node);
    },
    [loading]
  );

  return { visible, observer };
}

export default useIntersectionObserver;
