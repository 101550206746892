import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
} from '@chakra-ui/react';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { ApartmentInfo, UnitTypeEnum } from 'constants/schema';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import UnitForm from '../UnitForm';

const ROOM_COUNTS = [
  'studio',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '4.5',
  '5',
  '5.5',
  '6+',
] as const;
const FLOORS = ['-2', '-1', '0', '1', '2', '3', '4', '5', '6'] as const;
const RENTAL_STATUS = [
  'permanent_rental',
  'vacation_rental',
  'own_use',
  'unknown',
] as const;

interface Props {
  data?: ApartmentInfo;
}

const ApartmentForm: React.FC<Props> = (props: Props) => {
  const { data } = props;
  let mappedFt = data?.garage_features?.map((features) => {
    let { id, garage_type, garage_no, garage_quota, qty } = features;
    return {
      id,
      garage_type,
      garage_no,
      garage_quota,
      qty,
    };
  });

  const methods = useForm<ApartmentInfo>({
    defaultValues: {
      garage_features: mappedFt ?? [],
    },
  });

  // Form state
  const {
    register,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    setValue('type', UnitTypeEnum.APARTMENT);
    if (!data) return;
    const {
      apartment_quota,
      rental_status,
      room,
      living_space,
      floor,
      stwe_number,
    } = data;
    setValue('apartment_quota', apartment_quota);
    setValue('stwe_number', stwe_number);
    setValue('floor', floor);
    setValue('living_space', living_space);
    setValue('room', room);
    setValue('rental_status', rental_status);
  }, [data, setValue]);

  return (
    <FormProvider {...methods}>
      <form>
        <UnitForm unitType={UnitTypeEnum.APARTMENT} data={data}>
          <GridItem>
            <FormControl isInvalid={!!errors?.stwe_number} isRequired>
              <FormLabel>{strings.apartment_number}</FormLabel>
              <Input
                type="text"
                {...register('stwe_number', {
                  required: strings.stwe_number_is_required,
                })}
                placeholder={strings.enter_apartment_number}
              />
              <FormErrorMessage>
                <>{errors?.stwe_number && errors?.stwe_number?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.apartment_quota} isRequired>
              <FormLabel>{strings.quota}</FormLabel>
              <Input
                type="text"
                {...register('apartment_quota', {
                  required: strings.apartment_quota_is_required,
                })}
                placeholder={strings.enter_quota}
              />
              <FormErrorMessage>
                <>
                  {errors?.apartment_quota && errors?.apartment_quota?.message}
                </>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.floor}>
              <FormLabel>{strings.floor_number}</FormLabel>
              <CustomChakraSelect
                {...register('floor')}
                placeholder={strings.select_floor}>
                {FLOORS.map((ele) => (
                  <option key={ele} value={ele}>
                    {strings[`floor_${ele}`]}
                  </option>
                ))}
              </CustomChakraSelect>
              <FormErrorMessage>
                <>{errors?.floor && errors?.floor?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.living_space} isRequired>
              <FormLabel>{strings.living_space}</FormLabel>
              <Input
                type="text"
                {...register('living_space', {
                  required: strings.living_space_is_required,
                })}
                placeholder={strings.enter_living_space}
              />
              <FormErrorMessage>
                <>{errors?.living_space && errors?.living_space?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.room}>
              <FormLabel>{strings.number_of_rooms}</FormLabel>
              <CustomChakraSelect
                {...register('room')}
                placeholder={strings.select_rooms}>
                {ROOM_COUNTS.map((ele) => (
                  <option key={ele} value={ele}>
                    {strings[ele]}
                  </option>
                ))}
              </CustomChakraSelect>
              <FormErrorMessage>
                <>{errors?.room && errors?.room?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={[1, 2]} mb="32px"></GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.rental_status}>
              <FormLabel>{strings.status}</FormLabel>
              <CustomChakraSelect
                {...register('rental_status')}
                placeholder={strings.select_status}>
                {RENTAL_STATUS.map((ele) => (
                  <option key={ele} value={ele}>
                    {strings[ele]}
                  </option>
                ))}
              </CustomChakraSelect>
              <FormErrorMessage>
                <>{errors?.rental_status && errors?.rental_status?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </UnitForm>
      </form>
    </FormProvider>
  );
};

export default ApartmentForm;
