import { Switch, useToast } from '@chakra-ui/react';
import UserResource from 'api/user';
import { strings } from 'config/localization';
import { ROLE_TYPES } from 'constants/common';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

export interface TwoFaSwitchProps {
  has2FaEnabled: boolean;
  userId: number;
}

const TwoFaSwitch: React.FC<TwoFaSwitchProps> = (props) => {
  const { has2FaEnabled, userId } = props;

  const { role } = useSelector((state: RootState) => ({
    role: state?.data?.auth?.user?.role,
  }));

  const isNotAdmin = role !== ROLE_TYPES.ADMIN;
  const userAPI = new UserResource();
  const queryClient = useQueryClient();
  const toast = useToast();

  const disable2FAMutation = useMutation(userAPI.disable2FA, {
    onSuccess: () => {
      queryClient.invalidateQueries('userList');
      queryClient.invalidateQueries(`user${userId}-edit`);
      toast({
        title: strings.fa_Disable,
        status: 'success',
        isClosable: true,
      });
    },

    onError: () => {
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const disable2FA = () => {
    disable2FAMutation.mutate(userId.toString());
  };

  return (
    <Switch
      size="md"
      name="has_payment_enable"
      colorScheme="green"
      isChecked={has2FaEnabled}
      isDisabled={!has2FaEnabled || isNotAdmin}
      onChange={disable2FA}
    />
  );
};

export default TwoFaSwitch;
