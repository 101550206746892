import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  Stack,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import { INITIAL_CURRENT_PAGE } from 'constants/common';
import { ObjektListFilterParams } from 'pages/dashboard/oms/ObjektList';
import React, { ChangeEvent } from 'react';
import { BiFilter } from 'react-icons/bi';

type Props = {
  filterParams: ObjektListFilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<ObjektListFilterParams>>;
};

const ObjektSearch: React.FC<Props> = (props) => {
  const { filterParams, setFilterParams } = props;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFilterParams((prev) => ({
      ...prev,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      address: '',
    }));
  };

  return (
    <Accordion
      defaultIndex={0}
      bg="white"
      borderColor="white"
      allowToggle
      boxShadow="box">
      <AccordionItem>
        <h2>
          <AccordionButton p="4">
            <Box flex="1" textAlign="left">
              <Flex justify="space-between">
                <Heading fontSize="18px" fontWeight="medium">
                  <Icon as={BiFilter} /> {strings.filter}
                </Heading>
              </Flex>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel padding="0">
          <Stack sx={wrapperStyles}>
            <form>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="4"
                alignItems={['initial', 'initial', 'flex-end']}>
                <Grid
                  gap="4"
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(2, 1fr)',
                  ]}
                  flex="1">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.object}</FormLabel>
                      <Input
                        type="text"
                        name="keyword"
                        value={filterParams.keyword}
                        onChange={handleInputChange}
                        placeholder={strings.object}
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.address}</FormLabel>
                      <Input
                        type="text"
                        name="address"
                        value={filterParams.address}
                        onChange={handleInputChange}
                        placeholder={`${strings.street} ${strings.house_number}, ${strings.zip_code_abbreviation} ${strings.town}`}
                        size="lg"
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
                <Button
                  size="lg"
                  w="fit-content"
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </form>
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ObjektSearch;
