import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { Document } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getYearsList } from 'utils';
import OwnerShipDocumentsSelection from './OwnerShipDocumentsSelection';

interface Props {
  ownerDocumentData?: Document;
  isLoading: boolean;
}

interface PostDataInterface {
  ownerIds: number[];
  apartmentIds: number[];
  ownerShipIds: number[];
}

// TODO: Use enums or union types instead of strings here.
export interface EditOwnerDocumentFormData {
  tag: string;
  year: string;
  postData: {
    ownerIds: number[];
    ownerShipIds: number[];
    apartmentIds: number[];
  };
}

const EditOwnerDocumentForm: React.FC<Props> = (props) => {
  const { ownerDocumentData, isLoading } = props;
  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useFormContext<EditOwnerDocumentFormData>();

  const history = useHistory();

  const tagValue = watch('tag');
  const yearValue = watch('year');

  const initialApartmentIds = [
    ...new Set(
      ownerDocumentData?.apartment_owner_documents?.map(
        (item) => item.apartment_owner?.apartment_id
      ) ?? []
    ),
  ];

  const initialOwnerShipIds: number[] =
    ownerDocumentData?.apartment_owner_documents?.map(
      (item) => item.apartment_owner_id
    ) ?? [];

  const [postData, setPostData] = useState<PostDataInterface>({
    ownerIds: [],
    apartmentIds: initialApartmentIds as number[],
    ownerShipIds: initialOwnerShipIds,
  });

  const documentsAPI = new OwnerDocumentResource();

  const { data: tagsList } = useQuery(
    'tags-list',
    () => documentsAPI.getDocumentTags().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setValue('postData', postData);
    clearErrors();
  }, [postData]);

  const checkHasChanged = () => {
    if (
      ownerDocumentData?.tag !== tagValue ||
      ownerDocumentData?.document_year !== yearValue
    ) {
      return true;
    }

    if (
      !initialOwnerShipIds?.every((e) => postData.ownerShipIds.includes(e)) ||
      initialOwnerShipIds.length !== postData.ownerShipIds.length
    ) {
      return true;
    }

    return false;
  };

  const hasChanged = checkHasChanged();

  return (
    <>
      <Stack direction="column" spacing="4" sx={wrapperStyles}>
        <Flex align="center" justify="space-between" mb={4}>
          <Heading as="h2" size="md" mr={4}>
            {strings.document_properties}
          </Heading>
        </Flex>

        <Grid templateColumns="repeat(2, 1fr)" gap={8}>
          {tagsList && (
            <GridItem w="100%" colSpan={1}>
              <FormControl
                colorScheme="primary"
                isInvalid={!!errors.tag}
                isRequired>
                <FormLabel color="gray.600">{strings.tag}</FormLabel>
                <CustomChakraSelect
                  defaultValue={ownerDocumentData?.tag}
                  id="role_group"
                  placeholder={strings.tag_placeholder}
                  rounded="sm"
                  {...register('tag', {
                    required: strings.tag_required,
                  })}>
                  {tagsList?.data.map((item) => (
                    <option key={item} value={item}>
                      {strings.getString(item)}
                    </option>
                  ))}
                </CustomChakraSelect>

                <FormErrorMessage>
                  <>{errors?.tag && errors?.tag?.message}</>
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          )}

          <GridItem w="100%" colSpan={1}>
            <FormControl
              colorScheme="primary"
              isInvalid={!!errors.year}
              isRequired>
              <FormLabel color="gray.600">{strings.year}</FormLabel>
              <CustomChakraSelect
                defaultValue={ownerDocumentData?.document_year}
                id="year"
                placeholder={strings.year_placeholder}
                rounded="sm"
                {...register('year', {
                  required: strings.year_required,
                })}>
                {getYearsList(1960).map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </CustomChakraSelect>
              <FormErrorMessage>
                <>{errors?.year && errors?.year?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>

      <Box mt="5">
        <OwnerShipDocumentsSelection
          postData={postData}
          setPostData={setPostData}
          isEditMode
          initialOwnerShipIds={initialOwnerShipIds}
        />
      </Box>

      <ButtonGroup alignSelf="flex-end">
        <Button
          colorScheme="primary"
          variant="outline"
          onClick={() => history.goBack()}>
          {strings.cancel}
        </Button>
        <Button
          isDisabled={!hasChanged}
          colorScheme="primary"
          type="submit"
          isLoading={isLoading}>
          {strings.save_now}
        </Button>
      </ButtonGroup>
    </>
  );
};

export default EditOwnerDocumentForm;
