const DropUpSvg = () => {
  return (
    <svg
      height="15"
      width="20"
      viewBox="0 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="arrow-svg">
      <path
        d="M2.2299 13.9766L9 7.20646L15.7701 13.9766L18 11.7467L9 2.74667L0 11.7467L2.2299 13.9766Z"
        fill="#424360"
      />
    </svg>
  );
};

export default DropUpSvg;
