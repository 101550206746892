import { Country } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class OMSCountriesResource extends Resource<Country> {
  constructor() {
    super(`oms/countries`);
  }
  async list() {
    return http({
      url: this.uri,
      method: 'get',
    });
  }
}

export { OMSCountriesResource as default };
