import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import OMSCountriesResource from 'api/oms-countries';
import OMSObjectsResource from 'api/oms-objects';
import { AxiosError, AxiosResponse } from 'axios';
import CustomSelectCaretaker, {
  Option,
} from 'components/common/CustomSelectCaretaker';
import CustomSelectCountry, {
  CountryOption,
  OptionType,
} from 'components/common/CustomSelectCountry';
import FormCancelButton from 'components/common/FormCancelButton';
import { strings } from 'config/localization';
import { OMS_CARETAKERS_LIST } from 'constants/common';
import routes from 'constants/routes';
import { Address } from 'constants/schema';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { renderFullName } from 'utils';

interface Props {
  data?: Address;
}

interface ObjectFormValues {
  name: string;
  addition: string;
  street: string;
  house_number: string;
  zip_code: string;
  town: string;
  country_id: number;
  construction_year: string;
  caretaker_checkbox: boolean;
  caretaker: Option | null;
}

interface ObjectFormDTO {
  name?: string;
  addition?: string;
  street?: string;
  house_number?: string;
  zip_code?: string;
  town?: string;
  country_id?: number;
  construction_year?: string;
  caretaker_id?: number | null | undefined;
}

const formFieldsStyle = {
  bg: 'white',
  borderRadius: '3px',
  p: '16px',
  ml: '1',
  boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.06)',
};

const ObjectForm: React.FC<Props> = (props) => {
  const { data } = props;

  // Hooks
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const methods = useForm<ObjectFormValues>();

  // Country list
  const countryAPI = new OMSCountriesResource();
  const { data: countryList } = useQuery(
    'oms-country-list',
    () => countryAPI.list().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Form state
  const {
    register,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
  } = methods;
  useEffect(() => {
    if (!data || !countryList) return;
    const {
      name,
      addition,
      street,
      house_number,
      zip_code,
      town,
      country,
      construction_year,
    } = data;
    setValue('name', name);
    setValue('addition', addition);
    setValue('street', street);
    setValue('house_number', house_number);
    setValue('zip_code', zip_code);
    setValue('town', town);
    setValue('country_id', country.id);
    setValue('construction_year', construction_year);
  }, [countryList, data, setValue]);

  // Form submission
  const objectAPI = new OMSObjectsResource();
  const [errMsg, setErrMsg] = useState('');
  const createObject = useMutation<
    AxiosResponse<ObjectFormDTO>,
    AxiosError,
    ObjectFormDTO
  >((data) => objectAPI.store(data));
  const updateObject = useMutation<
    AxiosResponse<ObjectFormDTO>,
    AxiosError,
    ObjectFormDTO
  >((formData) => objectAPI.update(data?.id, formData));

  const onSubmit = (formData: ObjectFormValues) => {
    if (formData.caretaker_checkbox && noCareTaker) {
      methods.setError('caretaker', {
        message: strings.caretaker_is_required,
      });
      return;
    }
    if (formData.addition) {
      formData.addition = formData.addition.trim();
    }
    let API;
    let successMessage: string;
    let errorMessage: string;
    if (data?.id) {
      API = updateObject;
      successMessage = `${strings.object} ${strings.has_been_updated}`;
      errorMessage = `${strings.object} ${strings.has_not_been_updated}`;
    } else {
      API = createObject;
      successMessage = `${strings.object} ${strings.has_been_created}`;
      errorMessage = `${strings.object} ${strings.has_not_been_created}`;
    }

    const mappedData: ObjectFormDTO = {
      name: formData.name,
      addition: formData.addition,
      street: formData.street,
      house_number: formData.house_number,
      zip_code: formData.zip_code,
      town: formData.town,
      country_id: formData.country_id,
      construction_year: formData.construction_year,
      caretaker_id: data?.caretaker?.id,
    };
    if (formData?.caretaker?.id) {
      mappedData.caretaker_id = formData?.caretaker?.id;
    } else if (!formData.caretaker_checkbox) {
      mappedData.caretaker_id = undefined;
    }

    API.mutate(mappedData, {
      onSuccess: () => {
        if (data) {
          queryClient.invalidateQueries(`objectDetails-${data.id}`);
        }
        toast({
          title: successMessage,
          status: 'success',
          isClosable: true,
        });
        history.push(routes.oms.objects.list);
      },
      onError: () => {
        toast({
          title: errorMessage,
          status: 'error',
          isClosable: true,
        });
        setErrMsg(errorMessage);
      },
    });
  };

  const currentSelectedCareTaker = watch('caretaker');
  const careTakerCheckBoxSelected = watch('caretaker_checkbox');

  const [careTakerExists, setCareTakerExists] = useState(!!data?.caretaker);

  const noCareTaker = !careTakerExists && !currentSelectedCareTaker;

  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const handleCareTakerChange = (option: Option) =>
    setValue('caretaker', option);

  useEffect(() => {
    if (!!data?.caretaker) {
      setValue('caretaker_checkbox', true);
    }
  }, [data?.caretaker, setValue]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked && !noCareTaker) {
      setIsDeleteModal(true);
      onOpen();
      return;
    }

    setValue('caretaker_checkbox', e.target.checked);

    if (!e.target.checked) {
      setValue('caretaker', null);
    }
  };

  const removeCareTaker = () => {
    if (isDeleteModal && careTakerExists) {
      updateObject.mutate(
        { caretaker_id: null },
        {
          onSuccess: () => {
            if (data) {
              queryClient.invalidateQueries(`objectDetails-${data.id}`);
            }
            onClose();
            setValue('caretaker_checkbox', false);
            setValue('caretaker', null);

            toast({
              title: `${strings.object} ${strings.has_been_updated}`,
              status: 'success',
              isClosable: true,
            });
          },
          onError: () => {
            toast({
              title: `${strings.object} ${strings.has_not_been_updated}`,
              status: 'error',
              isClosable: true,
            });
          },
        }
      );
    } else {
      setValue('caretaker', null);
      onClose();

      if (isDeleteModal) {
        setValue('caretaker_checkbox', false);
      } else {
        setCareTakerExists(false);
      }
    }
  };

  const renderSelectedCareTaker = () => {
    if (noCareTaker) return null;
    return (
      <Flex
        padding="4"
        justifyContent="space-between"
        alignItems="center"
        display="inline-flex">
        {careTakerExists ? (
          <>
            <Text>
              {renderFullName(
                data?.caretaker?.first_name,
                data?.caretaker?.last_name
              )}
            </Text>
            <Text>{data?.caretaker?.address}</Text>
            <Text>{data?.caretaker?.email}</Text>
            <Text>{data?.caretaker?.contact_number}</Text>
          </>
        ) : (
          <>
            <Text>
              {renderFullName(
                currentSelectedCareTaker?.first_name,
                currentSelectedCareTaker?.last_name
              )}
            </Text>
            <Text>{currentSelectedCareTaker?.address}</Text>
            <Text>{currentSelectedCareTaker?.email}</Text>
            <Text>{currentSelectedCareTaker?.contact_number}</Text>
          </>
        )}

        <IconButton
          onClick={() => {
            setIsDeleteModal(false);
            onOpen();
          }}
          icon={<BiEdit size="18" />}
          variant="link"
          aria-label={strings.edit_caretaker}
          color="blue.300"
          minW="8"
        />
      </Flex>
    );
  };

  const selectOptions: OptionType[] =
    countryList?.data?.map((option: CountryOption) => ({
      value: option.id,
      label: option.name,
    })) ?? [];

  return (
    <FormProvider {...methods}>
      <form>
        <Stack direction="column" spacing="32px">
          <Stack direction="column" spacing="8" sx={formFieldsStyle}>
            {errMsg && (
              <Alert status="error">
                <AlertIcon />
                {errMsg}
              </Alert>
            )}
            <Stack direction="column">
              <Heading
                as="h3"
                p="16px"
                fontSize="18px"
                fontWeight="500"
                lineHeight="21px">
                {strings.basic_information}
              </Heading>
              <Grid
                p="16px"
                gap="3"
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                w="100%">
                <GridItem>
                  <FormControl isInvalid={!!errors?.name} isRequired>
                    <FormLabel>{strings.object_name}</FormLabel>
                    <Input
                      type="text"
                      {...register('name', {
                        required: strings.object_name_is_required,
                      })}
                      placeholder={strings.enter_object_name}
                    />
                    <FormErrorMessage>
                      <>{errors?.name && errors?.name?.message}</>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={!!errors?.addition}>
                    <FormLabel>{strings.object_addition}</FormLabel>
                    <Input
                      {...register('addition')}
                      type="text"
                      placeholder={strings.enter_object_addition}
                    />
                    <FormErrorMessage>
                      <>{errors?.addition && errors?.addition?.message}</>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[1, 2]} mb="34px"></GridItem>
                <GridItem>
                  <FormControl isInvalid={!!errors?.street} isRequired>
                    <FormLabel>{strings.street}</FormLabel>
                    <Input
                      type="text"
                      {...register('street', {
                        required: strings.street_name_is_required,
                      })}
                      placeholder={strings.enter_street}
                    />
                    <FormErrorMessage>
                      <>{errors?.street && errors?.street?.message}</>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={!!errors?.house_number} isRequired>
                    <FormLabel>{strings.house_number}</FormLabel>
                    <Input
                      type="text"
                      {...register('house_number', {
                        required: strings.house_number_is_required,
                      })}
                      placeholder={strings.enter_house_number}
                    />
                    <FormErrorMessage>
                      <>
                        {errors?.house_number && errors?.house_number?.message}
                      </>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={!!errors?.zip_code} isRequired>
                    <FormLabel>{strings.zip_code}</FormLabel>
                    <Input
                      type="text"
                      {...register('zip_code', {
                        required: strings.zip_code_is_required,
                      })}
                      placeholder={strings.enter_zip_code}
                    />
                    <FormErrorMessage>
                      <>{errors?.zip_code && errors?.zip_code?.message}</>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl isInvalid={!!errors?.town} isRequired>
                    <FormLabel>{strings.town}</FormLabel>
                    <Input
                      type="text"
                      {...register('town', {
                        required: strings.town_is_required,
                      })}
                      placeholder={strings.enter_town}
                    />
                    <FormErrorMessage>
                      <>{errors?.town && errors?.town?.message}</>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                {selectOptions.length > 0 && (
                  <GridItem>
                    <FormControl isInvalid={!!errors?.country_id} isRequired>
                      <FormLabel>{strings.country}</FormLabel>
                      <CustomSelectCountry
                        {...register('country_id', {
                          required: strings.country_is_required,
                        })}
                        field="label"
                        options={selectOptions}
                        defaultValue={data?.country?.name}
                      />

                      <FormErrorMessage>
                        <>{errors?.country_id && errors?.country_id?.message}</>
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                )}
                <GridItem colSpan={[1, 2]} mb="34px"></GridItem>
                <GridItem>
                  <FormControl isInvalid={!!errors?.construction_year}>
                    <FormLabel>{strings.construction_year}</FormLabel>
                    <Input
                      type="text"
                      {...register('construction_year')}
                      placeholder={strings.enter_construction_year}
                    />
                    <FormErrorMessage>
                      <>
                        {errors?.construction_year &&
                          errors?.construction_year?.message}
                      </>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[1, 2]} mb="34px"></GridItem>

                <GridItem colSpan={2}>
                  <FormControl>
                    <Checkbox
                      {...register('caretaker_checkbox')}
                      isChecked={careTakerCheckBoxSelected}
                      onChange={handleCheckBoxChange}>
                      {strings.caretaker}
                    </Checkbox>
                  </FormControl>
                </GridItem>
                {careTakerCheckBoxSelected && noCareTaker && (
                  <GridItem>
                    <FormControl isInvalid={!!errors?.caretaker} isRequired>
                      <CustomSelectCaretaker
                        placeholder={strings.select_caretaker}
                        SEARCH_API={OMS_CARETAKERS_LIST}
                        limit={15}
                        value={currentSelectedCareTaker}
                        onChange={handleCareTakerChange}
                      />
                      <FormErrorMessage>
                        <>{errors?.caretaker && errors?.caretaker?.message}</>
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                )}
              </Grid>
              {careTakerCheckBoxSelected && renderSelectedCareTaker()}
            </Stack>
          </Stack>
          <ButtonGroup alignSelf="end">
            <FormCancelButton
              isLoading={createObject.isLoading || updateObject.isLoading}
            />
            <Button
              colorScheme="primary"
              type="button"
              isLoading={createObject.isLoading || updateObject.isLoading}
              onClick={methods.handleSubmit(onSubmit)}>
              {data?.id ? strings.save : strings.add_object}
            </Button>
          </ButtonGroup>
        </Stack>
      </form>

      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {isDeleteModal
              ? strings.remove_caretaker
              : strings.change_caretaker}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isDeleteModal
              ? strings.remove_caretaker_info
              : strings.change_caretaker_info}
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={onClose}>
                {strings.no_thank_you}
              </Button>
              <Button
                isLoading={updateObject.isLoading}
                colorScheme={isDeleteModal ? 'red' : 'blue'}
                onClick={removeCareTaker}>
                {isDeleteModal ? strings.remove : strings.change}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};

export default ObjectForm;
