import { Country } from 'constants/schema';
import Resource from './resource';

class UserCountriesResource extends Resource<Country> {
  constructor() {
    super(`user/countries`);
  }
}

export { UserCountriesResource as default };
