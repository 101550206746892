import {
  Box,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import { Document } from 'constants/schema';
import React, { useState } from 'react';

import DocumentDeleteModal from './DocumentDeleteModal';
import DocumentListItem from './DocumentListItem';

interface Props {
  list: Document[];
  listLoading: boolean;
  title: string;
  hasBoth?: boolean;
  refetch: () => void;
}

const DocumentListTable: React.FC<Props> = (props) => {
  const { listLoading, list, title, hasBoth = false, refetch } = props;

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [selectedForDelete, setSelectedForDelete] = useState<Document | null>(
    null
  );

  const onCloseAndUnselect = () => {
    onClose();
    setSelectedForDelete(null);
  };

  const onDeleteClick = (item: Document) => {
    onOpen();
    setSelectedForDelete(item);
  };

  const renderContent = () => {
    if (listLoading) {
      return <TableSkeletonLoader rows={6} cols={4} />;
    }

    return list?.map((item) => (
      <DocumentListItem
        key={item.id}
        document={item}
        onDeleteClick={onDeleteClick}
      />
    ));
  };

  const renderTable = () => {
    return (
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w={'60%'}>
                <Text color="paragraph">{strings.document_name}</Text>
              </Th>
              <Th>
                <Text color="paragraph">{strings.tag}</Text>
              </Th>
              <Th isNumeric>
                <Text color="paragraph">{strings.year}</Text>
              </Th>
              <Th isNumeric>
                <Text color="paragraph">{strings.action}</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>{renderContent()}</Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Stack sx={wrapperStyles}>
      <Flex align="center" justify="space-between" mb={4}>
        <Heading as="h2" size="md" mr={4}>
          {title}
        </Heading>
      </Flex>
      <Box>{renderTable()}</Box>

      <DocumentDeleteModal
        selectedForDelete={selectedForDelete}
        isOpen={isOpen}
        onClose={onCloseAndUnselect}
        refetch={refetch}
      />
    </Stack>
  );
};

export default DocumentListTable;
