import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  Tooltip,
  useRadioGroup,
} from '@chakra-ui/react';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import Uploader from 'components/common/Uploader';
import { strings } from 'config/localization';
import { FAQGroupSchema, FAQSchema } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { BiFile, BiTrash } from 'react-icons/bi';
import { capitalizeFirstLetter } from 'utils';

interface Props {
  data?: FAQSchema | null;
  faqGroups?: FAQGroupSchema[];
}

export type FaqFormData = {
  description: string;
  faq_group_id: number;
  file_contact: File | null;
  status: '0' | '1';
  title: string;
};

const deteleIconStyles = {
  minW: '8',
  _hover: { color: 'red.300' },
};

const FaqForm: React.FC<Props> = (props) => {
  let { data, faqGroups } = props;

  const {
    register,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useFormContext<FaqFormData>();

  const [file_contact, setFile_contact] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      setValue('title', data.title);
      setValue('status', data.status.toString() as '0' | '1');
      setValue('faq_group_id', data.faq_group.id);
      setValue('description', data.description);
      setFile_contact(data.file_contact);
    }
  }, [data, setValue]);

  const handleDeleteContactFile = () => {
    setValue('file_contact', null);
    setFile_contact(null);
  };

  const handleStatusChange = (value: '0' | '1') => {
    setValue('status', value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'status',
    defaultValue: data?.status.toString() ?? '0',
    value: getValues('status'),
    onChange: handleStatusChange,
  });

  const group = getRootProps();

  return (
    <>
      <Stack direction="column" spacing="4">
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors.title}>
              <FormLabel>{strings.question}</FormLabel>
              <Textarea
                {...register('title', {
                  required: strings.title_required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                placeholder={strings.question}
                defaultValue={data?.title}
              />
              <FormErrorMessage>
                <>{errors.title && errors.title?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns={[
            'repeat(1, 1fr)',
            'repeat(1, 1fr)',
            'repeat(2, 1fr)',
          ]}
          w="100%">
          <GridItem>
            <FormControl isInvalid={!!errors.faq_group_id} isRequired>
              <FormLabel>{strings.group}</FormLabel>
              <CustomChakraSelect
                size="lg"
                {...register('faq_group_id')}
                placeholder={strings.select_group}
                rounded="sm">
                {faqGroups?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {capitalizeFirstLetter(item.name)}
                  </option>
                ))}
              </CustomChakraSelect>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.description}>
              <FormLabel>{strings.description}</FormLabel>
              <Textarea
                {...register('description', {
                  required: strings.required,
                  validate: (value) => {
                    if (value?.trim() === '') return strings.required;
                  },
                })}
                placeholder={strings.description}
              />
              <FormErrorMessage>
                <>{errors?.description && errors?.description?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          w="100%">
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors?.status} isRequired>
              <FormLabel>{strings.status}</FormLabel>
              <Controller
                name="status"
                rules={{
                  required: strings.required,
                }}
                defaultValue={data?.status ? '1' : '0'}
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field} id="status">
                    <Flex my="2" direction="row" {...group}>
                      <Stack direction="row" spacing="8">
                        <Radio value="1">{strings.active}</Radio>
                        <Radio value="0">{strings.in_active}</Radio>
                      </Stack>
                    </Flex>
                  </RadioGroup>
                )}
              />
              <FormErrorMessage>
                <>{errors?.status && errors?.status?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={[2, 2, 1]}>
            <FormControl isInvalid={!!errors?.file_contact}>
              <FormLabel>{strings.file}</FormLabel>
              <Box>
                <Uploader
                  fileKey="file_contact"
                  multiple={false}
                  acceptFileType=".pdf"
                />
              </Box>
              <Box mt="2" color="gray.400" w="260px">
                {file_contact && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="100%"
                    padding="6px 0 6px 0"
                    _hover={{ bg: 'gray.50' }}>
                    <a
                      href={data?.file_contact}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Icon as={BiFile} w="4" h="4" />
                      {data?.file_contact_name
                        ? data.file_contact_name
                        : data?.file_contact.split('/').pop()}
                    </a>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label={strings.delete_file}
                      fontSize="sm"
                      bg="red.300"
                      color="white">
                      <IconButton
                        icon={<BiTrash />}
                        variant="link"
                        aria-label={strings.delete_file}
                        sx={deteleIconStyles}
                        onClick={handleDeleteContactFile}
                      />
                    </Tooltip>
                  </Flex>
                )}
              </Box>
            </FormControl>
          </GridItem>
        </Grid>
      </Stack>
    </>
  );
};

export default FaqForm;
