import { AxiosResponse } from 'axios';
import { TicketCommentCreationResponse } from 'constants/schema';

export enum TicketActionTypes {
  SET_TICKET_LOG_INFO = 'SET_TICKET_LOG_INFO',
}

export interface TicketAction {
  data: AxiosResponse<TicketCommentCreationResponse>;
  type: TicketActionTypes;
}

/**
 * Action to set Ticket Log INfo
 * @param {object} ticketLog
 * @returns {object}
 */
export function setTicketLogInfo(
  ticketLog: AxiosResponse<TicketCommentCreationResponse>
): TicketAction {
  return {
    data: ticketLog,
    type: TicketActionTypes.SET_TICKET_LOG_INFO,
  };
}
