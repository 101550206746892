import { AxiosError, CancelTokenSource } from 'axios';
import { rolesData, TagItem } from 'constants/common';
import { IconType } from 'react-icons/lib';
import { AuthAction } from '../actions/data/auth';
import { UserPermission } from './interfaces';
import PermissionRequest from './PermissionRequest';

export type DataWrapper<T> = {
  data: T;
  success: boolean;
  meta: MetaSchema;
};

type NullableType<T> = { [K in keyof T]: T[K] | null };

export type Option<T> = {
  value: T;
  label: string;
};

export type TicketBoardState = {
  open: TicketInfoSchema[];
  'in-progress': TicketInfoSchema[];
  done: TicketInfoSchema[];
  closed: TicketInfoSchema[];
};

export type TicketBoardMetaState = {
  open: MetaSchema;
  'in-progress': MetaSchema;
  done: MetaSchema;
  closed: MetaSchema;
};

export type TicketUser = {
  name: string;
  status: boolean;
};

export type TicketCommentSchema = {
  comment: string;
  createdAt: string;
  created_by: number;
  created_by_user: TicketUser;
  id: number;
  level: number;
  parent_id: number;
  replied_by_user: TicketCommentSchema[];
  ticket_id: number;
};

export type TicketLogInfoAttributes = {
  comment: string;
  created_by: number;
  ticket_id: number;
};

export type TicketUpdateAction = 'create' | 'update' | 'status' | 'comment';

export type TicketLogInfoSchema = {
  comment_id: number;
  createdAt: string;
  created_by: number;
  created_by_user: TicketUser;
  original_attributes: TicketLogInfoAttributes;
  ticket_id: number;
  uuid: string;
} & (
  | {
      action: 'create';
      updated_attributes: {
        apartment_id: number;
        created_by: string;
        description: string;
        house_owner: number;
        title: string;
      };
    }
  | {
      action: 'update';
      updated_attributes: {
        apartment_id: number;
        assigned_user_id: number;
        files: File[];
        house_owner: number;
        priority: TicketPriority;
        title: string;
        updated_by: number;
      };
    }
  | {
      action: 'status';
      updated_attributes: {
        status: TicketStatus;
      };
    }
  | {
      action: 'comment';
      updated_attributes: {
        comment: string;
        created_by: number;
        ticket_id: number;
      };
    }
);

export type TicketStatus = keyof TicketBoardState;
export type TicketSchema = {
  [key: string]: Object[];
} & Partial<TicketInfoSchema>;

export type LanguageTypesSchema = 'de' | 'en' | 'fr' | 'it';

export type UserLoginType = 'service_center' | 'owner' | 'customer';

export type Role =
  | 'admin'
  | 'employee'
  | 'employee_with_ticket_support'
  | 'owner'
  | 'caretaker';

type NotOwnerRole = Exclude<Role, 'owner'>;

type OwnerDetails = {
  salutation?: string;
  first_name: string;
  last_name: string;
  title: string;
  street: string;
  house_number: string;
  zip_code: string;
  town: string;
  country_code: string;
  notes: string;
  contact_number2: string;
  contact_number3: string;
  email2: string;
  deceased_since: string;
  adittion: string;
  deceased?: boolean;
  is_newsletter_subscribed: boolean;
  allow_to_share_data: boolean;
  customer_number: string;
  owner_since: string;
  address_forwarding: boolean;
  address_forwarding_country_code: string;
  address_forwarding_title: string;
  address_forwarding_adittion: string;
  address_forwarding_reason:
    | 'guardianship'
    | 'temporary_change_of_address'
    | 'different_billing_address'
    | 'address_of_the_property'
    | '';
  address_forwarding_salutation: string;
  address_forwarding_prename: string;
  address_forwarding_surname: string;
  address_forwarding_street: string;
  address_forwarding_house_number: string;
  address_forwarding_zip_code: string;
  address_forwarding_town: string;
  address_forwarding_email: string;
  address_forwarding_phone: string;
};

export type OwnerSchema = {
  status: boolean | undefined;
  id: number;
  address: string;
  role: Role;
  role_id: RoleId;
  profile_picture_url: string;
  has_2fa_enabled: boolean;
  created_at: string;
  permissions: string[];
  email_verified_at?: string;
  is_deletable: boolean;
  email: string;
  contact_number: string;
  send_verification_email: boolean;
  language_code: LanguageTypesSchema;
} & OwnerDetails;

export type CareTakerSchema = {
  status: boolean | undefined;
  id: number;
  address: string;
  role: Role;
  role_id: RoleId;
  profile_picture_url: string;
  has_2fa_enabled: boolean;
  created_at: string;
  permissions: string[];
  email_verified_at?: string;
  is_deletable: boolean;
  email: string;
  contact_number: string;
  send_verification_email: boolean;
  language_code: LanguageTypesSchema;
  salutation?: string;
  first_name: string;
  last_name: string;
  title: string;
  street: string;
  house_number: string;
  zip_code: string;
  town: string;
  country_code: string;
  adittion: string;
};

export type UserSchema = {
  status: boolean | undefined;
  salutation: 'mr' | 'ms' | '';
  id: number;
  first_name: string;
  last_name: string;
  contact_number: string;
  address: string;
  email: string;
  role_id: RoleId;
  profile_picture_url: string;
  has_2fa_enabled: boolean;
  created_at: string;
  permissions: string[];
  email_verified_at?: string;
  send_verification_email: boolean;
  is_deletable: boolean;
  language_code: LanguageTypesSchema;
} & (
  | {
      role: 'owner';
      owner_details: NullableType<OwnerDetails>;
    }
  | {
      role: 'caretaker';
      caretaker_details: NullableType<CareTakerSchema>;
    }
  | {
      role: NotOwnerRole;
      owner_details: null;
    }
);

export type UserLoginLogSchema = {
  id: number;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    profile_picture_url: string;
  };
  status: 'login' | 'logout';
  login_ip: string;
  created_at: string;
};

export type TicketUserSchema = {
  email: string;
  first_name: string;
  id: number;
  image_url: string | null;
  language_code: string;
  last_name: string;
  name: string;
  role: string;
  status: boolean;
};

export type TicketApartmentInfo = {
  type: UnitTypeEnum;
  address: {
    addition: string;
    name: string;
  };
  stwe_number: string;
  garage_number: string;
};

export type TicketInfoSchema = {
  id: number;
  title: string;
  apartment_id: number | null;
  apartment: {
    address_id: number;
    id: number;
    info: AnyUnitInfo;
    name: string;
    owner_id: number | null;
  };
  assigned_user_id: number | null;
  assigned_user: TicketUserSchema;
  caretaker: CareTakerSchema;
  created_by_user: TicketUserSchema;
  owner: TicketOwnerSchema | null;
  house_owner: number | null;
  priority: TicketPriority;
  description: string;
  updated_by: number;
  status: TicketStatus;
  createdAt: string;
  updated_by_user: TicketUserSchema;
  updatedAt: string;
  source: string;
  appointment_date: string;
  ticket_attachments: ExtendedFileInfo[];
  isCreatedByOwner: boolean;
  isCreatedByCaretaker: boolean;
  remarks: string;
};

export type RoleSchema = {
  id: number;
  name: string;
  description: string;
  users_count: number;
  guard_name: string;
};

export type TicketLogSchema = { [key: string]: Object[] };

export type PermissionItemSchema = {
  id: number;
  name: string;
  guard_name: string;
};

export type PermissionGroupSchema = {
  id: number;
  name: string;
  permissions: [PermissionItemSchema];
};

export type PasswordSchema = {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
  confirm_password: string;
};

export type ReactSelectOptionSchema<T extends string | number | null> = {
  value: T;
  label: string;
  unit?: string;
};

export type MetaSchema = {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
};

export type PaginatedResponse<T> = {
  count: number;
  current_page: number;
  data: T;
  pages: number;
  total_rows: number;
};

export type FAQGroupSchema = {
  id: number;
  name: string;
  status: 0 | 1;
};

export type FAQSchema = {
  id: number;
  title: string;
  description: string;
  status: 0 | 1;
  file_contact: string;
  file_contact_name: string;
  faq_group: FAQGroupSchema;
};

export type TicketPriority = 'low' | 'medium' | 'high' | 'urgent';

export type menuLabel =
  | 'user'
  | 'ticket'
  | 'dms'
  | 'oms'
  | 'faq'
  | 'dashboard'
  | 'newsletter';

export type sidebarMenuListType = {
  service: string;
  menuLabel: menuLabel;
};

export type serviceRoute =
  | [string, permissionRoleSchema]
  | [string, permissionRoleSchema, permissionRoleSchema];

export type permissionRoleSchema =
  | 'read:user'
  | 'read:login-log'
  | 'read:ticket'
  | 'read:owner-dms'
  | 'read:object-dms'
  | 'read:oms'
  | 'read:faq'
  | 'read:faq-group'
  | 'read:dashboard'
  | 'is:owner'
  | 'manage:dms-owner'
  | 'manage:dms-ownership'
  | 'read:newsletter';

export type subMenusComp =
  | 'UserSubMenuList'
  | 'TicketSubMenuList'
  | 'DMSSubMenuList'
  | 'OmsSubMenuList'
  | 'FAQSubMenuList'
  | 'NewsletterSubMenuList'
  | 'null';

export type subMenuDataType = {
  id: number;
  link: string;
  text: subMenuTextType;
  checkPermission: () => void;
  isPermitted: boolean;
  showLink?: boolean;
};

export type subMenuTextType =
  | 'user_list'
  | 'user_roles'
  | 'user_login_log'
  | 'ticket_board'
  | 'ticket_list'
  | 'manage_units'
  | 'manage_objects'
  | 'faq'
  | 'group'
  | 'documents'
  | 'dashboard'
  | 'document_upload'
  | 'document_owner'
  | 'document_units'
  | 'newsletter_overview'
  | 'drafts'
  | 'newsletter'
  | 'manage_objects';

export type ServiceListSchema = {
  id: number;
  serviceName: menuLabel;
  hideForLabel: string;
  tooltipLabel: toolTipLabel;
  icon: IconType | string;
  splashLabel: splashLabel;
  rootLink: string;
  routes: serviceRoute[];
  subMenusComp: subMenusComp;
};

export type splashLabel =
  | 'user_management'
  | 'ticket'
  | 'dms'
  | 'objects'
  | 'faq'
  | 'dashboard'
  | 'newsletter';

export type toolTipLabel =
  | 'user_management_system'
  | 'ticket_system'
  | 'document_management_system'
  | 'object_management_system'
  | 'frequently_ask_question'
  | 'dashboard'
  | 'newsletter';

export type Ownership = {
  id: number;
  owner: Owner;
} & (
  | {
      is_current: true;
      owner_since: string;
    }
  | {
      is_current: false;
    }
);

export type OwnershipDTO = {
  owner_id: number;
} & (
  | {
      is_current: true;
      owner_since: string;
    }
  | {
      is_current: false;
    }
);

export type Owner = {
  id: number;
  name: string;
  email: string;
  details: OwnerDetails;
  user_id: number;
  first_name: string;
  last_name: string;
  pivot: {
    apartment_id: number;
    owner_id: number;
    id: number;
    is_current: number;
  };
};

export type ApartmentFormSchema = {
  id: number;
  type: UnitTypes;
  rental_status: string;
  room: string;
  living_space: string;
  floor: string;
  apartment_quota: string;
  stwe_number: string;
  is_garage: boolean;
  garage_features: GarageFeatures[];
  current_owner_ids: string[];
  previous_owner_ids: string[];
};

export type Unit = {
  id: number;
  name: string;
  info: ApartmentInfo | GarageInfo;
};

export type User = {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  status: boolean;
  image_url: string;
  role: Role;
};

export type NewsletterStatus =
  | NewsletterStatusEnum.DRAFT
  | NewsletterStatusEnum.DISPATCHED;

export enum NewsletterStatusEnum {
  DRAFT = 'draft',
  DISPATCHED = 'dispatched',
}

export interface NewsletterDetail {
  id: number;
  subject: string;
  message: string;
  recipient_group: string[];
  status: NewsletterStatus;
  created_at: string;
  updated_at: string;
  newsletterFiles: ExtendedFileInfo[];
}

export type UnitInfo = {
  id: number;
  name: string;
  owners: Owner[];
  address: Address;
  ownerships: Ownership[];
  address_line: string;
  has_locking_plan: boolean;
  currentOwners: Owner[];
  pastOwners: Owner[];
  apartment_owner_ids: number[];
};

export type ApartmentInfo = UnitInfo & {
  apartment_number: string | null;
  type: UnitTypeEnum.APARTMENT;
  stwe_number: string;
  garage_features: GarageFeatures[];
  is_garage: boolean;
  rental_status: RentalStatus;
  room: string;
  living_space: string;
  floor: string;
  apartment_quota: string;
};

export type GarageInfo = UnitInfo & {
  type: UnitTypeEnum.GARAGE;
  garage_number: string;
  garage_type: string;
  garage_quota: number;
};

export type RestaurantInfo = UnitInfo & {
  type: UnitTypeEnum.RESTAURANT;
  apartment_quota: string;
  stwe_number: string;
  living_space: string;
  rental_status: RentalStatus;
};

export type AnyUnitInfo = ApartmentInfo | GarageInfo | RestaurantInfo;

export interface UnitInfoGarage {
  id: number;
  name: string;
  type: UnitTypes;
  address: Address;
  owners: Owner[];
  previous_owners: Owner[];
  ownerships: Ownership[];
  garage_type: string;
  garage_quota: number;
  garage_number: string;
}

export enum UnitTypeEnum {
  APARTMENT = 'apartment',
  RESTAURANT = 'restaurant',
  GARAGE = 'garage',
}

export type UnitTypes =
  | UnitTypeEnum.APARTMENT
  | UnitTypeEnum.RESTAURANT
  | UnitTypeEnum.GARAGE;

export interface Address {
  id: number;
  name: string;
  addition: string;
  country: Country;
  street: string;
  address_line: string;
  zip_code: string;
  house_number: string;
  town: string;
  caretaker: CareTakerSchema;
  construction_year: string;
  apartments: string;
}

export interface Country {
  id: number;
  name: string;
  country_code: string;
  default: boolean;
}

export interface AppartmentAddress {
  id: number;
  name: string;
  addition: string;
  info: AppartmentAddressInfo;
}

export interface AppartmentAddressInfo {
  id: number;
  name: string;
  town: string;
  street: string;
  addition: string;
  zip_code: string;
  country_id: number;
  created_at: string;
  deleted_at: string;
  updated_at: string;
  address_line: string;
  house_number: string;
}

export type TicketOwnerSchema = {
  id: number;
  user_id: number;
  first_name: string;
  last_name: string;
  language_code: string;
};
export type DocumentListFilterParams = {
  keyword: string;
  tag: string;
  year: string;
  pageSize: number;
  currentPage: number;
};

export interface GarageFeatures {
  id: null | number;
  qty: null | number;
  garage_type: string;
  garage_no: string;
  garage_quota: string;
}

export interface ApartmentOwner {
  apartment?: Apartment;
  apartment_id: number;
  id: number;
  is_current: 0 | 1;
  owner?: Owner;
  owner_id: number;
}

export interface ApartmentOwnerDocument {
  apartment_owner: ApartmentOwner;
  apartment_owner_id: number;
  created_at: string;
  document_id: number;
  id: number;
  updated_at: string;
}

export type DocumentType = 'owner' | 'ownership';

export interface Document {
  apartment_owner_documents: ApartmentOwnerDocument[];
  created_at: string;
  document_type: DocumentType;
  document_year: string;
  file_name: string;
  file_path: string;
  id: number;
  name: string;
  tag: TagItem;
}

export interface DocumentOwner {
  id: number;
  first_name: string;
  last_name: string;
  details: OwnerDetails;
}

export interface DocumentApartmentOwner {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  pivot: {
    apartment_id: number;
    owner_id: number;
    id: number;
    is_current: 0 | 1;
  };
  details: OwnerDetails;
}

export interface DocumentUnit {
  id: number;
  name: string;
  address: Address;
  street: string;
  house_number: string;
  currentOwners: DocumentApartmentOwner[];
  pastOwners: DocumentApartmentOwner[];
}

export interface DocumentApartment extends DocumentUnit {
  type: UnitTypeEnum.APARTMENT;
  apartment_number: string;
}

export interface DocumentGarage extends DocumentUnit {
  type: UnitTypeEnum.GARAGE;
  garage_number: string;
}

export type AnyDocumentUnit = DocumentApartment | DocumentGarage;

export interface Count {
  total: number;
}

export interface AuthState {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  user: UserSchema | null;
  permissions: UserPermission;
}

export interface AuthDispatch {
  setLoggingIn: (isLoggingIn: boolean) => AuthAction;
  setIsLoggedIn: (isLoggedIn: boolean) => AuthAction;
  setLoggedInUser: (user: UserSchema) => AuthAction;
  setPermissions: (permissions: UserPermission) => AuthAction;
}

export interface AuthMethods {
  login: (
    email: string,
    password: string,
    verifyCode?: string
  ) => Promise<'enable_2fa' | undefined>;
  logout: () => void;
}

export type AuthProps = AuthState & AuthDispatch & AuthMethods;

export type AllowedPermissions =
  (typeof PermissionRequest)[keyof typeof PermissionRequest][];

export type RoleId = (typeof rolesData)[number]['id'];

export interface IdParams {
  id: string;
}

export type FileInfo = {
  file_name: string;
  mime_type: string;
  file_size: number;
  file_path: string;
};

export type ExtendedFileInfo = FileInfo & {
  id: string;
  file_url: string;
};

export type MessageFileInfo = FileInfo & {
  file_id: string;
};

export type Floor = '-2' | '-1' | '0' | '1' | '2' | '3' | '4';

export type RentalStatus = 'vacation_rental' | 'permanent_rental' | 'own_use';

export type RoomCount =
  | 'studio'
  | '1'
  | '1.5'
  | '2'
  | '2.5'
  | '3'
  | '3.5'
  | '4'
  | '4.5'
  | '5'
  | '5.5'
  | '6+';
export interface ApartmentData {
  id: string;
  apartment_owner: {
    apartment_id: string;
    apartment: {
      info: GarageInfo | ApartmentInfo;
    };
    owner: Owner;
  };
}

export interface DocumentDetails {
  id: number;
  apartment_owner_documents: ApartmentData[];
  name: string;
  tag: string;
  document_year: string;
  file_path: string;
  file_name: string;
  file_url: string;
}
export interface Apartment {
  id: number;
  name: string;
  type: UnitTypeEnum;
  apartment_number: null | number;
  garage_number: null | number;
  stwe_number: string;
  info: AnyUnitInfo;
}

export type MutationResponse = {
  success: boolean;
  message: string;
};

export type MutationResponse2 = {
  success: boolean;
  data: string;
};

export type Generate2FACodeResponse = MutationResponse & {
  qr_code: string;
};

export type Enable2FADTO = {
  'verify-code': string;
};

export type Disable2FADTO = {
  'current-password': string;
};

export interface TicketCommentCreationResponse {
  id: number;
  comment: string;
  created_by: number;
  ticket_id: number;
  parent_id?: number;
  createdAt: string;
}

export interface LoginDTO {
  email: string;
  password: string;
  'verify-code'?: string;
  role_group: string;
}

export interface FileUploadState {
  totalMB: number;
  loadedMB: number;
  progressPercentage: number;
  cancelTokenSource: CancelTokenSource;
  fileName: string;
  error?: AxiosError;
}

export interface TenantInfoSettings {
  theme: string;
  tenant: string;
  sidebar: string;
  agbLink: string;
  dataProtectionLink: string;
  imprintLink: string;
  contactLink: string;
  logo: string;
  logoTransparent: string;
}
