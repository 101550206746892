import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { ExcelFile } from 'assets/icons';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import TicketAddMenu from 'components/ticket/TicketAddMenu';
import TicketListItem from 'components/ticket/TicketListItem';
import TicketSearch, {
  FilterParamsFromForm,
} from 'components/ticket/TicketSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { DataWrapper, TicketInfoSchema } from 'constants/schema';
import * as FileSaver from 'file-saver';
import useCustomToast from 'hooks/useCustomToast';
import { useDebounce } from 'hooks/useDebounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { changeURL, getStartingSerialNumber, isDateValid } from 'utils';
import { DateFormat, DateFormatYMD } from 'utils/DateFormat';
import * as XLSX from 'xlsx';

const FILETYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const FILEEXTENSION = '.xlsx';

type FilterParams = FilterParamsFromForm & {
  currentPage: number;
  pageSize: number;
};

type FilterParamsForURL = Omit<
  FilterParams,
  'created_at_start' | 'created_at_end'
> & {
  created_at_start: string | null;
  created_at_end: string | null;
};

const TicketList: React.FC = () => {
  const ticketApi = new TicketResource();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: Record<string, string> = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    priority: searchValues.priority ?? '',
    status: searchValues.status ?? '',
    assigned_user_id: searchValues.assigned_user_id ?? '',
    address_id: searchValues.address_id ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    house_owner: searchValues.house_owner ?? '',
    created_at_start: isDateValid(searchValues.created_at_start)
      ? new Date(searchValues.created_at_start)
      : null,
    created_at_end: isDateValid(searchValues.created_at_end)
      ? new Date(searchValues.created_at_end)
      : null,
    order_by: searchValues.order_by ?? 'createdAt',
    order:
      searchValues.order === 'asc' || searchValues.order === 'desc'
        ? searchValues.order
        : 'desc',
  });

  const debouncedKeyword = useDebounce(filterParams?.title ?? '');

  const { showErrorToast } = useCustomToast();

  const ticketList = useQuery<DataWrapper<TicketInfoSchema[]>>(
    [
      'ticketList',
      debouncedKeyword,
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        title: debouncedKeyword,
        priority: filterParams.priority,
        status: filterParams.status,
        assigned_user_id: filterParams.assigned_user_id,
        address_id: filterParams.address_id,
        apartment_id: filterParams.apartment_id,
        house_owner: filterParams.house_owner,
        created_at_start: filterParams.created_at_start,
        created_at_end: filterParams.created_at_end,
        order_by: filterParams.order_by,
        order: filterParams.order,
      },
    ],
    async () => {
      try {
        const response = await getTicketList(
          filterParams.currentPage,
          filterParams.pageSize
        );
        return response?.data;
      } catch (error) {
        throw new Error('Some error occured');
      }
    },
    {
      onError: () => showErrorToast(),
    }
  );

  const getTicketList = async (page: number, limit: number) => {
    const {
      title,
      priority,
      status,
      assigned_user_id,
      address_id,
      apartment_id,
      house_owner,
      created_at_start,
      created_at_end,
      order_by,
      order,
    } = filterParams;

    const queryParams: Record<string, string> = {
      page: String(page),
      limit: String(limit),
    };

    if (title) queryParams.title = title;
    if (priority) queryParams.priority = priority;
    if (status) queryParams.status = status;
    if (assigned_user_id) queryParams.assigned_user_id = assigned_user_id;
    if (address_id) queryParams.address_id = address_id;
    if (apartment_id) queryParams.apartment_id = apartment_id;
    if (house_owner) queryParams.house_owner = house_owner;
    if (created_at_start)
      queryParams.created_at_start = DateFormatYMD(created_at_start);
    if (created_at_end)
      queryParams.created_at_end = DateFormatYMD(created_at_end);
    if (order_by) queryParams.order_by = order_by;
    if (order) queryParams.order = order;

    return await ticketApi.list(queryParams);
  };

  const handleAdvancedSearch = useCallback((data: FilterParamsFromForm) => {
    setFilterParams((prevState: FilterParams) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      title: data.title,
      priority: data.priority,
      status: data.status,
      assigned_user_id: data.assigned_user_id,
      address_id: data.address_id,
      apartment_id: data.apartment_id,
      house_owner: data.house_owner,
      created_at_start: data.created_at_start,
      created_at_end: data.created_at_end,
      order_by: data.order_by,
      order: data.order,
    }));
  }, []);

  useEffect(() => {
    const filterParamsForURL: FilterParamsForURL = {
      ...filterParams,
      created_at_start: DateFormatYMD(filterParams.created_at_start),
      created_at_end: DateFormatYMD(filterParams.created_at_end),
    };
    const searchURL = changeURL(filterParamsForURL);
    history.push(`?${searchURL}`);
  }, [history, filterParams]);

  const handleExport = async () => {
    const ticketListTotal = await getTicketList(
      1,
      Number(ticketList?.data?.meta?.total)
    );
    let ticketArray = [];
    ticketArray = await ticketListTotal?.data?.data?.map((res, index) => {
      return {
        [strings.sn]: index + 1,
        [strings.ticket_title]: res.title,
        [strings.priority]: res.priority.toUpperCase(),
        [strings.status]: res.status.toUpperCase(),
        [strings.assignee]: res?.assigned_user?.name,
        [strings.object]: res?.apartment?.name,
        [strings.created_at]: res.createdAt,
      };
    });
    const ws = XLSX.utils.json_to_sheet(ticketArray);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: FILETYPE });
    FileSaver.saveAs(
      data,
      `Ticketliste-${DateFormat(new Date())}` + FILEEXTENSION
    );
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  return (
    <>
      <Helmet>
        <title>
          {strings.ticket} | {strings.ticket_list}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ticket.list.default}>
              {strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg">{strings.ticket_list}</Heading>

          <Stack direction="row">
            <TicketAddMenu path={routes.ticket.list.create} />

            <Tooltip label={strings.download_excel} hasArrow>
              <Button
                size="lg"
                colorScheme="green"
                variant="outline"
                onClick={handleExport}>
                <Image w="6" src={ExcelFile} alt="Excel" title="Excel" />
              </Button>
            </Tooltip>
          </Stack>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          defaultIndex={0}
          boxShadow="box">
          <AccordionItem>
            <>
              <h2>
                <AccordionButton p="4">
                  <Box flex="1" textAlign="center">
                    <Flex justify="space-between">
                      <Heading fontSize="18px" fontWeight="medium">
                        <Icon as={BiFilter} /> {strings.filter}
                      </Heading>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding="0">
                <TicketSearch
                  handleAdvancedSearch={handleAdvancedSearch}
                  filter={filterParams}
                />
              </AccordionPanel>
            </>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          {ticketList.isError && (
            <Alert status="error">
              <AlertIcon />
              {strings.ticket_list_error}
            </Alert>
          )}
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.title}</Th>
                  <Th>{strings.priority}</Th>
                  <Th>{strings.status}</Th>
                  <Th>{strings.assignee}</Th>
                  <Th>{strings.object}</Th>
                  <Th>{strings.created_at}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {ticketList?.data?.data?.map((ticket, index) => (
                  <TicketListItem
                    ticketData={ticket}
                    index={startingSN + index}
                    key={ticket.id}
                    search={search}
                  />
                ))}
                {ticketList.isLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={10} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={ticketList}
        />
      </Stack>
    </>
  );
};

export default TicketList;
