import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { AxiosError, AxiosResponse } from 'axios';
import { strings } from 'config/localization';
import {
  Disable2FADTO,
  Enable2FADTO,
  MutationResponse,
  UserSchema,
} from 'constants/schema';
import React, { useRef, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';

interface Props {
  userInfo?: UserSchema;
}

const TwoFactorAuthentication: React.FC<Props> = (props) => {
  const { userInfo } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDisableOpen,
    onOpen: onDisableOpen,
    onClose: onDisableClose,
  } = useDisclosure();
  const queryClient = useQueryClient();
  const userAPI = new UserResource();
  const toast = useToast();

  const [QR, setQR] = useState<string | undefined>();
  const [error, setError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  /**
   * Handle User status changes
   */
  const generateQR = useMutation(() => userAPI.generateQRCode(), {
    onSuccess: (res) => {
      setQR(res?.data?.qr_code);
      onOpen();
    },
    onError: () => {
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const enable2FA = useMutation<
    AxiosResponse<MutationResponse>,
    AxiosError,
    Enable2FADTO
  >((data) => userAPI.enable2FA(data), {
    onSuccess: (res) => {
      if (res?.data?.success) {
        onClose();
        toast({
          title: strings.FA_successfully,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('user-auth');
      } else {
        setError(strings.authenticator_code_error);
      }
    },
    onError: () => {
      setError(strings.invalid_data);
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const disable2FA = useMutation<
    AxiosResponse<MutationResponse>,
    AxiosError,
    Disable2FADTO
  >((data) => userAPI.disableOwn2FA(data), {
    onSuccess: (res) => {
      if (res?.data?.success) {
        onDisableClose();
        toast({
          title: strings.fa_Disable,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('user-auth');
      } else {
        setError(strings.incorrect_password);
      }
    },
    onError: () => {
      setError(strings.invalid_data);
      toast({
        title: strings.error,
        status: 'error',
        isClosable: true,
      });
    },
  });

  const handle2FAStatus = () => {
    if (!userInfo?.has_2fa_enabled) {
      generateQR.mutate();
    } else {
      onDisableOpen();
      setError(null);
    }
  };

  const onSubmit = () => {
    const authCode = inputRef.current?.value;
    if (!authCode) {
      setError(strings.required_authenticator_code);
      inputRef.current?.focus();
      return;
    }

    const data = {
      'verify-code': authCode,
    };
    enable2FA.mutate(data);
  };

  const onDisableSubmit = () => {
    const password = passwordRef.current?.value;
    if (!password) {
      setError(strings.incorrect_password);
      passwordRef.current?.focus();
      return;
    }

    const data = {
      'current-password': password,
    };
    disable2FA.mutate(data);
  };

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="0" display="flex">
          {userInfo?.has_2fa_enabled
            ? strings.disable_2FA
            : strings.activate_2FA}
          <Tooltip
            hasArrow
            label={strings.fa_status_tooltip}
            fontWeight="normal"
            bg="white"
            placement="left"
            color="gray.500"
            p={4}>
            <IconButton
              icon={<BiInfoCircle />}
              variant="link"
              aria-label={strings.fa_status_tooltip}
              color="primary.500"
              ml={1}
              minW="4"
            />
          </Tooltip>
        </FormLabel>
        <Switch
          colorScheme="green"
          onChange={handle2FAStatus}
          isChecked={userInfo?.has_2fa_enabled}
        />
      </FormControl>

      {/* enable 2FA model */}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="1rem" w="95%">
            {strings.fa_message}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack dir="column" justifyContent="space-between" spacing="4">
              <Center>
                <Stack
                  maxWidth="200px"
                  border="1px"
                  borderColor="gray.200"
                  borderRadius="md">
                  {QR && <Image width="100%" src={QR} alt="QR" />}
                </Stack>
              </Center>
              <Stack>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)']}
                  flex="1"
                  w="100%">
                  <GridItem>
                    <FormControl
                      display="flex"
                      justifyContent="space-between"
                      alignItems="baseline">
                      <FormLabel>{strings.code}</FormLabel>
                      <Input
                        type="number"
                        size="lg"
                        placeholder="xxxx"
                        ref={inputRef}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Stack>
              {error && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {error}
                </Alert>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button size="lg" colorScheme="primary" onClick={onSubmit}>
                {strings.activate_2FA}
              </Button>
              <Button
                size="lg"
                colorScheme="primary"
                variant="outline"
                onClick={onClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* disable 2FA model */}
      <Modal isOpen={isDisableOpen} onClose={onDisableClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="1rem" w="95%">
            {strings.fa_disable_title}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack dir="column" spacing="4">
              <Stack>
                <Grid
                  gap="4"
                  templateColumns={['repeat(1, 1fr)']}
                  flex="1"
                  w="100%">
                  <GridItem>
                    <FormControl
                      display="flex"
                      justifyContent="space-between"
                      alignItems="baseline">
                      <FormLabel>{strings.password}</FormLabel>
                      <Input
                        type="password"
                        size="lg"
                        placeholder={strings.password}
                        ref={passwordRef}
                      />
                    </FormControl>
                  </GridItem>
                </Grid>
              </Stack>
              {error && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {error}
                </Alert>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="primary" size="lg" onClick={onDisableSubmit}>
                {strings.deactivate_2FA}
              </Button>
              <Button
                variant="outline"
                size="lg"
                colorScheme="primary"
                onClick={onDisableClose}>
                {strings.cancel}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TwoFactorAuthentication;
