import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import axios, { AxiosError } from 'axios';
import FileUploadModal from 'components/common/FileUploadModal';
import OwnerDocumentForm, {
  OwnerDocumentFormValues,
} from 'components/owner_document/OwnerDocumentForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { useFileUploadMutation } from 'hooks/useFileUploadMutation';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const UploadOwnersDocument: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<OwnerDocumentFormValues>();
  const [errorMessage, setErrorMessage] = useState('');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const filesData = methods.getValues('upload_files');

  const onSuccess = () => {
    toast({
      title: strings.general_document_created,
      status: 'success',
      isClosable: true,
    });
    queryClient.invalidateQueries('ownerShipDocumentList');
    history.push(routes.dms.owner.list);
    onClose();
  };

  const onError = (error: AxiosError) => {
    onClose();
    if (error?.response?.data?.file) {
      setErrorMessage(error?.response?.data?.file[0]);
    } else {
      setErrorMessage(strings.general_document_creation_failed);
    }
  };

  const { fileUploadState, cancelMutation, ...fileUploadMutation } =
    useFileUploadMutation({
      url: 'dms/document/ownership',
      onSuccess,
      onError,
    });

  const onSubmit = (data: OwnerDocumentFormValues) => {
    const formData = new FormData();

    if (data.postData.apartmentIds.length === 0) {
      methods.setError('postData.apartmentIds', {
        message: strings.select_object_validation,
      });
      return;
    }

    if (data.postData.ownerShipIds.length === 0) {
      methods.setError('postData.ownerShipIds', {
        message: strings.select_owners_validation,
      });
      return;
    }

    if (data.upload_files === undefined) {
      methods.setError('upload_files', { message: strings.required_upload });
      return;
    }
    formData.append('file', data.upload_files);
    formData.append('name', data.name || data.upload_files.name);

    formData.append('tag', data.tag);
    formData.append('document_year', String(data.year));
    onOpen();

    data.postData.ownerShipIds.forEach((item, index) => {
      formData.append(`apartment_owner_ids[${index}]`, String(item));
    });
    fileUploadMutation.reset();
    fileUploadMutation.mutate(formData);
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.documents} | {strings.document_upload}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.documents.upload}>
              {strings.documents}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.dms.documents.upload}>
              {strings.document_upload}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg">{strings.upload_document}</Heading>
        </Flex>

        <Stack>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack
                direction="column"
                spacing="10"
                justifyContent="space-between">
                {fileUploadMutation.isError &&
                  !axios.isCancel(fileUploadMutation.error) && (
                    <Alert status="error">
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  )}
                <OwnerDocumentForm isLoading={fileUploadMutation.isLoading} />
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
      <FileUploadModal
        isOpen={isOpen}
        name={filesData?.name}
        onClose={onClose}
        fileUploadState={fileUploadState}
        onCancelClick={cancelMutation}
      />
    </>
  );
};

export default UploadOwnersDocument;
