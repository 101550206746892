import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { AxiosError, AxiosResponse } from 'axios';
import FormCancelButton from 'components/common/FormCancelButton';
import UserForm from 'components/user/UserForm';
import { strings } from 'config/localization';
import { rolesData } from 'constants/common';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

const AddNewUser: React.FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let role_id = searchParams.get('role_id');
  const toast = useToast();
  const queryClient = useQueryClient();
  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const history = useHistory();
  const methods = useForm<UserSchema>();
  const userAPI = new UserResource();

  const [errorMessage, setErrorMessage] = useState<UserSchema>();

  const addUser = useMutation<
    AxiosResponse<Partial<UserSchema>>,
    AxiosError,
    Partial<UserSchema>
  >((data) => userAPI.store(data), {
    onSuccess: (res) => {
      toast({
        title: strings.user_created,
        description: res.data.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('userList');
      history.push(routes.users.list);
    },
    onError: (error) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  /*
   *set error message if Email is already taken.
   */
  const { setError } = methods;

  useEffect(() => {
    if (errorMessage?.email) {
      setError(
        'email',
        {
          type: 'manual',
          message: errorMessage?.email?.toString(),
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [errorMessage, setError]);

  const onSubmit = (data: Partial<UserSchema>) => {
    data.language_code = 'de'; // explictily set to german
    addUser.mutate(data);
  };

  let currentRole = rolesData.find((i) => i.id === Number(role_id));

  if (!role_id || !currentRole) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.error}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {strings.add_new_user}
        </title>
      </Helmet>

      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>
              {strings.formatString(strings.add_new, strings[currentRole.name])}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.formatString(strings.add_new, strings[currentRole.name])}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {addUser.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.user_creation_failed}
                  </Alert>
                )}
                <UserForm role={currentRole} />
                <ButtonGroup justifyContent="flex-end">
                  <FormCancelButton isLoading={addUser.isLoading} size="lg" />

                  <Button
                    colorScheme="primary"
                    type="submit"
                    size="lg"
                    isLoading={addUser.isLoading}>
                    {strings.formatString(
                      strings.add_new,
                      strings[currentRole.name]
                    )}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddNewUser;
