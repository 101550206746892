import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FormCancelButton from 'components/common/FormCancelButton';
import MultiFileUploadModal from 'components/common/MultiFileUploadModal';
import TicketForm, { TicketFormValues } from 'components/ticket/TicketForm';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  FileInfo,
  IdParams,
  TicketInfoSchema,
  TicketPriority,
} from 'constants/schema';
import useMultiFileUploadMutation from 'hooks/useMultiFileUploadMutation';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import { getTicketTitle, isCreatedFromOwnerOrCareTaker } from 'utils';
import history from 'utils/history';
import { checkSomePermissions } from 'utils/listInfo';
import useLoggedInUser from '../../../hooks/useLoggedInUser';
import { RootState } from '../../../store';

interface TicketEditDTO {
  title?: string;
  apartment_id?: number;
  assigned_user_id?: number;
  house_owner?: number;
  priority?: TicketPriority | null;
  description?: string;
  files?: FileInfo[];
  updated_by: number;
}

function matchURL() {
  return matchPath(location.pathname, {
    path: routes.ticket.task.view,
    exact: true,
    strict: false,
  });
}
const isTicketBoardView = matchURL();

const EditTicket: React.FC = () => {
  const { id } = useParams<IdParams>();
  const toast = useToast();
  const methods = useForm<TicketFormValues>();
  const { search } = useLocation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const ticketApi = new TicketResource();

  const loggedInUser = useLoggedInUser();

  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const isAllowedToEditAssignee = checkSomePermissions(userPermissions, [
    PermissionRequest['assign:ticket'],
  ]);

  const ticketQuery = useQuery<TicketInfoSchema>(
    [`ticket${id}-edit`, id],
    () => ticketApi.get(id).then((res) => res.data.data),
    { cacheTime: 0, refetchOnWindowFocus: false }
  );

  const updateTicket = useMutation((data: TicketEditDTO) =>
    ticketApi.update(id, data)
  );

  const uploadFiles = useMutation(
    (formData: FormData) => ticketApi.uploadToBucket(formData),
    {
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const { fileUploadStates, cancelMutations, ...fileUploadMutation } =
    useMultiFileUploadMutation({
      url: '/ticket/tickets/attachment',
    });

  if (ticketQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ticketQuery.isError) {
    history.push(routes.ticket.list.default);
  }

  const onCloseAndGoBack = () => {
    onClose();
    if (fileUploadStates.some((e) => !e.error)) {
      history.goBack();
    }
  };

  const handleEditTicket = async (data: TicketFormValues) => {
    // Upload to Bucket First
    let files: FileInfo[] = [];
    let hasError = false;

    if (data.files?.length > 0) {
      onOpen();

      const result = (await fileUploadMutation.mutateAsync(data.files)) ?? [];

      files = result
        .filter((e) => e.status === 'fulfilled' && e.value.data.status)
        .map((e) => ({
          file_name: e.status === 'fulfilled' && e.value.data.file_name,
          mime_type: e.status === 'fulfilled' && e.value.data.mime_type,
          file_size: e.status === 'fulfilled' && e.value.data.file_size,
          file_path: e.status === 'fulfilled' && e.value.data.file_path,
        }));

      hasError = result.some((e) => e.status === 'rejected');
    }

    // Create Ticket
    let cleanData: TicketEditDTO = {
      ...removeEmptyEntries(data),
      apartment_id: data.apartment_id?.value
        ? data.apartment_id.value
        : undefined,
      assigned_user_id:
        data.assigned_user_id && isAllowedToEditAssignee
          ? data.assigned_user_id.value
          : undefined,
      house_owner: data.house_owner?.value ? data.house_owner.value : undefined,
      updated_by: loggedInUser.id,
      title:
        ticketQuery?.data && isCreatedFromOwnerOrCareTaker(ticketQuery?.data)
          ? ticketQuery?.data?.title
          : data.title,
      files: files, // Use the uploaded files here
    };

    await saveTicket(cleanData, hasError);
  };

  /**
   * Actual save ticket
   */
  const saveTicket = (formData: TicketEditDTO, hasError: boolean) => {
    updateTicket.mutate(formData, {
      onSuccess: () => {
        if (!hasError) {
          toast({
            title: strings.ticket_updated,
            status: 'success',
            isClosable: true,
          });
          history.goBack();
        }
      },
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    });
  };

  const showActionButton = () => {
    return (
      <Flex justifyContent="flex-end">
        <ButtonGroup>
          <FormCancelButton
            size="lg"
            isLoading={updateTicket.isLoading || uploadFiles.isLoading}
          />
          <Button
            size="lg"
            colorScheme="primary"
            isLoading={uploadFiles.isLoading || updateTicket.isLoading}
            disabled={uploadFiles.isLoading || updateTicket.isLoading}
            onClick={methods.handleSubmit(handleEditTicket)}
            type="submit">
            {strings.save_now}
          </Button>
        </ButtonGroup>
      </Flex>
    );
  };

  /**
   * Remove keys if no data
   */
  const removeEmptyEntries = (data: TicketFormValues) =>
    (Object.keys(data) as (keyof typeof data)[]).reduce((obj, key) => {
      if (data[key]) {
        obj = { ...obj, [key]: data[key] };
      } else if (key === 'description' || key === 'priority') {
        obj = { ...obj, [key]: null };
      }
      return obj;
    }, {} as Partial<TicketFormValues>);

  const ticketTitle = ticketQuery?.data && getTicketTitle(ticketQuery?.data);

  return (
    <>
      <Helmet>
        <title>
          {strings.ticket} | {strings.edit_ticket}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                (isTicketBoardView
                  ? routes.ticket.task.board
                  : routes.ticket.list.default) + search
              }>
              {isTicketBoardView ? strings.ticket : strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              #{id} - {ticketTitle}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.edit_ticket}
          </Heading>
        </Flex>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleEditTicket)}>
            <Box mb={4}>
              <Stack sx={wrapperStyles}>
                <TicketForm
                  isAllowedToEditAssignee={isAllowedToEditAssignee}
                  ticketData={ticketQuery?.data}
                />
              </Stack>
            </Box>
            {showActionButton()}
          </form>
        </FormProvider>
      </Stack>

      <MultiFileUploadModal
        isOpen={isOpen}
        onClose={onCloseAndGoBack}
        fileUploadStates={fileUploadStates}
        onCancelClick={cancelMutations}
      />
    </>
  );
};
export default EditTicket;
