const de = {
  /*login Credential*/
  login: 'Anmeldung',
  login_now: 'Jetzt Anmelden',
  log_in: 'Anmelden',
  password: 'Passwort',
  email: 'E-Mail',
  your_email_address: 'Ihre E-Mail-Adresse',
  your_password: 'Ihr Passwort',
  forgot_password: 'Passwort vergessen?',
  back_to_login: 'Zurück zum Login',
  password_reset: 'Passwort zurücksetzen',
  login_message: 'Geben Sie Ihre Zugangsdaten ein, um sich im {0} anzumelden.',
  forgot_message:
    'Bitte geben Sie Ihre E-Mail-Adresse ein, mit der Sie sich bei Ihrem Konto angemeldet haben.',
  immo_portal: 'Immobilienportal',
  password_info:
    'Das Passwort muss mindestens 1 Zahl, 1 Sonderzeichen, 1 Grossbuchstaben und 5 weitere Zeichen enthalten',
  change_language: 'Ändern der Sprache',
  invalid_token: 'Mit diesem Link wurde bereits ein neues Passwort festgelegt.',
  two_factor_authentication: 'Zwei-Faktor-Authentifizierung',
  enter_the_pin_from_google_authentication:
    'Geben Sie den Code aus der Google Authenticator App ein.',
  authenticate: 'Authentifizieren',
  one_time_password: 'Einmaliges Passwort',
  FA_status: '2FA-Status',
  disable_2FA: '2FA ausschalten',
  enable_2FA: '2FA einschalten',
  fa_message:
    'Um die Zwei-Faktor-Authentifizierung zu aktivieren, scannen Sie bitte diese QR-Code mit der Google Authenticator App.',
  code: 'Code',
  fa_status_tooltip: 'Geheimen Schlüssel zur Aktivierung von 2FA generieren',
  authenticator_code: 'Authenticator-Code',
  FA_successfully: '2FA ist erfolgreich aktiviert',
  FA_enable: '2FA ist zurzeit für Ihr Konto aktiviert',
  fa_Disable: '2FA ist jetzt deaktiviert',
  authenticator_code_error: 'Authenticator-Code-Fehler',
  required_authenticator_code: 'Erforderlicher Authenticator-Code',
  fa_disable_title:
    'Wenn Sie die Zwei-Faktor-Authentifizierung deaktivieren möchten, dann geben Sie bitte Ihr Passwort ein, um die Aktion zu bestätigen.',
  activate_2FA: 'Aktiviere 2FA',
  deactivate_2FA: '2FA deaktivieren',

  /*button*/
  save_now: 'Jetzt speichern',
  save: 'Speichern',
  back: 'Zurück',
  editing: 'Bearbeiten',
  filter: 'Filter',
  apply_filter: 'Suchen',
  reset_filter: 'Filter zurücksetzen',
  more_filter: 'Mehr Filter',
  cancel: 'Abbrechen',
  clear: 'Leeren',
  reply: 'Antworten',
  comment: 'Kommentar',
  save_and_create_new_ticket: 'Speichern und neues Ticket erstellen',
  profile: 'Profil',
  my_profile: 'Mein Profil',
  edit_profile: 'Profil bearbeiten',
  profile_updated: 'Profil aktualisiert',
  password_updated: 'Passwort aktualisiert',
  profle_update_failed: 'Profil-Aktualisierung fehlgeschlagen',
  not_match_new_confirm_password: 'Die Passwörter stimmen nicht überein',
  incorrect_password: 'Das Passwort ist nicht korrekt',
  setting: 'Einstellungen',
  settings: 'Einstellungen',
  loggedOut: 'Ausloggen',
  logout: 'Abmeldung',
  change_password: 'Passwort ändern',
  change_now: 'Jetzt ändern',
  Current_password: 'Aktuelles Passwort',
  new_password: 'Neues Passwort',
  confirm_password: 'Passwort bestätigen',
  invalid_login_msg: 'E-Mail und Passwort stimmen nicht überein',
  invalid_current_password: 'Das Passwort ist falsch',
  user_delete_error:
    'Der Nutzer kann nicht gelöscht werden, da er noch Artikel hält',
  euro: 'Euro',
  euro_symbol: '€',
  frank_symbol: '₣',
  email_not_found: 'E-Mail Adresse nicht gefunden.',
  email_required: 'E-Mail Adresse ist erforderlich',

  /*Module*/
  user_management: 'Benutzerverwaltung',
  ticket: 'Ticket',
  dashboard: 'Dashboard',

  /*Splash Screen*/
  choose_subsystem: 'Wählen Sie ein Subsystem',

  /*User Management*/
  user_management_system: 'Benutzerverwaltung',
  user: 'Nutzer',
  user_list: 'Nutzerliste',
  user_rights: 'Nutzerrechte',
  user_roles: 'Nutzer Rollen',
  user_name: 'Nutzername',
  user_profile: 'Nutzerprofil',
  user_details: 'Nutzer Details',
  role_details: 'Rollen Details',
  role: 'Rolle',
  add_additional_role: 'Zusätzliche Rolle hinzufügen',
  add_additional_rights: 'Zusätzliche Rechte hinzufügen',
  additional_rights: 'Zusätzliche Rechte',
  manage_extra_rights: 'Zusätzliche Rechte verwalten',
  right_from_role: 'Rechte der Rolle',
  add_role: 'Rolle hinzufügen',
  role_setup: 'Rollen-Setup',
  system: 'System',
  all_users: 'Alle Nutzer',
  all_user_with_that_role: 'Alle Nutzer mit dieser Rolle',
  add_new_role: 'Neue Rolle hinzufügen',
  add_new_user: 'Neuen Nutzer hinzufügen',
  role_name: 'Rollenname',
  privillage: 'Privileg',
  view_user: 'Nutzer ansehen',
  add_user: 'Nutzer hinzufügen',
  edit_user: 'Nutzer bearbeiten',
  save_user: 'Nutzer speichern',
  delete_user: 'Nutzer löschen',
  upload_image: 'Bild hochladen',
  drop_image_or_click_to_select_image:
    'Bild ablegen oder anklicken, um Bild auszuwählen',
  assigned_tickets: 'Zugewiesene Tickets',
  access_user: 'Auf Benutzerverwaltung zugreifen',
  manage_user: 'Nutzer verwalten',
  access_user_log: 'Auf Nutzer-Login-Protokolle zugreifen',
  manage_owner: 'Eigentümer verwalten',
  manage_admin: 'Admin verwalten',
  manage_caretaker: 'Hausmeister verwalten',
  access_oms: 'Auf Objektverwaltung zugreifen',
  manage_oms: 'Objekte verwalten',
  access_dms_ownership: 'Auf Dokumente zugreifen',
  manage_dms_ownership: 'Dokumente verwalten',
  access_dms_owner: 'Auf Nachrichten zugreifen',
  manage_dms_owner: 'Nachrichten verwalten',
  access_faq: 'Auf FAQ zugreifen',
  manage_faq: 'FAQ verwalten',
  access_faq_group: 'Auf FAQ-Gruppen zugreifen',
  manage_faq_group: 'FAQ-Gruppen verwalten',
  access_ticket: 'Auf Ticket-System zugreifen',
  assign_ticket: 'Ticket zuweisen',
  manage_own_owner_information: 'Eigene Daten ändern',
  access_own_owner_object: 'Auf eigene Objekte zugreifen',
  access_own_owner_document: 'Auf eigene Dokumente zugreifen',
  access_newsletter: 'Newsletter und Entwürfe Zugriff',
  manage_newsletter: 'Newsletter managen',
  action: 'Aktion',
  first_name: 'Vorname',
  last_name: 'Nachname',
  email_address: 'E-Mail-Adresse',
  valid_email_address: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
  contact_info: 'Kontaktinformationen',
  address: 'Adresse',
  view: 'Ansicht',
  add: 'Hinzufügen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  search: 'Suche',
  login_with_new_password: 'Bitte mit neuem Passwort anmelden',
  msg_valid_password: 'Bitte geben Sie ein gültiges Passwort ein',
  msg_password_reset_link:
    'Wir haben Ihnen den Link zum Zurücksetzen des Passworts per E-Mail zugesandt',
  select_role: 'Rolle auswählen',
  rights: 'Rechte',
  reset_password: 'Passwort zurücksetzen',
  reset_password_subtitle:
    'Bitte füllen Sie folgende Felder aus, um Ihr Passwort zurückzusetzen.',
  save_reset_password: 'Passwort zurücksetzen',
  create_password: 'Passwort festlegen',
  create_password_subtitle:
    'Bitte füllen Sie die Felder aus, um ein Passwort für Ihren Account zu erstellen',
  save_password: 'Passwort speichern',
  password_helper_text:
    'Mindestens 8 Zeichen, mindestens ein Grossbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen',
  resend_verification_email: 'Verifizierungs-E-Mail erneut senden',
  verification_email_sent: 'Verifizierungs-E-Mail gesendet',
  email_verification_success: 'E-Mail erfolgreich verifiziert',
  email_verification_failed: 'E-Mail-Verifizierung fehlgeschlagen.',
  resend_verify_email_error:
    'Fehler beim erneuten Senden der E-Mail-Bestätigung',
  resend_verify_email_success:
    'Die Verifizierungs-E-Mail wurde erfolgreich versandt. Prüfen Sie Ihren Posteingang.',
  resend_verify_email_success_to:
    'Die Verifizierungs-E-Mail wurde erfolgreich versandt an',
  email_already_verified: 'Die E-Mail ist bereits verifiziert.',
  login_to_continue: 'Bitte loggen Sie sich ein, um fortzufahren.',
  create_password_to_proceed: 'Erstellen Sie ein Passwort, um fortzufahren',
  change_confirm: 'Möchten Sie dies wirklich ändern?',
  cellphone: 'Mobiltelefon',
  invalid_cellphone: 'Ungültige Handynummer',
  email_placeholder: 'ihremail@beispiel.com',
  admin: 'Admin',
  verified: 'Bestätigt',
  not_yet_verified: 'Noch nicht bestätigt',
  user_login_log: 'Benutzer-Login-Protokolle',
  all_login_logs: 'Alle Anmeldeprotokolle',
  correspondence_language: 'Korrespondenzsprache',
  correspondence_language_placeholder: 'Korrespondenzsprache auswählen',
  correspondence_language_required: 'Korrespondenzsprache ist erforderlich',

  salutation: 'Anrede',
  select_salutation: 'Anrede auswählen',
  salutation_is_required: 'Anrede ist erforderlich',
  valid_only_number: 'Bitte geben Sie nur Zahlen ein.',
  city_is_required: 'Ort ist erforderlich',
  notes_cannot_be_more_than_2000_characters_long:
    'Die Notizen dürfen nicht mehr als 2000 Zeichen lang sein',
  max_characters_exceeded: 'Maximale Buchstabenanzahl überschritten',
  add_new_owner: 'Neuen Eigentümer hinzufügen',
  add_new: 'Neuen {0} hinzufügen',
  employee: 'Mitarbeiter',
  caretaker: 'Hausmeister',
  edit_owner: 'Eigentümer bearbeiten',
  send_verification_email: 'Einladungsmail versenden',
  email_verification_sent: 'Bestätigungsemail versendet',

  adittion: 'Zusatz',
  deceased: 'Verstorben',
  deceased_since: 'Verstorben seit',
  address_forwarding: 'Adressweiterleitung',
  data_sharing: 'Datenweitergabe',
  data_sharing_activated: 'Datenweitergabe aktiviert',
  owner_created: 'Eigentümer wurde erstellt',
  employee_with_ticket_support: 'Mitarbeiter, Ticket-Zuweisung',

  notes_notizen: 'Notizen',
  deceased_since_is_required: 'Verstorben seit ist erforderlich',
  owner_updated: 'Eigentümer wurde aktualisiert',
  owner_details: 'Eigentümerdetails',

  total: 'Anzahl',
  select_owners_validation: 'Bitte Eigentümer auswählen',
  select_object_validation: 'Bitte Objekte auswählen',
  document_upload: 'Dokumenten-Upload',

  address_forwarding_reason_guardianship: 'Vormundschaft',
  address_forwarding_reason_temporary_change_of_address:
    'Temporäre Adressänderung',
  address_forwarding_reason_different_billing_address:
    'Abweichende Rechnungsadresse',
  address_forwarding_reason_address_of_the_property: 'Adresse der Liegenschaft',

  reason: 'Grund',
  select_reason: 'Grund auswählen',
  reason_is_required: 'Grund ist erforderlich',

  customer_number: 'Kundennummer',
  customer_number_is_required: 'Kundennummer ist erforderlich',
  owner_since: 'Eigentümer seit',
  required_owner_since: '"Eigentümer seit" erforderlich',

  discard_changes: 'Änderungen verwerfen',
  discard_changes_warning: 'Möchten Sie Ihre Änderungen wirklich verwerfen?',
  discard: 'Verwerfen',
  no_thank_you: 'Nein, danke',
  caretaker_created: 'Hausmeister wurde erstellt',
  add_caretaker: 'Neuen Hausmeister hinzufügen',
  caretaker_details: 'Hausmeister Details',
  edit_caretaker: 'Hausmeister bearbeiten',

  sn: '#',
  name: 'Name',
  assignee: 'Sachbearbeiter',
  description: 'Beschreibung',
  apartment: 'Wohnung',
  apartments: 'Wohnungen',
  unit: 'Einheit',
  yes: 'Ja',
  no: 'Nein',
  csv_datasharing: 'CSV Datenweitergabe',

  /*Ticket Board*/
  ticket_system: 'Ticket-System',
  all_tickets: 'Alle Tickets',
  ticket_board: 'Ticket-Board',
  add_task: 'Aufgabe hinzufügen',
  open: 'Offen',
  inprogress: 'In Bearbeitung',
  'in-progress': 'In Bearbeitung',
  done: 'Erledigt',
  closed: 'Geschlossen',
  document: 'Dokumente',
  normal_task: 'Normale Aufgabe ',
  create_ticket: 'Ticket erstellen',
  ticket_details: 'Ticket-Details',
  title: 'Titel',
  ticket_title: 'Ticketname',
  object: 'Objekt',
  all_objects: 'Alle Objekte',
  customer: 'Kunde',
  repeat_task: 'Ticket wiederholen',
  house_owner: 'Hausbesitzer',
  priority_label: 'Priorität',
  priority: 'Priorität',
  upload_files: 'Dokument hochladen',
  descriptions: 'Beschreibung',
  save_create_new_ticket: 'Speichern und neues Ticket erstellen',
  author: 'Autor',
  created_by: 'Erstellt von',
  created_at: 'Erstellt am',
  last_modified_by: 'Zuletzt geändert von',
  last_modified_at: 'Zuletzt geändert am',
  last_changed_by: 'Letzte Änderung von ',
  last_changed_at: 'Letzte Änderung am ',
  status: 'Status',
  ticket_log: 'Log',
  ticket_list: 'Ticket-Liste',
  ticket_created: 'Ticket wurde erstellt',
  has_been_created: 'wurde erstellt',
  has_not_been_created: 'wurde nicht erstellt',
  has_been_merged: 'zusammenführen',
  has_not_been_merged: 'wurde nicht zusammengeführt',
  has_been_updated: 'wurde aktualisiert',
  has_not_been_updated: 'wurde nicht aktualisiert',
  has_been_deleted: 'has been deleted',
  has_not_been_deleted: 'has not been deleted',
  ticket_error: 'Ticket konnte nicht erstellt werden',
  active: 'Aktiv',
  in_active: 'Inaktiv',
  select_apartment: 'Wohnung auswählen',
  select_unit: 'Einheit auswählen',
  select_assignee: 'Sachbearbeiter auswählen',
  select_owner: 'Eigentümer auswählen',
  select_priority: 'Priorität auswählen',
  select_source: 'Quelle auswählen',
  write_description: 'Beschreibung',
  expand: 'Ausklappen',
  ticket_list_error:
    'Es ist ein Fehler bei der Bearbeitung Ihrer Anfrage aufgetreten',
  title_required: 'Titel ist erforderlich',
  house_owner_is_required: 'Hausbesitzer ist erforderlich',
  repeating_info: 'Wiederholte Infos',
  interval: 'Intervall',
  repeat_from: 'Wiederholen von',
  repeat_until: 'Wiederholen bis',
  comment_here: 'Hier kommentieren',
  comment_required: 'Kommentar ist erforderlich',
  id_not_found: 'ID nicht gefunden',
  edit_ticket: 'Ticket bearbeiten',
  ticket_updated: 'Ticket erfolgreich aktualisiert',
  change_status: 'änderte den Ticket-Status',
  status_updated: 'Status erfolgreich aktualisiert',
  select_status: 'Status auswählen',
  comment_error: 'Kommentar-Fehler',
  view_more: 'Mehr anzeigen',
  view_less: 'Weniger anzeigen',
  spent_time: 'Aufgewendete Zeit',
  adjust_time_spent: 'Aufgewendete Zeit anpassen',
  correct_spent_time: 'Verbrachte Zeit korrigieren',
  spent_time_message: 'Die verbrachte Zeit wurde manuell korrigiert',
  task: 'Ticket',
  low: 'Niedrig',
  medium: 'Mittel',
  high: 'Hoch',
  urgent: 'Dringend',
  select_type: 'Typ auswählen',
  select_type_art: 'Art auswählen',
  ticket_detail: 'Ticket-Detail',
  edit_task: 'Ticket bearbeiten',
  created_ticket: 'erstellte das Ticket',
  updated_ticket: 'änderte das Ticket',
  changed_status: 'änderte den Ticket-Status',
  assigned_ticket: 'zugeordnetes Ticket',
  commented: 'kommentierte',
  order_by: 'Sortieren nach',
  createdat: 'Erstellt am',
  order: 'Auf/Absteigend',
  asc: 'Aufsteigend',
  desc: 'Absteigend',
  showing_all_options: 'Alle Optionen anzeigen',
  appointment: 'Termin',
  appointment_date_time: 'Datum und Uhrzeit des Termins',
  enter_appointment_date_time: 'Datum und Uhrzeit des Termins eingeben',
  full_screen: 'Vollbild',
  add_new_ticket: 'Neues Ticket hinzufügen',

  source: 'Quelle',
  destination: 'Ziel',
  street: 'Strasse',
  house_no: 'Hausnummer',
  zip_code: 'Postleitzahl',
  town: 'Ort',
  country: 'Land',
  phone: 'Telefonnummer',
  zip_code_abbreviation: 'PLZ',

  add_now: 'Hinzufügen',
  date_time: 'Datum / Uhrzeit',
  city: 'Stadt',
  file: 'Datei',
  images: 'Bilder',
  upload_file: 'Datei hochladen',
  question: 'Frage',
  from: 'Von',
  to: 'Zu',
  article: 'Artikel',
  type: 'Typ',
  objects: 'Objekte',
  object_id: 'Objekt-ID',
  tickets: 'Tickets',
  ticket_source: 'Ticketquelle',

  required: 'Erforderlich',
  user_created: 'Benutzer wurde erstellt',
  user_creation_failed: 'Benutzererstellung fehlgeschlagen',
  user_udpate_failed: 'Benutzeraktualisierung fehlgeschlagen',
  password_update_failed: 'Passwort-Aktualisierung fehlgeschlagen',
  user_not_found: 'Benutzer nicht gefunden',
  update: 'Aktualisieren',
  user_updated: 'Benutzer erfolgreich aktualisiert',
  user_deleted: 'Der Benutzer wurde gelöscht',
  roles_not_found: 'Rollen nicht gefunden',
  confirm_delete: 'Möchten Sie das wirklich löschen?',

  select: 'Auswahl',
  collapse_sidebar: 'Seitenleiste einklappen',
  expand_sidebar: 'Seitenleiste ausklappen',
  max_upload_size_5: 'Maximale Dateigrösse: 5MB',
  max_upload_size_10: 'Maximale Dateigrösse: 10MB',
  max_upload_size_15: 'Maximale Dateigrösse: 15MB',
  max_upload_size_100: 'Maximale Dateigrösse: 100MB',
  max_upload_size_1GB: 'Maximale Dateigrösse: 1GB',
  file_upload_failed: 'Datei-Upload fehlgeschlagen',

  drag_drop_files_msg:
    'Ziehen Sie Dateien per Drag & Drop in das Feld oder klicken Sie das “+”, um Dateien auszuwählen',
  files: 'Dateien',
  delete_file: 'Datei löschen',
  only_1_file_is_allowed: 'Nur 1 Datei ist erlaubt.',
  uploading: 'Hochladen',
  invalid_profile_upload: 'Ungültiger Profilbild-Upload',
  file_must_be_an_image_file: 'Die Datei muss ein Bildtyp sein',
  clear_profile_pic: 'Profilbild löschen',
  are_you_sure:
    'Sind Sie sicher? Diese Aktion kann nicht rückgängig gemacht werden',
  upload_label: 'Profilbild hochladen',
  remove_label: 'Profilbild entfernen',
  invalid_file_size: 'Ungültige Dateigrösse',
  file_size_info: 'Die Dateigrösse muss weniger als 5 MB betragen',
  uploading_profile: 'Profilbild hochladen',
  no_permission_msg:
    'Sie haben keine Zugangsberechtigung. Bitte kontaktieren Sie einen Administrator',
  fresh: 'Neu',
  consumed: 'Verbraucht',
  confirm: 'Bestätigen',
  house_number: 'Hausnummer',
  house: 'Haus',
  flat: 'Wohnung',
  general_information: 'Allgemeine Auskunft',
  change_ownership: 'Eigentümerwechsel',
  oil_level_report: 'Ölstandsmeldung',
  malfunction_defect_deficiencies: 'Störung, Defekt, Mängel',
  // common
  lang: 'de',
  english: 'Englisch',
  german: 'Deutsch',
  polish: 'Polnisch',
  french: 'Französisch',
  italian: 'Italienisch',
  result: 'Ergebnis',
  no_results_found: 'Keine Ergebnisse gefunden',
  no_records_found: 'Schlüssel nicht gefunden',
  no_options: 'Keine Optionen',
  error: 'Fehler',
  showing: 'Zeige',
  manually: 'Manuell',
  general: 'Allgemein',
  gotoDetails: 'Detail-Seite',
  out_of: 'von',
  loading: 'Lade',
  show_rows: 'Anzahl Reihen',
  error_boundary_heading_text: 'Entschuldiung, etwas ist schiefgelaufen',
  error_boundary_paragraph_text:
    'Unser Team wurde benachrichtigt und wir werden den Fehler so schnell wie möglich beheben',
  reload_page: 'Seite neu laden',
  take_me_home: 'Zur Startseite',
  manual: 'Manuell',
  ['v-office']: 'V-Office',
  guest: 'Gast',
  owner: 'Eigentümer',
  lead: 'Lead',
  supplier: 'Lieferant',
  test: 'Prüfen',
  other: 'Andere',
  daily: 'Täglich',
  weekly: 'Wöchentlich',
  bi_weekly: 'Zweiwöchentlich',
  monthly: 'Monatlich',
  semi_anually: 'Halbjährlich',
  anually: 'Jährlich',
  previous: 'Zurück',
  next: 'Weiter',
  date_validation_msg: 'Bitte geben Sie ein gültiges Datum an',
  freeze_inventory: 'Inventar einfrieren',
  create_inventory_list: 'Bestandsliste erstellen',
  correct_inventory: 'Korrektes Inventar.',
  create_delta_list: 'Delta-Liste erstellen',
  unfreeze_inventory_end: 'Inventar- und Endbestandsvorrat',
  download_pdf: 'PDF Herunterladen',
  download_excel: 'Excel Herunterladen',
  download__delta_list_pdf: 'DREDA LIST-PDF herunterladen',
  unfreeze_inventory: 'Inventar',
  no_transferring: '(Keine Übertragung von Gegenständen möglich.)',
  download_as: '(Als PDF herunterladen und speichern, dann ausdrucken.)',
  transfer_items:
    '(Übertragen Sie die Elemente von oder auf eine Bestandskorrektur.)',
  what_change: '(Welche Änderungen wurden während der Bestellungen gemacht.)',
  step_1: 'Schritt 1',
  step_2: 'Schritt 2',
  step_3: 'Schritt 3',
  step_4: 'Schritt 4',
  step_5: 'Schritt 5',
  actual_quantity: 'Tatsächliche Menge.',
  delta: 'delta',
  no_data_found: 'Keine Daten gefunden',
  ms: 'Frau',
  Ms: 'Frau',
  diverse: 'Divers',
  Diverse: 'Divers',
  language: 'Sprache',
  phone_number: 'Telefonnummer',
  sleeping_facilities: 'Schlafmöglichkeiten',
  number: 'Nummer',
  until: 'Bis',
  all_logs: 'Alle Logs',

  drag_drop_csv_files_msg: 'Laden Sie hier .csv Dateien hoch (Maximal 10 MB)',
  drag_drop_csv_15MB_files_msg:
    'Laden Sie hier .csv Dateien hoch (Maximal 15 MB)',
  processing_data: 'Daten verarbeiten',
  failed: 'Gescheitert',
  file_uploaded_successfully: 'Datei erfolgreich hochgeladen',
  uploaded_on: 'Zuletzt hochgeladen am',
  by: 'von',
  transferred: 'übertrug',
  internal_server_error: 'Interner Serverfehler',
  cannot_upload_csv_in_worng_category:
    'csv Datei kann nicht in falsche Kategorie hochgeladen werden',
  upload_date: 'Datum des Hochladens',
  location: 'Lage',

  /* OMS */
  object_management_system: 'Objekte verwalten',
  units: 'Einheiten',
  oms: 'OMS',
  object_list: 'Objektliste',
  unit_type: 'Einheit-Art',
  manage_units: 'Einheiten verwalten',
  manage_objects: 'Objekte verwalten',
  apartment_quota: 'Wohnung-Quoten',
  quota: 'Quote',
  stwe_number: 'STWE-Nummer',
  floor: 'Stockwerk',
  floor_number: 'Geschoss-Nr.',
  rooms: 'Zimmer',
  room_number: 'Anzahl Räume',
  number_of_rooms: 'Anzahl Räume',
  living_space: 'Wohnfläche (m²)',
  garage_number: 'Garagen-Nr.',
  garage_quota: 'Garagen-Quote',
  garage_type_is_required: 'Garagen-Art ist erforderlich',
  garage_quota_is_required: 'Garagen-Quote ist erforderlich',
  garage_number_is_required: 'Garagennummer ist erforderlich',
  add_another_garage: 'weitere Garage hinzufügen',
  no_abbreviation: 'Nr.',
  season_name: 'Name der Saison',
  construction_year: 'Baujahr',
  from_date: 'Von',
  to_date: 'Bis',
  status_changed: 'Erfolgreich bearbeitet',
  add_new_object: 'Neues Objekt hinzufügen',
  add_object: 'Objekt hinzufügen',
  add_new_unit: 'Neue Einheit hinzufügen',
  add_new_apartment: 'Neue Wohnung hinzufügen',
  add_apartment: 'Wohnung hinzufügen',
  edit_object: 'Objekt bearbeiten',
  features: 'Merkmale',
  picture: 'Bild',
  text: 'Texte',
  cannot_get_apartment_details: 'Kann die Objekt-Details nicht abrufen',
  must_be_greater_than_zero: 'Muss grösser als 0 sein',
  basic_information: 'Grundlegende Informationen',
  copy: 'Kopieren',
  save_object: 'Objekt speichern',
  save_apartment: 'Wohnung hinzufügen',
  add_owner: 'Eigentümer hinzufügen',
  enter_owner: 'Eigentümer eingeben',
  no_owner: 'Sie haben noch keinen Eigentümer mit diesem Objekt verknüpft.',
  owner_is_required:
    'Sie müssen mindestens einen Eigentümer mit der Einheit verknüpfen!',
  ical_link: 'iCal-Link',
  copied: 'Kopiert',
  copy_to_clipboard_ctrl_c_enter:
    'In die Zwischenablage kopieren: Strg+C, Enter',
  edit_ownership: 'Eigentumsverhältnis bearbeiten',
  delete_ownership: 'Eigentumsverhältnis löschen',
  select_ownership: 'Eigentumsverhältnis auswählen',
  actions: 'Aktionen',
  price: 'Preis',
  garage: 'Garage',
  garage_type: 'Garagen-Art',
  quantity: 'Anzahl',
  store: 'Geschäft',
  restaurant: 'Restaurant',
  select_object_type: 'Objekttyp auswählen',
  enter_apartment_number: 'STWE-Nr./Wohnungs-Nr. eingeben',
  enter_stwe_number: 'STWE-Nummer eingeben',
  select_floor: 'Geschoss auswählen',
  enter_living_space: 'Wohnfläche eingeben',
  select_rooms: 'Zimmer auswählen',
  select_garage_type: 'Garagen-Art auswählen',
  enter_garage_number: 'Garagen-Nr. eingeben',
  enter_street: 'Strasse eingeben',
  enter_quantity: 'Anzahl eingeben',
  enter_zip_code: 'PLZ eingeben',
  select_country: 'Land auswählen',
  select_unit_type: 'Einheit-Art auswählen',
  enter_apartment_quota: 'Wohnung-Quoten eingeben',
  enter_quota: 'Quote eingeben',
  enter_garage_quota: 'Garagen-Quoten eingeben',
  enter_house_number: 'Hausnummer eingeben',
  enter_town: 'Ort eingeben',
  enter_object_name: 'Objektname eingeben',
  enter_object_addition: 'Objektzusatz eingeben',
  enter_construction_year: 'Baujahr eingeben',
  enter_address_adittion: 'Adresszusatz eingeben',
  confirm_remove_garage:
    'Sind Sie sicher, dass dadurch alle gespeicherten Garagen aus dieser Wohnung entfernt werden.',
  object_type_is_required: 'Objekttyp ist erforderlich',
  object_name_is_required: 'Objektname ist erforderlich',
  object_number_is_required: 'Objektzusatz ist erforderlich',
  object_name: 'Objektname',
  object_addition: 'Objektzusatz',
  object_number: 'Objektnummer',
  unit_number: 'Einheitennummer',
  permanent_rental: 'Dauervermietung',
  vacation_rental: 'Ferienvermietung',
  single_garage: 'Einzelgarage',
  unknown: 'unbekannt',
  indoor_parking_space: 'Innenstellplatz',
  outdoor_parking_lot: 'Außenparkplatz',
  own_use: 'Eigennutzung',
  studio: 'Studio',
  '1': '1',
  '1.5': '1,5',
  '2': '2',
  '2.5': '2,5',
  '3': '3',
  '3.5': '3,5',
  '4': '4',
  '4.5': '4,5',
  '5': '5',
  '5.5': '5,5',
  '6+': '6+',
  'floor_-2': '2. UG',
  'floor_-1': '1. UG',
  floor_0: 'EG',
  floor_1: '1. OG',
  floor_2: '2. OG',
  floor_3: '3. OG',
  floor_4: '4. OG',
  floor_5: '5. OG',
  floor_6: '6. OG',
  change_caretaker: 'Hausmeister ändern',
  change_caretaker_info: 'Möchten Sie den Hausmeister wirklich ändern?',
  change: 'Ändern',
  remove_caretaker: 'Hausmeister entfernen',
  remove_caretaker_info: 'Möchten Sie den Hausmeister wirklich entfernen?',
  remove: 'Entfernen',
  select_caretaker: 'Hausmeister wählen',
  caretaker_is_required: 'Hausmeister ist erforderlich',
  enter_first_name: 'Vorname eingeben',
  enter_last_name: 'Nachname eingeben',
  enter_email_address: 'E-Mail Adresse eingeben',
  enter_phone_number: 'Telefon eingeben',
  enter_title: 'Titel eingeben',
  add_notes: 'Notiz hinzufügen',
  edit_unit: 'Einheit verwalten',
  add_new_garage: 'Neue Garage hinzufügen',
  add_garage: 'Garage hinzufügen',

  /**DMS Service */
  document_management_system: 'Dokumenten-Management-System',
  dms: 'DMS',
  upload_document: 'Dokument hochladen',
  edit_document: 'Dokument bearbeiten',
  all_documents: 'Alle Dokumente',
  document_name: 'Dokumentenname',
  owner_name: 'Eigentümer',
  availability: 'Verfügbarkeit',
  no_of_documents: 'Anzahl der Dokumente',
  select_object: 'Objekt auswählen',
  documents: 'Dokumente',
  general_document_deleted: 'Dokument wurde gelöscht',
  delete_general_document: 'Dokument löschen',
  general_document_not_found: 'Dokument nicht gefunden',
  general_document_udpate_failed: 'Bearbeiten des Dokuments fehlgeschlagen',
  general_document_created: 'Dokument wurde erstellt',
  general_document_creation_failed: 'Dokument konnte nicht erstellt werden',
  general_document_updated: 'Dokument erfolgreich aktualisiert',
  owner_documents: 'Eigentümerdokumente',
  import_file: 'Datei importieren',
  the_file_field_is_required: 'Das Dateifeld ist erforderlich',
  apartment_name: 'Name der Wohnung',

  first_name_is_required: 'Vorname ist erforderlich',
  last_name_is_required: 'Nachname ist erforderlich',
  status_is_required: 'Status ist erforderlich',
  living_space_is_required: 'Wohnfläche ist erforderlich',
  stwe_number_is_required: 'STWE-Nummer ist erforderlich',
  apartment_quota_is_required: 'Objekt-Quote ist erforderlich',
  street_name_is_required: 'Strasse ist erforderlich',
  country_is_required: 'Land ist erforderlich',
  invalid_phone_number: 'Ungültige Rufnummer',
  appartment_is_required: 'Wohnung ist erforderlich',
  house_number_is_required: 'Hausnummer ist erforderlich',
  town_is_required: 'Ort ist erforderlich',
  zip_code_is_required: 'Postleitzahl ist erforderlich',
  phone_number_is_required: 'Telefonnummer ist erforderlich',

  mr: 'Herr',
  Mr: 'Herr',
  mrs: 'Frau',
  misc: 'Divers',

  insurance_policy: 'Versicherungspolice',
  stwe_foundation_contract: 'STWE-Begründungsvertrag',
  stwe_regulations: 'STWE-Reglement',
  house_rules: 'Hausordnung',
  annual_invoice: 'Jahresrechnung',
  annual_report: 'Jahresbericht',
  minutes: 'Protokoll',
  investment_plan: 'Investitionsplan',
  budget: 'Budget',
  correspondence: 'Korrespondenz',
  invitation: 'Einladung',
  audit_report: 'Revisionsbericht',
  power_of_attorney: 'Vollmacht',
  plans: 'Pläne',
  service_charge_invoice: 'Nebekostenrechnung',
  offer: 'Offerte',
  report: 'Bericht',
  locking_plan: 'Schliessplan',
  await_contact_details: 'Abwart Kontaktdaten',
  restructuring_report: 'Sanierungsbericht',
  additional_contribution_to_reserve_fund_invoice:
    'Zusatzeinlage Reservefonds Rechnung',
  additional_contribution_reserve_fund_list:
    'Zusatzeinlage Reservefonds Liste',

  assignment: 'Zuweisung',
  assignment_placeholder: 'Zuweisung auswählen',
  assignment_required: 'Zuweisung ist erforderlich',

  year: 'Jahr',
  year_placeholder: 'Jahr auswählen',
  year_required: 'Jahr ist erforderlich',

  tag: 'Bezeichnung',
  tag_placeholder: 'Bezeichnung auswählen',
  tag_required: 'Bezeichnung ist erforderlich',

  document_upload_info: `Bitte laden Sie zunächst ein Dokument hoch und vergeben Sie dafür
  die entsprechende Zuweisung, die passende Bezeichnung und das
  jeweilige Jahr`,
  document_upload_info2: `Bitte wählen Sie zunächst eine Einheit aus um das Dokument dann 
  hier einem Eigentümer zuweisen zu können.`,

  current_owner: 'Aktueller Eigentümer',
  apartment_number: 'Wohnungs-Nr.',
  previous_owner: 'Vorbesitzer',

  document_owner: 'Dokumente - Eigentümer',
  document_units: 'Dokumente - Einheiten',
  reset: 'Zurücksetzen',
  reset_selection: 'Auswahl zurücksetzen',
  reset_warning:
    'Möchten Sie die bisher getätigte Auswahl wirklich zurücksetzen?',
  document_properties: 'Dokumenteneigenschaften',
  remove_assignment: 'Zuweisung entfernen',
  remove_assignment_warning:
    'Möchten Sie bereits zugewiesenen Einheiten wirklich entfernen?',
  document_uploading: 'Dokument wird hochgeladen',
  assigned_units: 'Zugewiesene Einheiten',
  other_units: 'Weitere Einheiten',
  delete_document_info:
    'Möchten Sie das Dokument {0} wirklich unwiderruflich löschen?',
  delete_document_notes:
    'Bitten beachten Sie, dass das ausgewählte Dokument noch mit folgenden Einheiten und Eigentümern verknüpft ist:',
  delete_document_warning: `Achtung: Mit dem klick auf “Löschen” wird das ausgewählte Dokument auch bei allen verknüpften Einheiten und Eigentümern unwiderruflich gelöscht.`,
  document_deleted_successfully: 'Das Dokument wurde gelöscht',

  /**FAQ Service */
  frequently_ask_question: 'Häufig gestellte Fragen',
  faq: 'FAQs',
  all_faq: 'Alle FAQs',
  success: 'Erfolg',
  group: 'Gruppe',
  all_groups: 'Alle Gruppen',
  add_faq: 'FAQ Hinzufügen',
  edit_faq: 'FAQ Bearbeiten',
  delete_faq: 'FAQ Löschen',
  select_group: 'Gruppe auswählen',
  faq_deleted_successfully: 'FAQ wurde erfolgreich gelöscht',

  faq_group: 'FAQ-Gruppe',
  add_group: 'Gruppe hinzufügen',
  edit_group: 'Gruppe bearbeiten',
  delete_group: 'Gruppe löschen',
  invalid_data: 'Die angegebenen Daten sind ungültig',
  download: 'Herunterladen',
  download_image: 'Bild herunterladen',
  select_date: 'Datum auswählen',
  select_date_time: 'Datum/Uhrzeit auswählen',

  /*Internet Status*/
  wifi: 'Ihre Internetverbindung wurde wiederhergestellt.',
  nowifi: 'Sie sind derzeit offline.',
  refresh: 'Aktualisieren',

  /*required*/
  required_first_name: 'Vorname ist erforderlich',
  required_last_name: 'Nachname ist erforderlich',
  required_email: 'E-Mail ist erforderlich',
  required_cellphone: 'Mobiltelefon ist erforderlich',
  required_role: 'Rolle ist erforderlich',
  required_address: 'Adresse ist erforderlich',
  required_type: 'Typ ist erforderlich',
  required_document_name: 'Dokumentname ist erforderlich',
  required_upload: 'Hochladen einer Datei ist erforderlich',
  required_group_name: 'Gruppenname ist erforderlich',
  required_status: 'Status ist erforderlich',
  required_owner: 'Eigentümer ist erforderlich.',
  required_ownership: 'Eigentumsverhältnis ist erforderlich.',
  only_one_file_is_allowed: 'Nur 1 Datei ist erlaubt',
  required_password: 'Passwort ist erfoderlich',
  required_sleeping_facilities: 'Schlafmöglichkeiten ist erforderlich',
  required_pets_allowed: 'Feld "Haustiere erlaubt" ist erforderlich',

  /* Message */
  email_taken: 'Die E-Mail ist bereits vergeben.',

  /* Appartment Address Form */
  address_location: 'Adresse & Lage',
  address_adittion: 'Adresszusatz',
  distance_lake: 'Entfernung See',
  distance_shopping: 'Entfernung Einkaufsmöglichkeiten',
  distance_restaurant: 'Entfernung Restaurant',
  distance_city: 'Entfernung Stadt',
  distance_train_station: 'Entfernung Bahnhof',
  distance_bank_atm: 'Entfernung Bank / Geldautomat',
  distance_doctor: 'Entfernung Arzt',
  the_google_maps_map_directions_make_it_easier_for_guests_to_navigate_to_your_apartment_and_offers_maximum_transparency:
    'Die Google-Maps-Karte / Wegbeschreibung erleichtert den Gästen die Navigation zu Ihrer Wohnung und bietet maximale Transparenz',
  type_is_required: 'Typ ist erforderlich',
  pets_allowed: 'Haustiere erlaubt',

  /**text form */
  headline: 'Überschrift',
  full_description: 'Vollständige Beschreibung',
  short_description: 'Kurzbeschreibung',
  directions: 'Wegbeschreibung',

  /**Settings Form */
  season_and_prices: 'Saisons & Preise',
  please_assign_price:
    'Bitte weisen Sie die verfügbaren Saisons der Wohnung zu und geben Sie einen Preis an.',
  assigned: 'Zugewiesen',
  adjust_order_of_images:
    'Die Anordnung / Reihenfolge der Bilder kann ganz einfach per Drag & Drop geändert werden.',
  delete_image: 'Bild löschen',

  // Newsletter
  newsletter_subscribed: 'Newsletter abonniert',
  newsletter: 'Newsletter',
  newsletter_contact: 'Newsletter',
  newsletters: 'Newsletter',
  newsletter_overview: 'Newsletter-Übersicht',
  draft: 'Entwurf',
  drafts: 'Entwürfe',
  group_of_recipients: 'Empfängergruppe',
  all_newsletters: 'Alle Newsletter',
  new_newsletter: 'Neuer Newsletter',
  create_new_newsletter: 'Neuen Newsletter erstellen',
  recipients: 'Empfänger',
  subject: 'Betreff',
  all_draft: 'Alle Entwürfe',
  message: 'Nachricht',
  attach_file: 'Datei anhängen',
  attachment: 'Anhang:',
  attachments: 'Anhänge:',
  preview: 'Vorschau',
  save_as_draft: 'Entwurf speichern',
  compose_message: 'Nachricht verfassen',
  preview_message: 'Newsletter-Vorschau',
  download_our_app_now: 'Laden Sie unsere App jetzt herunter',
  about_us: 'Über uns',
  customer_care: 'Kundenbetreuung',
  contact_us: 'Kontaktieren Sie uns',
  privacy_policy: 'Datenschutzbestimmungen',
  select_recipient_group: 'Empfängergruppe auswählen',
  edit_newsletter: 'Newsletter bearbeiten',
  delete_newsletter: 'Newsletter löschen',
  newsletter_deleted: 'Newsletter gelöscht.',
  newsletter_delete_error: 'Newsletter-Löschung fehlgeschlagen.',
  newsletter_sent_sucessfully: 'Newsletter erfolgreich gesendet.',
  newsletter_saved_to_draft_sucessfully:
    'Newsletter erfolgreich als Entwurf gespeichert.',
  newsletter_updated_sucessfully: 'Newsletter erfolgreich aktualisiert.',
  hello: 'Hallo',
  to_send: 'Zum Senden',
  save_and_send: 'Speichern und Senden',
  recipient_group_field_is_required:
    'Das Feld Empfängergruppe ist erforderlich.',
  subject_field_is_required: 'Betrefffeld ist erforderlich.',
  message_field_is_required: 'Nachrichtenfeld ist erforderlich.',
  send_newsletter: 'Newsletter versenden',
  attention_notification_will_be_send_continue:
    'Achtung: Newsletter wird gesendet, Fortfahren',
  privacy: 'Datenschutz',
  imprint: 'Impressum',
  text_search: 'Textsuche',
  subscribe: 'Abonniert',
  unsubscribe: 'Nicht abonniert',
  date: 'Datum',

  dispatched: 'Versendet',
  app: 'App',
  connect_with_us: 'Setzen Sie sich mit uns in Verbindung',
  website: 'Website',
  newsletter_unsubscribe: 'Newsletter abbestellen',

  first_select_the_unit_then_the_owner:
    'Erst Einheit auswählen, danach Eigentümer',

  enter_description: 'Beschreibung eingeben',
  // Delete Owner Message
  delete_owner_confirmation:
    'Möchten Sie den {0} {1}, {2} wirklich unwiderruflich löschen?',
  linked_units_message:
    'Bitte beachten Sie, dass der ausgewählte {0} noch mit folgenden Einheiten verknüpft ist:',
  delete_attention_message:
    'Achtung! Wenn Sie auf Löschen klicken, wird der ausgewählte {0} unwiderruflich aus allen verknüpften Einheiten gelöscht.',

  no_object_associated_with_message:
    'Es sind keine weiteren Objekte mit dem ausgewählten {0} verknüpft.',

  all_roles: 'Alle Rollen',
  upload_failed: 'Upload fehlgeschlagen!',
  attention: 'Achtung:',
  could_not_be_uploaded: 'konnte nicht hochgeladen werden.',
  documents_are_uploaded: 'Dokumente werden hochgeladen',
  close: 'Schließen',
};

export default de;
