import { strings } from 'config/localization';
import moment, { MomentInput } from 'moment';
import 'moment/locale/de';

moment.locale(strings.lang);

type momentDurationTypes = 'days' | 'weeks' | 'months' | 'years';

export const DateFormat = (date: MomentInput): string => {
  return moment(date).isValid() ? moment(date).format('DD.MM.YYYY') : '';
};

export const DateTimeFormat = (date: MomentInput): string => {
  return moment(date).isValid() ? moment(date).format('DD.MM.YYYY, HH:mm') : '';
};

export const DateFormatYMD = (date: MomentInput): string => {
  return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : '';
};

export const DateFormatFromNow = (date: MomentInput): string => {
  return moment(date).isValid() ? moment(date).fromNow() : '';
};

/**
 * @param {string} date - Date in format YYYY-MM-DD HH:mm:ss *
 * @returns {string} - Date in the given format DD:MM:YYYY HH:mm
 * @example utcToLocal('2020-01-01 00:00:00') => 01.01.2020 02:00 , for German locale ie +2 GMT
 */

export const utcToLocal = (date: string): string => {
  if (!moment(date).isValid()) return '';
  let utcDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
  let stillUTC = moment.utc(utcDate).toDate();
  let localDate = moment(stillUTC).local().format('DD.MM.YYYY HH:mm');
  return localDate;
};
