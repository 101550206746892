import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
} from '@chakra-ui/react';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { RentalStatus, RestaurantInfo, UnitTypeEnum } from 'constants/schema';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import UnitForm from '../UnitForm';

type RestaurantFormValues = {
  type: UnitTypeEnum;
  apartment_quota: string;
  stwe_number: string;
  living_space: string;
  rental_status: RentalStatus;
};

interface Props {
  data?: RestaurantInfo;
}

const RENTAL_STATUS = [
  'permanent_rental',
  'vacation_rental',
  'own_use',
] as const;

const RestaurantForm: React.FC<Props> = (props) => {
  const { data } = props;

  const methods = useForm<RestaurantFormValues>();

  // Form state
  const {
    register,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    setValue('type', UnitTypeEnum.RESTAURANT);
    if (!data) return;
    const { apartment_quota, rental_status, living_space, stwe_number } = data;
    setValue('apartment_quota', apartment_quota);
    setValue('stwe_number', stwe_number);
    setValue('living_space', living_space);
    setValue('rental_status', rental_status);
  }, [data, setValue]);

  return (
    <FormProvider {...methods}>
      <form>
        <UnitForm unitType={UnitTypeEnum.RESTAURANT}>
          <GridItem>
            <FormControl isInvalid={!!errors?.stwe_number} isRequired>
              <FormLabel>{strings.stwe_number}</FormLabel>
              <Input
                type="text"
                {...register('stwe_number', {
                  required: strings.stwe_number_is_required,
                })}
                placeholder={strings.enter_stwe_number}
              />
              <FormErrorMessage>
                <>{errors?.stwe_number && errors?.stwe_number?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.apartment_quota} isRequired>
              <FormLabel>{strings.apartment_quota}</FormLabel>
              <Input
                type="text"
                {...register('apartment_quota', {
                  required: strings.apartment_quota_is_required,
                })}
                placeholder={strings.enter_apartment_quota}
              />
              <FormErrorMessage>
                <>
                  {errors?.apartment_quota && errors?.apartment_quota?.message}
                </>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.living_space} isRequired>
              <FormLabel>{strings.living_space}</FormLabel>
              <Input
                type="text"
                {...register('living_space', {
                  required: strings.living_space_is_required,
                })}
                placeholder={strings.enter_living_space}
              />
              <FormErrorMessage>
                <>{errors?.living_space && errors?.living_space?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={[1, 2]}></GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors?.rental_status} isRequired>
              <FormLabel>{strings.status}</FormLabel>
              <CustomChakraSelect
                {...register('rental_status', {
                  required: strings.status_is_required,
                })}
                placeholder={strings.select_status}>
                {RENTAL_STATUS.map((ele) => (
                  <option key={ele} value={ele}>
                    {strings[ele]}
                  </option>
                ))}
              </CustomChakraSelect>
              <FormErrorMessage>
                <>{errors?.rental_status && errors?.rental_status?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </UnitForm>
      </form>
    </FormProvider>
  );
};

export default RestaurantForm;
