import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { resetPassword } from 'api/auth';
import { AxiosError, AxiosResponse } from 'axios';
import LogoInformation from 'components/auth/LogoInformation';
import LanguageMenu from 'components/common/LanguageMenu';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiEnvelope, BiKey } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import history from 'utils/history';
import { validatePassword, validEmail } from 'utils/validate';

export interface ResetPasswordFormValues {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token') || '';
  const email = params.get('email') || '';
  if (token === '' || email === '') history.push(routes.auth.login);
  const newPassword = useRef({});
  const [errMsg, setErrMsg] = useState<string>();
  const toast = useToast();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<ResetPasswordFormValues>();

  const resetPasswordMutation = useMutation<
    AxiosResponse<ResetPasswordFormValues>,
    AxiosError,
    ResetPasswordFormValues
  >((data) => resetPassword(data), {
    onSuccess: () => {
      toast({
        title: strings.login_with_new_password,
        status: 'success',
        isClosable: true,
      });
      history.push(routes.auth.login);
    },
    onError: (error) => {
      if (error?.response?.data?.errors?.email)
        setErrMsg(error?.response?.data?.errors?.email[0]);
      if (error?.response?.data?.errors?.token)
        setErrMsg(strings.invalid_token);
      if (error?.response?.data?.errors?.password)
        setErrMsg(error?.response?.data?.errors?.password[0]);
    },
  });

  newPassword.current = watch('password', '');

  const onSubmit = (data: ResetPasswordFormValues) => {
    data.token = token;
    if (data.email === email) resetPasswordMutation.mutate(data);
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Stack pos="absolute" right="5%">
        <LanguageMenu />
      </Stack>
      <Stack direction="column" spacing="12" alignItems="center" py="6">
        <LogoInformation />
        <Box bg="white" p="14" shadow="box" rounded="sm">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="8">
              <Stack direction="column" spacing="4">
                <Heading as="h1" size="xl">
                  {strings.reset_password}
                </Heading>
                <Text color="gray.500">{strings.reset_password_subtitle}</Text>
                {resetPasswordMutation.isError && (
                  <ScaleFade in={resetPasswordMutation.isError}>
                    <Alert status="error">
                      <AlertIcon />
                      {errMsg}
                    </Alert>
                  </ScaleFade>
                )}
              </Stack>
              {email && (
                <FormControl colorScheme="primary" isInvalid={!!errors.email}>
                  <FormLabel color="gray.600">{strings.email}</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <Icon color="gray.300" w="4" h="4" as={BiEnvelope} />
                      }
                    />
                    <Input
                      id="email"
                      type="email"
                      defaultValue={email}
                      placeholder={strings.your_email_address}
                      {...register('email', {
                        required: strings.email_address,
                        validate: (value) =>
                          validEmail(value) || strings.valid_email_address,
                      })}
                    />
                  </InputGroup>
                  <FormErrorMessage>
                    <>{errors.email && errors.email?.message}</>
                  </FormErrorMessage>
                </FormControl>
              )}

              <FormControl colorScheme="primary" isInvalid={!!errors.password}>
                <FormLabel color="gray.600">{strings.password}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={<Icon color="gray.300" w="4" h="4" as={BiKey} />}
                  />
                  <Input
                    id="password"
                    type="password"
                    placeholder={strings.new_password}
                    {...register('password', {
                      required: strings.new_password,
                      validate: (value) =>
                        validatePassword(value) || strings.msg_valid_password,
                    })}
                  />
                </InputGroup>
                <FormHelperText>{strings.password_helper_text}</FormHelperText>
                <FormErrorMessage>
                  <>{errors.password && errors.password?.message}</>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                colorScheme="primary"
                isInvalid={!!errors.password_confirmation}>
                <FormLabel color="gray.600">
                  {strings.confirm_password}
                </FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={<Icon color="gray.300" w="4" h="4" as={BiKey} />}
                  />
                  <Input
                    id="password_confirmation"
                    type="password"
                    placeholder={strings.confirm_password}
                    {...register('password_confirmation', {
                      required: strings.confirm_password,
                      validate: (value) =>
                        value === newPassword.current ||
                        strings.not_match_new_confirm_password,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <>
                    {errors.password_confirmation &&
                      errors.password_confirmation?.message}
                  </>
                </FormErrorMessage>
              </FormControl>
              <VStack spacing="6">
                <Button
                  w="full"
                  type="submit"
                  size="lg"
                  fontSize="md"
                  fontWeight="semibold"
                  colorScheme="primary"
                  textTransform="uppercase"
                  boxShadow="md"
                  isLoading={resetPasswordMutation.isLoading}>
                  {strings.save_reset_password}
                </Button>
                <Link to={routes.auth.login}>
                  <Button
                    variant="link"
                    color="gray.600"
                    fontWeight="medium"
                    leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                    {strings.back_to_login}
                  </Button>
                </Link>
              </VStack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default ResetPassword;
