const PermissionRequest = {
  /**
   * Permissions for User
   */
  'read:user': 'access-user',
  'manage:user': 'manage-user',
  'read:login-log': 'access-user-log',
  'manage:owner': 'manage-owner',
  'manage:caretaker': 'manage-caretaker',
  'manage:admin': 'manage-admin',

  /**
   * Permission for Ticket
   */
  'read:ticket': 'access-ticket',
  'create:ticket': 'create-ticket',
  'edit:ticket': 'edit-ticket',
  'assign:ticket': 'assign-ticket',

  /**
   * Permissions for OMS
   */
  'read:oms': 'access-oms',
  'manage:oms': 'manage-oms',
  'sync:voffice': 'voffice-data-sync',

  /**
   * Permissions for Faq Service
   */
  'read:faq': 'access-faq',
  'manage:faq': 'manage-faq',
  'read:faq-group': 'access-faq-group',
  'manage:faq-group': 'manage-faq-group',

  /**
   * Permissions for DMS
   */
  'read:object-dms': 'access-dms-object',
  'manage:object-dms': 'manage-dms-object',
  'read:owner-dms': 'access-dms-owner',
  'manage:owner-dms': 'manage-dms-owner',

  'read:dms-owner': 'access-dms-owner',
  'manage:dms-owner': 'manage-dms-owner',
  'read:dms-ownership': 'access-dms-ownership',
  'manage:dms-ownership': 'access-dms-ownership',

  /**
   * Permissions for Dashboard
   */
  'read:dashboard': 'access-dashboard',

  /**
   * Permissions for owner login
   */
  'is:owner': 'access-owner',

  /**
   * Permissions for Newsletter service
   */
  'read:newsletter': 'access-newsletter',
  'manage:newsletter': 'manage-newsletter',
} as const;

export default PermissionRequest;
