import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  DMS_OBJECT_LIST_WITH_DOCUMENTS_COUNT,
  INITIAL_CURRENT_PAGE,
  UnitTypeOptions,
} from 'constants/common';
import routes from 'constants/routes';
import {
  AnyDocumentUnit,
  Count,
  DataWrapper,
  UnitTypeEnum,
} from 'constants/schema';
import useCustomToast from 'hooks/useCustomToast';
import { useFilter } from 'hooks/useFilter';
import useWordSearch from 'hooks/useWordSearch';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import { Link as RouterLink, useHistory } from 'react-router-dom';

type FilterParams = {
  currentPage: number;
  pageSize: number;
  address: string;
  addressName: string;
  unitType: string;
};

type ObjectListQueryParams = {
  address: string;
  address_name: string;
  type: string | null;
  page: number;
  limit: number;
};

const ObjectList: React.FC = () => {
  const {
    error,
    loading: apartmentListLoading,
    result: apartmentQuery,
    setQuery,
  } = useWordSearch<
    DataWrapper<(AnyDocumentUnit & Count)[]>,
    ObjectListQueryParams
  >(`${DMS_OBJECT_LIST_WITH_DOCUMENTS_COUNT}`);

  const history = useHistory();
  const { searchValues, updateUrl } = useFilter();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    address: searchValues?.address ?? '',
    addressName: searchValues?.addressName ?? '',
    unitType: searchValues?.unitType ?? '',
  });

  const { showErrorToast } = useCustomToast();

  useEffect(() => {
    if (error) {
      showErrorToast();
    }
  }, [error, showErrorToast]);

  useEffect(() => {
    setQuery({
      address: filterParams.address,
      address_name: filterParams.addressName,
      type: filterParams.unitType || null,
      page: filterParams.currentPage,
      limit: filterParams.pageSize,
    });
    updateUrl(filterParams);
  }, [setQuery, updateUrl, filterParams]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      address: '',
      ownerName: '',
      addressName: '',
    }));

    history.replace(location.pathname);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));

    updateUrl({ [name]: value });
  };

  const handleRowClick = (id: number) => {
    history.push(routes.dms.object.objectDocuments.replace(':id', String(id)));
  };

  const renderContent = () => {
    if (apartmentListLoading) {
      return <TableSkeletonLoader rows={6} cols={6} />;
    }

    return apartmentQuery?.data?.map((item) => {
      return (
        <Tr
          key={item.id}
          onClick={() => handleRowClick(item.id)}
          cursor="pointer">
          <Td>{item.address.name} </Td>
          <Td>{item.address.addition}</Td>
          <Td>
            {item.address.street} {item.address.house_number},&nbsp;
            {item.address.zip_code} {item.address.town}
          </Td>
          <Td>{strings.getString(item.type)}</Td>
          <Td>
            {item.type === UnitTypeEnum.APARTMENT
              ? item.apartment_number
              : item.garage_number}
          </Td>
          <Td isNumeric>{item.total}</Td>
        </Tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>
                <Text color="paragraph">{strings.object}</Text>
              </Th>
              <Th>
                <Text color="paragraph">{strings.object_addition}</Text>
              </Th>
              <Th>
                <Text color="paragraph">{strings.address}</Text>
              </Th>
              <Th>
                <Text color="paragraph">{strings.unit_type}</Text>
              </Th>
              <Th>
                <Text color="paragraph">{strings.no_abbreviation}</Text>
              </Th>
              <Th isNumeric>
                <Text color="paragraph">{strings.total}</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>{renderContent()}</Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.document} | {strings.document_units}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.object.list}>
              {strings.document}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.object.list}>
              {strings.document_units}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.document_units}
          </Heading>
        </Flex>

        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          defaultIndex={0}
          boxShadow="box">
          <AccordionItem>
            <>
              <h2>
                <AccordionButton p="4">
                  <Box flex="1" textAlign="center">
                    <Flex justify="space-between">
                      <Heading fontSize="18px" fontWeight="medium">
                        <Icon as={BiFilter} /> {strings.filter}
                      </Heading>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding="0">
                <Stack sx={wrapperStyles}>
                  <Stack
                    direction={['column', 'column', 'row']}
                    spacing="4"
                    alignItems={['initial', 'initial', 'flex-end']}>
                    <Grid
                      gap="4"
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(3, 1fr)',
                      ]}
                      flex="1">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.object}</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              name="addressName"
                              value={filterParams.addressName}
                              onChange={handleInputChange}
                              placeholder={strings.object_name}
                              size="lg"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>

                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.address}</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              name="address"
                              value={filterParams.address}
                              onChange={handleInputChange}
                              placeholder={`${strings.street} ${strings.house_number}, ${strings.zip_code} ${strings.town}`}
                              size="lg"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>

                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.unit_type}</FormLabel>
                          <Select
                            id="unitType"
                            size="lg"
                            name="unitType"
                            rounded="sm"
                            placeholder={strings.select_unit_type}
                            value={filterParams.unitType}
                            onChange={handleInputChange}>
                            {UnitTypeOptions.map((val) => (
                              <option key={val} value={val}>
                                {strings[val]}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Button
                      size="lg"
                      w="fit-content"
                      colorScheme="primary"
                      variant="outline"
                      onClick={handleReset}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </AccordionPanel>
            </>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <Flex align="center" justify="space-between" mb={4}>
            <Heading as="h2" size="md" mr={4}>
              {strings.units}
            </Heading>
          </Flex>

          <Box>{renderTable()}</Box>
        </Stack>

        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={{ data: apartmentQuery, isLoading: apartmentListLoading }}
        />
      </Stack>
    </>
  );
};

export default ObjectList;
