import { ButtonProps } from '@chakra-ui/react';
import theme from 'config/theme';
import { CSSObjectWithLabel } from 'react-select';

// Styles for paginated buttons ------------------------------ //

export const baseStyles: ButtonProps = {
  w: 8,
  h: 8,
  fontSize: 'sm',
  _disabled: {
    bg: 'white',
    pointerEvents: 'none',
  },
};

export const normalStyles: ButtonProps = {
  ...baseStyles,
  _hover: {
    bg: 'primary.400',
    color: 'white',
  },
  bg: 'white',
  color: 'gray.900',
};

export const activeStyles: ButtonProps = {
  ...baseStyles,
  bg: 'primary.400',
  color: 'white',
  _hover: {
    bg: 'primary.400',
    color: 'white',
  },
};

export const separatorStyles: ButtonProps = {
  w: 7,
  pointerEvents: 'none',
};

// ---------------------------------------------------------- //

// Styles for react-select ------------------------------ //

export const reactSelectStyles = {
  control: (
    provided: CSSObjectWithLabel,
    state: {
      isDisabled: boolean;
      isFocused: boolean;
    }
  ) => ({
    ...provided,

    minHeight: '42px',
    boxShadow: theme.shadows.input,
    borderRadius: theme.radii.sm,
    borderColor: state.isDisabled
      ? theme.colors.gray[200]
      : state.isFocused
      ? theme.colors.blue
      : 'inherit',
    transition: 'all 0.35s ease-in-out',
    backgroundColor: theme.colors.white,
    opacity: state.isDisabled ? '0.4' : '1',
    cursor: state.isDisabled ? 'not-allowed' : 'inherit',

    '&:hover': {
      borderColor: theme.colors.gray[300],
    },
  }),

  // TODO: Write a type for the `provided` variable that works both with
  // multi and single selects and arbitrary option value types.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  placeholder: (provided: any) => ({
    ...provided,
    color: theme.colors.gray[400],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),

  indicatorSeparator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: theme.colors.gray[300],
  }),

  dropdownIndicator: (provided: CSSObjectWithLabel) => ({
    ...provided,
    color: theme.colors.gray[500],
  }),

  option: (
    provided: CSSObjectWithLabel,
    state: {
      isSelected: boolean;
      isFocused: boolean;
    }
  ) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? theme.colors.gray[300]
      : state.isFocused
      ? theme.colors.gray[200]
      : 'transparent',
  }),

  multiValue: (provided: CSSObjectWithLabel) => ({
    ...provided,
    backgroundColor: theme.colors.blue[50],
    p: 0,
  }),

  valueContainer: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: '0 10px',
  }),
  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    borderRadius: theme.radii.sm,
    zIndex: '3',
    fontSize: theme.fontSizes.sm,
  }),
  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
};

export const labelStyles = {
  color: 'gray.900',
  fontweight: 'bold',
  mb: 1,
};
export const infoStyles = {
  color: 'gray.600',
  fontWeight: 'normal',
};
export const wrapperStyles = {
  bg: 'white',
  p: [3, 6],
  shadow: 'box',
  w: 'full',
  direction: 'column',
  spacing: '4',
  rounded: 'sm',
};

export const selecLabelColorScheme = (group: string) => {
  const groupName = group.toLowerCase();
  const groupColorScheme: { [key: string]: string } = {
    owner: 'orange.400',
    guest: 'blue.400',
    lead: 'pink.400',
    supplier: 'green.400',
    app: 'purple.400',
    test: 'gray.400',
  };
  return groupColorScheme[groupName];
};

export const selectBGScheme = (group: string) => {
  const groupName = group.toLowerCase();
  const groupBGScheme: { [key: string]: string } = {
    owner: 'orange.50',
    guest: 'blue.50',
    lead: 'pink.50',
    supplier: 'green.50',
    app: 'purple.50',
    test: 'gray.50',
  };
  return groupBGScheme[groupName];
};
