import { IconButton, Link, Stack, Td, Tooltip, Tr } from '@chakra-ui/react';
import { getTag } from 'components/common/PriorityTag';
import { getStatus } from 'components/common/TaskStatus';
import CustomTagTitle from 'components/CustomTagTitle';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { TicketInfoSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { BiEdit, BiMinus, BiShow } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Link as RouterLink } from 'react-router-dom';
import { getTicketTitle } from 'utils';
import { DateFormat } from 'utils/DateFormat';

const ticketTypeMap = {
  icon: BiMinus,
  title: strings.normal_task,
  color: 'gray.300',
};

interface Props {
  ticketData: TicketInfoSchema;
  index: number;
  search: string;
}

const TicketListItem: React.FC<Props> = (props) => {
  /**
   * Initialize
   */
  const { ticketData, index, search } = props;
  const checkEditTicketPermission = useCheckPermission(
    [PermissionRequest['edit:ticket']],
    routes.ticket.list.edit.replace(':id', ticketData.id.toString()) + search
  );

  const priority = ticketData.priority;

  const ticketTitle = getTicketTitle(ticketData);

  return (
    <Tr>
      <Td>{index}</Td>

      <Td fontWeight="medium" color="gray.500" _hover={{ color: 'gray.600' }}>
        <RouterLink
          to={
            routes.ticket.list.view.replace(':id', ticketData.id.toString()) +
            search
          }>
          <Tooltip hasArrow label={ticketTitle} fontSize="normal">
            {ticketTitle}
          </Tooltip>
        </RouterLink>
      </Td>
      <Td>{getTag(priority)}</Td>

      <Td>{getStatus(ticketData.status)}</Td>
      <Td>
        <CustomTagTitle
          type="text"
          status={ticketData?.assigned_user?.status}
          name={ticketData?.assigned_user?.name || '-'}
        />
      </Td>
      <Td>{ticketData?.apartment?.name || '-'}</Td>
      <Td>{DateFormat(ticketData.createdAt)}</Td>
      <Td>
        <Stack direction="row">
          <RouterLink
            to={
              routes.ticket.list.view.replace(':id', ticketData.id.toString()) +
              search
            }>
            <Tooltip hasArrow label={`${strings.ticket_detail}`}>
              <IconButton
                icon={<BiShow size="18" />}
                variant="link"
                aria-label={`${strings.ticket_detail}`}
                color="green.300"
                minW="8"
              />
            </Tooltip>
          </RouterLink>
          <Link onClick={checkEditTicketPermission}>
            <Tooltip hasArrow label={`${strings.edit_task}`}>
              <IconButton
                icon={<BiEdit size="18" />}
                variant="link"
                aria-label={`${strings.edit_task}`}
                color="blue.300"
                minW="8"
              />
            </Tooltip>
          </Link>
        </Stack>
      </Td>
    </Tr>
  );
};

export default TicketListItem;
