import {
  Alert,
  AlertIcon,
  Avatar,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { UserSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

interface Props {
  userData: UserSchema;
  index: number;
}
const RoleUsersListItem: React.FC<Props> = (props) => {
  const { userData, index } = props;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const userAPI = new UserResource();
  const queryClient = useQueryClient();
  const toast = useToast();
  const checkEditUserPermission = useCheckPermission(
    [PermissionRequest['manage:user']],
    routes.users.edit.replace(':id', userData.id.toString())
  );

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteUser = useMutation(() => userAPI.destroy(userData.id), {
    onSuccess: (res) => {
      queryClient.invalidateQueries('userList');

      if (res.data[0].success) {
        setDeleteModalOpen(false);
        toast({
          title: strings.user_deleted,
          status: 'success',
          isClosable: true,
        });
      } else {
        setDeleteMsg(strings.user_delete_error);
      }
    },
  });

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteUser.mutate();
  };

  return (
    <Tr key={userData.id}>
      <Td>{index}</Td>
      <Td>
        <RouterLink
          to={routes.users.profile.replace(':id', userData.id.toString())}>
          <Stack direction="row" alignItems="center">
            <Avatar
              size="sm"
              rounded="full"
              src={userData.profile_picture_url}
            />
            <Text
              fontWeight="medium"
              color="gray.500"
              _hover={{ color: 'gray.600' }}>
              {userData.first_name} {userData.last_name}
            </Text>
          </Stack>
        </RouterLink>
      </Td>
      <Td>{userData.email}</Td>
      <Td isNumeric>{userData.contact_number}</Td>
      <Td>
        <RouterLink
          to={routes.users.profile.replace(':id', userData.id.toString())}>
          <Tooltip hasArrow label="View User">
            <IconButton
              icon={<BiShow size="18" />}
              variant="link"
              aria-label={strings.view_user}
              color="green.300"
              minW="8"
            />
          </Tooltip>
        </RouterLink>
        <Link onClick={checkEditUserPermission}>
          <Tooltip hasArrow label="Edit User">
            <IconButton
              icon={<BiEdit size="18" />}
              variant="link"
              aria-label={strings.edit_user}
              color="blue.300"
              minW="8"
            />
          </Tooltip>
        </Link>
        <Tooltip hasArrow label={strings.delete_user}>
          <IconButton
            icon={<BiTrash size="18" />}
            variant="link"
            aria-label={strings.delete_user}
            color="red.300"
            minW="8"
            onClick={onDeletePress}
          />
        </Tooltip>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_user}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[PermissionRequest['manage:user']]}
                  renderNoAccess={(data) => data}>
                  <Button
                    colorScheme="red"
                    onClick={onDeleteConfirm}
                    isLoading={deleteUser.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default RoleUsersListItem;
