import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import http from 'utils/http';

const useWordSearch = <T, Q>(url: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);
  const [result, setResult] = useState<T | null>(null);
  const [query, setQuery] = useState<Q | null>(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    setError(false);

    let cancel: Function;

    let debounce = setTimeout(() => {
      http({
        url: url,
        method: 'GET',
        params: query,
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      })
        .then((res) => {
          setResult(res.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (axios.isCancel(err)) return;
          setError(true);
        });
    }, 400);

    return () => {
      clearTimeout(debounce);
      cancel?.();
    };
  }, [query, url]);

  useEffect(() => {
    const cleanup = fetchData();
    return cleanup;
  }, [query, url, fetchData]);

  const refetch = () => {
    // Call the fetchData function to trigger a data fetch
    const cleanup = fetchData();
    return cleanup;
  };

  return { loading, error, result, setQuery, refetch };
};

export default useWordSearch;
