import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { CenterSpinner } from 'components/common/CenterSpinner';
import TicketAuthor from 'components/ticket/TicketAuthor';
import TicketComment from 'components/ticket/TicketComment';
import TicketInfo from 'components/ticket/TicketInfo';
import TicketLogInfoSchema from 'components/ticket/TicketLogInfo';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { TicketInfoSchema } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useParams,
} from 'react-router-dom';
import { getTicketTitle } from 'utils';
import { DateFormat } from 'utils/DateFormat';
import history from 'utils/history';

type ViewTicketParams = {
  id: string;
};

const ViewTicket: React.FC = () => {
  const { search } = useLocation();

  function matchURL() {
    return matchPath(location.pathname, {
      path: routes.ticket.task.view,
      exact: true,
      strict: false,
    });
  }
  const isTicketBoardView = matchURL();
  const { id } = useParams<ViewTicketParams>();
  const ticketApi = new TicketResource();

  const ticketQuery = useQuery<TicketInfoSchema>(
    [`ticket${id}`, id],
    () => ticketApi.get(id).then((res) => res.data.data),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (ticketQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (ticketQuery.isError) {
    history.push(routes.ticket.list.default);
  }

  const title = ticketQuery.data
    ? getTicketTitle(ticketQuery.data as TicketInfoSchema)
    : '';

  return (
    <>
      <Helmet>
        <title>
          {strings.ticket} | #{id} -{title}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={
                (isTicketBoardView
                  ? routes.ticket.task.board
                  : routes.ticket.list.default) + search
              }>
              {isTicketBoardView ? strings.ticket : strings.ticket_list}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              #{id} - {title}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {ticketQuery?.data && (
          <>
            <TicketInfo
              ticket={ticketQuery.data}
              path={
                isTicketBoardView
                  ? routes.ticket.task.edit
                  : routes.ticket.list.edit
              }
            />

            <TicketAuthor
              createdBy={
                ticketQuery.data?.created_by_user ?? ticketQuery.data?.caretaker
              }
              createdAt={DateFormat(ticketQuery.data?.createdAt)}
              lastChangedBy={ticketQuery.data?.updated_by_user}
              lastChangedAt={DateFormat(ticketQuery.data?.updatedAt)}
            />

            <TicketLogInfoSchema ticketId={ticketQuery.data.id} />
            <TicketComment ticketId={ticketQuery.data.id} />
          </>
        )}
      </Stack>
    </>
  );
};

export default ViewTicket;
