import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { default as CustomerDocumentResource } from 'api/owner_document';
import { CenterSpinner } from 'components/common/CenterSpinner';
import Pagination from 'components/common/Pagination';
import DocumentListTable from 'components/dms/DocumentList';
import DocumentListFilter from 'components/dms/DocumentListFilter';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  DMS_OWNER_DOCUMENT_LIST,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import {
  DataWrapper,
  Document,
  DocumentListFilterParams,
} from 'constants/schema';
import useCustomToast from 'hooks/useCustomToast';
import { useFilter } from 'hooks/useFilter';
import useWordSearch from 'hooks/useWordSearch';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { renderFullName } from 'utils';

type DocumentListQueryParams = {
  keyword: string;
  tags: string[] | undefined;
  document_years: string[] | undefined;
  page: number;
  limit: number;
};

interface OwnersDocumentListParams {
  id: string;
}

const OwnersDocumentList: React.FC = () => {
  let ownerDocumentAPI = new CustomerDocumentResource();

  const { id } = useParams<OwnersDocumentListParams>();
  const { search } = useLocation();

  const { searchValues, updateUrl } = useFilter();

  const [filterParams, setFilterParams] = useState<DocumentListFilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    keyword: searchValues?.keyword ?? '',
    tag: searchValues?.tag ?? '',
    year: searchValues?.year ?? '',
  });

  const {
    error: ownerListError,
    loading: ownerListLoading,
    result: ownerDocumentsList,
    setQuery,
    refetch,
  } = useWordSearch<DataWrapper<Document[]>, DocumentListQueryParams>(
    `${DMS_OWNER_DOCUMENT_LIST}${id}`
  );

  useEffect(() => {
    setQuery({
      keyword: filterParams.keyword,
      tags: filterParams.tag ? [filterParams.tag] : undefined,
      document_years: filterParams.year ? [filterParams.year] : undefined,
      page: filterParams.currentPage,
      limit: filterParams.pageSize,
    });
    updateUrl(filterParams);
  }, [setQuery, updateUrl, filterParams]);

  const {
    data: ownerDetails,
    isError: ownerDetailsError,
    isLoading: ownerDetailsLoading,
  } = useQuery(
    'getOwnerDetails',
    () => ownerDocumentAPI.getOwner(id).then((res) => res.data.data),
    {
      enabled: !!id,
    }
  );

  const { showErrorToast } = useCustomToast();

  useEffect(() => {
    if (ownerListError || ownerDetailsError) {
      showErrorToast();
    }
  }, [ownerListError, ownerDetailsError]);

  if (ownerDetailsLoading) {
    return <CenterSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>
          {strings.document} | {strings.document_owner}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.owner.list}>
              {strings.document}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.owner.list}>
              {strings.document_owner}
            </BreadcrumbLink>
          </BreadcrumbItem>

          {ownerDetails && (
            <BreadcrumbItem>
              <BreadcrumbLink
                as={RouterLink}
                to={routes.dms.owner.ownerDocuments.replace(':id', id)}>
                {renderFullName(
                  ownerDetails?.first_name,
                  ownerDetails?.last_name
                )}
              </BreadcrumbLink>
            </BreadcrumbItem>
          )}
        </Breadcrumb>
        {ownerDetails && (
          <Flex justify="space-between">
            <Heading size="lg" textTransform="capitalize">
              {renderFullName(
                ownerDetails?.first_name,
                ownerDetails?.last_name
              )}
            </Heading>
          </Flex>
        )}

        <DocumentListFilter
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />

        <DocumentListTable
          title={strings.documents}
          list={ownerDocumentsList?.data ?? []}
          listLoading={ownerListLoading}
          refetch={refetch}
          // hasBoth={hasBoth}
        />

        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={{ data: ownerDocumentsList, isLoading: ownerListLoading }}
        />
      </Stack>
    </>
  );
};

export default OwnersDocumentList;
