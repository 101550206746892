import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import OwnerDocumentResource from 'api/owner_document';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { INITIAL_CURRENT_PAGE } from 'constants/common';
import { DocumentListFilterParams } from 'constants/schema';
import { useFilter } from 'hooks/useFilter';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { BiFilter } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getYearsList } from 'utils';

interface Props {
  filterParams: DocumentListFilterParams;
  setFilterParams: Dispatch<SetStateAction<DocumentListFilterParams>>;
}

const DocumentListFilter: React.FC<Props> = (props) => {
  const { filterParams, setFilterParams } = props;

  const { updateUrl } = useFilter();
  const history = useHistory();

  const documentsAPI = new OwnerDocumentResource();

  const toast = useToast();

  const { data: tagsList } = useQuery(
    'tags-list',
    () => documentsAPI.getDocumentTags().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      onError: () => {
        toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      tag: '',
      year: '',
    }));

    history.replace(location.pathname);
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));

    updateUrl({ [name]: value });
  };

  return (
    <Accordion
      bg="white"
      borderColor="white"
      allowToggle
      defaultIndex={0}
      boxShadow="box">
      <AccordionItem>
        <>
          <h2>
            <AccordionButton p="4">
              <Box flex="1" textAlign="center">
                <Flex justify="space-between">
                  <Heading fontSize="18px" fontWeight="medium">
                    <Icon as={BiFilter} /> {strings.filter}
                  </Heading>
                </Flex>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel padding="0">
            <Stack sx={wrapperStyles}>
              <Stack
                direction={['column', 'column', 'row']}
                spacing="4"
                alignItems={['initial', 'initial', 'flex-end']}>
                <Grid
                  gap="4"
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(3, 1fr)',
                  ]}
                  flex="1">
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.document_name}</FormLabel>
                      <InputGroup>
                        <Input
                          type="text"
                          name="keyword"
                          value={filterParams.keyword}
                          onChange={handleInputChange}
                          placeholder={strings.document_name}
                          size="lg"
                        />
                      </InputGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.tag}</FormLabel>
                      <CustomChakraSelect
                        id="tag"
                        size="lg"
                        name="tag"
                        rounded="sm"
                        placeholder={strings.tag_placeholder}
                        value={filterParams.tag}
                        onChange={handleInputChange}>
                        {tagsList?.data.map((item) => (
                          <option key={item} value={item}>
                            {strings[item]}
                          </option>
                        ))}
                      </CustomChakraSelect>
                    </FormControl>
                  </GridItem>

                  <GridItem>
                    <FormControl>
                      <FormLabel>{strings.year}</FormLabel>
                      <CustomChakraSelect
                        id="year"
                        size="lg"
                        name="year"
                        rounded="sm"
                        placeholder={strings.year_placeholder}
                        value={filterParams.year}
                        onChange={handleInputChange}>
                        {getYearsList(1960).map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </CustomChakraSelect>
                    </FormControl>
                  </GridItem>
                </Grid>
                <Button
                  size="lg"
                  w="fit-content"
                  colorScheme="primary"
                  variant="outline"
                  onClick={handleReset}>
                  {strings.reset_filter}
                </Button>
              </Stack>
            </Stack>
          </AccordionPanel>
        </>
      </AccordionItem>
    </Accordion>
  );
};

export default DocumentListFilter;
