import {
  Button,
  ButtonGroup,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Td,
  Text,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import OwnershipModal from 'components/oms/OwnershipModal';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import { Owner, OwnershipDTO } from 'constants/schema';
import React, { useMemo, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { renderFullName } from 'utils';
import AccessControl from '../../services/AccessControl';

interface Props {
  ownershipDTO: OwnershipDTO;
  ownersList: Owner[];
  editOwnership: (ownershipDTO: OwnershipDTO) => void;
  deleteOwnership: (ownershipDTO: OwnershipDTO) => void;
  apartmentOwnerIds: number[];
  isLoading: boolean;
}

const OwnershipListItem: React.FC<Props> = (props) => {
  const {
    ownershipDTO,
    ownersList,
    editOwnership,
    deleteOwnership,
    isLoading,
    apartmentOwnerIds,
  } = props;

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const onDeletePress = () => {
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteOwnership(ownershipDTO);
    setDeleteModalOpen(false);
  };

  const onEditPress = () => {
    setEditModalOpen(true);
  };

  const onEditModalClose = () => {
    setEditModalOpen(false);
  };

  const onEditConfirm = (editedOwnershipDTO: OwnershipDTO) => {
    editOwnership(editedOwnershipDTO);
    setEditModalOpen(false);
  };

  const owner =
    ownersList?.find((owner) => owner.id === ownershipDTO.owner_id) ?? null;

  const ownerSince = useMemo(() => {
    if (!ownershipDTO.is_current) {
      return '';
    }

    const date = new Date(ownershipDTO.owner_since);
    const language = localStorage.getItem('language');
    return date.toLocaleDateString(language ?? undefined);
  }, [ownershipDTO]);

  return (
    <Tr>
      <Td h="63px" w="100%">
        <Text>{renderFullName(owner?.first_name, owner?.last_name)}</Text>
      </Td>
      <Td>
        {!ownershipDTO.is_current ? (
          <Text
            p="6px"
            h="auto"
            lineHeight="0.75"
            color="white"
            backgroundColor="#FF7A7A"
            borderRadius="6px"
            width="fit-content"
            fontSize="10px">
            {strings.previous_owner}
          </Text>
        ) : (
          ownerSince
        )}
      </Td>
      <Td>
        <AccessControl allowedPermissions={[PermissionRequest['manage:owner']]}>
          <>
            <Tooltip hasArrow label={strings.edit_ownership}>
              <IconButton
                icon={<BiEdit />}
                variant="link"
                aria-label={strings.edit_ownership}
                color="blue.300"
                minW="6"
                onClick={onEditPress}
              />
            </Tooltip>
            <Tooltip hasArrow label={strings.delete_ownership}>
              <IconButton
                icon={<MdOutlineDeleteForever />}
                variant="link"
                aria-label={strings.delete_ownership}
                color="red.500"
                minW="6"
                onClick={onDeletePress}
              />
            </Tooltip>
          </>
        </AccessControl>
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_ownership}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.confirm_delete}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="red"
                  onClick={onDeleteConfirm}
                  isLoading={isLoading}>
                  {strings.delete}
                </Button>
                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <OwnershipModal
          isOpen={isEditModalOpen}
          onClose={onEditModalClose}
          onSubmit={onEditConfirm}
          ownershipDTO={ownershipDTO}
          ownersList={ownersList}
          apartmentOwnerIds={apartmentOwnerIds}
        />
      </Td>
    </Tr>
  );
};

export default OwnershipListItem;
