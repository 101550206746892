import { Alert, AlertIcon, Stack } from '@chakra-ui/react';
import OMSApartmentsResource from 'api/oms-apartments';
import { CenterSpinner } from 'components/common/CenterSpinner';
import UnitPageHeader from 'components/oms/UnitPageHeader';
import { strings } from 'config/localization';
import { UnitTypeEnum } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { getUnitTypeName } from 'utils';
import ApartmentForm from './ApartmentForm';
import GarageForm from './GarageForm';
import RestaurantForm from './RestaurantForm';

interface EditUnitParams {
  id: string;
}

const EditUnit: React.FC = () => {
  const { id: apartmentId } = useParams<EditUnitParams>();
  const params = useLocation();
  const unitType = params.pathname.split('/')[4];
  const apartmentAPI = new OMSApartmentsResource();

  const {
    data: apartmentDetails,
    isLoading,
    isError,
  } = useQuery(
    `apartmentDetails-${apartmentId}`,
    () =>
      apartmentAPI
        .get(apartmentId)
        .then((res) => res.data.data)
        .catch((err) => {
          throw new Error(strings.cannot_get_apartment_details);
        }),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div>
        <CenterSpinner />
      </div>
    );
  } else if (isError || apartmentDetails?.type !== unitType) {
    return (
      <Alert status="error">
        <AlertIcon />
        {strings.cannot_get_apartment_details}
      </Alert>
    );
  }
  const { address, type } = apartmentDetails;
  const { name, addition } = address;
  let objectAddition = addition ? `, ${addition}` : '';
  let addr = `${name}${objectAddition}`;
  let breadcrumbName = `${addr},  ${getUnitTypeName(type)} ${
    type === UnitTypeEnum.APARTMENT
      ? apartmentDetails.stwe_number
      : type === UnitTypeEnum.GARAGE
      ? apartmentDetails.garage_number
      : ''
  }`;

  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {breadcrumbName}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <UnitPageHeader breadcrumb={breadcrumbName} />

        {type === UnitTypeEnum.APARTMENT && (
          <ApartmentForm data={apartmentDetails} />
        )}
        {type === UnitTypeEnum.RESTAURANT && (
          <RestaurantForm data={apartmentDetails} />
        )}
        {type === UnitTypeEnum.GARAGE && <GarageForm data={apartmentDetails} />}
      </Stack>
    </>
  );
};

export default EditUnit;
