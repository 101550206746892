import { Stack } from '@chakra-ui/react';
import UnitPageHeader from 'components/oms/UnitPageHeader';
import { strings } from 'config/localization';
import React from 'react';
import { Helmet } from 'react-helmet';
import GarageForm from './GarageForm';

const CreateGarage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {strings.add_new_garage}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <UnitPageHeader breadcrumb={strings.add_new_garage} />
        <GarageForm />
      </Stack>
    </>
  );
};

export default CreateGarage;
