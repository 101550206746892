import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import FAQResource from 'api/faq';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import { AxiosError, AxiosResponse } from 'axios';
import FaqForm, { FaqFormData } from 'components/faq/FaqForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { FAQGroupSchema } from 'constants/schema';
import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { buildFormData } from 'utils';
import history from 'utils/history';

interface AddFaqParams {
  id: string;
}

const AddFaq: React.FC = () => {
  const { id: contactID } = useParams<AddFaqParams>();

  const form = useRef() as React.MutableRefObject<HTMLFormElement>;
  const methods = useForm<FaqFormData>();
  const toast = useToast();
  let faqAPI = new FAQResource();
  let faqGroupAPI = new FAQGroupResource();

  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const createFAQ = useMutation<
    AxiosResponse<FaqFormData>,
    AxiosError,
    FormData
  >((data) => faqAPI.store(data));

  const { data: faqGroups } = useQuery<FAQGroupSchema[]>(
    ['list-faq-groups'],
    async () => {
      const res = await faqGroupAPI.list();
      return res.data.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );
  const onSubmit = async (data: FaqFormData) => {
    const formData = buildFormData(data);
    setIsLoading(true);
    createFAQ.mutate(formData, {
      onSuccess: () => {
        toast({
          title: `${strings.faq} ${
            contactID ? strings.has_been_updated : strings.has_been_created
          }`,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.faq.faqList.list);
      },
      onError: () => {
        setErrMsg(
          `${strings.faq} ${
            contactID
              ? strings.has_not_been_updated
              : strings.has_not_been_created
          }`
        );
        setIsLoading(false);
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.faq} | {strings.add_faq}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
              {strings.faq}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.add}>
              {contactID ? strings.edit_faq : strings.add_faq}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {contactID ? strings.edit_faq : strings.add_faq}
          </Heading>
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form ref={form} onSubmit={methods.handleSubmit(onSubmit)}>
              {errMsg && (
                <Alert status="error">
                  <AlertIcon />
                  {errMsg}
                </Alert>
              )}
              <FaqForm faqGroups={faqGroups} />
              <ButtonGroup pt="4" justifyContent="flex-end" w="100%">
                <RouterLink to={routes.faq.faqList.list}>
                  <Button
                    size="lg"
                    colorScheme="primary"
                    variant="outline"
                    isDisabled={isLoading}>
                    {strings.cancel}
                  </Button>
                </RouterLink>
                <Button
                  size="lg"
                  colorScheme="primary"
                  type="submit"
                  isLoading={isLoading}>
                  {!contactID ? strings.add_faq : strings.edit_faq}
                </Button>
              </ButtonGroup>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default AddFaq;
