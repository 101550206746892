import {
  Box,
  Checkbox,
  Flex,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import theme from 'config/theme';
import { ApartmentInfo, GarageInfo, UnitTypeEnum } from 'constants/schema';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { renderFullName } from 'utils';

interface Props {
  selectedUnits: (ApartmentInfo | GarageInfo)[];
  addOwnershipId: (val: number) => void;
  ownerShipIds: number[];
  stepTwoDone: boolean;
  stepOneDone: boolean;
}

const OwnerShipDocumentsSelectionOwnersColumn: React.FC<Props> = (props) => {
  const {
    selectedUnits,
    addOwnershipId,
    ownerShipIds,
    stepTwoDone,
    stepOneDone,
  } = props;

  return !stepTwoDone ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="500px">
      <Flex direction="column" align="center" justify="center">
        <BiInfoCircle size={44} color={theme.colors.grey['400']} />
        <Box textAlign="center" mt="6" maxW="300px" color="grey.400">
          {!stepOneDone
            ? strings.document_upload_info
            : strings.document_upload_info2}
        </Box>
      </Flex>
    </Box>
  ) : (
    <>
      {selectedUnits?.map((apartmentData) => {
        return (
          <TableContainer key={apartmentData.id} mt={2}>
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <Stack direction={['column', 'row']} spacing="24px">
                      <Box>
                        <Text color="paragraph">
                          {apartmentData.address.name}
                        </Text>
                      </Box>
                      <Spacer />

                      <Box>
                        <Stack direction={['column', 'row']} spacing="16px">
                          <Text color="paragraph">
                            {strings.getString(apartmentData.type)}
                          </Text>
                          <Text color="paragraph">
                            {apartmentData.type === UnitTypeEnum.APARTMENT
                              ? apartmentData.apartment_number
                              : apartmentData.garage_number}
                          </Text>
                        </Stack>
                      </Box>
                    </Stack>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {apartmentData?.currentOwners?.map((item) => {
                  return (
                    <Tr key={item.id}>
                      <Td>
                        <Checkbox
                          size="lg"
                          isChecked={ownerShipIds.includes(item.pivot.id)}
                          onChange={() => addOwnershipId(item.pivot.id)}>
                          {renderFullName(item.first_name, item.last_name)}
                        </Checkbox>
                      </Td>
                    </Tr>
                  );
                })}

                {apartmentData?.pastOwners?.map((item) => {
                  return (
                    <Tr key={item.id}>
                      <Td>
                        <Flex>
                          <Checkbox
                            size="lg"
                            isChecked={ownerShipIds.includes(item.pivot.id)}
                            onChange={() => addOwnershipId(item.pivot.id)}>
                            {renderFullName(item.first_name, item.last_name)}
                          </Checkbox>
                          <Spacer />
                          <Tag size={'md'} variant="solid" bg="red.100" ml={2}>
                            {strings.previous_owner}
                          </Tag>
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        );
      })}
    </>
  );
};

export default OwnerShipDocumentsSelectionOwnersColumn;
