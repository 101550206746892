import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
} from '@chakra-ui/react';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { GarageInfo, UnitInfoGarage, UnitTypeEnum } from 'constants/schema';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import UnitForm from '../UnitForm';

const GARAGE_TYPE = [
  'single_garage',
  'indoor_parking_space',
  'outdoor_parking_lot',
] as const;

interface Props {
  data?: GarageInfo;
}

const GarageForm: React.FC<Props> = (props: Props) => {
  const { data } = props;

  const methods = useForm<UnitInfoGarage>();

  // Form state
  const {
    register,
    formState: { errors },
    setValue,
  } = methods;

  useEffect(() => {
    setValue('type', UnitTypeEnum.GARAGE);
    if (!data) return;
    const { garage_number, garage_quota, garage_type } = data;

    setValue('garage_number', garage_number);
    setValue('garage_quota', garage_quota);
    setValue('garage_type', garage_type);
  }, [data, setValue]);

  return (
    <FormProvider {...methods}>
      <form>
        <UnitForm unitType={UnitTypeEnum.GARAGE} data={data}>
          <GridItem>
            <FormControl isInvalid={!!errors?.garage_type} isRequired>
              <FormLabel>{strings.garage_type}</FormLabel>
              <CustomChakraSelect
                {...register('garage_type', {
                  required: strings.garage_type_is_required,
                })}
                placeholder={strings.garage_type}>
                {GARAGE_TYPE.map((ele) => (
                  <option key={ele} value={ele}>
                    {strings.getString(ele)}
                  </option>
                ))}
              </CustomChakraSelect>
              <FormErrorMessage>
                <>{errors?.garage_type && errors?.garage_type?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.garage_quota} isRequired>
              <FormLabel>{strings.quota}</FormLabel>
              <Input
                type="number"
                {...register('garage_quota', {
                  required: strings.garage_quota_is_required,
                })}
                placeholder={strings.enter_quota}
              />
              <FormErrorMessage>
                <>{errors?.garage_quota && errors?.garage_quota?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!errors?.garage_number} isRequired>
              <FormLabel>{strings.garage_number}</FormLabel>
              <Input
                type="text"
                {...register('garage_number', {
                  required: strings.garage_number_is_required,
                })}
                placeholder={strings.enter_garage_number}
              />
              <FormErrorMessage>
                <>{errors?.garage_number && errors?.garage_number?.message}</>
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </UnitForm>
      </form>
    </FormProvider>
  );
};

export default GarageForm;
