import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { strings } from 'config/localization';
import { Owner, OwnershipDTO } from 'constants/schema';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { renderFullName } from 'utils';

type OwnershipFormProps = {
  ownersList: Owner[];
  ownerDropdownDefaultValue?: {
    value: number;
    label: string;
  };
  defaultOwnership?: OwnershipDTO;
  apartmentOwnerIds: number[];
  form: UseFormReturn<OwnershipFormData>;
};

export type OwnershipFormData = {
  owner: {
    value: number;
    label: string;
  };
} & (
  | {
      is_current: 'true';
      owner_since: string;
    }
  | {
      is_current: 'false';
    }
);

const OwnershipForm = (props: OwnershipFormProps) => {
  const { ownersList, form, defaultOwnership, apartmentOwnerIds } = props;
  const { formState, register } = form;

  // Owners which already own the apartment can not be added again.
  const selectableOwnersList =
    ownersList?.filter((owner) => !apartmentOwnerIds.includes(owner.id)) ?? [];
  const ownerDropdownOptions = selectableOwnersList?.map((owner) => ({
    value: owner.id,
    label: renderFullName(owner.first_name, owner.last_name) ?? owner.name,
  }));
  const owner = ownersList.find(
    (owner) => owner.id === defaultOwnership?.owner_id
  );
  const defaultOwnershipDropdownOption = {
    value: defaultOwnership?.owner_id,
    label: renderFullName(owner?.first_name, owner?.last_name),
  };

  return (
    <FormProvider {...form}>
      <form>
        <VStack spacing="36px">
          <Controller
            name="owner"
            defaultValue={
              defaultOwnership ? defaultOwnershipDropdownOption : null
            }
            rules={{
              required: strings.required_owner,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl isInvalid={!!error} id="owner" isRequired>
                <FormLabel>{strings.select_owner}</FormLabel>
                <Select
                  {...field}
                  isSearchable={true}
                  isDisabled={!!defaultOwnership}
                  placeholder={strings.enter_owner}
                  options={ownerDropdownOptions}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                <FormErrorMessage>
                  <>
                    {formState.errors?.owner &&
                      formState.errors?.owner?.message}
                  </>
                </FormErrorMessage>
              </FormControl>
            )}></Controller>
          <Controller
            name="is_current"
            defaultValue={
              defaultOwnership
                ? defaultOwnership.is_current
                  ? 'true'
                  : 'false'
                : null
            }
            rules={{
              required: strings.required_ownership,
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl isInvalid={!!error} isRequired>
                <FormLabel>{strings.select_ownership}</FormLabel>
                <RadioGroup {...field}>
                  <VStack align="flex-start" spacing="12px">
                    <Radio value="true">{strings.current_owner}</Radio>
                    {form.getValues().is_current === 'true' ? (
                      <FormControl
                        isInvalid={!!form.getFieldState('owner_since').error}
                        isRequired>
                        <FormLabel>{strings.owner_since}</FormLabel>
                        <Input
                          type="date"
                          isRequired
                          defaultValue={
                            defaultOwnership?.is_current
                              ? defaultOwnership.owner_since
                              : undefined
                          }
                          {...register('owner_since', {
                            required: strings.required_owner_since,
                          })}></Input>
                        <FormErrorMessage>
                          <>
                            {form.getFieldState('owner_since').error &&
                              form.getFieldState('owner_since').error?.message}
                          </>
                        </FormErrorMessage>
                      </FormControl>
                    ) : null}
                    <Radio value="false">{strings.previous_owner}</Radio>
                  </VStack>
                </RadioGroup>
                <FormErrorMessage>
                  <>
                    {formState.errors?.is_current &&
                      formState.errors?.is_current?.message}
                  </>
                </FormErrorMessage>
              </FormControl>
            )}></Controller>
        </VStack>
      </form>
    </FormProvider>
  );
};

export default OwnershipForm;
