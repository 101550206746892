import { useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';

const useCustomToast = () => {
  const toast = useToast();

  const showErrorToast = () =>
    toast({
      title: strings.error,
      status: 'error',
      isClosable: true,
    });

  return { showErrorToast };
};

export default useCustomToast;
