import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  DMS_OWNER_LIST_WITH_DOCUMENTS_COUNT,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import { Count, DataWrapper, DocumentOwner } from 'constants/schema';
import useCustomToast from 'hooks/useCustomToast';
import { useFilter } from 'hooks/useFilter';
import useWordSearch from 'hooks/useWordSearch';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { renderFullName } from 'utils';

type FilterParams = {
  currentPage: number;
  pageSize: number;
  keyword: string;
  address: string;
};

type OwnersListQueryParams = {
  keyword: string;
  page: number;
  limit: number;
  address: string;
};

const ObjectList: React.FC = () => {
  const {
    loading: ownerListLoading,
    result: ownerQuery,
    setQuery,
    error,
  } = useWordSearch<
    DataWrapper<(DocumentOwner & Count)[]>,
    OwnersListQueryParams
  >(`${DMS_OWNER_LIST_WITH_DOCUMENTS_COUNT}/`);

  const ownerList = ownerQuery?.data;

  const history = useHistory();
  const { searchValues, updateUrl } = useFilter();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    keyword: searchValues?.keyword ?? '',
    address: searchValues?.address ?? '',
  });

  const { showErrorToast } = useCustomToast();

  useEffect(() => {
    if (error) {
      showErrorToast();
    }
  }, [error, showErrorToast]);

  useEffect(() => {
    setQuery({
      keyword: filterParams.keyword,
      page: filterParams.currentPage,
      limit: filterParams.pageSize,
      address: filterParams.address,
    });
    updateUrl(filterParams);
  }, [setQuery, updateUrl, filterParams]);

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      address: '',
    }));

    history.replace(location.pathname);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));

    updateUrl({ [name]: value });
  };

  const handleRowClick = (id: number) => {
    history.push(routes.dms.owner.ownerDocuments.replace(':id', String(id)));
  };

  const renderContent = () => {
    if (ownerListLoading) {
      return <TableSkeletonLoader rows={6} cols={3} />;
    }

    return ownerList?.map((item) => {
      return (
        <Tr
          key={item.id}
          onClick={() => handleRowClick(item.id)}
          cursor="pointer">
          <Td>{renderFullName(item.first_name, item.last_name)}</Td>

          <Td>
            {item.details?.street},&nbsp;{item.details?.house_number},&nbsp;
            {item.details?.zip_code},&nbsp;{item.details?.town}
          </Td>
          <Td isNumeric>{item.total}</Td>
        </Tr>
      );
    });
  };

  const renderTable = () => {
    return (
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th w={'45%'}>
                <Text color="paragraph">{strings.owner}</Text>
              </Th>
              <Th>
                <Text color="paragraph">{strings.address}</Text>
              </Th>
              <Th isNumeric>
                <Text color="paragraph">{strings.total}</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>{renderContent()}</Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.document} | {strings.document_owner}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.owner.list}>
              {strings.document}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.dms.owner.list}>
              {strings.document_owner}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.document_owner}
          </Heading>
        </Flex>

        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          defaultIndex={0}
          boxShadow="box">
          <AccordionItem>
            <>
              <h2>
                <AccordionButton p="4">
                  <Box flex="1" textAlign="center">
                    <Flex justify="space-between">
                      <Heading fontSize="18px" fontWeight="medium">
                        <Icon as={BiFilter} /> {strings.filter}
                      </Heading>
                    </Flex>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel padding="0">
                <Stack sx={wrapperStyles}>
                  <Stack
                    direction={['column', 'column', 'row']}
                    spacing="4"
                    alignItems={['initial', 'initial', 'flex-end']}>
                    <Grid
                      gap="4"
                      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                      flex="1">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.owner}</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              name="keyword"
                              value={filterParams.keyword}
                              onChange={handleInputChange}
                              placeholder={`${strings.last_name}, ${strings.first_name}`}
                              size="lg"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.address}</FormLabel>
                          <InputGroup>
                            <Input
                              type="text"
                              name="address"
                              value={filterParams.address}
                              onChange={handleInputChange}
                              placeholder={`${strings.street} ${strings.house_number}, ${strings.zip_code} ${strings.town}`}
                              size="lg"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Button
                      size="lg"
                      w="fit-content"
                      colorScheme="primary"
                      variant="outline"
                      onClick={handleReset}>
                      {strings.reset_filter}
                    </Button>
                  </Stack>
                </Stack>
              </AccordionPanel>
            </>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <Flex align="center" justify="space-between" mb={4}>
            <Heading as="h2" size="md" mr={4}>
              {strings.owner}
            </Heading>
          </Flex>

          <Box>{renderTable()}</Box>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={{ data: ownerQuery, isLoading: ownerListLoading }}
        />
      </Stack>
    </>
  );
};

export default ObjectList;
