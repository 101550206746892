import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  EditOwnersDocument,
  ObjectDocumentList,
  ObjectList,
  OwnersDocumentList,
  OwnersList,
  UploadOwnersDocument,
} from 'pages/dashboard/dms';
import {
  AddFaq,
  AddGroup,
  EditFaq,
  Faqs,
  GroupList,
} from 'pages/dashboard/faq';
import EditApartment from 'pages/dashboard/oms/Apartment/EditUnit';
import CreateObject from 'pages/dashboard/oms/Object/CreateObject';
import EditObject from 'pages/dashboard/oms/Object/EditObject';
import UnitList from 'pages/dashboard/oms/UnitList';
import { EditProfile, MyProfile } from 'pages/dashboard/Profile';
import { SplashScreen } from 'pages/dashboard/splashScreen';
import {
  CreateTicket,
  EditTicket,
  TicketBoard,
  TicketList,
} from 'pages/dashboard/ticket';
import ViewTicket from 'pages/dashboard/ticket/ViewTicket';
import UserLoginLogList from 'pages/dashboard/users/UserLoginLogList';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AddNewCareTaker,
  AddNewOwner,
  AddNewUser,
  CareTakerProfile,
  EditCareTaker,
  EditOwner,
  EditUser,
  OwnerProfile,
  UserList,
  UserProfile,
  UserRole,
  UserRoles,
} from '../pages/dashboard/users';
import RoleBasedRouting from './RoleBasedRouting';

import {
  ComposeMessage,
  EditNewsLetter,
  NewsLetterList,
} from 'pages/dashboard/newsletter';
import CreateApartment from 'pages/dashboard/oms/Apartment/CreateApartment';
import ObjektList from 'pages/dashboard/oms/ObjektList';
import CreateGarage from '../pages/dashboard/oms/Apartment/CreateGarage';

const PrivateRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={routes.dashboard}
        component={() => <Redirect to={routes.splash} />}
      />
      <Route exact path={routes.splash} component={SplashScreen} />
      <Route exact path={routes.profile.myProfile} component={MyProfile} />
      <Route exact path={routes.profile.edit} component={EditProfile} />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.list}
        component={UserList}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.add}
        component={AddNewUser}
        allowedPermissions={[PermissionRequest['manage:user']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.owner.add}
        component={AddNewOwner}
        allowedPermissions={[PermissionRequest['manage:owner']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.owner.edit}
        component={EditOwner}
        allowedPermissions={[PermissionRequest['manage:owner']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.owner.profile}
        component={OwnerProfile}
        allowedPermissions={[PermissionRequest['read:user']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.careTaker.add}
        component={AddNewCareTaker}
        allowedPermissions={[PermissionRequest['manage:caretaker']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.careTaker.edit}
        component={EditCareTaker}
        allowedPermissions={[PermissionRequest['manage:caretaker']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.careTaker.profile}
        component={CareTakerProfile}
        allowedPermissions={[PermissionRequest['read:user']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.edit}
        component={EditUser}
        allowedPermissions={[PermissionRequest['manage:user']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.roles.list}
        component={UserRoles}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.roles.view}
        component={UserRole}
        allowedPermissions={[PermissionRequest['read:user']]}
      />
      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.profile}
        component={UserProfile}
        allowedPermissions={[PermissionRequest['read:user']]}
      />

      <RoleBasedRouting
        serviceName="user-service"
        exact
        path={routes.users.loginLog.list}
        component={UserLoginLogList}
        allowedPermissions={[PermissionRequest['read:login-log']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.task.board}
        component={TicketBoard}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={routes.ticket.list.default}
        component={TicketList}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.view, routes.ticket.list.view]}
        component={ViewTicket}
        allowedPermissions={[PermissionRequest['read:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.create, routes.ticket.list.create]}
        component={CreateTicket}
        allowedPermissions={[PermissionRequest['create:ticket']]}
      />
      <RoleBasedRouting
        serviceName="ticket-service"
        exact
        path={[routes.ticket.task.edit, routes.ticket.list.edit]}
        component={EditTicket}
        allowedPermissions={[PermissionRequest['edit:ticket']]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.units.list}
        component={UnitList}
        allowedPermissions={[
          PermissionRequest['read:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={[routes.oms.units.add.apartment]}
        component={CreateApartment}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />

      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={[routes.oms.units.add.garage]}
        component={CreateGarage}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={[routes.oms.units.edit.apartment, routes.oms.units.edit.garage]}
        component={EditApartment}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.list}
        component={ObjektList}
        allowedPermissions={[
          PermissionRequest['read:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.add}
        component={CreateObject}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="oms-service"
        exact
        path={routes.oms.objects.edit}
        component={EditObject}
        allowedPermissions={[
          PermissionRequest['manage:oms'],
          PermissionRequest['is:owner'],
        ]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.list}
        component={Faqs}
        allowedPermissions={[PermissionRequest['read:faq']]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.add}
        component={AddFaq}
        allowedPermissions={[PermissionRequest['manage:faq']]}
      />
      <RoleBasedRouting
        serviceName="faq-list"
        exact
        path={routes.faq.faqList.edit}
        component={EditFaq}
        allowedPermissions={[PermissionRequest['manage:faq']]}
      />
      <RoleBasedRouting
        serviceName="group-faq"
        exact
        path={routes.faq.faqGroup.list}
        component={GroupList}
        allowedPermissions={[PermissionRequest['read:faq-group']]}
      />
      <RoleBasedRouting
        serviceName="group-faq"
        exact
        path={[routes.faq.faqGroup.add, routes.faq.faqGroup.edit]}
        component={AddGroup}
        allowedPermissions={[PermissionRequest['manage:faq-group']]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.owner.ownerDocuments}
        component={OwnersDocumentList}
        allowedPermissions={[PermissionRequest['read:dms-owner']]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.object.objectDocuments}
        component={ObjectDocumentList}
        allowedPermissions={[PermissionRequest['read:dms-ownership']]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.object.list}
        component={ObjectList}
        allowedPermissions={[PermissionRequest['read:dms-ownership']]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.owner.list}
        component={OwnersList}
        allowedPermissions={[PermissionRequest['read:dms-owner']]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.documents.upload}
        component={UploadOwnersDocument}
        allowedPermissions={[PermissionRequest['manage:dms-owner']]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.object.objectDocumentEdit}
        component={EditOwnersDocument}
        allowedPermissions={[
          PermissionRequest['manage:dms-owner'],
          PermissionRequest['manage:dms-ownership'],
        ]}
      />

      <RoleBasedRouting
        serviceName="owner-documents"
        exact
        path={routes.dms.owner.ownerDocumentEdit}
        component={EditOwnersDocument}
        allowedPermissions={[
          PermissionRequest['manage:dms-owner'],
          PermissionRequest['manage:dms-ownership'],
        ]}
      />

      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.list}
        component={NewsLetterList}
        allowedPermissions={[PermissionRequest['read:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.add}
        component={ComposeMessage}
        allowedPermissions={[PermissionRequest['manage:newsletter']]}
      />
      <RoleBasedRouting
        serviceName="newsletter-service"
        exact
        path={routes.newsletter.newsletters.edit}
        component={EditNewsLetter}
        allowedPermissions={[PermissionRequest['manage:newsletter']]}
      />

      <Route exact path="*" component={() => <Redirect to={routes.splash} />} />
    </Switch>
  );
};

export default PrivateRouter;
