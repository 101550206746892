import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Input,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { wrapperStyles } from 'assets/css/commonStyles';
import axios from 'axios';
import FormCancelButton from 'components/common/FormCancelButton';
import MultiFileUploadModal from 'components/common/MultiFileUploadModal';
import TicketForm, { TicketFormValues } from 'components/ticket/TicketForm';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  FileInfo,
  ReactSelectOptionSchema,
  TicketPriority,
} from 'constants/schema';
import useMultiFileUploadMutation from 'hooks/useMultiFileUploadMutation';
import React, { forwardRef, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { shallowEqual, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { checkSomePermissions } from 'utils/listInfo';
import useLoggedInUser from '../../../hooks/useLoggedInUser';
import { RootState } from '../../../store';

interface TicketCreateDTO {
  title: string;
  apartment_id?: number | ReactSelectOptionSchema<number> | null;
  assigned_user_id?: number | ReactSelectOptionSchema<number> | null;
  house_owner?: number | ReactSelectOptionSchema<number | null> | null;
  priority: TicketPriority | null;
  description?: string;
  files: FileInfo[];
  created_by: number;
  updated_by: number;
}

const defaultValues: TicketFormValues = {
  title: '',
  apartment_id: null,
  assigned_user_id: null,
  house_owner: null,
  priority: null,
  description: '',
  files: [],
};

const InputDatePicker = forwardRef((props) => {
  return <Input {...props} />;
});

InputDatePicker.displayName = 'InputDatePicker';

const CreateTicket: React.FC = () => {
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();

  const ticketAPI = new TicketResource();

  const methods = useForm<TicketFormValues>({ defaultValues });
  const { reset } = methods;
  const ticketForm = useRef() as React.MutableRefObject<HTMLFormElement>;
  const [isLoading, setIsLoading] = useState<string | null>();
  const [clearFileInputToggle, setClearFileInputToggle] =
    useState<boolean>(false); // Toggle this field to clear the file input (of child comp: Uploader)
  const { isOpen, onClose, onOpen } = useDisclosure();

  const [action, setAction] = useState('');

  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const isAllowedToEditAssignee = checkSomePermissions(userPermissions, [
    PermissionRequest['assign:ticket'],
  ]);

  const loggedInUser = useLoggedInUser();

  const createTicket = useMutation((data: TicketCreateDTO) =>
    ticketAPI.store(data)
  );

  const uploadFiles = useMutation((formData: FormData) =>
    ticketAPI.uploadToBucket(formData)
  );

  const showActionButton = () => {
    return (
      <ButtonGroup
        justifyContent="flex-end"
        flexDirection={['column-reverse', 'row']}
        spacing={[0, 2]}
        w="100%">
        <FormCancelButton
          size="lg"
          isLoading={isLoading === 'save' || isLoading === 'saveandcontinue'}
        />

        <Button
          colorScheme="primary"
          size="lg"
          mb="2"
          isLoading={isLoading === 'saveandcontinue'}
          isDisabled={isLoading === 'save' || uploadFiles.isLoading}
          onClick={methods.handleSubmit(handleCreateTicketAndContinue)}>
          {strings.save_and_create_new_ticket}
        </Button>
        <Button
          colorScheme="primary"
          mb="2"
          isLoading={isLoading === 'save'}
          isDisabled={isLoading === 'saveandcontinue' || uploadFiles.isLoading}
          type="button"
          size="lg"
          onClick={methods.handleSubmit(handleCreateTicket)}>
          {strings.save_now}
        </Button>
      </ButtonGroup>
    );
  };

  /**
   * Remove keys if no data
   */
  const removeEmptyEntries = (data: TicketFormValues) =>
    (Object.keys(data) as (keyof typeof data)[]).reduce((obj, key) => {
      if (data[key]) {
        const newVal = data[key];
        obj = newVal ? { ...obj, [key]: newVal } : obj;
      }
      return obj;
    }, {} as TicketFormValues);

  /**
   * Actual save ticket
   */
  const saveTicket = (
    formData: TicketCreateDTO,
    whichBtnClick: string,
    hasError: boolean
  ) => {
    createTicket.mutate(formData, {
      onSuccess: () => {
        toast({
          title: strings.ticket_created,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('tickets');

        if (!hasError) {
          if (whichBtnClick === 'saveNow') {
            history.push(routes.ticket.task.board);
          } else {
            handleReset();
            onClose();
          }
        }
      },
      onError: () => {
        toast({
          title: strings.ticket_error,
          status: 'error',
          isClosable: true,
        });
      },
      onSettled: () => {
        setIsLoading(() => null);
      },
    });
  };

  const { fileUploadStates, cancelMutations, ...fileUploadMutation } =
    useMultiFileUploadMutation({
      url: '/ticket/tickets/attachment',
    });

  /**
   * Create Ticket and redirect to index page
   */
  const handleCreateTicket = async (data: TicketFormValues) => {
    setIsLoading(() => 'save');
    // upload to Bucket First

    let files: FileInfo[] = [];
    let hasError = false;

    if (data.files?.length > 0) {
      onOpen();

      const result = (await fileUploadMutation.mutateAsync(data.files)) ?? [];

      files = result
        .filter((e) => e.status === 'fulfilled' && e.value.data.status)
        .map((e) => ({
          file_name: e.status === 'fulfilled' && e.value.data.file_name,
          mime_type: e.status === 'fulfilled' && e.value.data.mime_type,
          file_size: e.status === 'fulfilled' && e.value.data.file_size,
          file_path: e.status === 'fulfilled' && e.value.data.file_path,
        }));

      hasError =
        result.some((e) => e.status === 'rejected') &&
        !result.some(
          (e) => e.status === 'rejected' && axios.isCancel(e.reason)
        );
    }

    // Create Ticket
    let cleanData: TicketCreateDTO = {
      ...removeEmptyEntries(data),
      ...(data.apartment_id?.value
        ? { apartment_id: data.apartment_id?.value }
        : {}),
      ...(data.assigned_user_id?.value && isAllowedToEditAssignee
        ? { assigned_user_id: data.assigned_user_id?.value }
        : {}),
      ...(data.house_owner?.value
        ? { house_owner: data.house_owner?.value }
        : {}),
      created_by: loggedInUser.id,
      updated_by: loggedInUser.id,
      files,
    };

    setAction('saveNow');
    await saveTicket(cleanData, 'saveNow', hasError);
  };

  /**
   * Create Ticket and Continue
   */
  const handleCreateTicketAndContinue = async (data: TicketFormValues) => {
    // upload to Bucket First

    let files: FileInfo[] = [];
    let hasError = false;

    if (data.files?.length > 0) {
      onOpen();

      const result = (await fileUploadMutation.mutateAsync(data.files)) ?? [];

      files = result
        .filter((e) => e.status === 'fulfilled' && e.value.data.status)
        .map((e) => ({
          file_name: e.status === 'fulfilled' && e.value.data.file_name,
          mime_type: e.status === 'fulfilled' && e.value.data.mime_type,
          file_size: e.status === 'fulfilled' && e.value.data.file_size,
          file_path: e.status === 'fulfilled' && e.value.data.file_path,
        }));

      hasError =
        result.some((e) => e.status === 'rejected') &&
        !result.some(
          (e) => e.status === 'rejected' && axios.isCancel(e.reason)
        );
    }

    // Create Ticket
    let cleanData: TicketCreateDTO = {
      ...removeEmptyEntries(data),
      ...(data.apartment_id?.value
        ? { apartment_id: data.apartment_id?.value }
        : {}),
      ...(data.assigned_user_id?.value && isAllowedToEditAssignee
        ? { assigned_user_id: data.assigned_user_id?.value }
        : {}),
      house_owner: data.house_owner?.value ? data.house_owner.value : null,
      created_by: loggedInUser.id,
      updated_by: loggedInUser.id,
      files,
    };

    setAction('saveAndContinue');
    await saveTicket(cleanData, 'saveAndContinue', hasError);
  };

  const onCloseModal = () => {
    onClose();

    if (action === 'saveAndContinue') {
      handleReset();
    }
    if (action === 'saveNow') {
      history.push(routes.ticket.task.board);
    }
  };

  /**
   * Reset inputs
   */
  const handleReset = () => {
    reset(defaultValues);
    setClearFileInputToggle((prevState) => !prevState);
  };

  return (
    <>
      <Helmet>
        <title>
          {strings.ticket} | {strings.create_ticket}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.task.board}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.ticket.task.create}>
              {strings.add_new_ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between" flexWrap="wrap">
          <Heading size="lg" textTransform="capitalize">
            {strings.add_new_ticket}
          </Heading>
        </Flex>

        <FormProvider {...methods}>
          <form ref={ticketForm}>
            <Box mb={4}>
              <Stack sx={wrapperStyles}>
                <TicketForm
                  clearFileInputToggle={clearFileInputToggle}
                  isAllowedToEditAssignee={isAllowedToEditAssignee}
                />
              </Stack>
            </Box>
            {showActionButton()}
          </form>
        </FormProvider>
      </Stack>
      <MultiFileUploadModal
        isOpen={isOpen}
        onClose={onCloseModal}
        fileUploadStates={fileUploadStates}
        onCancelClick={cancelMutations}
      />
    </>
  );
};

export default CreateTicket;
