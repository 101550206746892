import {
  Alert,
  AlertIcon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { AxiosError, AxiosResponse } from 'axios';
import FormCancelButton from 'components/common/FormCancelButton';
import CareTakerForm from 'components/user/CareTakerForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { CareTakerSchema, DataWrapper } from 'constants/schema';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AddNewCareTaker: React.FC = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();
  const methods = useForm<CareTakerSchema>();
  const userAPI = new UserResource();

  const [errorMessage, setErrorMessage] = useState<CareTakerSchema>();

  const addCareTakerMutation = useMutation<
    AxiosResponse<DataWrapper<Partial<CareTakerSchema>>>,
    AxiosError,
    Partial<CareTakerSchema>
  >((data) => userAPI.createCareTaker(data), {
    onSuccess: (res) => {
      toast({
        title: strings.caretaker_created,
        description: res?.data?.data?.email,
        status: 'success',
        isClosable: true,
      });
      queryClient.invalidateQueries('userList');
      history.push(routes.users.list);
    },
    onError: (error) => {
      setErrorMessage(error?.response?.data?.errors);
    },
  });

  /*
   *set error message if Email is already taken.
   */
  const { setError } = methods;

  useEffect(() => {
    if (errorMessage?.email) {
      setError(
        'email',
        {
          type: 'manual',
          message: errorMessage?.email?.toString(),
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, [errorMessage, setError]);

  const onSubmit = (data: Partial<CareTakerSchema>) => {
    const mappedData = { ...data };
    addCareTakerMutation.mutate(mappedData);
  };

  return (
    <Stack direction="column" spacing="4">
      <Helmet>
        <title>
          {strings.user} | {strings.add_caretaker}
        </title>
      </Helmet>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.users.list}>
            {strings.user}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{strings.add_caretaker}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.add_caretaker}
        </Heading>
      </Flex>

      <Stack sx={wrapperStyles}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="4">
              {addCareTakerMutation.isError && (
                <Alert status="error">
                  <AlertIcon />
                  {strings.user_creation_failed}
                </Alert>
              )}
              <CareTakerForm />
              <ButtonGroup justifyContent="flex-end">
                <FormCancelButton
                  isLoading={addCareTakerMutation.isLoading}
                  size="lg"
                />

                <Button
                  colorScheme="primary"
                  type="submit"
                  size="lg"
                  isLoading={addCareTakerMutation.isLoading}>
                  {strings.add_caretaker}
                </Button>
              </ButtonGroup>
            </Stack>
          </form>
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default AddNewCareTaker;
