import { strings } from 'config/localization';
import {
  AnyUnitInfo,
  Document,
  ExtendedFileInfo,
  TicketInfoSchema,
  UnitTypeEnum,
} from 'constants/schema';
import moment from 'moment';

/**
 * Trunacte string and add ellipsis
 * @param str
 * @returns
 */
export function truncateString(str: string, count: number = 20) {
  if (str) return str.slice(0, count) + (str.length > count ? '...' : '');
}

export function capitalizeFirstLetter(str: string): string {
  const language = strings.getLanguage();

  if (language !== 'en') return str;

  if (!str) {
    return '';
  }
  return str && str[0].toUpperCase() + str.slice(1);
}

export const cleanData = (formData: Record<string, unknown>) => {
  let cleanObj: Record<string, unknown> = {};
  Object.keys(formData).forEach((val) => {
    const newVal = formData[val];
    cleanObj = newVal ? { ...cleanObj, [val]: newVal } : cleanObj;
  });
  return cleanObj;
};

/**
 * changeURL is a function for changing the url of the current page with the given parameters
 * used while navigating between pages
 * @param {any} formdata
 * @returns {string} urlstring
 */
export const changeURL = <T extends Record<string, unknown>>(data: T) => {
  const url_data: T = { ...data };

  const searchKeys: string[] = [];
  Object.entries(url_data).forEach(([key, value]) => {
    if (
      value !== '' &&
      value !== undefined &&
      value !== null &&
      !(
        (typeof value === 'string' || value instanceof Array) &&
        value.length === 0
      )
    )
      searchKeys.push(`${key}=${value}`) ?? '';
  });
  let searchURL = searchKeys.join('&');
  return searchURL;
};

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Get starting serial number (S.N.) based on current page and page size
 * The starting S.N. is dynamic with page
 *
 * page = 1, limit = 5 ->  staring SN = 1
 * page = 2, limit = 5 ->  staring SN = 6
 *
 * @param currentPage
 * @param pageSize
 * @returns
 */
export const getStartingSerialNumber = (
  currentPage: number,
  pageSize: number
) => {
  return currentPage * pageSize - (pageSize - 1);
};

export const buildFormData = <
  T extends Record<string, string | number | File | null>
>(
  model: T,
  form?: FormData,
  namespace = ''
): FormData => {
  let formData = form || new FormData();

  for (let propertyName in model) {
    if (
      !model.hasOwnProperty(propertyName) ||
      model[propertyName] === undefined
    )
      continue;
    let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    const value = model[propertyName];
    if (value instanceof Date) formData.append(formKey, value.toISOString());
    else if (value instanceof File) formData.append(formKey, value);
    else if (value instanceof Array) {
      value.forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        buildFormData(element, formData, tempFormKey);
      });
    } else if (value === null) {
      formData.append(formKey, 'null');
    } else if (
      typeof value === 'object' &&
      !((value as object) instanceof File)
    )
      buildFormData(value, formData, formKey);
    else formData.append(formKey, value.toString());
  }
  return formData;
};

export const noop = () => {
  // noop
};

export const renderMultipleOwnerNames = (
  items: {
    first_name: string;
    last_name: string;
    name?: string;
  }[]
) => {
  const names = items.map(
    (e: { first_name: string; last_name: string; name?: string }) =>
      renderFullName(e.first_name, e.last_name) ?? e.name
  );
  return names.join('; ');
};

export const getYearsList = (lastYear: number) => {
  const currentYear = new Date().getFullYear() + 1;

  return Array.from(
    { length: currentYear - lastYear + 1 },
    (_, index) => currentYear - index
  );
};

export const getCurrentYear = () => new Date().getFullYear();

export const GroupOfRecipientOptionLocalization = () => {
  const groupOfRecipientOptions = [{ value: 'owner', label: strings.owner }];
  return groupOfRecipientOptions;
};

export const localizeGroupOfRecipient = (group: string) => {
  const groupName = group.toLowerCase();
  const recipientGroupLocalize: { [key: string]: string } = {
    owner: strings.owner,
    guest: strings.guest,
  };
  return recipientGroupLocalize[groupName];
};

/**
 *
 * @param first First Name
 * @param last Last Name
 * @returns "Last name, First name"
 */
export const renderFullName = (
  first: string | undefined,
  last: string | undefined
) => {
  if (first && last) {
    return `${last}, ${first}`;
  }
  return last ?? first;
};

export const isDateValid = (date: string | null): boolean =>
  !!(date && moment(date).isValid());

export const getUnitLabel = (info: AnyUnitInfo) => {
  if (!info?.address) return '';

  const addition = info.address.addition ? ', ' + info.address.addition : '';

  return `${info.address.name}${addition}, ${strings.getString(info.type)} ${
    info.type === UnitTypeEnum.APARTMENT
      ? info.stwe_number
      : info.type === UnitTypeEnum.GARAGE
      ? info.garage_number
      : null
  }`;
};

export const getFileExtension = (fileName: string) => {
  const lastIndexOfDot = fileName.lastIndexOf('.');
  return fileName.substring(lastIndexOfDot);
};

export const formatFileNameDMS = (file: Document) => {
  return file.name + getFileExtension(file.file_name);
};

export const isCreatedFromOwnerOrCareTaker = (
  ticket: TicketInfoSchema
): boolean => {
  return (
    ticket.created_by_user?.role === 'caretaker' ||
    ticket.created_by_user?.role === 'owner' ||
    ticket.remarks === 'DELETED USER'
  );
};

export const getTicketTitle = (ticket: TicketInfoSchema) => {
  const createdFromOwner = isCreatedFromOwnerOrCareTaker(ticket);
  const ticketTitle = ticket?.title ?? '';
  return createdFromOwner
    ? truncateString(
        strings.getString(ticketTitle.toLowerCase()) ?? ticketTitle,
        20
      )
    : truncateString(ticketTitle, 20);
};

export const formatFileNameTicket = (file: ExtendedFileInfo) => {
  return file.file_name + getFileExtension(file.file_path);
};

export const getUnitTypeName = (type: UnitTypeEnum) => {
  return strings.getString(type);
};
