import { Link, ListItem } from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { subMenuDataType } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import { checkPermissions, checkSomePermissions } from 'utils/listInfo';
import { RootState } from '../../store';

const linkStyles = {
  d: 'block',
  rounded: 'md',
  p: '2',
  whiteSpace: 'nowrap',
  _hover: {
    bg: 'rgba(255,255,255, 0.1)',
  },
};

const activeLinkStyles = {
  ...linkStyles,
  p: '2',
  bg: 'rgba(255,255,255, 0.2)',
  shadow: 'box',
};

function getRouteStyle(path: string) {
  let match = matchPath(location.pathname, {
    path: path,
  });
  return match?.isExact ? activeLinkStyles : linkStyles;
}

type DisplaySubLinksType = {
  subMenuData: subMenuDataType[];
};
const DisplaySubLinks: React.FC<DisplaySubLinksType> = (props) => {
  const { subMenuData } = props;

  return (
    <>
      {subMenuData.map((item) => {
        return (
          item.isPermitted && (
            <ListItem key={item.id}>
              <Link
                sx={getRouteStyle(item.link)}
                onClick={() => {
                  item.checkPermission();
                }}>
                {strings[item.text]}
              </Link>
            </ListItem>
          )
        );
      })}
    </>
  );
};

export const UserSubMenuList: React.FC = () => {
  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const checkUserListPermission = useCheckPermission(
    [PermissionRequest['read:user']],
    routes.users.list
  );
  const checkRolesListPermission = useCheckPermission(
    [PermissionRequest['read:user']],
    routes.users.roles.list
  );
  const checkLoginLogPermission = useCheckPermission(
    [PermissionRequest['read:login-log']],
    routes.users.loginLog.list
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.users.base,
      text: 'user_list',
      checkPermission: checkUserListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:user'],
      ]),
    },
    {
      id: 2,
      link: routes.users.roles.base,
      text: 'user_roles',
      checkPermission: checkRolesListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:user'],
      ]),
    },
    {
      id: 3,
      link: routes.users.loginLog.base,
      text: 'user_login_log',
      checkPermission: checkLoginLogPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:login-log'],
      ]),
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const TicketSubMenuList: React.FC = () => {
  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const checkTicketBoardPermission = useCheckPermission(
    [PermissionRequest['read:ticket']],
    routes.ticket.task.board
  );
  const checkTicketListPermission = useCheckPermission(
    [PermissionRequest['read:ticket']],
    routes.ticket.list.default
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.ticket.task.base,
      text: 'ticket_board',
      checkPermission: checkTicketBoardPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:ticket'],
      ]),
    },
    {
      id: 2,
      link: routes.ticket.list.base,
      text: 'ticket_list',
      checkPermission: checkTicketListPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:ticket'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const OmsSubMenuList: React.FC = () => {
  const checkUnitListPermission = useCheckPermission(
    [PermissionRequest['read:oms'], PermissionRequest['is:owner']],
    routes.oms.units.list,
    undefined,
    'some'
  );
  const checkObjectListPermission = useCheckPermission(
    [PermissionRequest['read:oms'], PermissionRequest['is:owner']],
    routes.oms.objects.list,
    undefined,
    'some'
  );
  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );
  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.oms.units.base,
      text: 'manage_units',
      checkPermission: checkUnitListPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:oms'],
        PermissionRequest['is:owner'],
      ]),
    },
    {
      id: 2,
      link: routes.oms.objects.base,
      text: 'manage_objects',
      checkPermission: checkObjectListPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:oms'],
        PermissionRequest['is:owner'],
      ]),
    },
  ];
  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const FAQSubMenuList: React.FC = () => {
  const checkFAQServicePermission = useCheckPermission(
    [PermissionRequest['read:faq']],
    routes.faq.faqList.list
  );

  const checkFAQGroupServicePermission = useCheckPermission(
    [PermissionRequest['read:faq-group']],
    routes.faq.faqGroup.list
  );

  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.faq.faqList.base,
      text: 'faq',
      checkPermission: checkFAQServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:faq'],
      ]),
    },
    {
      id: 2,
      link: routes.faq.faqGroup.base,
      text: 'group',
      checkPermission: checkFAQGroupServicePermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:faq-group'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const DMSSubMenuList: React.FC = () => {
  const checkDMSUploadPermission = useCheckPermission(
    [
      PermissionRequest['manage:dms-ownership'],
      PermissionRequest['manage:dms-owner'],
    ],
    routes.dms.documents.upload,
    undefined,
    'some'
  );

  const checkOwnerDMSServicePermission = useCheckPermission(
    [
      PermissionRequest['read:dms-owner'],
      PermissionRequest['manage:dms-owner'],
    ],
    routes.dms.owner.list,
    undefined,
    'some'
  );

  const checkOwnerShipDMSServicePermission = useCheckPermission(
    [
      PermissionRequest['read:dms-ownership'],
      PermissionRequest['manage:dms-ownership'],
    ],
    routes.dms.object.list,
    undefined,
    'some'
  );

  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.dms.documents.base,
      text: 'document_upload',
      checkPermission: checkDMSUploadPermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['manage:dms-ownership'],
        PermissionRequest['manage:dms-owner'],
      ]),
    },
    {
      id: 2,
      link: routes.dms.owner.base,
      text: 'document_owner',
      checkPermission: checkOwnerDMSServicePermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:dms-owner'],
        PermissionRequest['manage:dms-owner'],
      ]),
    },
    {
      id: 3,
      link: routes.dms.object.base,
      text: 'document_units',
      checkPermission: checkOwnerShipDMSServicePermission,
      isPermitted: checkSomePermissions(userPermissions, [
        PermissionRequest['read:dms-ownership'],
        PermissionRequest['manage:dms-ownership'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};

export const NewsletterSubMenuList: React.FC = () => {
  const checkNewsletterPermission = useCheckPermission(
    [PermissionRequest['read:newsletter']],
    routes.newsletter.newsletters.list
  );

  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const subMenuData: subMenuDataType[] = [
    {
      id: 1,
      link: routes.newsletter.newsletters.list,
      text: 'newsletter_overview',
      checkPermission: checkNewsletterPermission,
      isPermitted: checkPermissions(userPermissions, [
        PermissionRequest['read:newsletter'],
      ]),
    },
  ];

  return <DisplaySubLinks subMenuData={subMenuData} />;
};
