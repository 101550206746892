import { useCallback, useEffect, useState } from 'react';

const useImageSlide = (total: number) => {
  const [imageIndex, setImageIndex] = useState<number>(0);

  const onIncrement = useCallback(() => {
    setImageIndex((prev) => {
      if (prev < total - 1) {
        return prev + 1;
      } else {
        return 0;
      }
    });
  }, [total]);
  const onDecrement = useCallback(() => {
    setImageIndex((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return total - 1;
      }
    });
  }, [total]);

  useEffect(() => {
    // let key = initialIndex;
    const keyHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        onDecrement();
      }
      if (e.key === 'ArrowRight') {
        onIncrement();
      }
    };

    let xDown: number | null = null;
    let yDown: number | null = null;

    function handleTouchStart(evt: TouchEvent) {
      const firstTouch = evt.touches[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt: TouchEvent) {
      if (!xDown || !yDown) {
        return;
      }

      let xUp = evt.changedTouches[0].clientX;
      let yUp = evt.changedTouches[0].clientY;

      let xDiff = xDown - xUp;
      let yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          onIncrement();
        } else {
          onDecrement();
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }

    document.addEventListener('keydown', keyHandler);
    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchend', handleTouchMove, false);

    return () => {
      document.removeEventListener('keydown', keyHandler);
      document.removeEventListener('touchstart', handleTouchStart, false);
      document.removeEventListener('touchend', handleTouchMove, false);
    };
  }, [onDecrement, onIncrement]);

  return { imageIndex, onIncrement, onDecrement, setImageIndex };
};

export default useImageSlide;
