import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  SystemStyleObject,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import { strings } from 'config/localization';
import { ApartmentInfo, GarageFeatures } from 'constants/schema';
import React, { Fragment, useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { BiTrashAlt } from 'react-icons/bi';
import { IoIosAddCircle } from 'react-icons/io';

const garageStyle: SystemStyleObject = {
  gap: 4,
};

type FormValues = {
  is_garage: boolean;
  garage_features: GarageFeatures[];
};
interface Props {
  data?: ApartmentInfo | null;
}

const ApartmentGarageForm: React.FC<Props> = (props) => {
  const { data } = props;
  const [showGarage, setShowGarage] = useState(!!data?.is_garage);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const methods = useFormContext<FormValues>();

  // Form state
  const {
    control,
    register,
    formState: { errors },
    setValue,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'garage_features',
  });
  const garageLength = useWatch({
    name: 'garage_features',
    control,
  });

  useEffect(() => {
    setValue('is_garage', showGarage);
  }, [setValue, showGarage]);

  const addGarageLine = () => {
    append({
      id: null,
      garage_type: '',
      garage_no: '',
      garage_quota: '',
      qty: null,
    });
  };

  const onShowGarage = () => {
    if (!showGarage) {
      setShowGarage(true);
      if (garageLength.length === 0) {
        append({
          id: null,
          garage_type: '',
          garage_no: '',
          garage_quota: '',
          qty: null,
        });
      }
    } else {
      onOpen();
    }
  };

  const confirmRemoveGarage = () => {
    setShowGarage(false);
    const indices = Array.from(
      { length: garageLength.length },
      (_, index) => index
    );
    remove(indices);
    onClose();
  };

  return (
    <form>
      <Stack gap={4}>
        <Checkbox
          width="fit-content"
          size="lg"
          onChange={onShowGarage}
          isChecked={showGarage}>
          <Text fontSize="md">{strings.garage}</Text>
        </Checkbox>
        {showGarage && (
          <Stack gap={2}>
            <Grid templateColumns="repeat(5, auto)" sx={garageStyle}>
              <FormControl isRequired>
                <FormLabel margin={0}>{strings.garage_type}</FormLabel>
              </FormControl>
              <FormControl>
                <FormLabel margin={0}>{strings.quantity}</FormLabel>
              </FormControl>
              <FormControl>
                <FormLabel margin={0}>{strings.garage_number}</FormLabel>
              </FormControl>
              <FormControl isRequired>
                <FormLabel margin={0}>{strings.garage_quota}</FormLabel>
              </FormControl>
              <Box w="14px"></Box>
              {fields.map((a, index) => {
                return (
                  <Fragment key={a.id}>
                    <FormControl
                      isInvalid={
                        !!errors?.garage_features?.[index]?.garage_type
                      }
                      isRequired>
                      <CustomChakraSelect
                        sx={{ color: 'gray.400' }}
                        {...register(`garage_features.${index}.garage_type`, {
                          required: strings.garage_type_is_required,
                        })}
                        placeholder={strings.select_garage_type}>
                        <option value="single_garage">
                          {strings.single_garage}
                        </option>
                        <option value="indoor_parking_space">
                          {strings.indoor_parking_space}
                        </option>
                        <option value="outdoor_parking_lot">
                          {strings.outdoor_parking_lot}
                        </option>
                      </CustomChakraSelect>
                      <FormErrorMessage>
                        <>
                          {errors?.garage_features?.[index]?.garage_type &&
                            errors?.garage_features?.[index]?.garage_type
                              ?.message}
                        </>
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors?.garage_features?.[index]?.qty}>
                      <Input
                        type="number"
                        {...register(`garage_features.${index}.qty`, {
                          valueAsNumber: true,
                        })}
                        placeholder={strings.enter_quantity}
                      />
                      <FormErrorMessage>
                        <>
                          {errors?.garage_features?.[index]?.qty &&
                            errors?.garage_features?.[index]?.qty?.message}
                        </>
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={!!errors?.garage_features?.[index]?.garage_no}>
                      <Input
                        type="text"
                        {...register(`garage_features.${index}.garage_no`)}
                        placeholder={strings.enter_garage_number}
                      />
                      <FormErrorMessage>
                        <>
                          {errors?.garage_features?.[index]?.garage_no &&
                            errors?.garage_features?.[index]?.garage_no
                              ?.message}
                        </>
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!errors?.garage_features?.[index]?.garage_quota
                      }
                      isRequired>
                      <Input
                        type="text"
                        {...register(`garage_features.${index}.garage_quota`, {
                          required: strings.garage_quota_is_required,
                        })}
                        placeholder={strings.enter_garage_quota}
                      />
                      <FormErrorMessage>
                        <>
                          {errors?.garage_features?.[index]?.garage_quota &&
                            errors?.garage_features?.[index]?.garage_quota
                              ?.message}
                        </>
                      </FormErrorMessage>
                    </FormControl>

                    {index !== 0 ? (
                      <Icon
                        cursor="pointer"
                        as={BiTrashAlt}
                        onClick={() => remove(index)}
                      />
                    ) : (
                      <Box w="14px"></Box>
                    )}
                  </Fragment>
                );
              })}
            </Grid>
            <Flex
              alignItems="center"
              gap={2}
              p={1}
              pl={0}
              width="fit-content"
              color="primary.400"
              cursor="pointer"
              onClick={addGarageLine}>
              <Icon as={IoIosAddCircle} fontSize={25} />
              <Text>{strings.add_another_garage}</Text>
            </Flex>
          </Stack>
        )}
        <Modal isOpen={isOpen} isCentered onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.garage}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{strings.confirm_remove_garage}</ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  colorScheme="primary"
                  variant="outline"
                  onClick={onClose}>
                  {strings.cancel}
                </Button>
                <Button colorScheme="red" onClick={confirmRemoveGarage}>
                  {strings.delete}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Stack>
    </form>
  );
};

export default ApartmentGarageForm;
