/**
 Custom hook to check user permission.
 Returns true if user have given permission.
 Returns false if user does not have given permission.
 */

import { useSelector } from 'react-redux';
import { checkPermissions } from 'utils/listInfo';
import { RootState } from '../store';

const useIsPermitted = (allowedPermissions: string[]) => {
  const userPermission = useSelector(
    (state: RootState) => state.data.auth.permissions
  );
  let isPermitted = checkPermissions(userPermission, allowedPermissions);

  return isPermitted;
};

export default useIsPermitted;
