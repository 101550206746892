import { Button, ButtonGroup, Flex, Heading, Text } from '@chakra-ui/react';
import { NotFound } from 'assets/images';
import { strings } from 'config/localization';
import React from 'react';

export interface FallBackUIProps {}

const FallBackUI: React.FC<FallBackUIProps> = () => {
  return (
    <Flex
      width="100%"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center">
      <NotFound />
      <Heading as="h3" size="lg" mt={10} mb={3}>
        {strings.error_boundary_heading_text}
      </Heading>
      <Text fontSize="lg">{strings.error_boundary_paragraph_text}</Text>
      <ButtonGroup mt={10}>
        <Button
          colorScheme="primary"
          size="lg"
          onClick={() => {
            window.location.reload();
          }}>
          {strings.reload_page}
        </Button>
        <Button
          variant="outline"
          colorScheme="primary"
          size="lg"
          onClick={() => {
            window.location.href = '/splash';
          }}>
          {strings.take_me_home}
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default FallBackUI;
