import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import OwnerForm from 'components/user/OwnerForm';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { OwnerSchema } from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { renderFullName } from 'utils';

type OwnerProfileParams = {
  id: string;
};

const OwnerProfile: React.FC = () => {
  const { search } = useLocation();

  const { id } = useParams<OwnerProfileParams>();
  const userAPI = new UserResource();

  const userQuery = useQuery(
    [`user${id}-edit`, id],
    () => userAPI.getOwner(id).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );
  const methods = useForm<OwnerSchema>();

  const checkUserEditPermission = useCheckPermission(
    [PermissionRequest['manage:user']],
    routes.users.owner.edit.replace(':id', id.toString())
  );

  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  const ownerData = userQuery?.data?.data;

  return (
    <>
      <Helmet>
        <title>
          {strings.user} |
          {renderFullName(ownerData?.first_name, ownerData?.last_name)}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list + search}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {renderFullName(ownerData?.first_name, ownerData?.last_name)}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.owner_details}
          </Heading>
          {userQuery.data?.role !== 'owner' && (
            <Button
              size="lg"
              colorScheme="primary"
              type="button"
              onClick={checkUserEditPermission}>
              {strings.edit_owner}
            </Button>
          )}
        </Flex>
        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form>
              <Stack direction="column" spacing="4">
                <OwnerForm userData={ownerData} isViewOnly />
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default OwnerProfile;
