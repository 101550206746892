import { UnitTypeEnum, UnitTypes } from './schema';

export const ADMIN_OR_EMPLOYEE_ROLE_GROUP = 'service_center';

// API urls
export const OMS_OBJECT_SEARCH_API = `/oms/apartment-address`;
export const DMS_OWNER_SEARCH_API = `/dms/owner`;
export const DMS_APARTMENT_SEARCH_API = `/dms/apartment?only_owners_assigned=1`;
export const DMS_OWNER_LIST_WITH_DOCUMENTS_COUNT = `/dms/document-owner-ownership-count`;
export const DMS_OBJECT_LIST_WITH_DOCUMENTS_COUNT = `/dms/document-apartment-count`;
export const DMS_OWNER_DOCUMENT_LIST = `/dms/document?owner_id=`;
export const DMS_OBJECT_DOCUMENT_LIST = `/dms/document?apartment_id=`;
export const OMS_CARETAKERS_LIST = `/oms/caretakers`;

// Auth
export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expires_in';

// Pagination
export const DEFAULT_PAGE_SIZE = 15;
export const INITIAL_CURRENT_PAGE = 1;
export const PAGE_LIMITS = [5, 15, 30, 50, 100];
export const DEFAULT_REFETCH_TIME = 90000; //90 second

// Accepted Files
export const TICKET_ACCEPT_FILE_TYPE =
  'image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, .xlsx,.xls';
export const DMS_ACCEPT_FILE_TYPE = 'application/pdf';

// Themes
export const THEME_USER = 'theme-default';
export const THEME_TICKET = 'theme-purple';
export const THEME_DMS = 'theme-blue2';
export const THEME_OMS = 'theme-purple2';
export const THEME_FAQ = 'theme-green2';
export const THEME_DASHBOARD = 'theme-pink';
export const THEME_NEWS = 'theme-yellow';

export const imageCompressionOptions = {
  maxSizeMB: 5,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const message2FA =
  '2FA authentication enabled, Please enter the verification code.';
export const invalid2FA = 'Invalid Verification Code, Please try again.';

export enum ROLE_TYPES {
  ADMIN = 'admin',
  EMPLOYEE_WITH_TICKET_SUPPORT = 'employee_with_ticket_support',
  EMPLOYEE = 'employee',
  OWNER = 'owner',
  CARETAKER = 'caretaker',
}

export const DOCUMENT_TAGS = [
  'insurance_policy',
  'stwe_foundation_contract',
  'stwe_regulations',
  'house_rules',
  'audit_report',
  'power_of_attorney',
  'plans',
  'offer',
  'report',
  'locking_plan',
  'annual_invoice',
  'annual_report',
  'minutes',
  'investment_plan',
  'budget',
  'correspondence',
  'invitation',
] as const;

export type TagItem = (typeof DOCUMENT_TAGS)[number];

export const ADDRESS_FORWARDING_REASONS = [
  'guardianship',
  'temporary_change_of_address',
  'different_billing_address',
  'address_of_the_property',
] as const;
export const NEWSLETTER_ACCEPT_FILE_TYPE =
  'image/*,.doc,.docx,application/msword,.pdf';

export const objectTypeOptions = [
  {
    label: UnitTypeEnum.APARTMENT,
    value: UnitTypeEnum.APARTMENT,
  },
  {
    label: UnitTypeEnum.GARAGE,
    value: UnitTypeEnum.GARAGE,
  },
  // not relevant for now
  // {
  //   label: 'store',
  //   value: 'store',
  // },
  // {
  //   label: 'restaurant',
  //   value: 'restaurant',
  // },
] as const;

export const rolesData = [
  {
    id: 1,
    name: 'admin',
  },
  {
    id: 2,
    name: 'employee',
  },
  {
    id: 3,
    name: 'employee_with_ticket_support',
  },
] as const;

export const COMPANY_NAME = 'Treuhand & Immobilien Dala GmbH';
export const COMPANY_STREET = 'Kurparkstrasse';
export const COMPANY_HOUSE_NO = '10';
export const COMPANY_ZIP_CODE = '3954';
export const COMPANY_TOWN = 'Leukerbad';
export const COMPANY_COUNTRY = 'Schweiz';

export const UnitTypeOptions: Array<UnitTypes> = [
  UnitTypeEnum.APARTMENT,
  UnitTypeEnum.GARAGE,
];

export enum OMS_GET_ROLE_TYPES_NAME {
  OWNERS = 'owners',
  CARETAKERS = 'caretakers',
}
