import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import InternetStatus from 'components/alert/InternetStatus';
import Sidebar from 'components/layout/Sidebar';
import routes from 'constants/routes';
import React from 'react';
import { useLocation } from 'react-router-dom';
import PrivateRouter from 'router/PrivateRouter';

const Dashboard: React.FC = () => {
  const [isLargerThan1140] = useMediaQuery('(min-width: 1140px)');
  const location = useLocation();

  const isPaddingDisabled = location.pathname.includes(routes.splash);
  const dashboardPadding = isLargerThan1140 ? 8 : 4;

  return (
    <Flex maxH="100vh" overflow="hidden" position="relative">
      <Sidebar />
      <Box overflow="auto" flex="1">
        <Box p={isPaddingDisabled ? 0 : dashboardPadding} minH="100vh">
          <PrivateRouter />
        </Box>
      </Box>
      <InternetStatus />
    </Flex>
  );
};

export default Dashboard;
