import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
} from '@chakra-ui/react';

import UserCountriesResource from 'api/user-countries';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import CustomSelectCountry, {
  CountryOption,
  OptionType,
} from 'components/common/CustomSelectCountry';

import { strings } from 'config/localization';
import { OwnerSchema } from 'constants/schema';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { validateNumber, validEmail } from 'utils/validate';
import RenderSimpleInput from './RenderSimpleInput';
import TwoFaSwitch from './TwoFaSwitch';

interface Props {
  userData?: OwnerSchema;
  isViewOnly?: boolean;
}

const CareTakerForm: React.FC<Props> = (props) => {
  const { userData, isViewOnly } = props;
  const { register, formState } = useFormContext<OwnerSchema>();
  const { errors } = formState;

  const isSetToEdit = !!userData?.id;

  const countryAPI = new UserCountriesResource();

  const { data: countryList } = useQuery(
    'user-country-list',
    () => countryAPI.list().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const selectOptions: OptionType[] =
    countryList?.data?.map((option: CountryOption) => ({
      value: option.country_code,
      label: option.name,
    })) ?? [];

  return (
    <form>
      <Stack direction="row" align="start" spacing="4">
        <Grid
          gap="4"
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
          flex="1">
          <Box>
            <GridItem>
              <FormControl
                isInvalid={!!errors?.salutation}
                pointerEvents={isViewOnly ? 'none' : 'all'}>
                <FormLabel>{strings.salutation}</FormLabel>
                <CustomChakraSelect
                  isReadOnly={isViewOnly}
                  placeholder={strings.select_salutation}
                  size="lg"
                  defaultValue={userData?.salutation}
                  isRequired={false}
                  {...register('salutation')}>
                  <option value="mr">{strings.mr}</option>
                  <option value="ms">{strings.ms}</option>
                </CustomChakraSelect>
                <FormErrorMessage>
                  {errors?.salutation && errors?.salutation?.message}
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Box>

          {isSetToEdit && !isViewOnly ? (
            <GridItem justifySelf="right" mb="14">
              <Stack direction={['column', 'row']}>
                <FormLabel>{strings.FA_status}</FormLabel>
                <TwoFaSwitch
                  has2FaEnabled={userData.has_2fa_enabled}
                  userId={userData.id}
                />
              </Stack>
            </GridItem>
          ) : (
            <br />
          )}

          <RenderSimpleInput
            variableKey="last_name"
            label={strings.last_name}
            isRequired={true}
            isRequiredValidation={strings.required_last_name}
            isReadOnly={isViewOnly}
            defaultValue={userData?.last_name}
            placeholder={strings.enter_last_name}
          />

          <RenderSimpleInput
            variableKey="first_name"
            label={strings.first_name}
            isRequired={true}
            isRequiredValidation={strings.required_first_name}
            isReadOnly={isViewOnly}
            defaultValue={userData?.first_name}
            placeholder={strings.enter_first_name}
          />
          <RenderSimpleInput
            variableKey="street"
            label={strings.street}
            isRequired={true}
            isRequiredValidation={strings.street_name_is_required}
            isReadOnly={isViewOnly}
            defaultValue={userData?.street}
            placeholder={strings.enter_street}
          />
          <RenderSimpleInput
            variableKey="house_number"
            label={strings.house_no}
            isRequired={true}
            isRequiredValidation={strings.house_number_is_required}
            isReadOnly={isViewOnly}
            defaultValue={userData?.house_number}
            placeholder={strings.enter_house_number}
          />
          <GridItem>
            <FormControl isRequired isInvalid={!!errors?.zip_code}>
              <FormLabel>{strings.zip_code}</FormLabel>
              <Input
                isReadOnly={isViewOnly}
                {...register('zip_code', {
                  validate: (value) => {
                    if (!value) {
                      return strings.zip_code_is_required;
                    } else {
                      return validateNumber(value) || strings.valid_only_number;
                    }
                  },
                })}
                size="lg"
                type="text"
                defaultValue={userData?.zip_code}
                placeholder={strings.enter_zip_code}
              />
              <FormErrorMessage>
                {errors?.zip_code && errors?.zip_code?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <RenderSimpleInput
            variableKey="town"
            label={strings.town}
            isRequired={true}
            isRequiredValidation={strings.city_is_required}
            isReadOnly={isViewOnly}
            defaultValue={userData?.town}
            placeholder={strings.enter_town}
          />
          {selectOptions.length > 0 && (
            <GridItem>
              <FormControl
                isInvalid={!!errors?.country_code}
                isRequired
                pointerEvents={isViewOnly ? 'none' : 'all'}>
                <FormLabel>{strings.country}</FormLabel>

                <CustomSelectCountry
                  {...register('country_code', {
                    required: strings.country_is_required,
                  })}
                  options={selectOptions}
                  defaultValue={userData?.country_code}
                  field="value"
                />
                <FormErrorMessage>
                  <>{errors?.country_code && errors?.country_code?.message}</>
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          )}
          <RenderSimpleInput
            variableKey="adittion"
            label={strings.address_adittion}
            isRequired={false}
            isReadOnly={isViewOnly}
            defaultValue={userData?.adittion}
            placeholder={strings.enter_address_adittion}
          />

          <GridItem mt="14">
            <FormControl isInvalid={!!errors?.email} isRequired>
              <FormLabel>{strings.email_address}</FormLabel>
              <Input
                isReadOnly={isViewOnly || isSetToEdit}
                defaultValue={userData?.email}
                size="lg"
                placeholder={strings.enter_email_address}
                {...register('email', {
                  required: strings.required_email,
                  validate: (value) =>
                    validEmail(value) || strings.valid_email_address,
                })}
              />
              <FormErrorMessage>
                {errors?.email && errors?.email?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem mt="14">
            <FormControl isInvalid={!!errors?.contact_number} isRequired>
              <FormLabel>{strings.phone_number}</FormLabel>
              <Input
                isReadOnly={isViewOnly}
                type="text"
                size="lg"
                {...register('contact_number', {
                  required: strings.phone_number_is_required,
                })}
                defaultValue={userData?.contact_number ?? ''}
                placeholder={strings.enter_phone_number}
              />
              <FormErrorMessage>
                {errors?.contact_number && errors?.contact_number?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <Box>
            <GridItem>
              <FormControl
                pointerEvents={isViewOnly ? 'none' : 'all'}
                isDisabled={isSetToEdit}
                isRequired={!(isViewOnly || isSetToEdit)}
                isInvalid={!!errors?.language_code}>
                <FormLabel>{strings.correspondence_language}</FormLabel>
                <CustomChakraSelect
                  isReadOnly={isViewOnly || isSetToEdit}
                  size="lg"
                  defaultValue={userData?.language_code}
                  isRequired={!(isViewOnly || isSetToEdit)}
                  placeholder={strings.correspondence_language_placeholder}
                  {...register(
                    'language_code',
                    !(isViewOnly || isSetToEdit)
                      ? {
                          required: strings.correspondence_language_required,
                        }
                      : {}
                  )}>
                  <option value="en">{strings.english}</option>
                  <option value="de">{strings.german}</option>
                  <option value="fr">{strings.french}</option>
                  <option value="it">{strings.italian}</option>
                </CustomChakraSelect>
                <FormErrorMessage>
                  <>{errors?.language_code && errors?.language_code?.message}</>
                </FormErrorMessage>
              </FormControl>
            </GridItem>
          </Box>

          {!isSetToEdit && (
            <GridItem colSpan={2} mt="14">
              <FormControl isInvalid={!!errors?.send_verification_email}>
                <Checkbox
                  defaultChecked
                  {...register('send_verification_email')}
                  isReadOnly={isViewOnly}>
                  {strings.send_verification_email}
                </Checkbox>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Stack>
    </form>
  );
};

export default CareTakerForm;
