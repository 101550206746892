import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ObjectForm from './ObjectForm';

const CreateObject: React.FC = () => {
  const toast = useToast();
  const local = useLocation();
  let { search } = local;

  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {strings.add_object}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.objects.list}>
              {strings.units}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink
              as={RouterLink}
              to={routes.oms.objects.list + search}>
              {strings.manage_objects}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {strings.add_new_object}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex gridGap="2">
          <Heading size="lg" textTransform="capitalize">
            {strings.add_new_object}
          </Heading>
        </Flex>
        <Stack>
          <ObjectForm />
        </Stack>
      </Stack>
    </>
  );
};

export default CreateObject;
