import {
  Box,
  BoxProps,
  Center,
  forwardRef,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
} from '@chakra-ui/react';
import DropdownSvg from 'components/common/DropdownSvg';
import { strings } from 'config/localization';
import { Address, DataWrapper } from 'constants/schema';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import useWordSearch from 'hooks/useWordSearch';
import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  SEARCH_API: string;
  limit: number;
  onChange: (option: Option) => void;
  id?: string;
  placeholder?: string;
  value?: Option;
  replaceNameInOption?: string;
  isInvalid?: boolean;
  disable?: boolean;
}

export interface Option {
  id: number;
  name: string;
  country: number;
  street: string;
  town: string;
  zip_code: string;
  house_number: string;
  disabled?: boolean;
}

interface query {
  page: number;
  limit: number;
}

const PopoverTriggerContent = forwardRef<BoxProps, 'div'>((props, ref) => {
  return <Box {...props} ref={ref} />;
});

export default function CustomSelectObjects({
  id,
  placeholder,
  onChange,
  value,
  limit,
  SEARCH_API,
  replaceNameInOption = '',
  isInvalid = false,
  disable = false,
}: Props) {
  const openModalRef = useRef<HTMLDivElement>(null);
  const { visible, observer } = useIntersectionObserver();
  const focusRef = useRef<HTMLInputElement>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [options, setOptions] = useState<Option[]>([]);
  const [queryParams, setQueryParams] = useState<query>({
    page: 1,
    limit: limit,
  });

  const {
    loading: loading,
    result: query,
    setQuery,
  } = useWordSearch<DataWrapper<Address[]>, query>(`${SEARCH_API}`);

  const handleInputChange = useCallback((value: string) => {
    setQueryParams((prevState: query) => ({
      ...prevState,
      keyword: value ? value : null, // queryparams for search should be "keyword"
      page: 1,
    }));
    setOptions([]);
  }, []);

  useEffect(() => {
    if (!query?.data || query?.data.length === 0) return;
    const listData = query?.data;
    const meta = query?.meta;
    const currentPage = meta?.current_page;
    const lastPage = meta?.last_page;
    setHasMore(currentPage < lastPage);

    const formattedOptions = listData?.map((item) => {
      let addressAddition = item.addition ? `, ${item.addition}` : '';
      return {
        id: item.id,
        name: `${item.name}${addressAddition}`,
        street: item.street,
        house_number: item.house_number,
        zip_code: item.zip_code,
        town: item.town,
        country: item.country.id,
      };
    });

    setOptions((prevState) => [...prevState, ...formattedOptions]);
  }, [query?.data, query?.meta, replaceNameInOption]);

  useEffect(() => {
    setQuery(queryParams);
  }, [queryParams, setQuery]);

  useEffect(() => {
    if (openModal && visible && hasMore && !loading) {
      setQueryParams((prevState: query) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  }, [loading, hasMore, visible, openModal]);
  useEffect(() => {
    function handleClick(e: MouseEvent) {
      if (
        e.target instanceof HTMLElement &&
        !e.target?.classList.contains('disabled-ul')
      ) {
        if (
          openModalRef.current !== null &&
          openModalRef.current.contains(e.target)
        ) {
          setOpenModal(true);
          focusRef.current && focusRef.current.focus();
        } else {
          setOpenModal(false);
        }
      }
    }
    // When disable flag is on, clicks are disabled
    !disable && document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [openModal, disable]);

  useEffect(() => {
    const inputSearchHandler = (value: string) => {
      handleInputChange(value);
      if (searchInput !== '') setOpenModal(true);
    };
    inputSearchHandler(searchInput);
  }, [searchInput, handleInputChange]);

  const selectHandler = (option: Option) => {
    if (option.disabled) return;
    setSearchInput('');
    setOpenModal(false);
    onChange(option);
  };

  let invalidStyle = isInvalid
    ? {
        borderColor: '#f23535',
        boxShadow: '0 0 0 1px #f23535',
      }
    : {};

  return (
    <div
      className={`container-and-modal${disable ? ' cursor-not-allowed' : ''}`}>
      <div className="custom-select-container">
        <span
          aria-live="polite"
          aria-atomic="false"
          aria-relevant="additions text"
          className="outer-line-span"
        />
        <Popover
          matchWidth
          gutter={1}
          isOpen={openModal}
          initialFocusRef={focusRef}
          onClose={() => setOpenModal(false)}>
          <PopoverTrigger>
            <div
              className="all-content-container"
              ref={openModalRef}
              style={invalidStyle}>
              <PopoverTriggerContent className="text-placeholder-and-input-field-container">
                <div className="input-field-inner-container">
                  <input
                    id={id}
                    value={searchInput}
                    placeholder={value?.name ? value.name : placeholder}
                    className={`input-field${
                      disable ? ' cursor-not-allowed' : ''
                    }`}
                    autoComplete="off"
                    ref={focusRef}
                    disabled={disable}
                    style={{ opacity: disable ? 0.6 : 1 }}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
              </PopoverTriggerContent>
              <div className="seperator-arrow-outer-container">
                <div className="arrow-inner-container" aria-hidden="true">
                  <div style={{ display: 'flex', opacity: disable ? 0.4 : 1 }}>
                    <DropdownSvg />
                  </div>
                </div>
              </div>
            </div>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              width="full"
              rootProps={{ width: 'full', maxWidth: 'unset' }}>
              <div className="modal-box">
                {options?.map((option: Option) => {
                  return (
                    <div key={option.id}>
                      <ul
                        key={option.id}
                        className={`list-item ${
                          option.disabled
                            ? 'list-item-disabled disabled-ul'
                            : ''
                        }`}
                        onClick={() => selectHandler(option)}>
                        {option.name}
                      </ul>
                    </div>
                  );
                })}
                <div ref={observer} style={{ height: '1px' }}></div>
                {loading && (
                  <Center h="20px" color="grey">
                    <Spinner size="sm" />
                  </Center>
                )}
                {!loading && (
                  <Center fontSize={12} color="grey">
                    {options.length === 0
                      ? strings.no_options
                      : strings.showing_all_options}
                  </Center>
                )}
              </div>
            </PopoverContent>
          </Portal>
        </Popover>
      </div>
    </div>
  );
}
