import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import OMSApartmentsResource from 'api/oms-apartments';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import UnitListItems from 'components/oms/UnitListItems';
import UnitSearch from 'components/oms/UnitSearch';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import { AnyUnitInfo, DataWrapper, UnitTypeEnum } from 'constants/schema';
import useCustomToast from 'hooks/useCustomToast';
import { useDebounce } from 'hooks/useDebounce';
import { useFilter } from 'hooks/useFilter';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import AccessControl from 'services/AccessControl';

export type UnitListFilterParams = {
  currentPage: number;
  pageSize: number;
  address_name: string;
  address: string;
  owner: string;
  type: string;
};

const UnitList: React.FC = () => {
  const { search } = useLocation();
  const { searchValues, updateUrl } = useFilter();

  let apartmentAPI = new OMSApartmentsResource();

  const [filterParams, setFilterParams] = useState<UnitListFilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    address_name: searchValues.address_name ?? '',
    address: searchValues.address ?? '',
    owner: searchValues.owner ?? '',
    type: searchValues.type ?? '',
  });

  const { address_name, address, owner, ...restFilters } = filterParams;
  const debouncedAddressName = useDebounce(address_name);
  const debouncedAddress = useDebounce(address);
  const debouncedOwner = useDebounce(owner);

  const { showErrorToast } = useCustomToast();

  const unitList = useQuery<DataWrapper<AnyUnitInfo[]>>(
    [
      'unitList',
      debouncedAddressName,
      debouncedAddress,
      debouncedOwner,
      restFilters,
    ],
    async () => {
      const queryParams = {
        address_name: debouncedAddressName || null,
        address: debouncedAddress || null,
        owner: debouncedOwner || null,
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
        type: filterParams.type || null,
      };

      const res = await apartmentAPI.list(queryParams);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: () => showErrorToast(),
    }
  );

  useEffect(() => {
    updateUrl(filterParams);
  }, [updateUrl, filterParams]);

  return (
    <>
      <Helmet>
        <title>
          {strings.units} | {strings.manage_units}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.oms.units.list}>
              {strings.units}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.oms.units.list}>
              {strings.manage_units}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Stack>
          <Flex justify="space-between">
            <Heading size="lg">{strings.manage_units}</Heading>
            <AccessControl
              allowedPermissions={[PermissionRequest['manage:oms']]}>
              <Menu>
                <MenuButton
                  p={2}
                  transition="all 0.2s"
                  h="12"
                  pl="6"
                  pr="6"
                  borderRadius="sm"
                  bg="primary.400"
                  _hover={{ borderBottom: 'primary.300' }}
                  _expanded={{ borderBottom: 'primary.400' }}>
                  <Flex gap={2} alignItems="center">
                    <Box color="white">{strings.add_new_unit}</Box>
                    <Box color="white">
                      <BsFillCaretDownFill />
                    </Box>
                  </Flex>
                </MenuButton>
                <MenuList>
                  <AccessControl
                    allowedPermissions={[PermissionRequest['manage:oms']]}>
                    <RouterLink to={routes.oms.units.add.apartment}>
                      <MenuItem>{strings.apartment}</MenuItem>
                    </RouterLink>
                  </AccessControl>
                  <AccessControl
                    allowedPermissions={[PermissionRequest['manage:oms']]}>
                    <RouterLink to={routes.oms.units.add.garage}>
                      <MenuItem>{strings.garage}</MenuItem>
                    </RouterLink>
                  </AccessControl>
                </MenuList>
              </Menu>
            </AccessControl>
          </Flex>
        </Stack>
        <UnitSearch
          filterParams={filterParams}
          setFilterParams={setFilterParams}
        />
        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Flex>
              <Heading
                as="h3"
                p="16px"
                fontSize="18px"
                fontWeight="500"
                lineHeight="21px">
                {strings.units}
              </Heading>
            </Flex>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.object}</Th>
                  <Th>{strings.object_addition}</Th>
                  <Th>{strings.address}</Th>
                  <Th>{strings.owner}</Th>
                  <Th>{strings.unit_type}</Th>
                  <Th>{strings.no_abbreviation}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {unitList.data?.data.map((data: AnyUnitInfo) => (
                  <UnitListItems
                    key={data.id}
                    data={data}
                    search={search}
                    unitNumber={
                      data.type === UnitTypeEnum.APARTMENT
                        ? data.stwe_number
                        : data.type === UnitTypeEnum.GARAGE
                        ? data.garage_number
                        : ''
                    }
                  />
                ))}
                {unitList.isLoading && (
                  <TableSkeletonLoader rows={6} cols={7} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={unitList}
        />
      </Stack>
    </>
  );
};

export default UnitList;
