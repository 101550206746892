import { Button, useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { checkPermissions } from 'utils/listInfo';
import { RootState } from '../../store';

interface Props {
  path: string;
}
const TicketAddMenu: React.FC<Props> = ({ path }) => {
  const history = useHistory();
  const toast = useToast();
  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const handleTicketType = (ticketType: string) => {
    const isPermitted: boolean = checkPermissions(userPermissions, [
      PermissionRequest['create:ticket'],
    ]);
    if (!isPermitted) {
      toast({
        title: strings.no_permission_msg,
        status: 'error',
        isClosable: true,
      });
      return;
    }
    history.push({
      pathname: path,
      state: {
        ticketType,
      },
    });
  };
  return (
    <Button
      size="lg"
      colorScheme="primary"
      onClick={() => handleTicketType('normal')}>
      {strings.add_task}
    </Button>
  );
};

export default TicketAddMenu;
