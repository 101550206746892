import { AxiosPromise } from 'axios';
import { TagItem } from 'constants/common';
import { DataWrapper, Document } from 'constants/schema';
import http from 'utils/http';
import Resource from './resource';

class OwnerDocumentResource extends Resource<Document> {
  constructor() {
    super('dms/document');
  }

  getOwner(id: string | number) {
    return http({
      url: 'dms/owner/' + id,
      method: 'get',
    });
  }

  getApartmentDetail(id: string | number) {
    return http({
      url: 'dms/apartment/' + id,
      method: 'get',
    });
  }

  getDocumentTags(): AxiosPromise<DataWrapper<TagItem[]>> {
    return http({
      url: 'dms/document-tags',
      method: 'get',
    });
  }

  downloadDMSdocuments(path: string) {
    return http({
      url: 'dms/get-document/' + path,
      method: 'get',
      responseType: 'blob',
    });
  }
}

export { OwnerDocumentResource as default };
