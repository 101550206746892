import {
  Center,
  Icon,
  Image,
  Link,
  Popover,
  PopoverTrigger,
  Tooltip,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import { ServiceListSchema, serviceRoute } from 'constants/schema';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { checkPermissions, checkSomePermissions } from 'utils/listInfo';
import { RootState } from '../../store';
import { FilterSubMenuLink } from './FilterSubMenuLink';

// styles
const navBoxStyles = {
  h: '45px',
  cursor: 'pointer',
};

const navLinkActiveStyles = {
  d: 'block',
  bg: 'primary.500',
};

export interface MenuLinkProps {
  menu: ServiceListSchema;
  shouldDisableToolTipOnMenus: boolean;
  shouldShowHoverSubMenus: boolean;
  handleClickCallback: (route: string) => void;
}

const MenuLink: React.FC<MenuLinkProps> = (props) => {
  const {
    menu,
    shouldDisableToolTipOnMenus,
    shouldShowHoverSubMenus,
    handleClickCallback,
  } = props;

  const { rootLink, routes, tooltipLabel, icon, subMenusComp, serviceName } =
    menu;

  const { userPermissions } = useSelector(
    (state: RootState) => ({
      userPermissions: state?.data?.auth?.permissions,
    }),
    shallowEqual
  );

  const getIcon = () => {
    if (typeof icon === 'string') {
      return <Image src={icon} w="5" h="5" color="white" />;
    }
    return <Icon as={icon} w="5" h="5" />;
  };

  const handleMenuIconClick = () => {
    let authorizedRoute = routes.find((currentRoute: serviceRoute) => {
      if (currentRoute[2]) {
        return checkSomePermissions(userPermissions, [
          PermissionRequest[currentRoute[1]],
          PermissionRequest[currentRoute[2]],
        ]);
      } else {
        return checkPermissions(userPermissions, [
          PermissionRequest[currentRoute[1]],
        ]);
      }
    });
    if (authorizedRoute !== undefined) {
      handleClickCallback(authorizedRoute[0]);
    }
  };

  return (
    <Popover placement="right" trigger="hover">
      <PopoverTrigger>
        <Link
          sx={
            location.pathname?.includes(rootLink)
              ? navLinkActiveStyles
              : undefined
          }
          transition="all 0.35s"
          display="block"
          bg={`balckgreen.400`}
          _hover={{ bg: `primary.600` }}
          borderBottom="1px solid rgba(255, 255, 255, 0.7);"
          onClick={handleMenuIconClick}>
          <Tooltip
            label={strings[tooltipLabel]}
            placement="left-end"
            hasArrow
            isDisabled={shouldDisableToolTipOnMenus}>
            <Center sx={navBoxStyles}>{getIcon()}</Center>
          </Tooltip>
        </Link>
      </PopoverTrigger>

      {/* Popover body element (sub-links) */}
      <FilterSubMenuLink
        subMenuType={subMenusComp}
        shouldShowHoverSubMenus={shouldShowHoverSubMenus}
        themeColor={'primary'}
      />
    </Popover>
  );
};

export default MenuLink;
