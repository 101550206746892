import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import { FileUploadState } from 'constants/schema';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCancelClick: () => void;
  fileUploadStates: FileUploadState[];
}
const MultiFileUploadModal: React.FC<Props> = (props) => {
  const { isOpen, onClose, fileUploadStates, onCancelClick } = props;

  const cancel = () => {
    onCancelClick();
    onClose();
  };

  const filesWithErrors = fileUploadStates
    .filter((fileUploadState) => fileUploadState.error)
    .map((fileUploadState) => ({
      fileName: fileUploadState.fileName,
    }));

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      onClose={onClose}
      size="lg"
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {filesWithErrors.length > 0
            ? strings.documents_are_uploaded
            : strings.document_uploading}
        </ModalHeader>
        <ModalBody overflowY="auto" maxHeight="350px">
          {fileUploadStates.map((fileUploadState) => (
            <Box key={fileUploadState.fileName}>
              <Text colorScheme={fileUploadState.error ? 'red' : 'primary'}>
                {`${fileUploadState.fileName}${
                  fileUploadState.error ? ` ${strings.upload_failed}` : ''
                }`}
              </Text>
              <Progress
                value={fileUploadState.progressPercentage}
                colorScheme={fileUploadState.error ? 'red' : 'primary'}
                mt="2"
              />
              <Text align="right" mt="2" fontSize={'sm'}>
                {fileUploadState.loadedMB} / {fileUploadState.totalMB} MB
              </Text>
            </Box>
          ))}
          {filesWithErrors.length > 0 && (
            <Text color="red" mt="4">
              {strings.attention}

              {filesWithErrors.map((fileWithErrors, index, array) => (
                <span key={fileWithErrors.fileName}>
                  {fileWithErrors.fileName}&nbsp;
                  {index < array.length - 1 && ','}
                </span>
              ))}
              {strings.could_not_be_uploaded}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button colorScheme="primary" onClick={cancel}>
              {filesWithErrors.length > 0 ? strings.close : strings.cancel}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MultiFileUploadModal;
