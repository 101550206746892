import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Heading,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FaqGroupItem from 'components/faq/FaqGroupItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import useCustomToast from 'hooks/useCustomToast';
import { useFilter } from 'hooks/useFilter';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { getStartingSerialNumber } from 'utils';

type FilterParams = {
  currentPage: number;
  pageSize: number;
};

const GroupList: React.FC = () => {
  const { showErrorToast } = useCustomToast();
  const faqGroupAPI = new FAQGroupResource();

  const { searchValues, updateUrl } = useFilter();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: Number(searchValues.currentPage) || INITIAL_CURRENT_PAGE,
    pageSize: Number(searchValues.pageSize) || DEFAULT_PAGE_SIZE,
  });

  const faqGroupList = useQuery(
    [
      'faq-group-list',
      {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      },
    ],
    async () => {
      const queryParams = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      const res = await faqGroupAPI.list(queryParams);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      onError: () => showErrorToast(),
    }
  );

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  useEffect(() => {
    updateUrl(filterParams);
  }, [updateUrl, filterParams]);

  return (
    <>
      <Helmet>
        <title>
          {strings.faq} | {strings.group}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
              {strings.faq}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqGroup.list}>
              {strings.group}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.group}
          </Heading>
          <RouterLink to={routes.faq.faqGroup.add}>
            <Button colorScheme="primary" size="lg">
              {strings.add_group}
            </Button>
          </RouterLink>
        </Flex>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.name}</Th>
                  <Th>{strings.status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {faqGroupList.data?.data?.map((item, index) => (
                  <FaqGroupItem
                    key={item.id}
                    index={startingSN + index}
                    data={item}
                  />
                ))}
                {faqGroupList.isLoading && (
                  <TableSkeletonLoader rows={7} cols={4} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={faqGroupList}
        />
      </Stack>
    </>
  );
};

export default GroupList;
