import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { FiDownloadCloud } from 'react-icons/fi';
import { IoDocumentTextOutline } from 'react-icons/io5';

interface Props {
  name: string;
  size: string;
  link: string;
}

const AttachmentListItem: React.FC<Props> = (props) => {
  const { name, size, link } = props;

  return (
    <Flex
      as="li"
      w="204px"
      h="48px"
      border="1px solid"
      borderColor="gray.500"
      borderRadius="8px"
      listStyleType="none"
      marginX="8px"
      marginY="4px"
      columnGap="16px"
      alignItems="center">
      <Icon as={IoDocumentTextOutline} w="32px" h="32px" color="gray.500" />
      <Flex direction="column" w="150px">
        <Text
          color="gray.500"
          fontSize="12px"
          lineHeight="20px"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap">
          {name}
        </Text>
        <Flex alignItems="center" columnGap="12px">
          <Text color="gray.300" fontSize="12px" lineHeight="20px">
            {size}
          </Text>
          <Box w="14px" h="14px">
            <Link href={link} w="14px" h="14px">
              <Icon as={FiDownloadCloud} display="block" color="gray.300" />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AttachmentListItem;
