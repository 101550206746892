import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import { strings } from 'config/localization';
import theme from 'config/theme';
import { ApartmentInfo, GarageInfo, UnitTypeEnum } from 'constants/schema';
import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { renderMultipleOwnerNames } from 'utils';

interface Props {
  handleCheckboxChange: () => void;
  addApartmentData: (item: ApartmentInfo | GarageInfo) => void;
  isChecked: boolean | null;
  stepOneDone: boolean;
  isEditMode?: boolean;
  apartmentQueryData: (ApartmentInfo | GarageInfo)[];
  initialOwnerShipIds: number[];
  apartmentIds: number[];
  apartmentListLoading: boolean;
}

const OwnerShipDocumentsSelectionObjectsColumn: React.FC<Props> = (props) => {
  const {
    handleCheckboxChange,
    addApartmentData,
    isChecked,
    stepOneDone,
    isEditMode,
    apartmentQueryData,
    initialOwnerShipIds,
    apartmentIds,
    apartmentListLoading,
  } = props;

  const existingOwnerData =
    apartmentQueryData?.filter((item) =>
      initialOwnerShipIds?.some((e) => item.apartment_owner_ids?.includes(e))
    ) ?? [];

  const renderExistingOwners = () =>
    existingOwnerData?.map((item, index) => {
      return (
        <Tr
          key={item.id}
          className={index === existingOwnerData.length - 1 ? 'no-border' : ''}>
          <Td>
            <Checkbox
              size="lg"
              isChecked={apartmentIds.includes(item.id)}
              onChange={() => addApartmentData(item)}
            />
          </Td>

          <Td>{item.address.name}</Td>

          <Td>
            <Text isTruncated maxWidth="200px">
              {renderMultipleOwnerNames(item?.currentOwners)}
            </Text>
          </Td>
          <Td>{strings.getString(item.type)}</Td>

          <Td isNumeric>
            {item.type === UnitTypeEnum.APARTMENT
              ? item.apartment_number
              : item.garage_number}
          </Td>
        </Tr>
      );
    });

  const renderOwnersList = () =>
    apartmentQueryData?.map((item) => {
      if (isEditMode && existingOwnerData?.find((e) => e.id === item.id)) {
        return null;
      }

      return (
        <Tr key={item.id}>
          <Td>
            <Checkbox
              size="lg"
              isChecked={apartmentIds.includes(item.id)}
              onChange={(e) => addApartmentData(item)}
            />
          </Td>
          <Td>{item.address.name}</Td>
          <Td>
            <Text isTruncated maxWidth="200px">
              {renderMultipleOwnerNames(item?.currentOwners)}
            </Text>
          </Td>
          <Td>{strings.getString(item.type)}</Td>
          <Td isNumeric>
            {item.type === UnitTypeEnum.APARTMENT
              ? item.apartment_number
              : item.garage_number}
          </Td>
        </Tr>
      );
    });

  const renderContent = () => {
    if (apartmentListLoading) {
      return <TableSkeletonLoader rows={6} cols={5} />;
    }

    if (apartmentQueryData?.length === 0) {
      return (
        <Tr>
          <Td colSpan={4}>
            <Text textAlign="center" color="paragraph">
              {strings.no_results_found}
            </Text>
          </Td>
        </Tr>
      );
    }

    return (
      <>
        {isEditMode && (
          <>
            {existingOwnerData.length > 0 ? (
              <Tr>
                <Td colSpan={5}>{strings.assigned_units}</Td>
              </Tr>
            ) : null}
            {renderExistingOwners()}
            <Tr>
              <Td colSpan={5}>{strings.other_units}</Td>
            </Tr>
          </>
        )}

        {renderOwnersList()}
      </>
    );
  };

  if (!stepOneDone) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="500px">
        <Flex direction="column" align="center" justify="center">
          <BiInfoCircle size={44} color={theme.colors.grey['400']} />
          <Box textAlign="center" mt="6" maxW="300px" color="grey.400">
            {strings.document_upload_info}
          </Box>
        </Flex>
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr className="no-border">
            <Th width="1%">
              <Checkbox
                size="lg"
                isChecked={isChecked === true}
                isIndeterminate={isChecked === null}
                onChange={handleCheckboxChange}
              />
            </Th>
            <Th>
              <Text color="paragraph">{strings.object_name}</Text>
            </Th>
            <Th>
              <Text color="paragraph">{strings.current_owner}</Text>
            </Th>
            <Th>
              <Text color="paragraph">{strings.unit_type}</Text>
            </Th>
            <Th isNumeric>
              <Text color="paragraph">{strings.no_abbreviation}</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{renderContent()}</Tbody>
      </Table>
    </TableContainer>
  );
};

export default OwnerShipDocumentsSelectionObjectsColumn;
