import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import OMSApartmentsResource from 'api/oms-apartments';
import OMSCountriesResource from 'api/oms-countries';
import OMSOwnersResource from 'api/oms-owners';
import { AxiosError, AxiosResponse } from 'axios';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import CustomSelectObjects, {
  Option,
} from 'components/common/CustomSelectObjects';
import OwnershipListItem from 'components/oms/OwnershipListItem';
import OwnershipModal from 'components/oms/OwnershipModal';
import { strings } from 'config/localization';
import { OMS_OBJECT_SEARCH_API } from 'constants/common';
import routes from 'constants/routes';
import {
  ApartmentInfo,
  Country,
  Floor,
  GarageFeatures,
  GarageInfo,
  OwnershipDTO,
  RentalStatus,
  RoomCount,
  UnitTypeEnum,
  UnitTypes,
} from 'constants/schema';
import { default as React, useEffect, useMemo, useState } from 'react';
import {
  Controller,
  FormProvider,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import ApartmentGarageForm from './Apartment/ApartmentGarageForm';

interface Props {
  data?: ApartmentInfo | GarageInfo;
  children: React.ReactNode;
  unitType: UnitTypes;
}

interface ApartmentFormValues {
  address_id: Option;
  address_line: string;
  apartment_quota: string;
  country_id: number | undefined;
  floor: Floor;
  garage_features: GarageFeatures;
  house_number: string | undefined;
  is_garage: boolean;
  living_space: string;
  name: string;
  ownerships: OwnershipDTO[];
  rental_status: RentalStatus;
  room: RoomCount;
  street: string | undefined;
  stwe_number: string;
  town: string | undefined;
  type: UnitTypes;
  zip_code: string | undefined;
  has_locking_plan: boolean;
}

interface ApartmentFormDTO {
  address_id: number;
  address_line: string;
  apartment_quota: string;
  country_id: number | undefined;
  floor: Floor;
  garage_features: GarageFeatures;
  house_number: string | undefined;
  is_garage: boolean;
  living_space: string;
  name: string;
  ownerships: OwnershipDTO[];
  rental_status: RentalStatus;
  room: RoomCount;
  street: string | undefined;
  stwe_number: string;
  town: string | undefined;
  type: UnitTypes;
  zip_code: string | undefined;
}

const formFieldsStyle = {
  bg: 'white',
  borderRadius: '3px',
  p: '16px',
  ml: '1',
  boxShadow: '0px 0px 60px rgba(0, 0, 0, 0.06)',
};

const UnitForm: React.FC<Props> = (props) => {
  const { data, unitType } = props;

  // Hooks
  const toast = useToast();
  const history = useHistory();
  const queryClient = useQueryClient();
  const methods = useFormContext<ApartmentFormValues>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  // Country list
  const countryAPI = new OMSCountriesResource();
  const { data: countryList } = useQuery(
    'oms-country-list',
    () => countryAPI.list().then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Owners list
  const omsOwnersAPI = new OMSOwnersResource();
  const ownersListQuery = useQuery(
    'owners-list',
    () => omsOwnersAPI.list({ limit: 'all' }).then((res) => res.data),
    { refetchOnWindowFocus: false }
  );
  const ownersList = ownersListQuery?.data?.data;

  // Ownership
  const ownerships = useMemo(() => data?.ownerships ?? [], [data]);
  const [ownershipDTOs, setOwnershipDTOs] = useState(
    ownerships.map((ownership) =>
      ownership.is_current
        ? {
            is_current: ownership.is_current,
            owner_id: ownership.owner.id,
            owner_since: ownership.owner_since,
          }
        : {
            is_current: ownership.is_current,
            owner_id: ownership.owner.id,
          }
    )
  );
  const addOwnership = (formData: OwnershipDTO) => {
    setOwnershipDTOs((prevDTOs) => [...prevDTOs, formData]);
  };
  const editOwnership = (formData: OwnershipDTO) => {
    setOwnershipDTOs((prevDTOs) =>
      prevDTOs.map((dto) =>
        dto.owner_id === formData.owner_id ? formData : dto
      )
    );
  };
  const deleteOwnership = (formData: OwnershipDTO) => {
    setOwnershipDTOs((prevDTOs) =>
      prevDTOs.filter((dto) => dto.owner_id !== formData.owner_id)
    );
  };

  // Apartment owner IDs
  const apartmentOwnerIds = ownershipDTOs.map(
    (ownershipDTO) => ownershipDTO.owner_id
  );

  // Form state
  const {
    register,
    formState: { errors, dirtyFields },
    setValue,
    control,
    setError,
    clearErrors,
    watch,
  } = methods;
  const objectID = useWatch({
    name: 'address_id',
    control,
  });

  useEffect(() => {
    if (!data || !countryList) return;
    const { address, address_line } = data;
    const {
      country,
      street,
      zip_code,
      house_number,
      town,
      name,
      addition,
      id,
    } = address;
    let objectAddition = addition ? `, ${addition}` : '';
    let addr = {
      id,
      name: `${name}${objectAddition}`,
      country: country.id,
      street,
      zip_code,
      house_number,
      town,
    };
    setValue('address_id', addr);
    setValue('country_id', country.id);
    setValue('zip_code', zip_code);
    setValue('house_number', house_number);
    setValue('street', street);
    setValue('town', town);
    setValue('address_line', address_line);
  }, [countryList, data, setValue, unitType]);
  useEffect(() => {
    setValue('ownerships', ownershipDTOs);
  }, [ownershipDTOs, setValue]);

  // Form submission
  const apartmentAPI = new OMSApartmentsResource();
  const [errMsg, setErrMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const createObject = useMutation<
    AxiosResponse<ApartmentFormDTO>,
    AxiosError,
    ApartmentFormDTO
  >(['create-object'], (data) => apartmentAPI.store(data));
  const updateObject = useMutation<
    AxiosResponse<ApartmentFormDTO>,
    AxiosError,
    ApartmentFormDTO
  >((formData: ApartmentFormDTO) => apartmentAPI.update(data?.id, formData));
  const onSubmit = (formData: ApartmentFormValues) => {
    if (formData.ownerships.length === 0) {
      setError('ownerships', {
        message: strings.owner_is_required,
      });
      return;
    }

    setIsLoading(true);
    let API;
    let successMessage: string;
    let errorMessage: string;
    if (data?.id) {
      API = updateObject;
      successMessage = `${strings[unitType]} ${strings.has_been_updated}`;
      errorMessage = `${strings[unitType]} ${strings.has_not_been_updated}`;
    } else {
      API = createObject;
      successMessage = `${strings[unitType]} ${strings.has_been_created}`;
      errorMessage = `${strings[unitType]} ${strings.has_not_been_created}`;
    }

    // Add address as apartment name to form data
    const dto: ApartmentFormDTO = {
      ...formData,
      name: `${formData.street} ${formData.house_number}, ${formData.zip_code} ${formData.town}`,
      address_id: formData.address_id.id,
    };
    API.mutate(dto, {
      onSuccess: () => {
        if (data) {
          queryClient.invalidateQueries(`apartmentDetails-${data.id}`);
        }
        toast({
          title: successMessage,
          status: 'success',
          isClosable: true,
        });
        setIsLoading(false);
        history.push(routes.oms.units.list);
      },
      onError: () => {
        toast({
          title: errorMessage,
          status: 'error',
          isClosable: true,
        });
        setErrMsg(errorMessage);
        setIsLoading(false);
      },
    });
  };

  // Modal state
  const [addModalOpen, setAddModalOpen] = useState(false);
  const onAddModalClose = () => {
    setAddModalOpen(false);
  };
  const onAddConfirm = (newOwnershipDTO: OwnershipDTO) => {
    clearErrors('ownerships');
    addOwnership(newOwnershipDTO);
    setAddModalOpen(false);
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'address_id') {
        setValue('country_id', value.address_id?.country);
        setValue('zip_code', value.address_id?.zip_code);
        setValue('house_number', value.address_id?.house_number);
        setValue('street', value.address_id?.street);
        setValue('town', value.address_id?.town);
      }
    });
    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  const onCancelClick = () => {
    const isDirty = !!Object.keys(dirtyFields).length;
    if (isDirty) {
      onOpen();
    } else {
      history.goBack();
    }
  };

  // Subcomponents
  const AddOwnerButton = () => (
    <>
      <Button
        w="auto"
        colorScheme="primary"
        sx={
          errors.ownerships && {
            borderColor: '#f23535',
            borderWidth: '1px',
            boxShadow: '0 0 0 1px #f23535',
          }
        }
        type="button"
        isLoading={isLoading}
        onClick={() => setAddModalOpen(true)}>
        {strings.add_owner}
      </Button>
      {ownersList && (
        <OwnershipModal
          ownersList={ownersList}
          apartmentOwnerIds={apartmentOwnerIds}
          isOpen={addModalOpen}
          onClose={onAddModalClose}
          onSubmit={onAddConfirm}
        />
      )}
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Stack direction="column" spacing="32px">
          <Stack direction="column" spacing="8" sx={formFieldsStyle}>
            {errMsg && (
              <Alert status="error">
                <AlertIcon />
                {errMsg}
              </Alert>
            )}
            <Stack direction="column">
              <Heading
                as="h3"
                p="16px"
                fontSize="18px"
                fontWeight="500"
                lineHeight="21px">
                {strings.basic_information}
              </Heading>
              <Grid
                p="16px"
                gap="3"
                templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)']}
                w="100%">
                <GridItem>
                  <FormControl isInvalid={!!errors?.address_id} isRequired>
                    <FormLabel>{strings.object}</FormLabel>
                    <Controller
                      control={control}
                      name="address_id"
                      rules={{
                        required: strings.object_name_is_required,
                      }}
                      render={({ field }) => (
                        <CustomSelectObjects
                          placeholder={strings.select_object}
                          SEARCH_API={OMS_OBJECT_SEARCH_API}
                          limit={15}
                          value={field.value}
                          onChange={field.onChange}
                          isInvalid={!!errors?.address_id}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      <>{errors?.address_id && errors.address_id?.message}</>
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>

                <GridItem colSpan={[1, 2]} mb="32px"></GridItem>
                {props.children}
                {objectID && (
                  <>
                    <GridItem colSpan={[1, 2]} mb="32px"></GridItem>
                    <GridItem>
                      <FormControl isInvalid={!!errors?.street}>
                        <FormLabel>{strings.street}</FormLabel>
                        <Input
                          type="text"
                          {...register('street')}
                          placeholder={strings.enter_street}
                          isDisabled={true}
                        />
                        <FormErrorMessage>
                          <>{errors?.street && errors?.street?.message}</>
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={!!errors?.house_number}>
                        <FormLabel>{strings.house_number}</FormLabel>
                        <Input
                          type="text"
                          {...register('house_number')}
                          placeholder={strings.enter_house_number}
                          isDisabled={true}
                        />
                        <FormErrorMessage>
                          <>
                            {errors?.house_number &&
                              errors?.house_number?.message}
                          </>
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={!!errors?.zip_code}>
                        <FormLabel>{strings.zip_code}</FormLabel>
                        <Input
                          type="text"
                          {...register('zip_code')}
                          placeholder={strings.enter_zip_code}
                          isDisabled={true}
                        />
                        <FormErrorMessage>
                          <>{errors?.zip_code && errors?.zip_code?.message}</>
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={!!errors?.town}>
                        <FormLabel>{strings.town}</FormLabel>
                        <Input
                          type="text"
                          {...register('town')}
                          placeholder={strings.enter_town}
                          isDisabled={true}
                        />
                        <FormErrorMessage>
                          <>{errors?.town && errors?.town?.message}</>
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={!!errors?.country_id}>
                        <FormLabel>{strings.country}</FormLabel>
                        <CustomChakraSelect
                          {...register('country_id')}
                          placeholder={strings.select_country}
                          isDisabled={true}>
                          {countryList?.data?.map((country: Country) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </CustomChakraSelect>
                        <FormErrorMessage>
                          <>
                            {errors?.country_id && errors?.country_id?.message}
                          </>
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                    <GridItem>
                      <FormControl isInvalid={!!errors?.address_line}>
                        <FormLabel>{strings.address_adittion}</FormLabel>
                        <Input
                          type="text"
                          {...register('address_line')}
                          placeholder={strings.enter_address_adittion}
                        />
                        <FormErrorMessage>
                          <>
                            {errors?.address_line &&
                              errors?.address_line?.message}
                          </>
                        </FormErrorMessage>
                      </FormControl>
                    </GridItem>
                  </>
                )}

                <GridItem colSpan={2} mt="8">
                  <FormControl isInvalid={!!errors?.has_locking_plan}>
                    <Checkbox
                      size="lg"
                      {...register('has_locking_plan')}
                      defaultChecked={!!data?.has_locking_plan}>
                      <Text fontSize="md">{strings.locking_plan}</Text>
                    </Checkbox>
                  </FormControl>
                </GridItem>

                {unitType === UnitTypeEnum.APARTMENT && (
                  <GridItem colSpan={[2]}>
                    <ApartmentGarageForm
                      data={data?.type === UnitTypeEnum.APARTMENT ? data : null}
                    />
                  </GridItem>
                )}
              </Grid>
            </Stack>
          </Stack>
          <Stack direction="column" spacing="12px" sx={formFieldsStyle}>
            <Flex justifyContent="space-between">
              <Heading
                as="h3"
                p="16px"
                fontSize="18px"
                fontWeight="500"
                lineHeight="21px">
                {strings.owner}
              </Heading>
              {ownershipDTOs.length > 0 ? <AddOwnerButton /> : null}
            </Flex>
            {ownershipDTOs.length > 0 ? (
              <TableContainer>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>
                        {strings.last_name},&nbsp;{strings.first_name}
                      </Th>
                      <Th>{strings.owner_since}</Th>
                      <Th>{strings.actions}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {ownersList
                      ? ownershipDTOs
                          .sort(
                            // Current ownerships come before previous ones in the list
                            (a: OwnershipDTO, b: OwnershipDTO) => {
                              if (a.is_current === b.is_current) {
                                return 0;
                              }
                              return a.is_current ? -1 : 1;
                            }
                          )
                          .map((ownershipDTO: OwnershipDTO) => (
                            <OwnershipListItem
                              key={`current${ownershipDTO.owner_id}`}
                              ownershipDTO={ownershipDTO}
                              ownersList={ownersList}
                              apartmentOwnerIds={apartmentOwnerIds}
                              editOwnership={editOwnership}
                              deleteOwnership={deleteOwnership}
                              isLoading={isLoading}
                            />
                          ))
                      : null}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <VStack
                direction="column"
                alignItems="center"
                rowGap="24px"
                pb="64px">
                <AddOwnerButton />
                <Text color={errors.ownerships ? '#f23535' : 'inherit'}>
                  {strings.owner_is_required}
                </Text>
              </VStack>
            )}
          </Stack>
          <ButtonGroup alignSelf="end">
            <Button
              variant="outline"
              colorScheme="primary"
              isDisabled={isLoading}
              onClick={onCancelClick}>
              {strings.cancel}
            </Button>
            <Button
              colorScheme="primary"
              type="button"
              isLoading={isLoading}
              onClick={methods.handleSubmit(onSubmit)}>
              {data
                ? strings.save
                : unitType === UnitTypeEnum.APARTMENT
                ? strings.add_apartment
                : strings.add_garage}
            </Button>
          </ButtonGroup>
        </Stack>
      </form>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.discard_changes}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.discard_changes_warning}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button colorScheme="primary" variant="outline" onClick={onClose}>
                {strings.no_thank_you}
              </Button>
              <Button colorScheme="primary" onClick={() => history.goBack()}>
                {strings.discard}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};

export default UnitForm;
