const routes = {
  dashboard: '/',
  splash: '/splash',
  profile: {
    base: '/profile/*',
    myProfile: '/profile/my-profile',
    edit: '/profile/edit',
  },
  users: {
    base: '/users/info/*',
    list: '/users/info/list',
    add: '/users/info/user/add',
    profile: '/users/info/user/profile/:id',
    edit: '/users/info/user/edit/:id',
    owner: {
      add: '/users/info/owner/add',
      edit: '/users/info/owner/edit/:id',
      profile: '/users/info/owner/profile/:id',
    },
    careTaker: {
      add: '/users/info/caretaker/add',
      edit: '/users/info/caretaker/edit/:id',
      profile: '/users/info/caretaker/profile/:id',
    },
    roles: {
      base: '/users/roles/*',
      list: '/users/roles/list',
      view: '/users/roles/:id',
    },
    loginLog: {
      base: '/users/login-log/*',
      list: '/users/login-log/list',
    },
  },
  auth: {
    login: '/login',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    setPassword: '/set-password',
    verifyEmail: '/verify-email',
    api: '/api',
  },
  ticket: {
    task: {
      base: '/ticket/task/*',
      board: '/ticket/task/board',
      create: '/ticket/task/create',
      edit: '/ticket/task/edit/:id',
      view: '/ticket/task/view/:id',
    },
    list: {
      base: '/ticket/list/*',
      create: '/ticket/list/create',
      edit: '/ticket/list/edit/:id',
      view: '/ticket/list/view/:id',
      default: '/ticket/list/all',
    },
  },
  oms: {
    units: {
      base: '/oms/units/*',
      list: '/oms/units/list',
      add: {
        apartment: '/oms/units/add/apartment',
        restaurant: '/oms/units/add/restaurant',
        garage: '/oms/units/add/garage',
      },
      edit: {
        apartment: '/oms/units/edit/apartment/:id',
        restaurant: '/oms/units/edit/restaurant/:id:',
        garage: '/oms/units/edit/garage/:id',
      },
    },
    objects: {
      base: '/oms/objects/*',
      list: '/oms/objects/list',
      add: '/oms/objects/add',
      edit: '/oms/objects/edit/:id',
    },
    appartments: {
      address: '/oms/appartments/address',
    },
  },
  faq: {
    faqList: {
      base: '/faq/faq-list/*',
      list: '/faq/faq-list/list',
      add: '/faq/faq-list/add',
      edit: '/faq/faq-list/edit/:id',
    },
    faqGroup: {
      base: '/faq/faq-group/*',
      list: '/faq/faq-group/list',
      add: '/faq/faq-group/add',
      edit: '/faq/faq-group/edit/:id',
    },
  },
  dms: {
    documents: {
      base: '/dms/documents/*',
      upload: '/dms/documents/upload',
    },
    object: {
      base: '/dms/object/*',
      list: '/dms/object/list',
      objectDocuments: '/dms/object/details/:id',
      objectDocumentEdit: '/dms/object/document/edit/:id',
    },
    owner: {
      base: '/dms/owner/*',
      list: '/dms/owner/list',
      ownerDocuments: '/dms/owner/details/:id',
      ownerDocumentEdit: '/dms/owner/document/edit/:id',
    },
  },
  newsletter: {
    newsletters: {
      base: '/newsletter/newsletters/*',
      list: '/newsletter/newsletters/list',
      add: '/newsletter/newsletters/add',
      edit: '/newsletter/newsletters/edit/:id',
    },
  },
} as const;

export default routes;
