import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Stack,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FormCancelButton from 'components/common/FormCancelButton';
import CareTakerForm from 'components/user/CareTakerForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { CareTakerSchema } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { renderFullName } from 'utils';

type EditCareTakerParams = {
  id: string;
};

const EditCareTaker: React.FC = () => {
  const { id } = useParams<EditCareTakerParams>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<CareTakerSchema>();

  const userAPI = new UserResource();

  const userQuery = useQuery(
    [`user${id}-edit`, id],
    () => userAPI.getCareTaker(id).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0, // For update on navigation if the owner changed their data.
    }
  );

  const updateUser = useMutation((data: CareTakerSchema) =>
    userAPI.updateCareTaker(id, data)
  );

  const onSubmit = (data: CareTakerSchema) => {
    const mappedData = { ...data };

    updateUser.mutate(mappedData, {
      onSuccess: () => {
        toast({
          title: strings.user_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userList');
        queryClient.invalidateQueries(`user${id}-edit`);
        history.push(routes.users.list);
      },
    });
  };

  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  const careTakerData = userQuery?.data?.data;

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {strings.edit_caretaker}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {renderFullName(
                userQuery?.data?.data?.first_name,
                userQuery?.data?.data?.last_name
              )}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg">
            {renderFullName(
              userQuery?.data?.data?.first_name,
              userQuery?.data?.data?.last_name
            )}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {updateUser.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.user_udpate_failed}
                  </Alert>
                )}
                <CareTakerForm userData={careTakerData} />
                <ButtonGroup justifyContent="flex-end">
                  <FormCancelButton
                    isLoading={updateUser.isLoading}
                    size="lg"
                  />

                  <Button
                    isDisabled={!methods.formState.isDirty}
                    size="lg"
                    colorScheme="primary"
                    type="submit"
                    isLoading={updateUser.isLoading}>
                    {strings.save}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>
    </>
  );
};

export default EditCareTaker;
