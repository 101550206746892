import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import { reactSelectStyles, wrapperStyles } from 'assets/css/commonStyles';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import NewsletterListItem from 'components/newsletter/NewsletterListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  DataWrapper,
  NewsletterDetail,
  NewsletterStatus,
  NewsletterStatusEnum,
  Option,
} from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import useCustomToast from 'hooks/useCustomToast';
import { useDebounce } from 'hooks/useDebounce';
import { useFilter } from 'hooks/useFilter';
import React, {
  FormEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from 'react-helmet';
import { BiCalendar, BiFilter, BiSearch } from 'react-icons/bi';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import ReactSelect, { OnChangeValue } from 'react-select';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
  GroupOfRecipientOptionLocalization,
} from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import ReactDatePickerRoot from '../../../../components/ReactDatePickerRoot/ReactDatePickerRoot';

type FilterParams = {
  currentPage: number;
  pageSize: number;
  title: string;
  updated_at_start: string | null;
  updated_at_end: string | null;
  group_of_recipients: string[];
  status: NewsletterStatus | null;
};

const CreatedAtInput = forwardRef((props) => {
  return <Input {...props} size="lg" />;
});

CreatedAtInput.displayName = 'CreatedAtInput';

const NewsletterList: React.FC = () => {
  const newsletterAPI = new NewsletterAPI();
  const groupOfRecipientOptions = GroupOfRecipientOptionLocalization();

  const checkAddNewsletterPermission = useCheckPermission(
    [
      PermissionRequest['read:newsletter'],
      PermissionRequest['manage:newsletter'],
    ],
    routes.newsletter.newsletters.add
  );

  const history = useHistory();
  const { search } = useLocation();
  const { searchValues, updateUrl } = useFilter();

  const [startDate, setStartDate] = useState<string | null>(
    searchValues.updated_at_start ? searchValues.updated_at_start : null
  );
  const [endDate, setEndDate] = useState<string | null>(
    searchValues.updated_at_start ? searchValues.updated_at_end : null
  );

  const group_of_recipients_url: string[] =
    searchValues?.group_of_recipients?.split(',') ?? [];

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    updated_at_start: startDate,
    updated_at_end: endDate,
    group_of_recipients: group_of_recipients_url ?? [],
    status:
      searchValues?.status === NewsletterStatusEnum.DRAFT ||
      searchValues?.status === NewsletterStatusEnum.DISPATCHED
        ? searchValues?.status
        : null,
  });

  const recipient_group_value = filterParams?.group_of_recipients?.map(
    (item) => {
      const newLabel = capitalizeFirstLetter(item);
      return {
        value: item,
        label: newLabel,
      };
    }
  );

  const { title, ...restFilters } = filterParams;
  const debouncedTitle = useDebounce(title);

  const { showErrorToast } = useCustomToast();

  const queryList = useQuery<DataWrapper<NewsletterDetail[]>>(
    ['newsletterList', debouncedTitle, restFilters],
    async () => {
      const queryParams: Record<string, unknown> = {
        page: filterParams.currentPage,
        limit: filterParams.pageSize,
      };
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.title) queryParams.keyword = debouncedTitle;
      if (startDate) queryParams.updated_at_start = DateFormatYMD(startDate);
      if (endDate) queryParams.updated_at_end = DateFormatYMD(endDate);
      if (filterParams.group_of_recipients)
        queryParams.recipient_group = filterParams.group_of_recipients;
      const response = await newsletterAPI.list(queryParams);
      return response?.data;
    },
    {
      onError: () => showErrorToast(),
    }
  );

  const { data: newsLetterQuery, isLoading: newsLetterLoading } = queryList;

  const handleDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(DateFormatYMD(start));
    setEndDate(DateFormatYMD(end));
    const newFilter: FilterParams = {
      ...filterParams,
      updated_at_start: DateFormatYMD(start),
      updated_at_end: DateFormatYMD(end),
    };
    setFilterParams(newFilter);
    setSearchURL(newFilter);
  };
  const getFormattedRangeDate = (
    sDate: Date | null,
    eDate: Date | null
  ): string => {
    if (!sDate && !eDate) return '';
    if (!eDate) return DateFormatYMD(sDate);
    return `${DateFormatYMD(sDate)} - ${DateFormatYMD(eDate)}`;
  };

  const handleInputChange = (
    e: FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      [name]: value,
    }));
    const data = {
      ...filterParams,
      [name]: value,
    };
    setSearchURL(data);
  };
  const setSearchURL = (filterQuery: FilterParams) => {
    const searchURL = changeURL(filterQuery);
    history.push(`?${searchURL}`);
  };

  const handleGroupOfRecipientsChange = (
    recipient: OnChangeValue<Option<string>, true>
  ) => {
    setFilterParams((prevState) => ({
      ...prevState,
      group_of_recipients: recipient.map((item) => item.value),
    }));
    const data = {
      ...filterParams,
      group_of_recipients: recipient.map((item) => item.value),
    };
    setSearchURL(data);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      title: '',
      updated_at_start: null,
      updated_at_end: null,
      group_of_recipients: [],
      status: null,
    }));

    history.push(routes.newsletter.newsletters.list);
  };

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  useEffect(() => {
    updateUrl(filterParams);
  }, [updateUrl, filterParams]);

  const statusOptions = [
    {
      label: strings.draft,
      value: 'draft',
    },
    {
      label: strings.dispatched,
      value: 'dispatched',
    },
  ] as const;

  return (
    <>
      <Helmet>
        <title>
          {strings.newsletter} | {strings.newsletter_overview}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink>{strings.newsletter}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink
              as={RouterLink}
              to={routes.newsletter.newsletters.list}>
              {strings.newsletter_overview}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.newsletter_overview}
          </Heading>
          <Button
            size="lg"
            colorScheme="primary"
            type="button"
            onClick={checkAddNewsletterPermission}>
            {strings.create_new_newsletter}
          </Button>
        </Flex>

        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          boxShadow="box"
          defaultIndex={0}>
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form>
                  <Stack
                    direction={['column', 'column', 'row']}
                    spacing="4"
                    alignItems={['initial', 'initial', 'flex-end']}>
                    <Grid
                      gap="4"
                      templateColumns={[
                        'repeat(1, 1fr)',
                        'repeat(2, 1fr)',
                        'repeat(4, 1fr)',
                      ]}
                      flex="1">
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.subject}</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              h="100%"
                              children={<BiSearch />}
                              color="gray.500"
                            />
                            <Input
                              type="text"
                              size="lg"
                              placeholder={strings.subject}
                              name="title"
                              value={filterParams.title}
                              onChange={handleInputChange}
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.date}</FormLabel>
                          <InputGroup>
                            <InputRightElement
                              children={<BiCalendar />}
                              h="100%"
                              color="gray.500"
                            />
                            <ReactDatePickerRoot
                              placeholderText={strings.select_date}
                              dateFormat="yyyy-MM-dd"
                              customInput={<CreatedAtInput />}
                              onChange={handleDateChange}
                              selected={startDate ? new Date(startDate) : null}
                              startDate={startDate ? new Date(startDate) : null}
                              endDate={endDate ? new Date(endDate) : null}
                              selectsRange
                              value={
                                startDate && endDate
                                  ? `${startDate} - ${endDate}`
                                  : undefined
                              }
                              shouldCloseOnSelect={false}
                              autoComplete="off"
                            />
                          </InputGroup>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.group_of_recipients}</FormLabel>
                          <ReactSelect
                            name="group_of_recipients"
                            closeMenuOnSelect={false}
                            placeholder={strings.select_recipient_group}
                            menuPortalTarget={document.getElementById(
                              'root-popover'
                            )}
                            isMulti
                            options={groupOfRecipientOptions}
                            defaultValue={recipient_group_value}
                            onChange={handleGroupOfRecipientsChange}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            styles={reactSelectStyles}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel>{strings.status}</FormLabel>
                          <CustomChakraSelect
                            name="status"
                            placeholder={strings.select_status}
                            onChange={handleInputChange}
                            value={
                              filterParams.status ? filterParams.status : ''
                            }>
                            {statusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </CustomChakraSelect>
                        </FormControl>
                      </GridItem>
                    </Grid>
                    <Stack direction="row" spacing="4">
                      <Button
                        size="lg"
                        colorScheme="primary"
                        variant="outline"
                        onClick={handleReset}>
                        {strings.reset_filter}
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.subject}</Th>
                  <Th>{strings.date}</Th>
                  <Th>{strings.group_of_recipients}</Th>
                  <Th>{strings.status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {newsLetterQuery?.data.map((newsletterItem, index) => (
                  <NewsletterListItem
                    newsletterItem={newsletterItem}
                    key={newsletterItem.id}
                    index={startingSN + index}
                    status={newsletterItem.status}
                    search={search}
                  />
                ))}
                {newsLetterLoading && (
                  <TableSkeletonLoader rows={filterParams.pageSize} cols={6} />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default NewsletterList;
