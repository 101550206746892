import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import {
  infoStyles,
  labelStyles,
  wrapperStyles,
} from 'assets/css/commonStyles';
import { ChatNotAvailable as FallBackImage } from 'assets/images';
import FileDetails from 'components/common/FileDetails';
import { getTag } from 'components/common/PriorityTag';
import CustomTagTitle from 'components/CustomTagTitle';
import StatusForm from 'components/ticket/StatusForm';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import { ExtendedFileInfo, TicketInfoSchema } from 'constants/schema';
import * as FileSaver from 'file-saver';
import useCheckPermission from 'hooks/useCheckPermission';
import useImageSlide from 'hooks/useImageSlider';
import React, { useState } from 'react';
import { BiChevronLeft, BiChevronRight, BiCloudDownload } from 'react-icons/bi';
import { FaSearchPlus } from 'react-icons/fa';
import {
  formatFileNameTicket,
  getTicketTitle,
  getUnitLabel,
  renderFullName,
} from 'utils';

const imageSearchIconStyle = {
  position: 'absolute',
  color: 'white',
  top: '45%',
  left: '25%',
  cursor: 'pointer',
};

const imageDownloadIconStyle = {
  position: 'absolute',
  color: 'white',
  top: '40%',
  right: '25%',
  cursor: 'pointer',
};

const hoverImageStyle = {
  position: 'absolute',
  _hover: {
    top: '0',
    rigt: '0',
    cursor: 'pointer',
    width: '100%',
  },
};
const btnLeft = {
  position: 'absolute',
  cursor: 'pointer',
  top: '50%',
  left: '0%',
};

const btnRight = {
  position: 'absolute',
  cursor: 'pointer',
  top: '50%',
  right: '0%',
};

const sliderImage = {
  width: '100%',
  height: '450px',
};

interface Props {
  ticket: TicketInfoSchema;
  path: string;
}

const TicketInfo: React.FC<Props> = (props) => {
  const { ticket, path } = props;

  const [openImageModal, setOpenImageModal] = useState<boolean>(false);

  const imageFiles = ticket?.ticket_attachments.filter(
    (file) => file.mime_type.split('/')[0].toLowerCase() === 'image'
  );
  const otherFiles = ticket?.ticket_attachments.filter(
    (file) => file.mime_type.split('/')[0].toLowerCase() !== 'image' // all files except images
  );

  const { imageIndex, onIncrement, onDecrement, setImageIndex } = useImageSlide(
    imageFiles?.length
  );

  const checkEditTicketPermission = useCheckPermission(
    [PermissionRequest['edit:ticket']],
    path.replace(':id', String(ticket?.id))
  );

  const handleOpenModal = (index: number) => {
    setImageIndex(index);
    setOpenImageModal(true);
  };

  const handleCloseModal = () => {
    setOpenImageModal(false);
  };

  const downloadFile = (file: ExtendedFileInfo) => {
    FileSaver.saveAs(file.file_url, formatFileNameTicket(file));
  };

  return (
    <>
      <Flex justify="space-between">
        <Heading size="lg" textTransform="capitalize">
          {strings.ticket_details}
        </Heading>
        <Stack direction="row" spacing="6">
          <StatusForm ticketId={ticket?.id} status={ticket?.status} />
          <Button
            size="lg"
            colorScheme="primary"
            onClick={checkEditTicketPermission}>
            {strings.edit_ticket}
          </Button>
        </Stack>
      </Flex>

      <Stack sx={wrapperStyles}>
        <Grid
          gap="4"
          templateColumns={['repeat(2, 1fr)', 'repeat(4, 1fr)']}
          w="100%">
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.title}</FormLabel>

              <Box sx={infoStyles}>{getTicketTitle(ticket)}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.unit}</FormLabel>
              <Box sx={infoStyles}>
                {(ticket?.apartment?.info &&
                  getUnitLabel(ticket.apartment.info)) ||
                  '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.assignee}</FormLabel>
              <Box sx={infoStyles}>
                {ticket?.assigned_user ? (
                  <CustomTagTitle
                    type="text"
                    status={ticket?.assigned_user?.status}
                    name={
                      renderFullName(
                        ticket?.assigned_user?.first_name,
                        ticket?.assigned_user?.last_name
                      ) || '-'
                    }
                  />
                ) : (
                  '-'
                )}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.house_owner}</FormLabel>
              <Box sx={infoStyles}>
                {renderFullName(
                  ticket?.owner?.first_name,
                  ticket?.owner?.last_name
                ) || '-'}
              </Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.priority_label}</FormLabel>
              <Box sx={infoStyles}>{getTag(ticket?.priority)}</Box>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl>
              <FormLabel sx={labelStyles}>{strings.descriptions}</FormLabel>
              <Box sx={infoStyles}>{ticket?.description || '-'}</Box>
            </FormControl>
          </GridItem>
        </Grid>
        {imageFiles.length >= 0 && (
          <FormLabel sx={labelStyles}>{strings.document}</FormLabel>
        )}
        <Grid gap="2" templateColumns="repeat(auto-fit, 100px)" w="100%">
          {imageFiles?.map((item, index) => (
            <GridItem key={item.id}>
              <Box
                position="relative"
                boxSize="100px"
                _hover={{
                  opacity: '60%',
                  background: 'rgba(168, 169, 175, 0.78);',
                }}>
                <Image
                  className="tickets-image"
                  src={item.file_url}
                  boxSize="100px"
                  fallbackSrc={FallBackImage}
                  objectFit="cover"
                  alt={item.file_name}
                  sx={hoverImageStyle}
                />

                <Flex className="hidden-icon" h="100%" w="100%">
                  <Icon
                    onClick={() => handleOpenModal(index)}
                    as={FaSearchPlus}
                    sx={imageSearchIconStyle}
                  />
                  <Icon
                    h={6}
                    w={6}
                    sx={imageDownloadIconStyle}
                    as={BiCloudDownload}
                    onClick={() => downloadFile(imageFiles[imageIndex])}
                  />
                </Flex>
              </Box>
            </GridItem>
          ))}
          <Modal
            isOpen={openImageModal}
            isCentered
            size="4xl"
            onClose={handleCloseModal}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Tooltip
                  placement="top"
                  hasArrow
                  label={strings.download_image}
                  fontSize="sm">
                  <Button
                    variant="outline"
                    onClick={() => downloadFile(imageFiles[imageIndex])}>
                    <Icon as={BiCloudDownload} w="6" h="6" />
                  </Button>
                </Tooltip>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Stack>
                  {imageFiles?.length > 1 && (
                    <Icon
                      h={8}
                      w={8}
                      as={BiChevronLeft}
                      sx={btnLeft}
                      onClick={onDecrement}
                    />
                  )}
                  <Image
                    objectFit="scale-down"
                    src={imageFiles[imageIndex]?.file_url}
                    alt={imageFiles[imageIndex]?.file_name}
                    sx={sliderImage}
                  />

                  {imageFiles?.length > 1 && (
                    <Icon
                      h={8}
                      w={8}
                      as={BiChevronRight}
                      sx={btnRight}
                      onClick={onIncrement}
                    />
                  )}
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Grid>
        <Grid gap="4" templateColumns="repeat(1, 1fr)" w="100%">
          {ticket?.ticket_attachments.length > 0 && (
            <GridItem>
              <FormControl>
                <List>
                  {otherFiles?.map((res) => {
                    return (
                      <ListItem
                        key={res.id}
                        color="grey.300"
                        _hover={{
                          cursor: 'pointer',
                          color: 'blue.300',
                          textDecoration: 'underline',
                        }}>
                        <a onClick={() => downloadFile(res)}>
                          <FileDetails
                            fileName={res.file_name}
                            fileSize={res.file_size}
                            mimeType={res.mime_type}
                          />
                        </a>
                      </ListItem>
                    );
                  })}
                </List>
              </FormControl>
            </GridItem>
          )}
        </Grid>
      </Stack>
    </>
  );
};
export default TicketInfo;
