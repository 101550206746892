import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    paragraph: '#7C7D8F',
    heading: '#424360',
    primary: {
      50: '#dff0f7',
      100: '#a2d3e8',
      200: '#6cb8d9',
      300: '#3c9fc9',
      400: '#1388ba',
      500: '#007eb3',
      600: '#00648f',
      700: '#00577d',
      800: '#003e59',
      900: '#003247',
    },
    blackgreen: {
      400: '#384549',
      500: '#333F43',
    },
    green: {
      400: '#34d399',
      500: '#10b981',
      600: '#7CB499',
    },
    red: {
      400: '#f56565',
      500: '#f23535',
      100: '#FF7A7A',
    },
    cyan: {
      400: '#0bc5ea',
      500: '#00b5d8',
    },
    teal: {
      400: '#4cbab4',
      500: '#38b2ac',
    },
    orang: {
      400: '#ffa94d',
      500: '#ffa647',
    },
    purple: {
      400: '#a78bfa',
      500: '#8b5cf6',
    },

    pink: {
      400: '#f472b6',
      500: '#ec4899',
    },
    blue: {
      10: '#ecf3ff',
    },
    malibu: {
      400: '#7f90ff',
      500: '#7083ff',
    },
    purple2: {
      400: '#aa81de',
      500: '#a073da',
    },
    brown: {
      400: '#918f77',
      500: '#858268',
    },
    darkbrown: {
      400: '#a99e66',
      500: '#9f9355',
    },
    green2: {
      400: '#7daa47',
      500: '#6fa032',
    },
    blue2: {
      400: '#1caac8',
      500: '#03a0c2',
    },
    blue3: {
      400: '#519ac3',
      500: '#3e8fbc',
    },
    pink2: {
      400: '#bf4687',
      500: '#b8317a',
    },
    burlywood: {
      400: '#d7ae73',
      500: '#d7ac70',
    },
    yellow: {
      400: '#fcb324',
      500: '#f5a303',
    },
    sunrise: {
      400: '#ea7d61',
      500: '#e76f51',
    },
    greygreen: {
      400: '#6BA163',
      500: '#5F9656',
    },
    chat: {
      400: '#6C90F7',
      500: '#6283DF',
    },
    grey: {
      400: '#A2AEBE',
      600: '#6B7B8C',
    },
  },
  styles: {
    global: {
      'html,body': {
        bg: '#f5f5f5',
        fontSize: '14px',
      },
    },
  },
  fonts: {
    heading: 'Poppins',
    body: 'Roboto',
  },

  shadows: {
    box: '0px 0px 60px rgba(0, 0, 0, 0.06)',
    input: '0px 1px 0px #EFF0F6',
  },
  components: {
    Popover: {
      baseStyle: {
        popper: {
          width: 'fit-content',
          maxWidth: 'fit-content',
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 'sm',
        fontWeight: 'normal',
      },
      sizes: {
        lg: {
          fontSize: '14px',
        },
      },
    },

    Checkbox: {
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Text: {
      baseStyle: {
        color: 'paragraph',
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            borderRadius: 'sm',
            shadow: 'input',
          },
        },
        lg: {
          field: {
            borderRadius: 'sm',
            shadow: 'input',
            fontSize: '14px',
          },
        },
      },

      variants: {
        outline: {
          addon: {
            bg: '#f8f8f8',
            borderRadius: 'sm',
            fontSize: 'sm',
          },
        },
      },

      defaultProps: {
        size: 'lg',
        _placeholder: {
          color: 'red',
        },
      },
    },
    NumberInput: {
      defaultProps: {
        size: 'lg',
      },
      sizes: {
        lg: {
          field: {
            borderRadius: 'sm',
            shadow: 'input',
            fontSize: '14px',
          },
        },
      },
    },
    Textarea: {
      sizes: {
        md: {
          borderRadius: 'sm',
          shadow: 'input',
        },
      },
    },
    Heading: {
      baseStyle: {
        color: 'heading',
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        fontWeight: 'semibold',
        mb: '3',
      },
    },

    Select: {
      defaultProps: {
        size: 'lg',
      },
      sizes: {
        md: {
          field: {
            borderRadius: 'sm',
            shadow: 'input',
          },
        },
        lg: {
          field: {
            borderRadius: 'sm',
            shadow: 'input',
            fontSize: '14px',
          },
        },
      },
    },
    Table: {
      baseStyle: {
        tr: {
          '&:last-of-type': {
            td: {
              borderBottom: 'none',
            },
          },
          td: {
            color: 'paragraph',
          },
          th: {
            fontFamily: 'Roboto',
            fontWeight: 'medium',
            fontSize: '14px',
            textTransform: 'none',
            color: 'heading',
          },
        },
      },
    },
    Card: {},
  },
});

export default theme;
