import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import FAQResource from 'api/faq';
import FAQGroupResource from 'api/faq-groups';
import { wrapperStyles } from 'assets/css/commonStyles';
import CustomChakraSelect from 'components/common/CustomChakraSelect';
import Pagination from 'components/common/Pagination';
import TableSkeletonLoader from 'components/common/TableSkeletonLoader';
import FaqListItem from 'components/faq/FaqListItem';
import { strings } from 'config/localization';
import { DEFAULT_PAGE_SIZE, INITIAL_CURRENT_PAGE } from 'constants/common';
import routes from 'constants/routes';
import { FAQSchema } from 'constants/schema';
import useCustomToast from 'hooks/useCustomToast';
import { useDebounce } from 'hooks/useDebounce';
import { useFilter } from 'hooks/useFilter';
import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BiFilter, BiSearch } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  changeURL,
  getStartingSerialNumber,
} from 'utils';

type FilterParams = {
  currentPage: number;
  pageSize: number;
  keyword: string;
  status: string;
  group_id: string;
  type: string;
};

const Faqs: React.FC = () => {
  let faqAPI = new FAQResource();
  let faqGroupAPI = new FAQGroupResource();

  const history = useHistory();
  const { search } = useLocation();
  const { searchValues, updateUrl } = useFilter();

  const [filterParams, setFilterParams] = useState<FilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    keyword: searchValues.keyword ?? '',
    status: searchValues.status ?? '',
    group_id: searchValues.group_id ?? '',
    type: searchValues.type ?? '',
  });

  const { keyword, ...restFilters } = filterParams;
  const debouncedKeyword = useDebounce(keyword);
  const { showErrorToast } = useCustomToast();

  const queryList = useQuery(
    [`faq-list`, debouncedKeyword, restFilters],
    async () => {
      const queryParams: Record<string, string> = {
        page: String(filterParams.currentPage),
        limit: String(filterParams.pageSize),
      };
      if (filterParams.keyword) queryParams.keyword = debouncedKeyword;
      if (filterParams.status) queryParams.status = filterParams.status;
      if (filterParams.group_id) queryParams.group_id = filterParams.group_id;
      if (filterParams.type) queryParams.type = filterParams.type;
      const res = await faqAPI.list(queryParams);
      return res.data;
    },
    {
      onError: () => showErrorToast(),
    }
  );
  const {
    data: faqsQuery,
    isLoading: faqsLoading,
    error: faqsError,
  } = queryList;

  const { data: faqGroupList } = useQuery(
    ['faq-group-list'],
    async () => {
      const res = await faqGroupAPI.list();
      return res.data?.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const faqGroupOptions = useMemo(() => {
    return faqGroupList?.map((faqGroup) => {
      return {
        label: faqGroup.name,
        value: faqGroup.id,
      };
    });
  }, [faqGroupList]);

  const handleInputChange = (
    e: FormEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilterParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    const data: FilterParams = {
      ...filterParams,
      [name]: value,
    };
    const searchURL = changeURL(data);
    history.push(`?${searchURL}`);
  };

  const handleReset = () => {
    setFilterParams((prevState) => ({
      ...prevState,
      currentPage: INITIAL_CURRENT_PAGE,
      keyword: '',
      status: '',
      group_id: '',
      type: '',
    }));
    history.push(routes.faq.faqList.list);
  };

  /**
   * Handle filter params submit
   */

  const startingSN = useMemo(() => {
    return getStartingSerialNumber(
      filterParams.currentPage,
      filterParams.pageSize
    );
  }, [filterParams.currentPage, filterParams.pageSize]);

  useEffect(() => {
    updateUrl(filterParams);
  }, [updateUrl, filterParams]);

  return (
    <>
      <Helmet>
        <title>
          {strings.faq} | {strings.all_faq}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
              {strings.faq}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink as={RouterLink} to={routes.faq.faqList.list}>
              {strings.all_faq}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg" textTransform="capitalize">
            {strings.faq}
          </Heading>
          <RouterLink to={routes.faq.faqList.add}>
            <Button colorScheme="primary" size="lg">
              {strings.add_faq}
            </Button>
          </RouterLink>
        </Flex>
        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          boxShadow="box"
          defaultIndex={0}>
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <Stack sx={wrapperStyles}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <Stack direction="column" spacing="4">
                    <Stack
                      direction={['column', 'column', 'row']}
                      spacing="4"
                      align={['start', 'start', 'end']}>
                      <Grid
                        gap={['4', '4', '6']}
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(3, 1fr)',
                        ]}
                        w="100%">
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.search}</FormLabel>
                            <InputGroup>
                              <InputLeftElement
                                pointerEvents="none"
                                h="100%"
                                children={<BiSearch />}
                                color="gray.800"
                              />
                              <Input
                                name="keyword"
                                size="lg"
                                value={filterParams.keyword}
                                type="text"
                                onChange={handleInputChange}
                              />
                            </InputGroup>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.group}</FormLabel>
                            <CustomChakraSelect
                              name="group_id"
                              size="lg"
                              value={filterParams.group_id}
                              placeholder={strings.select_group}
                              onChange={handleInputChange}
                              rounded="sm">
                              {faqGroupOptions?.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {capitalizeFirstLetter(item.label)}
                                </option>
                              ))}
                            </CustomChakraSelect>
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel>{strings.status}</FormLabel>
                            <CustomChakraSelect
                              name="status"
                              size="lg"
                              placeholder={strings.select_status}
                              value={filterParams.status}
                              onChange={handleInputChange}
                              rounded="sm">
                              <option value="1">{strings.active}</option>
                              <option value="0">{strings.in_active}</option>
                            </CustomChakraSelect>
                          </FormControl>
                        </GridItem>
                      </Grid>
                      <Stack direction="row" spacing="4">
                        <Button
                          size="lg"
                          colorScheme="primary"
                          type="button"
                          variant="outline"
                          onClick={() => handleReset()}>
                          {strings.reset_filter}
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </form>
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <Stack sx={wrapperStyles}>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>{strings.sn}</Th>
                  <Th>{strings.question}</Th>
                  <Th>{strings.group}</Th>
                  <Th>{strings.status}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {faqsQuery?.data?.map((faqItem: FAQSchema, index: number) => (
                  <FaqListItem
                    data={faqItem}
                    key={faqItem.id}
                    index={startingSN + index}
                    search={search}
                  />
                ))}
                {faqsLoading && <TableSkeletonLoader rows={7} cols={5} />}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Pagination
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          dataList={queryList}
        />
      </Stack>
    </>
  );
};

export default Faqs;
