import routes from 'constants/routes';
import { ServiceListSchema } from 'constants/schema';
import {
  BiBuildingHouse,
  BiFile,
  BiHelpCircle,
  BiMailSend,
  BiPurchaseTag,
  BiUser,
} from 'react-icons/bi';

export const serviceListData: ServiceListSchema[] = [
  {
    id: 1,
    serviceName: 'user',
    hideForLabel: 'user-service',
    tooltipLabel: 'user_management_system',
    icon: BiUser,
    splashLabel: 'user_management',
    rootLink: '/users',
    routes: [
      [routes.users.list, 'read:user'],
      [routes.users.roles.list, 'read:user'],
      [routes.users.loginLog.list, 'read:login-log'],
    ],
    subMenusComp: 'UserSubMenuList',
  },
  {
    id: 3,
    serviceName: 'ticket',
    hideForLabel: 'ticket-service',
    tooltipLabel: 'ticket_system',
    icon: BiPurchaseTag,
    splashLabel: 'ticket',
    rootLink: '/ticket',

    routes: [
      [routes.ticket.task.board, 'read:ticket'],
      [routes.ticket.list.default, 'read:ticket'],
    ],
    subMenusComp: 'TicketSubMenuList',
  },
  {
    id: 9,
    serviceName: 'dms',
    hideForLabel: 'dms-service',
    tooltipLabel: 'document_management_system',
    icon: BiFile,
    splashLabel: 'dms',
    rootLink: '/dms',
    routes: [
      [routes.dms.documents.upload, 'manage:dms-owner', 'manage:dms-ownership'],
    ],
    subMenusComp: 'DMSSubMenuList',
  },
  {
    id: 10,
    serviceName: 'oms',
    hideForLabel: 'oms-service',
    tooltipLabel: 'object_management_system',
    icon: BiBuildingHouse,
    splashLabel: 'objects',
    rootLink: '/oms',
    routes: [
      [routes.oms.units.list, 'read:oms', 'is:owner'],
      [routes.oms.objects.list, 'read:oms', 'is:owner'],
    ],
    subMenusComp: 'OmsSubMenuList',
  },
  {
    id: 16,
    serviceName: 'faq',
    hideForLabel: 'faq-service',
    tooltipLabel: 'frequently_ask_question',
    icon: BiHelpCircle,
    splashLabel: 'faq',
    rootLink: '/faq',
    routes: [
      [routes.faq.faqList.list, 'read:faq'],
      [routes.faq.faqGroup.list, 'read:faq-group'],
    ],
    subMenusComp: 'FAQSubMenuList',
  },
  {
    id: 17,
    serviceName: 'newsletter',
    hideForLabel: 'newsletter-service',
    tooltipLabel: 'newsletter',
    icon: BiMailSend,
    splashLabel: 'newsletter',
    rootLink: '/newsletter',
    routes: [[routes.newsletter.newsletters.list, 'read:newsletter']],
    subMenusComp: 'NewsletterSubMenuList',
  },
];
