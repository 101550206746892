/**
 * Utils that are specific to List Info Business Solutions Domain
 */
import { Permission, UserPermission } from 'constants/interfaces';

/**
 * Get an array combining common permissions from two permission arrays
 *
 * @param permissionArrayOne
 * @param permissionArrayTwo
 * @returns single array having common permissions
 */
export const getCommonPermissions = (
  permissionArrayOne: Permission[],
  permissionArrayTwo: Permission[]
) => {
  return permissionArrayOne?.filter(({ name: id1 }) =>
    permissionArrayTwo?.some(({ name: id2 }) => id2 === id1)
  );
};

/**
 * Check permissions for given route by tallying userPermission with allowedPermissions
 *
 * @param userPermissions : Permissions provided for logged in user
 * @param allowedPermissions
 * @returns true if permitted
 */
export const checkPermissions = (
  userPermissions: UserPermission,
  allowedPermissions: string[]
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  const permissions = [...userPermissions.direct, ...userPermissions.via_role];
  return allowedPermissions.every((allowedPer) =>
    permissions.some((permission: Permission) => allowedPer === permission.name)
  );
};

export const checkSomePermissions = (
  userPermissions: UserPermission,
  allowedPermissions: string[]
) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  const permissions = [...userPermissions.direct, ...userPermissions.via_role];
  return permissions.some((permission) =>
    allowedPermissions.includes(permission.name)
  );
};
