import { withAuthState } from 'components/hoc/auth';
import routes from 'constants/routes';
import { AuthProps } from 'constants/schema';
import { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props extends AuthProps {
  path: string;
  component: React.ComponentType;
}

/**
 * Component to authenticate private routes.
 */
class AuthenticateRoute extends Component<Props> {
  render() {
    let { isLoggedIn, path, component } = this.props;

    return isLoggedIn ? (
      <Route path={path} component={component} />
    ) : (
      <Redirect to={routes.auth.login} />
    );
  }
}

export default withAuthState(AuthenticateRoute);
