import { Input, InputProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

const CustomDateInput = forwardRef(
  (props: InputProps, ref: React.LegacyRef<HTMLInputElement>) => {
    return <Input {...props} ref={ref} inputMode="none" />;
  }
);
CustomDateInput.displayName = 'CustomDateInput';

export default CustomDateInput;
