import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ScaleFade,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { setNewPassword } from 'api/auth';
import { AxiosError, AxiosResponse } from 'axios';
import LogoInformation from 'components/auth/LogoInformation';
import LanguageMenu from 'components/common/LanguageMenu';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiChevronLeft, BiKey } from 'react-icons/bi';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { validatePassword } from 'utils/validate';

export interface SetPasswordFormValues {
  new_password: string;
  new_password_confirmation: string;
}

const SetPassword: React.FC = () => {
  const newPassword = useRef({});
  const [errMsg, setErrMsg] = useState<string>();
  const toast = useToast();
  const history = useHistory();
  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm<SetPasswordFormValues>();

  const setPasswordMutation = useMutation<
    AxiosResponse<SetPasswordFormValues>,
    AxiosError,
    SetPasswordFormValues
  >((data) => setNewPassword(data), {
    onSuccess: () => {
      toast({
        title: strings.login_with_new_password,
        status: 'success',
        isClosable: true,
      });
      history.push(routes.auth.login);
    },
    onError: (error) => {
      if (error?.response?.data?.errors?.token)
        setErrMsg(strings.invalid_token);
      if (error?.response?.data?.errors?.password)
        setErrMsg(error?.response?.data?.errors?.password[0]);
    },
  });
  newPassword.current = watch('new_password', '');

  const onSubmit = (data: SetPasswordFormValues) => {
    setPasswordMutation.mutate(data);
  };

  const goBackToLoginPage = () => {
    history.push(routes.auth.login);
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Stack pos="absolute" right="5%">
        <LanguageMenu />
      </Stack>
      <Stack direction="column" spacing="12" alignItems="center" py="6">
        <LogoInformation />
        <Box bg="white" p="14" shadow="box" rounded="sm">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" spacing="8">
              <Stack direction="column" spacing="4">
                <Heading as="h1" size="xl">
                  {strings.create_password}
                </Heading>
                <Text color="gray.500">{strings.create_password_subtitle}</Text>
                {setPasswordMutation.isError && (
                  <ScaleFade in={setPasswordMutation.isError}>
                    <Alert status="error">
                      <AlertIcon />
                      {errMsg}
                    </Alert>
                  </ScaleFade>
                )}
              </Stack>
              <FormControl
                colorScheme="primary"
                isInvalid={!!errors.new_password}>
                <FormLabel color="gray.600">{strings.password}</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={<Icon color="gray.300" w="4" h="4" as={BiKey} />}
                  />
                  <Input
                    id="new_password"
                    type="password"
                    placeholder={strings.new_password}
                    {...register('new_password', {
                      required: strings.required,
                      validate: (value) =>
                        validatePassword(value) || strings.msg_valid_password,
                    })}
                  />
                </InputGroup>
                <FormHelperText>{strings.password_helper_text}</FormHelperText>
                <FormErrorMessage>
                  <>{errors.new_password && errors.new_password?.message}</>
                </FormErrorMessage>
              </FormControl>

              <FormControl
                colorScheme="primary"
                isInvalid={!!errors.new_password_confirmation}>
                <FormLabel color="gray.600">
                  {strings.confirm_password}
                </FormLabel>
                <InputGroup>
                  <InputLeftElement
                    children={<Icon color="gray.300" w="4" h="4" as={BiKey} />}
                  />
                  <Input
                    id="new_password_confirmation"
                    type="password"
                    placeholder={strings.confirm_password}
                    {...register('new_password_confirmation', {
                      required: strings.required,
                      validate: (value) =>
                        value === newPassword.current ||
                        strings.not_match_new_confirm_password,
                    })}
                  />
                </InputGroup>
                <FormErrorMessage>
                  <>
                    {errors.new_password_confirmation &&
                      errors.new_password_confirmation?.message}
                  </>
                </FormErrorMessage>
              </FormControl>
              <VStack spacing="6">
                <Button
                  w="full"
                  type="submit"
                  size="lg"
                  fontSize="md"
                  fontWeight="semibold"
                  colorScheme="primary"
                  textTransform="uppercase"
                  boxShadow="md"
                  isLoading={setPasswordMutation.isLoading}>
                  {strings.save_password}
                </Button>
                <Button
                  variant="link"
                  color="gray.600"
                  isDisabled={setPasswordMutation.isLoading}
                  onClick={goBackToLoginPage}
                  fontWeight="medium"
                  leftIcon={<Icon as={BiChevronLeft} w="6" h="6" />}>
                  {strings.back_to_login}
                </Button>
              </VStack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Box>
  );
};

export default SetPassword;
