import axios, { AxiosPromise, AxiosResponse, Canceler } from 'axios';
import { DataWrapper } from 'constants/schema';
import http from 'utils/http';

/**
 * Simple REST resource class
 */

const CancelToken = axios.CancelToken;
let cancel: Canceler;

class Resource<T> {
  uri: string;

  constructor(uri: string) {
    this.uri = uri;
  }

  list(
    query?: Record<string, unknown>
  ): Promise<AxiosResponse<DataWrapper<T[]>>> {
    if (cancel) {
      cancel(); // cancel request
    }
    return http({
      url: '/' + this.uri,
      method: 'get',
      params: query,
      cancelToken: new CancelToken(function executor(c) {
        cancel = c;
      }),
    });
  }

  get(
    id: string | number,
    query?: Record<string, string>
  ): AxiosPromise<DataWrapper<T>> {
    return http({
      url: '/' + this.uri + '/' + id,
      params: query,
      method: 'get',
    });
  }
  store<T>(resource: unknown, headers?: unknown): AxiosPromise<T> {
    return http({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
      headers: headers,
    });
  }
  // TODO: Remove `undefined` as possible parameter of the next two functions
  // and rather properly refactor the components such that `undefined` cannot
  // show up here in the first place.
  update(id: string | number | undefined, resource: unknown) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'put',
      data: resource,
    });
  }
  destroy(id: string | number | undefined) {
    return http({
      url: '/' + this.uri + '/' + id,
      method: 'delete',
    });
  }
}

export { Resource as default };
