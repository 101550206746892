import {
  setIsLoggedIn,
  setLoggedInUser,
  setLoggingIn,
  setPermissions,
} from 'actions/data/auth';
import { getAuthPermission, getAuthUser, login } from 'api/auth';
import { strings } from 'config/localization';
import {
  ADMIN_OR_EMPLOYEE_ROLE_GROUP,
  invalid2FA,
  message2FA,
} from 'constants/common';
import { AuthProps } from 'constants/schema';
import React from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import * as authService from 'services/auth';
import { RootState } from 'store';
import { Diff } from 'utils/types';

/**
 * Auth state Higher Order Component.
 * Use this HOC if you need to use/modify User state.
 */
function withAuthState<BaseProps extends AuthProps>(
  BaseComponent: React.ComponentType<BaseProps>
) {
  const mapStateToProps = (state: RootState) => {
    const { isLoggedIn, isLoggingIn, user, permissions } = state.data.auth;

    return {
      isLoggedIn,
      isLoggingIn,
      loggedInUser: user,
      permissions: permissions,
    };
  };

  const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
    return bindActionCreators(
      { setLoggingIn, setIsLoggedIn, setLoggedInUser, setPermissions },
      dispatch
    );
  };

  type HocProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

  class AuthHoc extends React.Component<HocProps> {
    /**
     * Login user and save tokens and user data.
     *
     * @param {string} email
     * @param {string} password
     */
    login = async (email: string, password: string, verifyCode?: string) => {
      try {
        const { setLoggingIn, setIsLoggedIn, setLoggedInUser, setPermissions } =
          this.props;

        setLoggingIn(true);

        const { data } = await login({
          email,
          password,
          'verify-code': verifyCode,
          role_group: ADMIN_OR_EMPLOYEE_ROLE_GROUP,
        });

        if (data?.message == message2FA) {
          return 'enable_2fa';
        } else if (data?.message == invalid2FA) {
          throw new Error('Invalid Auth Code');
        }

        authService.persist({
          accessToken: data.access_token,
          refreshToken: data?.refresh_token,
          expiryTime: data?.expires_in,
        });

        let response = await getAuthUser();

        /**
         * Fetch permissions for a given users
         */
        const permissions = await getAuthPermission();

        setLoggingIn(false);
        setIsLoggedIn(true);
        setLoggedInUser(response.data.data);
        setPermissions(permissions?.data?.data);

        const language = response.data.data.language_code;

        if (language) {
          strings.setLanguage(language);
          localStorage.setItem('language', language);
        }
      } catch (err) {
        authService.clearStorage();
        setLoggingIn(false);
        throw new Error('Invalid Credentials');
      }
    };

    logout = () => {
      authService.logout();
    };

    render() {
      const {
        isLoggedIn,
        isLoggingIn,
        loggedInUser,
        permissions,
        setLoggingIn,
        setIsLoggedIn,
        setLoggedInUser,
        setPermissions,
        ...restProps
      } = this.props;

      return (
        <BaseComponent
          {...(restProps as BaseProps)}
          isLoggedIn={isLoggedIn}
          isLoggingIn={isLoggingIn}
          loggedInUser={loggedInUser}
          permissions={permissions}
          setLoggingIn={setLoggingIn}
          setIsLoggedIn={setIsLoggedIn}
          setLoggedInUser={setLoggedInUser}
          setPermissions={setPermissions}
          login={this.login}
          logout={this.logout}
        />
      );
    }
  }

  const ConnectedAuthHoc = connect<
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps>,
    Diff<BaseProps, AuthProps>,
    RootState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(AuthHoc);

  return ConnectedAuthHoc;
}

export { withAuthState };
