import { UserPermission } from 'constants/interfaces';
import PermissionRequest from 'constants/PermissionRequest';
import { AuthState } from 'constants/schema';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { checkSomePermissions } from 'utils/listInfo';

interface Props {
  hideFor: string;
  children: ReactElement;
  renderNoAccess: (data: ReactElement) => ReactElement;
  userPermissions: UserPermission;
}
const HideControl: React.FC<Props> = ({
  hideFor,
  children,
  renderNoAccess,
  userPermissions,
}) => {
  switch (hideFor) {
    case 'user-service':
      if (
        process.env.REACT_APP_USER_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:user'],
          PermissionRequest['read:login-log'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'ticket-service':
      if (
        process.env.REACT_APP_TICKET_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:ticket'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'dashboard':
      if (process.env.REACT_APP_DASHBOARD === 'true') {
        return renderNoAccess(children);
      }
      break;

    case 'oms-service':
      if (
        process.env.REACT_APP_OMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:oms'],
          PermissionRequest['is:owner'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'dms-service':
      if (
        process.env.REACT_APP_DMS_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:owner-dms'],
          PermissionRequest['read:object-dms'],
          PermissionRequest['is:owner'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'newsletter-service':
      if (
        process.env.REACT_APP_NEWSLETTER_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:newsletter'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;

    case 'faq-service':
      if (
        process.env.REACT_APP_FAQ_SERVICE === 'true' &&
        checkSomePermissions(userPermissions, [
          PermissionRequest['read:faq'],
          PermissionRequest['read:faq-group'],
        ])
      ) {
        return renderNoAccess(children);
      }
      break;
  }

  return renderNoAccess(<></>);
};

export default connect((state: { data: { auth: AuthState } }) => ({
  userPermissions: state?.data?.auth?.permissions,
}))(HideControl);
