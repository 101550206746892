import {
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import UserResource from 'api/user';
import { wrapperStyles } from 'assets/css/commonStyles';
import { CenterSpinner } from 'components/common/CenterSpinner';
import FormCancelButton from 'components/common/FormCancelButton';
import OwnerForm from 'components/user/OwnerForm';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import { OwnerSchema } from 'constants/schema';
import React from 'react';
import { Helmet } from 'react-helmet';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { renderFullName } from 'utils';

type EditOwnerParams = {
  id: string;
};

const EditOwner: React.FC = () => {
  const { id } = useParams<EditOwnerParams>();
  const toast = useToast();
  const queryClient = useQueryClient();
  const history = useHistory();

  const methods = useForm<OwnerSchema>();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const userAPI = new UserResource();

  const userQuery = useQuery(
    [`user${id}-edit`, id],
    () => userAPI.getOwner(id).then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0, // For update on navigation if the owner changed their data.
    }
  );

  const updateUser = useMutation((data: OwnerSchema) =>
    userAPI.updateOwner(id, data)
  );

  const onSubmit = (data: OwnerSchema) => {
    const mappedData = { ...data };

    if (!mappedData.deceased) {
      mappedData.deceased_since = '';
    }
    delete mappedData.deceased;

    if (!mappedData.address_forwarding) {
      mappedData.address_forwarding_salutation = '';
      mappedData.address_forwarding_country_code = '';
      mappedData.address_forwarding_title = '';
      mappedData.address_forwarding_reason = '';
      mappedData.address_forwarding_adittion = '';
      mappedData.address_forwarding_email = '';
      mappedData.address_forwarding_house_number = '';
      mappedData.address_forwarding_phone = '';
      mappedData.address_forwarding_prename = '';
      mappedData.address_forwarding_street = '';
      mappedData.address_forwarding_surname = '';
      mappedData.address_forwarding_town = '';
      mappedData.address_forwarding_zip_code = '';
    }

    updateUser.mutate(mappedData, {
      onSuccess: () => {
        toast({
          title: strings.user_updated,
          status: 'success',
          isClosable: true,
        });
        queryClient.invalidateQueries('userList');
        queryClient.invalidateQueries(`user${id}-edit`);
        history.push(routes.users.list);
      },
    });
  };

  if (userQuery.isLoading) {
    return <CenterSpinner />;
  }

  if (userQuery.isError) {
    return (
      <Box>
        <Alert status="error">
          <AlertIcon />
          {strings.user_not_found}
        </Alert>
      </Box>
    );
  }

  const ownerData = userQuery?.data?.data;

  return (
    <>
      <Helmet>
        <title>
          {strings.user} | {strings.edit_owner}
        </title>
      </Helmet>
      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.users.list}>
              {strings.user}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink textTransform="capitalize">
              {renderFullName(
                userQuery?.data?.data?.first_name,
                userQuery?.data?.data?.last_name
              )}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading size="lg">
            {renderFullName(
              userQuery?.data?.data?.first_name,
              userQuery?.data?.data?.last_name
            )}
          </Heading>
        </Flex>

        <Stack sx={wrapperStyles}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack direction="column" spacing="4">
                {updateUser.isError && (
                  <Alert status="error">
                    <AlertIcon />
                    {strings.user_udpate_failed}
                  </Alert>
                )}
                <OwnerForm userData={ownerData} />
                <ButtonGroup justifyContent="flex-end">
                  <FormCancelButton
                    isLoading={updateUser.isLoading}
                    size="lg"
                  />

                  <Button
                    isDisabled={!methods.formState.isDirty}
                    size="lg"
                    colorScheme="primary"
                    type="submit"
                    isLoading={updateUser.isLoading}>
                    {strings.save}
                  </Button>
                </ButtonGroup>
              </Stack>
            </form>
          </FormProvider>
        </Stack>
      </Stack>

      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{strings.discard_changes}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{strings.discard_changes_warning}</ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button variant="outline" onClick={onClose}>
                {strings.no_thank_you}
              </Button>
              <Button colorScheme="blue" onClick={() => history.goBack()}>
                {strings.discard}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditOwner;
