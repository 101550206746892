import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Td,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react';
import NewsletterAPI from 'api/newsletter';
import { selecLabelColorScheme, selectBGScheme } from 'assets/css/commonStyles';
import { strings } from 'config/localization';
import PermissionRequest from 'constants/PermissionRequest';
import routes from 'constants/routes';
import {
  NewsletterDetail,
  NewsletterStatus,
  NewsletterStatusEnum,
} from 'constants/schema';
import useCheckPermission from 'hooks/useCheckPermission';
import React, { useState } from 'react';
import { BiEdit, BiShow, BiTrash } from 'react-icons/bi';
import { useMutation } from 'react-query';
import AccessControl from 'services/AccessControl';
import { localizeGroupOfRecipient } from 'utils';
import { DateFormat } from 'utils/DateFormat';
import PreviewNewsletterModal from './PreviewNewsletterModal';

interface Props {
  newsletterItem: NewsletterDetail;
  index: number;
  status: NewsletterStatus;
  search: string;
}
const NewsletterListItem: React.FC<Props> = (props) => {
  const { newsletterItem, status } = props;
  const newsletterDetailsAPI = new NewsletterAPI();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const toast = useToast();
  let draftPage: boolean = status === NewsletterStatusEnum.DRAFT;

  const composeNewsletterContent = {
    recipient_group: newsletterItem?.recipient_group ?? [],
    subject: newsletterItem?.subject ?? '',
    message: newsletterItem?.message ?? '',
    file: {
      fileName: newsletterItem.newsletterFiles.map((file) => file.file_name),
      fileSize: newsletterItem.newsletterFiles.map((file) => file.file_size),
      fileUrl: newsletterItem.newsletterFiles.map((file) => file.file_url),
    },
    status: newsletterItem?.status ?? '',
  };

  const checkEditUserPermission = useCheckPermission(
    [PermissionRequest['manage:newsletter']],
    routes.newsletter.newsletters.edit.replace(
      ':id',
      newsletterItem.id.toString()
    )
  );

  const [deleteMsg, setDeleteMsg] = useState('');

  const deleteNewsletter = useMutation(
    () => newsletterDetailsAPI.destroy(newsletterItem.id),
    {
      onSuccess: () => {
        setDeleteModalOpen(false);
        toast({
          title: strings.newsletter_deleted,
          status: 'success',
          isClosable: true,
        });
      },
      onError: () => {
        setDeleteModalOpen(false);
        toast({
          title: strings.newsletter_delete_error,
          status: 'error',
          isClosable: true,
        });
      },
    }
  );

  const onDeletePress = () => {
    setDeleteMsg('');
    setDeleteModalOpen(true);
  };

  const onDeleteModalClose = () => {
    setDeleteMsg('');
    setDeleteModalOpen(false);
  };

  const onDeleteConfirm = () => {
    deleteNewsletter.mutate();
  };

  const onPreviewPress = () => {
    setPreviewModalOpen(true);
  };

  const onPreviewModalClose = () => {
    setPreviewModalOpen(false);
  };

  return (
    <Tr key={newsletterItem.id}>
      <Td>{newsletterItem?.subject}</Td>
      <Td>{DateFormat(newsletterItem?.updated_at)}</Td>
      <Td>
        <HStack>
          {newsletterItem?.recipient_group?.map((group) => (
            <Tag
              key={group}
              variant="solid"
              rounded="md"
              color={selecLabelColorScheme(group)}
              fontSize="sm"
              size="sm"
              bg={selectBGScheme(group)}>
              <TagLabel>{localizeGroupOfRecipient(group)}</TagLabel>
            </Tag>
          ))}
        </HStack>
      </Td>
      <Td>
        {status && status === NewsletterStatusEnum.DRAFT
          ? strings.draft
          : strings.dispatched}
      </Td>
      <Td>
        <Tooltip label="View" placement="top-start" hasArrow>
          <IconButton
            icon={<BiShow size="18" />}
            variant="link"
            aria-label={strings.view}
            color="green.300"
            minW="8"
            onClick={onPreviewPress}
          />
        </Tooltip>
        {draftPage && (
          <>
            <Link onClick={checkEditUserPermission}>
              <Tooltip hasArrow label={strings.edit_newsletter}>
                <IconButton
                  icon={<BiEdit size="18" />}
                  variant="link"
                  aria-label={strings.edit_newsletter}
                  color="blue.300"
                  minW="8"
                />
              </Tooltip>
            </Link>
            <Tooltip hasArrow label={strings.delete_newsletter}>
              <IconButton
                icon={<BiTrash size="18" />}
                variant="link"
                aria-label={strings.delete_newsletter}
                color="red.300"
                minW="8"
                onClick={onDeletePress}
              />
            </Tooltip>
          </>
        )}
        <PreviewNewsletterModal
          isOpen={isPreviewModalOpen}
          onClose={onPreviewModalClose}
          composeNewsletterContent={composeNewsletterContent}
        />
        <Modal
          isOpen={isDeleteModalOpen}
          isCentered
          onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{strings.delete_newsletter}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {strings.confirm_delete}
              {deleteMsg && (
                <Alert status="error" mb={2}>
                  <AlertIcon />
                  {deleteMsg}
                </Alert>
              )}
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <AccessControl
                  allowedPermissions={[PermissionRequest['manage:newsletter']]}
                  renderNoAccess={(data) => data}>
                  <Button
                    colorScheme="red"
                    onClick={onDeleteConfirm}
                    isLoading={deleteNewsletter.isLoading}>
                    {strings.delete}
                  </Button>
                </AccessControl>

                <Button variant="outline" onClick={onDeleteModalClose}>
                  {strings.cancel}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Td>
    </Tr>
  );
};

export default NewsletterListItem;
