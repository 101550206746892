import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { DateFormat, DateFormatFromNow } from 'utils/DateFormat';

interface Props {
  dateLabel: string;
}
const DateFromNowToolTip: React.FC<Props> = (props) => {
  const { dateLabel } = props;
  return (
    <Tooltip hasArrow label={DateFormat(dateLabel)} fontSize="sm">
      {DateFormatFromNow(dateLabel)}
    </Tooltip>
  );
};

export default DateFromNowToolTip;
