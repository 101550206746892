import { Box, List } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DMSSubMenuList,
  FAQSubMenuList,
  NewsletterSubMenuList,
  OmsSubMenuList,
  TicketSubMenuList,
  UserSubMenuList,
} from './SubMenus';

enum Path {
  Users = 'users',
  Ticket = 'ticket',
  Oms = 'oms',
  FAQ = 'faq',
  DMS = 'dms',
  Newsletter = 'newsletter',
}

const SidebarSubMenu: React.FC = () => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [location.pathname]);

  return (
    <Box position="relative" width="100%">
      {path && (
        <List
          p={['3', '4']}
          pt="12"
          spacing="4"
          fontSize="xs"
          color="white"
          height={['115vh', '120vh']}>
          {path === Path.Users && <UserSubMenuList />}
          {path === Path.Ticket && <TicketSubMenuList />}
          {path === Path.Oms && <OmsSubMenuList />}
          {path === Path.FAQ && <FAQSubMenuList />}
          {path === Path.DMS && <DMSSubMenuList />}
          {path === Path.Newsletter && <NewsletterSubMenuList />}
        </List>
      )}
    </Box>
  );
};

export default SidebarSubMenu;
