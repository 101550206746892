import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
} from '@chakra-ui/react';
import { strings } from 'config/localization';
import routes from 'constants/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  breadcrumb: string;
}

const UnitPageHeader: React.FC<Props> = (props) => {
  const { breadcrumb } = props;

  return (
    <>
      <Breadcrumb color="gray.400" size="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.oms.units.list}>
            {strings.units}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={RouterLink} to={routes.oms.units.list}>
            {strings.manage_units}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage color="gray.900">
          <BreadcrumbLink>{breadcrumb}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Flex gridGap="2">
        <Heading size="lg">{breadcrumb}</Heading>
      </Flex>
    </>
  );
};

export default UnitPageHeader;
