import { Box, Flex, Heading, Stack, Tooltip } from '@chakra-ui/react';
import TicketSkeletonLoader from 'components/common/TicketSkeletonLoader';
import { strings } from 'config/localization';
import { MetaSchema, TicketInfoSchema, TicketStatus } from 'constants/schema';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroll-component';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useLocation } from 'react-router-dom';
import { getTicketTitle, getUnitLabel, renderFullName } from 'utils';
import TaskListItem from './TaskListItem';

interface Props {
  ticketStatus: TicketStatus;
  ticketList: TicketInfoSchema[];
  ticketMeta: MetaSchema;
  isLoading: boolean;
  initialRender: boolean;
  handleLoadMore: (ticketStatus: TicketStatus) => void;
}

const getTicketBorderColor = (status: TicketStatus) => {
  switch (status) {
    case 'open':
      return 'blue.400';
    case 'in-progress':
      return 'purple.400';
    case 'done':
      return 'green.400';
    case 'closed':
      return 'red.400';
    default:
      return 'blue.400';
  }
};

const getTicketStatusName = (status: TicketStatus) => {
  switch (status) {
    case 'open':
      return strings.open;
    case 'in-progress':
      return strings.inprogress;
    case 'done':
      return strings.done;
    case 'closed':
      return strings.closed;
    default:
      return '';
  }
};

const MemoizedInnerList = React.memo(function InnerList(props: Props) {
  const {
    ticketStatus,
    ticketList,
    ticketMeta,
    handleLoadMore,
    isLoading,
    initialRender,
  } = props;

  const { search } = useLocation();

  return (
    <Stack bg="white" shadow="box" borderRadius="lg">
      <Flex
        justifyContent="space-between"
        pt="6"
        pb="4"
        mx="6"
        borderBottom="1px"
        borderColor={getTicketBorderColor(ticketStatus)}>
        <Heading size="sm">{getTicketStatusName(ticketStatus)}</Heading>
        <Tooltip hasArrow label={`${ticketMeta.total} ${strings.tickets}`}>
          <Heading size="sm" color="gray.400">
            {ticketMeta.total}
          </Heading>
        </Tooltip>
      </Flex>

      <Box h={['85vh', '80vh', '75vh', '100vh']}>
        {isLoading && initialRender && <TicketSkeletonLoader rows={3} />}
        <PerfectScrollbar
          className="show-scroll"
          id={`scrollable-ticket-parent-target-${ticketStatus}`}>
          <InfiniteScroll
            dataLength={ticketList.length}
            next={() => handleLoadMore(ticketStatus)}
            hasMore={ticketMeta.current_page < ticketMeta.last_page}
            loader={<TicketSkeletonLoader rows={3} />}
            scrollableTarget={`scrollable-ticket-parent-target-${ticketStatus}`}>
            {ticketList?.map((task: TicketInfoSchema, index: number) => {
              return (
                <Draggable
                  key={task.id}
                  draggableId={task.id.toString()}
                  index={index}>
                  {(provided) => (
                    <Box
                      bg="white"
                      py="2"
                      px="4"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}>
                      <TaskListItem
                        ticketId={task.id}
                        title={getTicketTitle(task) ?? '-'}
                        appointment_date={task?.appointment_date}
                        object={getUnitLabel(task?.apartment?.info)}
                        assignedUserStatus={task.assigned_user?.status}
                        assignedUserName={
                          renderFullName(
                            task.assigned_user?.first_name,
                            task.assigned_user?.last_name
                          ) ?? task.assigned_user?.name
                        }
                        priority={task.priority}
                        avatar={task.assigned_user?.image_url ?? ''}
                        search={search}
                      />
                    </Box>
                  )}
                </Draggable>
              );
            })}
          </InfiniteScroll>
        </PerfectScrollbar>
      </Box>
    </Stack>
  );
});

const TaskBoard = (props: Props) => {
  const {
    ticketStatus,
    ticketList,
    ticketMeta,
    handleLoadMore,
    isLoading,
    initialRender,
  } = props;

  return (
    <Box w="25%" minWidth="300px">
      <Droppable droppableId={ticketStatus} key={ticketStatus}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <MemoizedInnerList
              ticketStatus={ticketStatus}
              ticketList={ticketList}
              ticketMeta={ticketMeta}
              handleLoadMore={handleLoadMore}
              isLoading={isLoading}
              initialRender={initialRender}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </Box>
  );
};

export default TaskBoard;
