import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { changeURL } from 'utils';

export const useFilter = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const searchValues: Record<string, string> = {};
  for (let pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  const history = useHistory();

  const updateUrl = useCallback(
    (updatedData: Record<string, unknown>) => {
      const searchURL = changeURL(updatedData);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  return { searchValues, updateUrl };
};
