import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  Spinner,
  Stack,
  ToastId,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import TicketResource from 'api/ticket';
import { ExitFullScreen } from 'assets/icons';
import axios, { AxiosError } from 'axios';
import Column from 'components/task/TaskBoard';
import TicketAddMenu from 'components/ticket/TicketAddMenu';
import TicketSearch, {
  FilterParamsFromForm,
} from 'components/ticket/TicketSearch';
import { strings } from 'config/localization';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_REFETCH_TIME,
  INITIAL_CURRENT_PAGE,
} from 'constants/common';
import routes from 'constants/routes';
import {
  MetaSchema,
  TicketBoardMetaState,
  TicketBoardState,
  TicketInfoSchema,
  TicketStatus,
} from 'constants/schema';
import { useDebounce } from 'hooks/useDebounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Helmet } from 'react-helmet';
import { BiFilter } from 'react-icons/bi';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import { Entries } from 'type-fest';
import { changeURL, cleanData, isDateValid } from 'utils';
import { DateFormatYMD } from 'utils/DateFormat';
import { reorderTickets } from 'utils/reorder';
import { v4 as uuidv4 } from 'uuid';
import useLoggedInUser from '../../../hooks/useLoggedInUser';

type FilterParams = FilterParamsFromForm & {
  currentPage: number;
  pageSize: number;
};

type FilterParamsForURL = Omit<
  FilterParams,
  'created_at_start' | 'created_at_end' | 'status' // Is not a filter option for the list, only for the board
> & {
  created_at_start: string | null;
  created_at_end: string | null;
};

const TicketBoard = () => {
  /**
   * Initialize
   */
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const searchEntries = searchParams.entries();

  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const ticketBoardRef = useRef<HTMLDivElement>(null);

  const ticketBoardFullScreenHandler = () => {
    const fullscreenElement = document.fullscreenElement;
    if (!fullscreenElement) {
      ticketBoardRef.current?.requestFullscreen &&
        ticketBoardRef.current.requestFullscreen();
    } else {
      document.exitFullscreen && document.exitFullscreen();
    }
  };

  useEffect(() => {
    const escFullScreenListener = () => {
      const isFullScreen: boolean = !!document.fullscreenElement;
      setFullScreen(isFullScreen);
    };
    document.addEventListener('fullscreenchange', escFullScreenListener);
    return () =>
      document.removeEventListener('fullscreenchange', escFullScreenListener);
  }, [fullScreen]);

  const searchValues: Record<string, string> = {};
  for (var pair of searchEntries) {
    searchValues[pair[0]] = pair[1];
  }

  // TODO: Testen
  const [filter, setFilter] = useState<FilterParams>({
    currentPage: searchValues?.currentPage
      ? +searchValues?.currentPage
      : INITIAL_CURRENT_PAGE,
    pageSize: searchValues?.pageSize
      ? +searchValues?.pageSize
      : DEFAULT_PAGE_SIZE,
    title: searchValues.title ?? '',
    priority: searchValues.priority ?? '',
    status: undefined,
    assigned_user_id: searchValues.assigned_user_id ?? '',
    address_id: searchValues.address_id ?? '',
    apartment_id: searchValues.apartment_id ?? '',
    house_owner: searchValues.house_owner ?? '',
    created_at_start: isDateValid(searchValues.created_at_start)
      ? new Date(searchValues.created_at_start)
      : null,
    created_at_end: isDateValid(searchValues.created_at_end)
      ? new Date(searchValues.created_at_end)
      : null,
    order_by: searchValues.order_by ?? 'createdAt',
    order:
      searchValues.order === 'asc' || searchValues.order === 'desc'
        ? searchValues.order
        : 'desc',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState(false);
  const [tickets, setTickets] = useState<TicketBoardState>({
    open: [],
    'in-progress': [],
    done: [],
    closed: [],
  });
  // Meta information for ticket fetch (pagination)
  const metaInformation = {
    current_page: 0,
    from: 0,
    last_page: 0,
    per_page: 0,
    to: 0,
    total: 0,
  };
  const [ticketsMeta, setTicketsMeta] = useState<TicketBoardMetaState>({
    open: { ...metaInformation },
    'in-progress': { ...metaInformation },
    done: { ...metaInformation },
    closed: { ...metaInformation },
  });

  const fetchTicketList = useRef(() => {});

  const ticketApi = new TicketResource();

  const loggedInUser = useLoggedInUser();

  const fetchTicketListByStatus = async (
    status: TicketStatus,
    page: number = 1
  ): Promise<[TicketInfoSchema[], MetaSchema]> => {
    let query: Record<string, unknown> = { ...filter };

    query.status = status;
    query.page = page;
    query.limit = DEFAULT_PAGE_SIZE + 3;
    const queryData = cleanData(query);

    const response = await ticketApi.list(queryData);
    return [response.data.data, response.data.meta];
  };

  const toast = useToast();

  const toastIdRef = React.useRef<ToastId>();

  /**
   * Get Ticket List
   * Status = open , in-progress, done, closed
   */
  fetchTicketList.current = async () => {
    try {
      setIsLoading(true);
      const [openTicketList, openTicketMeta] = await fetchTicketListByStatus(
        'open'
      );
      const [inProgressTicketList, inProgressTicketMeta] =
        await fetchTicketListByStatus('in-progress');
      const [doneTicketList, doneTicketMeta] = await fetchTicketListByStatus(
        'done'
      );
      const [closedTicketList, closedTicketMeta] =
        await fetchTicketListByStatus('closed');
      setTickets({
        open: openTicketList,
        'in-progress': inProgressTicketList,
        done: doneTicketList,
        closed: closedTicketList,
      });
      setTicketsMeta({
        open: openTicketMeta,
        'in-progress': inProgressTicketMeta,
        done: doneTicketMeta,
        closed: closedTicketMeta,
      });
      setInitialRender(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      }
      if (toastIdRef.current) {
        toast.update(toastIdRef.current, {
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      } else {
        toastIdRef.current = toast({
          title: strings.error,
          status: 'error',
          isClosable: true,
        });
      }

      if ((err as AxiosError).response !== undefined) {
        //if error is isn't cancelled by user
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetch more tickets for a particular ticket status
   *
   * @param ticketStatus 'open' | 'in-progress' | 'done' | 'closed'
   */
  const handleLoadMore = async (ticketStatus: TicketStatus) => {
    if (!ticketStatus) return;
    if (!ticketsMeta) return;
    let currentPage = ticketsMeta[ticketStatus]?.current_page ?? 0;
    const [ticketList, ticketMeta] = await fetchTicketListByStatus(
      ticketStatus,
      ++currentPage
    );
    setTickets((prevState) => ({
      ...prevState,
      [ticketStatus]: [...prevState[ticketStatus], ...ticketList],
    }));
    setTicketsMeta((prevState) => ({
      ...prevState,
      [ticketStatus]: ticketMeta,
    }));
  };

  /**
   * Update total of ticket categories
   *
   * If user picks ticket from `Done` to `Closed`,
   * increase the total of closed by 1 and
   * decrease the total of done by 1
   *
   * @param source sourceId
   * @param destination destinationId
   */
  const updateTotalTickets = (
    source: TicketStatus,
    destination: TicketStatus
  ) => {
    setTicketsMeta((prevState) => ({
      ...prevState,
      [source]: {
        ...prevState[source],
        total: --prevState[source].total,
      },
      [destination]: {
        ...prevState[destination],
        total: ++prevState[destination].total,
      },
    }));
  };

  //  Auto Fetch after 90 second
  useEffect(() => {
    const autoreload = setInterval(() => {
      fetchTicketList.current();
    }, DEFAULT_REFETCH_TIME);
    return () => {
      clearInterval(autoreload);
    };
  }, []);

  const debouncedTitle = useDebounce(filter?.title ?? '');

  useEffect(() => {
    fetchTicketList.current();
  }, [
    debouncedTitle,
    filter.priority,
    filter.assigned_user_id,
    filter.apartment_id,
    filter.address_id,
    filter.house_owner,
    filter.created_at_start,
    filter.created_at_end,
    filter.house_owner,
    filter.order_by,
    filter.order,
  ]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) return;

    setTickets(
      reorderTickets(
        tickets,
        source,
        destination,
        loggedInUser.id,
        updateTotalTickets
      )
    );
  };
  const handleAdvancedSearch = useCallback(
    (data: FilterParamsFromForm) => {
      const filterParamsForURL: FilterParamsForURL = {
        ...data,
        currentPage: INITIAL_CURRENT_PAGE,
        pageSize: filter.pageSize,
        created_at_start: null,
        created_at_end: null,
      };

      if (data.created_at_start) {
        filterParamsForURL.created_at_start = DateFormatYMD(
          data.created_at_start
        );
      }
      if (data.created_at_end) {
        filterParamsForURL.created_at_end = DateFormatYMD(data.created_at_end);
      }

      setFilter((prevState) => ({
        ...prevState,
        title: data.title,
        priority: data.priority,
        assigned_user_id: data.assigned_user_id,
        address_id: data.address_id,
        apartment_id: data.apartment_id,
        house_owner: data.house_owner,
        created_at_start: data.created_at_start,
        created_at_end: data.created_at_end,
        order_by: data.order_by,
        order: data.order,
      }));
      const searchURL = changeURL(filterParamsForURL);
      history.push(`?${searchURL}`);
    },
    [history]
  );

  return (
    <>
      <Helmet>
        <title>
          {strings.ticket} | {strings.ticket_board}
        </title>
      </Helmet>

      <Stack direction="column" spacing="4">
        <Breadcrumb color="gray.400" size="4">
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={routes.ticket.task.board}>
              {strings.ticket}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage color="gray.900">
            <BreadcrumbLink>{strings.ticket_board}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Flex justify="space-between">
          <Heading
            size="lg"
            textTransform="capitalize"
            display="flex"
            alignItems="center">
            {strings.ticket_board}
            {isLoading && !initialRender && (
              <Center ml={5}>
                <Spinner color="gray.300" size="sm" />
              </Center>
            )}
          </Heading>
          <Stack direction="row">
            <TicketAddMenu path={routes.ticket.task.create} />
            <Tooltip hasArrow label={strings.full_screen}>
              <Button
                size="lg"
                border="1px solid #EBECF2"
                backgroundColor="gray.50"
                color="paragraph"
                type="button"
                padding="8px"
                onClick={ticketBoardFullScreenHandler}>
                <Icon as={BsArrowsFullscreen} w={6} h={6} />
              </Button>
            </Tooltip>
          </Stack>
        </Flex>

        <Accordion
          bg="white"
          borderColor="white"
          allowToggle
          boxShadow="box"
          defaultIndex={0}>
          <AccordionItem>
            <h2>
              <AccordionButton p="4">
                <Box flex="1" textAlign="left">
                  <Flex justify="space-between">
                    <Heading fontSize="18px" fontWeight="medium">
                      <Icon as={BiFilter} /> {strings.filter}
                    </Heading>
                  </Flex>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel padding="0">
              <TicketSearch
                isStatus={false}
                handleAdvancedSearch={handleAdvancedSearch}
                filter={filter}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        {isError && (
          <Alert status="error">
            <AlertIcon />
            {strings.ticket_list_error}
          </Alert>
        )}

        <Stack
          direction="row"
          spacing="4"
          width="100%"
          overflow="auto hidden"
          marginBottom="50px !important"
          backgroundColor="gray.50"
          position="relative"
          ref={ticketBoardRef}>
          <DragDropContext onDragEnd={onDragEnd}>
            {(Object.entries(tickets) as Entries<typeof tickets>).map(
              ([key, value], index) => (
                <Column
                  key={uuidv4()}
                  ticketStatus={key}
                  ticketList={value}
                  ticketMeta={ticketsMeta[key]}
                  handleLoadMore={handleLoadMore}
                  isLoading={isLoading}
                  initialRender={initialRender}
                />
              )
            )}
          </DragDropContext>
          {fullScreen && (
            <>
              <Button
                size="md"
                colorScheme="primary"
                type="button"
                position="fixed"
                bottom="10px"
                right="10px"
                padding="8px"
                onClick={ticketBoardFullScreenHandler}>
                <Image
                  src={ExitFullScreen}
                  alt="Exit Fullscreen"
                  w="16px"
                  h="16px"
                  filter="invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%)"
                />
              </Button>
              {isLoading && (
                <Box
                  w="100%"
                  backgroundColor="transparent"
                  position="fixed"
                  top="0px"
                  marginInlineStart="0px !important">
                  <Center
                    width="max-content"
                    margin="auto"
                    borderRadius="50%"
                    backgroundColor="gray.50"
                    padding="10px">
                    <Spinner color="gray.300" size="lg" />
                  </Center>
                </Box>
              )}
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default TicketBoard;
