import { Select, SelectProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

const CustomChakraSelect = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const formContext = useFormContext<FieldValues>();
    const { value, name, defaultValue } = props;

    const finalValue =
      value ?? formContext?.watch(name ?? '') ?? defaultValue ?? '';

    return (
      <Select
        iconSize="20"
        iconColor="heading"
        {...props}
        {...(finalValue === '' && {
          color: 'gray.400',
        })}
        ref={ref}>
        {props.children}
      </Select>
    );
  }
);

CustomChakraSelect.displayName = 'CustomChakraSelect';

export default CustomChakraSelect;
