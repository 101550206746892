import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Owner, OwnershipDTO } from 'constants/schema';
import { useForm } from 'react-hook-form';
import { strings } from '../../config/localization';
import OwnershipForm, { OwnershipFormData } from './OwnershipForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: OwnershipDTO) => void;
  ownersList: Owner[];
  ownershipDTO?: OwnershipDTO;
  apartmentOwnerIds: number[];
}

export default function OwnershipModal(props: Props) {
  const {
    isOpen,
    ownershipDTO,
    onClose,
    onSubmit,
    ownersList,
    apartmentOwnerIds,
  } = props;

  const form = useForm<OwnershipFormData>();
  const { handleSubmit } = form;

  const submitOwnership = (formData: OwnershipFormData) => {
    const owner_id = (
      ownersList.find((o) => o.id === formData.owner.value) as Owner
    ).id;

    const newOwnershipDTO: OwnershipDTO =
      formData.is_current === 'true'
        ? {
            is_current: true,
            owner_id,
            owner_since: formData.owner_since,
          }
        : {
            is_current: false,
            owner_id,
          };

    onSubmit(newOwnershipDTO);
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {ownershipDTO ? strings.edit_owner : strings.add_new_owner}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <OwnershipForm
            form={form}
            defaultOwnership={ownershipDTO}
            ownersList={ownersList}
            apartmentOwnerIds={apartmentOwnerIds}
          />
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={onClose}>
              {strings.back}
            </Button>
            <Button
              colorScheme="primary"
              onClick={handleSubmit(submitOwnership)}>
              {ownershipDTO ? strings.save : strings.add}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
