import { useToast } from '@chakra-ui/react';
import { strings } from 'config/localization';
import { UserPermission } from 'constants/interfaces';
import routes from 'constants/routes';
import { AllowedPermissions, AuthState } from 'constants/schema';
import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { checkSomePermissions } from 'utils/listInfo';

const settings = process.env.REACT_APP_CONFIGURATION;
interface Props {
  serviceName?: string;
  component: React.FC<RouteComponentProps>;
  userPermissions: UserPermission;
  allowedPermissions: AllowedPermissions;
  path: string | string[];
  exact?: boolean;
}

const RoleBasedRouting: React.FC<Props> = ({
  serviceName,
  userPermissions,
  allowedPermissions,
  component: Component,
  path,
  exact = false,
}) => {
  const history = useHistory();
  const toast = useToast();
  // settings check to show/hide services(based on .env)
  if (settings === 'true') {
    switch (serviceName) {
      case 'user-service':
        if (process.env.REACT_APP_USER_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'ticket-service':
        if (process.env.REACT_APP_TICKET_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'dashboard':
        if (process.env.REACT_APP_DASHBOARD === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'oms-service':
        if (process.env.REACT_APP_OMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
      case 'documents':
        if (process.env.REACT_APP_DMS_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;

      case 'newsletter-service':
        if (process.env.REACT_APP_NEWSLETTER_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;

      case 'faq-list':
      case 'group-faq':
        if (process.env.REACT_APP_FAQ_SERVICE === 'false') {
          return <Redirect to={routes.dashboard} />;
        }
        break;
    }
  }

  const permitted = checkSomePermissions(userPermissions, allowedPermissions);

  const handlingNoAccess = () => {
    toast({
      title: strings.no_permission_msg,
      status: 'error',
      isClosable: true,
    });
    history.goBack();
    return null;
  };

  return (
    <>
      <Route
        exact={exact}
        path={path}
        render={(props: RouteComponentProps) =>
          permitted ? <Component {...props} /> : handlingNoAccess()
        }
      />
    </>
  );
};

export default connect((state: { data: { auth: AuthState } }) => ({
  userPermissions: state?.data?.auth?.permissions,
}))(RoleBasedRouting);
